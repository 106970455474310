import React from "react";
import {DateTime, IANAZone, Interval} from "luxon";
import PropTypes from "prop-types";
import {
  CartesianGrid,
  ComposedChart,
  Label,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip as ChartTooltip,
  XAxis,
} from "recharts";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import InboxEntityContext from "../../contexts/InboxEntityContext.jsx";
import ZoomContext from "../../contexts/ZoomContext.jsx";
import DateAndTime, {formatDateAndTime} from "../DateAndTime/DateAndTime.jsx";

function OffsetLabel({value, viewBox}) {
  return (
    <text fill="rgb(118, 133, 140)" fontSize={12} offset={5} x={viewBox.x + 17} y={15} textAnchor="left">
      <tspan x={viewBox.x} dy="0em">
        {value}
      </tspan>
    </text>
  );
}
OffsetLabel.propTypes = {
  value: PropTypes.string,
  viewBox: PropTypes.object,
};

function TimelineChart({
  // Props
  startTime,
  endTime,
  data,
  tooltipFormatter,
  reportType,
  study,
  children,
}) {
  //---------------------------------------------------------------------------
  // Determine if this component is in a generated report so that we can disable
  // zoom and tooltips
  //---------------------------------------------------------------------------
  const {type: parentType = "inbox-item"} = React.useContext(InboxEntityContext) || {};
  const isGeneratedReport = React.useMemo(() => parentType === "generated-report", [parentType]);

  const isDailyTrend = React.useMemo(() => reportType === "Daily Trend", [reportType]);

  //---------------------------------------------------------------------------
  // Configure the axis tick marks
  //---------------------------------------------------------------------------
  const ticks = React.useMemo(() => {
    const tickArray = Interval.fromDateTimes(startTime, endTime)
      .splitBy({hours: isDailyTrend ? 1 : 4})
      .map((i) => i.start.startOf("hour").toMillis())
      .filter((i) => i > startTime.toMillis());

    tickArray.push(endTime.startOf("hour").toMillis());

    return tickArray;
  }, [startTime, endTime, isDailyTrend]);

  const days = React.useMemo(() => {
    return Interval.fromDateTimes(startTime, endTime)
      .splitBy({days: 1})
      .map((i) => (isDailyTrend ? i.end.startOf("day") : i.start));
  }, [startTime, endTime, isDailyTrend]);

  //---------------------------------------------------------------------------
  // Configure tooltip formatting
  //---------------------------------------------------------------------------
  const timeFormatter = React.useCallback(
    (unixTime) => {
      const timeZone = IANAZone.isValidZone(study.timeZone) ? study.timeZone : DateTime.local().toFormat("z");
      const isEveryFourthTick = DateTime.fromMillis(unixTime).setZone(timeZone).hour % 4 === 0;

      return isDailyTrend && isEveryFourthTick
        ? formatDateAndTime({datetime: unixTime, zone: study.timeZone, format: "HH:mm"})
        : "";
    },
    [isDailyTrend, study.timeZone]
  );
  const tooltipLabelFormatter = React.useCallback(
    (label) => {
      if (typeof label === "number") {
        return <DateAndTime datetime={label} zone={study.timeZone} />;
      }
      return label;
    },
    [study.timeZone]
  );

  //---------------------------------------------------------------------------
  // Layout formatting
  //---------------------------------------------------------------------------
  const layout = React.useMemo(() => (data.some((point) => point.label) ? "vertical" : "horizontal"), [data]);

  //---------------------------------------------------------------------------
  // Zoom state management
  //---------------------------------------------------------------------------
  const {zoomedIn = false, setZoomedIn = () => {}} = React.useContext(ZoomContext) || {};
  const [left, setLeft] = React.useState(startTime.toMillis());
  const [right, setRight] = React.useState(endTime.toMillis());
  const [refAreaLeft, setRefAreaLeft] = React.useState("");
  const [refAreaRight, setRefAreaRight] = React.useState("");

  //---------------------------------------------------------------------------
  // Zoom event callbacks
  //---------------------------------------------------------------------------
  const handleOnMouseDown = React.useCallback((e) => setRefAreaLeft(e?.activeLabel), []);
  const handleOnMouseMove = React.useCallback((e) => setRefAreaRight(e?.activeLabel), []);

  const zoom = React.useCallback(() => {
    if (refAreaLeft === refAreaRight || refAreaRight === "") {
      setRefAreaLeft("");
      setRefAreaRight("");
      return;
    }

    setZoomedIn(true);

    if (refAreaLeft > refAreaRight) {
      setLeft(refAreaRight);
      setRight(refAreaLeft);
    } else {
      setLeft(refAreaLeft);
      setRight(refAreaRight);
    }

    setRefAreaLeft("");
    setRefAreaRight("");
  }, [refAreaLeft, refAreaRight, setZoomedIn]);

  // If the Zoom Out button has been clicked (in the parent component), reset the graph
  React.useEffect(() => {
    if (!zoomedIn) {
      setRefAreaLeft("");
      setRefAreaRight("");
      setLeft(startTime.toMillis());
      setRight(endTime.toMillis());
    }
  }, [endTime, startTime, zoomedIn]);

  return (
    <ResponsiveContainer width="100%" height={250} style={{margin: "auto"}}>
      <ComposedChart
        data={data}
        margin={{top: 20, right: 16}}
        layout={layout}
        onMouseDown={!isGeneratedReport && handleOnMouseDown}
        onMouseMove={!isGeneratedReport && handleOnMouseMove}
        onMouseUp={!isGeneratedReport && zoom}
      >
        <CartesianGrid />
        <XAxis
          dataKey="mt"
          type="number"
          scale="time"
          domain={[left, right]}
          ticks={ticks}
          interval={0}
          allowDataOverflow
          tick={{fontSize: 10}}
          tickFormatter={timeFormatter}
          tickLine={false}
          tickMargin={0}
        />

        {children}

        {days.map((day) => (
          <ReferenceLine
            key={day.toMillis()}
            yAxisId={children?.[0]?.props?.yAxisId || children?.[0]?.[0]?.props?.yAxisId}
            x={day.toMillis()}
            stroke="rgb(118, 133, 140)"
            strokeWidth={2}
            isFront={false}
          >
            <Label
              value={formatDateAndTime({datetime: day, zone: study.timeZone, format: "LLL dd"})}
              position="top"
              fill="rgb(118, 133, 140)"
              fontSize={14}
              content={OffsetLabel}
            />
          </ReferenceLine>
        ))}

        {!isGeneratedReport && !!tooltipFormatter && (
          <ChartTooltip
            offset={20}
            contentStyle={{borderRadius: 4, fontSize: 14}}
            labelFormatter={tooltipLabelFormatter}
            isAnimationActive={false}
            separator=": "
            formatter={tooltipFormatter}
          />
        )}

        {refAreaLeft && refAreaRight ? (
          <ReferenceArea
            x1={refAreaLeft}
            x2={refAreaRight}
            strokeOpacity={0.3}
            yAxisId={children?.[0]?.props?.yAxisId || children?.[0]?.[0]?.props?.yAxisId}
          />
        ) : null}
      </ComposedChart>
    </ResponsiveContainer>
  );
}

TimelineChart.propTypes = {
  startTime: PropTypes.object.isRequired,
  endTime: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  tooltipFormatter: PropTypes.func,
  reportType: PropTypes.string,
  study: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default TimelineChart;
