/* eslint-env browser */
import React from "react";
import {useForm, useFormState} from "react-hook-form";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import AssignmentInd from "@mui/icons-material/AssignmentInd";
import Close from "@mui/icons-material/Close";
import PersonAdd from "@mui/icons-material/PersonAdd";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../../axiosClient.js";
import Alert from "../../../shared/react/Alert.jsx";
import AutocompleteInput from "../../../shared/react/AutocompleteInput.jsx";
import CancelButton from "../../../shared/react/CancelButton.jsx";
import IconWithText from "../../../shared/react/IconWithText.jsx";
import TableLoading from "../../../shared/react/TableLoading.jsx";

function AssignStudyDialog({
  // Props
  study,
  variant = "studies",
  handleConfirm = () => {},
  handleCancel = () => {},
}) {
  //---------------------------------------------------------------------------
  // Form and button loading state management
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);

  //---------------------------------------------------------------------------
  // API state management
  //---------------------------------------------------------------------------
  const [users, setUsers] = React.useState([]);
  const [assignedUsers, setAssignedUsers] = React.useState([]);

  //---------------------------------------------------------------------------
  // Modal state management
  //---------------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);
  const handleOpen = React.useCallback(() => {
    setOpen(true);
    setLoading(true);
  }, []);
  const handleClose = React.useCallback(() => {
    handleCancel();
    setOpen(false);
  }, [handleCancel]);

  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Helper Functions
  //---------------------------------------------------------------------------
  const selectedUsersDefaultValue = React.useMemo(() => {
    return users.filter((user) => assignedUsers.includes(user.id));
  }, [users, assignedUsers]);

  //---------------------------------------------------------------------------
  // Load data from the API
  //---------------------------------------------------------------------------
  const getUsers = React.useCallback(async () => {
    // Get all ancestor facility IDs
    const splitFacilityIds = study.facilityId.split("_");
    const facilityIds = splitFacilityIds.map((id, i) => splitFacilityIds.slice(0, i + 1).join("_"));

    try {
      const [{data: usersResponse}, {data: assignedUsersResponse}] = await Promise.all([
        axios({
          method: "get",
          url: "/users",
          params: {
            state: {$notIn: ["pending", "disabled"]},
            role: "physician",
            facilityId: {$or: facilityIds},
          },
        }),
        axios({
          method: "get",
          url: `/studyAssignments/${study.id}`,
          validateStatus: (status) => status === 200 || status === 404,
        }),
      ]);

      setUsers(usersResponse);

      if (
        assignedUsersResponse?.title === "No study assignments found" &&
        assignedUsersResponse?.httpStatus === 404
      ) {
        setAssignedUsers([]);
      } else {
        setAssignedUsers(assignedUsersResponse.users.map((user) => user.userId));
      }
    } catch (err) {
      setError(err.message);
    }
  }, [study]);

  React.useEffect(() => {
    if (loading) {
      getUsers().then(() => setLoading(false));
    }
  }, [loading, getUsers]);

  React.useEffect(() => {
    if (variant === "reports" && !open && !loading) {
      handleOpen();
    }
  }, [handleOpen, loading, open, variant]);

  //---------------------------------------------------------------------------
  // Form Submission
  //---------------------------------------------------------------------------
  const {handleSubmit, control} = useForm();
  const {isDirty} = useFormState({control});

  const onSubmit = React.useCallback(
    async (data) => {
      // Disable the submit button and display the spinner
      setSubmitting(true);

      // If an assigned user is no longer selected, we need to remove their assignment
      const removedUserIds = assignedUsers
        .filter((assignedUserId) =>
          data.selectedUsers.every((selectedUser) => selectedUser.id !== assignedUserId)
        )
        .map((id) => ({userId: id}));

      // We only want to create assignments for users that did not previously have an assignment
      const addedUserIds = data.selectedUsers
        .filter((selectedUser) => !assignedUsers.includes(selectedUser.id))
        .map((user) => ({userId: user.id}));

      try {
        if (removedUserIds.length > 0) {
          await axios({
            method: "delete",
            url: `/studyAssignments/${study.id}`,
            data: {
              facilityId: study.facilityId,
              studyId: study.id,
              users: removedUserIds,
            },
          });
        }
        if (addedUserIds.length > 0) {
          await axios({
            method: "post",
            url: `/studyAssignments/${study.id}`,
            data: {
              facilityId: study.facilityId,
              studyId: study.id,
              users: addedUserIds,
            },
          });
        }

        // Finally, close the modal
        setSubmitting(false);
        setOpen(false);
        handleConfirm();
      } catch (err) {
        setError(err.message);
        setSubmitting(false);
      }
    },
    [assignedUsers, handleConfirm, study.facilityId, study.id]
  );

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <>
      {variant === "studies" && (
        <MenuItem onClick={handleOpen} data-cy={`assign-study-button-${study.id}`}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          <ListItemText>Manage Reading Physicians</ListItemText>
        </MenuItem>
      )}

      <Dialog
        open={open}
        maxWidth="xs"
        fullWidth
        data-cy="assign-study-dialog"
        PaperProps={{
          component: "form",
          onSubmit: handleSubmit(onSubmit),
          noValidate: true,
        }}
      >
        <Alert message={error} setMessage={setError} level="error" />

        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <IconWithText icon={<AssignmentInd color="secondary" />} text="Assign Study" />

          <CancelButton color="secondary" isDirty={isDirty} onClick={handleClose} data-cy="cancel-button">
            <Close size="small" />
          </CancelButton>
        </DialogTitle>

        <Divider sx={{bgcolor: "secondary.main"}} />

        <DialogContent>
          {loading && <TableLoading />}
          {!loading && (
            <>
              {variant === "reports" && (
                <Box sx={{mb: 2}}>
                  <Alert
                    message="This study is not currently being monitored by a reading physician. Please assign a reading physician to this study to review submitted reports."
                    level="warning"
                  />
                </Box>
              )}

              {users.length === 0 && (
                <Box sx={{mb: 2}}>
                  <Alert message="There are no physicians to assign to this study." level="warning" />
                </Box>
              )}

              <AutocompleteInput
                name="selectedUsers"
                control={control}
                defaultValue={selectedUsersDefaultValue}
                data-cy="assign-study-select"
                options={users}
                disabled={submitting || loading}
                getOptionLabel={(option) => option.fullName}
                label="Select a Reading Physician"
                otherProps={{disableCloseOnSelect: false}}
              />
            </>
          )}
        </DialogContent>

        <DialogActions>
          {variant === "reports" && (
            <Box sx={{m: 2}}>
              <Button
                data-cy="continue-button"
                disabled={loading || submitting}
                color="secondary"
                onClick={handleConfirm}
              >
                Continue without assigning
              </Button>
            </Box>
          )}

          <Box sx={{m: 2}}>
            <LoadingButton
              data-cy="submit-button"
              disabled={loading || submitting}
              variant="contained"
              color="secondary"
              loading={submitting}
              type="submit"
            >
              Submit
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}
AssignStudyDialog.propTypes = {
  study: PropTypes.object.isRequired,
  variant: PropTypes.string,
  handleConfirm: PropTypes.func,
  handleCancel: PropTypes.func,
};
export default AssignStudyDialog;
