import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useLocalStorage} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import useArrayReducer from "../components/hooks/useArrayReducer.jsx";
import useJwt from "../components/hooks/useJwt.jsx";

const ReportsContext = React.createContext(null);
const ReportsDispatchContext = React.createContext(null);
const ReportStatusFilterContext = React.createContext(null);

export function ReportsProvider({children}) {
  const {username, isInAnyRole} = useJwt();

  const defaultFilter = React.useMemo(
    () => ({
      ...(!isInAnyRole(["physician", "clinicalStaff"]) && {generated: true}),
      submitted: true,
      published: true,
      signed: !isInAnyRole(["physician"]),
      printed: !isInAnyRole(["physician"]),
      ...(!isInAnyRole(["physician", "clinicalStaff"]) && {rejectedByTech: false}),
      rejectedByPhysician: !isInAnyRole(["physician", "clinicalStaff"]),
    }),
    [isInAnyRole]
  );

  const [statusFilter, setStatusFilter] = useLocalStorage(`${username}.reports.filter`, defaultFilter);

  const statusFilterState = React.useMemo(() => {
    return {statusFilter, setStatusFilter};
  }, [statusFilter, setStatusFilter]);

  const reducer = useArrayReducer([{filter: statusFilter, filterBy: "state"}]);
  const [reports, dispatch] = React.useReducer(reducer, []);

  return (
    <ReportStatusFilterContext.Provider value={statusFilterState}>
      <ReportsContext.Provider value={reports}>
        <ReportsDispatchContext.Provider value={dispatch}>{children}</ReportsDispatchContext.Provider>
      </ReportsContext.Provider>
    </ReportStatusFilterContext.Provider>
  );
}
ReportsProvider.propTypes = {
  children: PropTypes.node,
};

export function useReports() {
  return React.useContext(ReportsContext);
}

export function useReportsDispatch() {
  return React.useContext(ReportsDispatchContext);
}

export function useReportsStatusFilter() {
  return React.useContext(ReportStatusFilterContext);
}
