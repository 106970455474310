import angular from "angular";

import {angularizeDirective} from "../../shared/react-in-angular.js";
import GeneratedReportPage from "./GeneratedReportPageWrapper.jsx";

export default angular.module("app.components.generatedReportPage", []).name;

// matches <inbox-generated-report-page>
angularizeDirective(
  GeneratedReportPage,
  "inboxGeneratedReportPage",
  angular.module("app.components.generatedReportPage"),
  {id: "<", actions: "<", saved: "<"}
);
