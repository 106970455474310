import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useLocalStorage} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import useArrayReducer from "../components/hooks/useArrayReducer.jsx";
import useJwt from "../components/hooks/useJwt.jsx";

const StudiesContext = React.createContext(null);
const StudiesDispatchContext = React.createContext(null);
const StudiesStatusFilterContext = React.createContext(null);
const StudiesTypeFilterContext = React.createContext(null);

export function StudiesProvider({children}) {
  const {username} = useJwt();

  const [statusFilter, setStatusFilter] = useLocalStorage(`${username}.studies.filter`, {
    pending: true,
    recording: true,
    done: true,
    finalized: true,
    archived: false,
    failed: false,
  });
  const [typeFilter, setTypeFilter] = useLocalStorage(`${username}.studies.types.filter`, {
    mct: true,
    mctWithFullDisclosure: true,
    cem: true,
    holter: true,
    extendedHolter: true,
    wirelessHolter: true,
    wirelessExtendedHolter: true,
    cardiacRehab: true,
  });

  const statusFilterState = React.useMemo(() => {
    return {statusFilter, setStatusFilter};
  }, [statusFilter, setStatusFilter]);

  const typeFilterState = React.useMemo(() => {
    return {typeFilter, setTypeFilter};
  }, [typeFilter, setTypeFilter]);

  // status filter is special and needs to be coerced to match the field on the object "studyStatus"
  const coercedFilter = React.useMemo(() => {
    const filter = {};
    Object.keys(statusFilter).forEach((status) => {
      let updatedStatus = `${status[0].toUpperCase()}${status.slice(1)}`;
      if (status === "done") {
        updatedStatus = "Done Recording";
      }

      filter[updatedStatus] = statusFilter[status];
    });

    return filter;
  }, [statusFilter]);

  const filters = React.useMemo(() => {
    return [
      {filter: coercedFilter, filterBy: "studyStatus"},
      {filter: typeFilter, filterBy: "studyType"},
    ];
  }, [coercedFilter, typeFilter]);

  const reducer = useArrayReducer(filters);
  const [studies, dispatch] = React.useReducer(reducer, []);

  return (
    <StudiesStatusFilterContext.Provider value={statusFilterState}>
      <StudiesTypeFilterContext.Provider value={typeFilterState}>
        <StudiesContext.Provider value={studies}>
          <StudiesDispatchContext.Provider value={dispatch}>{children}</StudiesDispatchContext.Provider>
        </StudiesContext.Provider>
      </StudiesTypeFilterContext.Provider>
    </StudiesStatusFilterContext.Provider>
  );
}
StudiesProvider.propTypes = {
  children: PropTypes.node,
};

export function useStudies() {
  return React.useContext(StudiesContext);
}

export function useStudiesDispatch() {
  return React.useContext(StudiesDispatchContext);
}

export function useStudiesStatusFilter() {
  return React.useContext(StudiesStatusFilterContext);
}

export function useStudiesTypeFilter() {
  return React.useContext(StudiesTypeFilterContext);
}
