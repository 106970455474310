import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import InfoOutlined from "@mui/icons-material/InfoOutlined";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import {useTheme} from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";

function InfoTooltip({style, placement, children, otherProps}) {
  const theme = useTheme();

  const tooltipStyle = {
    ...style,
    marginLeft: "5px",
    fontSize: "1.125rem",
    cursor: "pointer",
  };

  tooltipStyle.color = tooltipStyle.color || theme.palette.tertiary.light;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Tooltip arrow disableInteractive title={children} placement={placement} {...otherProps}>
      <InfoOutlined style={tooltipStyle} />
    </Tooltip>
  );
}

InfoTooltip.propTypes = {
  style: PropTypes.object,
  placement: PropTypes.string,
  children: PropTypes.node.isRequired,
  otherProps: PropTypes.object,
};

export default InfoTooltip;
