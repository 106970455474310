/* @ngInject */
export default class Drawing {
  constructor(Config) {
    this.features = Config.features;
  }

  clearCanvas(canvas) {
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.clientWidth, canvas.clientHeight);
  }

  /**
   * Draws a line
   *
   * @param  {HTMLElement} canvas
   * @param  {object}  line
   * @param  {integer} line.startX
   * @param  {integer} line.startY
   * @param  {integer} line.endX
   * @param  {integer} line.endY
   * @param  {string}  line.color [color="black"]
   * @param  {integer} line.width [width=1]        Line width in pixels
   */
  drawLine(canvas, line) {
    const ctx = canvas.getContext("2d");
    const {startX, startY, endX, endY, color, width} = line;

    ctx.strokeStyle = color || "black";
    ctx.lineWidth = width || 1;

    ctx.beginPath();
    ctx.moveTo(startX, startY);
    ctx.lineTo(endX, endY);
    ctx.closePath();
    ctx.stroke();
  }

  /*
   * Draws a polygon.
   *
   * fillStyle and/or strokeStyle must be populated in order to display a polygon.
   * Examples: "black", "#ff00ff", "rgb(255, 255, 0)"
   *
   * @param {HTMLElement} canvas
   * @param {object} polygon
   * @param {array<object>} polygon.vertices {x, y} coordinates
   * @param {fillStyle} polygon.fillStyle
   * @param {strokeStyle} polygon.strokeStyle
   * @param {integer} polygon.lineWidth
   */
  drawPolygon(canvas, polygon) {
    const ctx = canvas.getContext("2d");
    const {vertices, strokeStyle, fillStyle, lineWidth} = polygon;

    ctx.strokeStyle = strokeStyle;
    ctx.fillStyle = fillStyle;
    ctx.lineWidth = lineWidth || 1;

    ctx.beginPath();

    if (Array.isArray(vertices) && vertices.length > 0) {
      ctx.moveTo(vertices[0].x, vertices[0].y);

      for (let i = 1; i < vertices.length; i++) {
        ctx.lineTo(vertices[i].x, vertices[i].y);
      }
    }

    ctx.closePath();
    if (fillStyle) {
      ctx.fill();
    }
    if (strokeStyle) {
      ctx.stroke();
    }
  }

  /**
   * Draws rectangle background and then text above the rectangle
   * @param  {HTMLElement} canvas
   * @param  {integer}     xCoord
   * @param  {integer}     yCoord
   * @param  {string}      text
   * @param  {object}      [displaySettings]
   * @param  {string}      displaySettings.color="black"
   * @param  {number}      displaySettings.fontSize=14
   * @param  {string}      displaySettings.fontFamily="Roboto"
   * @param  {boolean}     displaySettings.boxPadding=0 (0 is no box)
   * @param  {boolean}     displaySettings.textAlign="left"
   * @param  {array}       displaySettings.screenBounds=[0, 1500]
   *
   * @returns {Object}     bounding box of text
   */
  drawText(canvas, xCoord, yCoord, text, displaySettings) {
    let color;
    let font;
    let fontSize;
    let boxPadding;
    let textAlign;
    let screenBounds;
    if (!displaySettings) {
      color = "black";
      font = "14px Roboto sans-serif";
      fontSize = 14;
      boxPadding = 0;
      textAlign = "left";
      screenBounds = null;
    } else {
      color = displaySettings.color;
      font = `${displaySettings.fontSize}px ${displaySettings.fontFamily}`;
      if (displaySettings.fontWeight) {
        font = `${displaySettings.fontWeight} ${font}`;
      }
      fontSize = displaySettings.fontSize;
      boxPadding = displaySettings.boxPadding || 0;
      textAlign = displaySettings.textAlign || "left";
      screenBounds = displaySettings.screenBounds || null;
    }

    const ctx = canvas.getContext("2d");

    ctx.font = font;

    const textWidth = ctx.measureText(text).width;
    const boxWidth = Math.round(textWidth + 2 * boxPadding);
    let boxHeight = Math.round(fontSize + 2 * boxPadding);
    let boxX = xCoord - boxPadding;
    const boxY = yCoord - boxPadding;

    if (textAlign === "center") {
      boxX -= textWidth / 2;
    } else if (textAlign === "right") {
      boxX -= textWidth;
    }

    // If screenBounds are defined, ensure the text is on screen
    if (screenBounds) {
      let delta = 0;
      if (boxX < screenBounds[0]) {
        delta = screenBounds[0] - boxX;
      }
      if (boxX + textWidth > screenBounds[1]) {
        delta = screenBounds[1] - boxX - textWidth;
      }
      boxX += delta;
      xCoord += delta;
    }

    if (boxPadding !== 0) {
      // Set Bottom padding correctly depending on the text in the label
      boxHeight = this.calculateDisplayedHeight(text, fontSize) + 2 * boxPadding;

      ctx.fillStyle = "white";
      ctx.fillRect(boxX, boxY, boxWidth, boxHeight);
      ctx.lineJoin = "miter";
      ctx.strokeRect(boxX - 0.5, boxY - 0.5, boxWidth + 1, boxHeight + 1);
    }
    ctx.textBaseline = "top";
    ctx.fillStyle = color;
    ctx.textAlign = textAlign;
    ctx.fillText(text, xCoord, yCoord);
    return {
      x: boxX,
      y: boxY,
      w: boxWidth,
      h: boxHeight,
    };
  }

  /**
   * Draws a box
   *
   * @param      {HTMLElement}  canvas  The canvas
   * @param      {number}       startX  The start x
   * @param      {number}       endX    The end x
   */
  drawBox(canvas, startX, endX) {
    this.clearCanvas(canvas);
    const width = endX - startX;
    const height = canvas.clientHeight;
    const ctx = canvas.getContext("2d");

    startX += 0.5;
    ctx.fillStyle = "rgba(0, 0, 100, 0.2)";
    ctx.fillRect(startX, 0, width, height);
  }

  /**
   * Draws boxes for the given endpoints
   *
   * @param      {HTMLElement}  canvas  The canvas
   * @param      {Array}       endpoints  The array of start and end points for the different boxes
   */
  drawBoxes(canvas, endpoints) {
    this.clearCanvas(canvas);
    const height = canvas.clientHeight;
    const ctx = canvas.getContext("2d");
    const displaySettings = {
      fontSize: 12,
      fontFamily: "Roboto",
      color: "#004c8b", // Primary Dark
      fontWeight: "bold",
    };

    endpoints.forEach((endpoint) => {
      const width = endpoint.endX - endpoint.startX;
      if (width > 0) {
        endpoint.startX += 0.5;
        if (endpoint.isGeneratedReport || endpoint.editing) {
          // New Strip or Generated Report: use accent
          ctx.fillStyle = "rgba(66, 165, 245, 0.4)";
        } else {
          // Old Strip: use accentGreyLight
          ctx.fillStyle = "rgba(98, 114, 123, 0.4)";
        }

        ctx.fillRect(endpoint.startX, 0, width, height);
        if (endpoint.order) {
          this.drawText(canvas, endpoint.startX + 3, 2, endpoint.order, displaySettings);
        }

        // The selected strip gets a blue outline
        if (endpoint.isSelected) {
          const weight = 2;
          ctx.strokeStyle = "rgba(66, 165, 245)";
          ctx.lineWidth = weight;
          ctx.strokeRect(endpoint.startX + weight / 2, weight / 2, width - weight, height - weight);
        }
      }
    });
  }

  /**
   * Calculates the displayed hight of font
   * If the text does not contain [gjpqy], then the text will have less height
   *
   * @param {string} text
   * @param {number} fontSize in pixels
   * @returns {number}
   */
  calculateDisplayedHeight(text, fontSize) {
    let amountToSubtract = 0;
    if (text.match(/[gjpqy]/) === null) {
      amountToSubtract = Math.round(fontSize / 5);
    }
    return fontSize - amountToSubtract;
  }
}
