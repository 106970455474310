/* eslint-env browser */
import React from "react";
import {zxcvbnAsync, zxcvbnOptions} from "@zxcvbn-ts/core";
import {matcherPwnedFactory} from "@zxcvbn-ts/matcher-pwned";

const matcherPwned = matcherPwnedFactory(fetch, zxcvbnOptions);
zxcvbnOptions.addMatcher("pwned", matcherPwned);

const MIN_STRENGTH = 3;
const SEQUENTIAL_MATCH_LENGTH = 4;
const SEQUENTIAL_UP = "0123456789";
const SEQUENTIAL_DOWN = "9876543210";

const useValidatePasswordStrength = (setScore, setSuggestionList, banList = []) => {
  return React.useCallback(
    async (value) => {
      if (!value.length) {
        setScore(0);
        setSuggestionList([]);
        return "Please select a password for your BitRhythm account.";
      }
      if (value.length < 20) {
        setScore(10);
        setSuggestionList([]);
        return "Password must be at least 20 characters long.";
      }

      //---------------------------------------------------------------------------
      // Lazy-load the zxcvbn dictionaries ONLY when we need them
      //---------------------------------------------------------------------------
      const zxcvbnCommon = await import("@zxcvbn-ts/language-common");
      const zxcvbnEn = await import("@zxcvbn-ts/language-en");
      zxcvbnOptions.setOptions({
        dictionary: {
          ...zxcvbnCommon.dictionary,
          ...zxcvbnEn.dictionary,
        },
        graphs: zxcvbnCommon.adjacencyGraphs,
        translations: zxcvbnEn.translations,
      });

      //---------------------------------------------------------------------------
      // Since zxcvbn allows sequential numbers in long passwords (12345678910111213141), ensure the user
      // does not enter sequences of numbers up or down greater than 4 in a row. Since regular expressions
      // are poorly suited to this, try to map each 4 characters of the input into a sequential
      // list. If there is no match, it's passes. If it matches into the sequential string, it fails.
      //---------------------------------------------------------------------------
      let i;
      for (i = 0; i < value.length + 1 - SEQUENTIAL_MATCH_LENGTH; i += 1) {
        const chunk = value.substring(i, i + SEQUENTIAL_MATCH_LENGTH);
        // if it maps into an ascending or descending list, that's a lame password.
        if (SEQUENTIAL_UP.indexOf(chunk) !== -1 || SEQUENTIAL_DOWN.indexOf(chunk) !== -1) {
          setScore(10);
          setSuggestionList([]);
          return "Please avoid using sequential numbers in your password.";
        }
      }

      //---------------------------------------------------------------------------
      // We also want to follow NIST guidelines and ban certain words from being used
      // at all in created passwords.
      //---------------------------------------------------------------------------
      let banError;
      ["password", ...banList].forEach((bannedWord) => {
        if (bannedWord.length > 3) {
          if (value.toLowerCase().includes(bannedWord.toLowerCase())) {
            banError = `Please avoid using '${bannedWord}' in your password.`;
          }
        }
      });
      if (banError) {
        setScore(10);
        setSuggestionList([]);
        return banError;
      }

      const {score, feedback} = await zxcvbnAsync(value);
      setScore((100 * (score + 1)) / 5);
      setSuggestionList(feedback.suggestions);
      if (score < MIN_STRENGTH || feedback.warning) {
        return feedback.warning || "Please choose a stronger password";
      }
      return true;
    },
    [banList, setScore, setSuggestionList]
  );
};

export default useValidatePasswordStrength;
