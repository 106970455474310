import angular from "angular";

import "angular-socket-io/socket";

import DisplayChannelToggleController from "./ecgChannelControls/displayChannelToggle.controller.js";
import displayChannelToggleDirective from "./ecgChannelControls/displayChannelToggle.directive.js";
import InvertChannelButtonController from "./ecgChannelControls/invertChannelButton.controller.js";
import invertChannelButtonDirective from "./ecgChannelControls/invertChannelButton.directive.js";
import Drawing from "./ecgViewer/drawing.service.js";
import EcgNavigation from "./ecgViewer/ecgNavigation.service.js";
import EcgViewerController from "./ecgViewer/ecgViewer.controller.js";
import ecgViewerDirective from "./ecgViewer/ecgViewer.directive.js";
import EcgViewerFactory from "./ecgViewer/ecgViewer.service.js";
import GridLines from "./ecgViewer/gridLines.service.js";
import Leads from "./ecgViewer/leads.service.js";
import LeadSelectMenuController from "./ecgViewer/leadSelectMenu.controller.js";
import MarkerMenuController from "./ecgViewer/markerMenu.controller.js";
import Markers from "./ecgViewer/markers.service.js";
import StripMeasurementTableController from "./ecgViewer/stripMeasurementTable/stripMeasurementTable.controller.js";
import stripMeasurementTableDirective from "./ecgViewer/stripMeasurementTable/stripMeasurementTable.directive.js";
import StripToolsController from "./ecgViewer/stripTools/stripTools.controller.js";
import stripToolsDirective from "./ecgViewer/stripTools/stripTools.directive.js";
import Timeline from "./ecgViewer/timeline.service.js";
import EventClassificationOptionController from "./eventClassifications/eventClassificationOption.controller.js";
import eventClassificationOptionDirective from "./eventClassifications/eventClassificationOption.js";
import ArrhythmiaTimelineController from "./graphs/arrhythmiaTimeline.controller.js";
import arrhythmiaTimelineDirective from "./graphs/arrhythmiaTimeline.directive.js";
import AverageHeartRateGraphController from "./graphs/averageHeartRateGraph.controller.js";
import averageHeartRateGraphDirective from "./graphs/averageHeartRateGraph.directive.js";
import DonutChartController from "./graphs/donutChart.controller.js";
import donutChartDirective from "./graphs/donutChart.directive.js";
import HeartRateTrendController from "./graphs/heartRateTrend.controller.js";
import heartRateTrendDirective from "./graphs/heartRateTrend.directive.js";
import LeadDisconnectedTimelineController from "./graphs/leadDisconnectedTimeline.controller.js";
import leadDisconnectedTimelineDirective from "./graphs/leadDisconnectedTimeline.directive.js";
import StripViewerController from "./stripViewer/stripViewer.controller.js";
import Action from "./action.service.js";
import ArrhythmiaDataService from "./arrhythmiaData.service.js";
import AtrialFibrillationStatsService from "./atrialFibrillationStats.service.js";
import Auth from "./auth.factory.js";
import Authentication from "./authentication.service.js";
import ChannelConnectionService from "./channelConnection.service.js";
import Config from "./config.service.js";
import DonutChartService from "./donutChart.service.js";
import EnrollmentService from "./enrollment.service.js";
import EventService from "./event.service.js";
import ExcludeArtifactService from "./excludeArtifact.service.js";
import Facility from "./facility.service.js";
import GeneratedReportService from "./generatedReport.service.js";
import HeartRateService from "./heartRate.service.js";
import InboxItemService from "./inboxItem.service.js";
import InboxItemSearchParams from "./inboxItemSearchParams.service.js";
import Interceptors from "./interceptors.service.js";
import ItemAssignmentService from "./itemAssignment.service.js";
import LoadingCircle from "./loadingCircle.service.js";
import Lock from "./lock.service.js";
import NotificationService from "./notification.service.js";
import PaginationService from "./pagination.service.js";
import PubsubMessageService from "./pubsubMessage.service.js";
import ReportService from "./report.service.js";
import ScrollService from "./scroll.service.js";
import SearchService from "./search.service.js";
import Session from "./session.service.js";
import Socket from "./socket.service.js";
import SocketDisconnect from "./socketDisconnect.service.js";
import SortService from "./sort.service.js";
import StripService from "./strip.service.js";
import StripClassificationsService from "./stripClassifications.service.js";
import StudyService from "./study.service.js";
import User from "./user.service.js";
import VentricularEctopyDataService from "./ventricularEctopyData.service.js";
import Workflows from "./workflows.service.js";

export default angular
  .module("app.shared", ["btford.socket-io"])
  .service("Authentication", Authentication)
  .service("Config", Config)
  .factory("Auth", Auth)
  .service("Session", Session)
  .service("Lock", Lock)
  .service("LoadingCircleService", LoadingCircle)
  .service("SocketDisconnectService", SocketDisconnect)
  .service("SocketService", Socket)
  .service("UserService", User)
  .factory("Interceptors", Interceptors)
  .service("WorkflowsService", Workflows)
  .service("InboxItemService", InboxItemService)
  .controller("DisplayChannelToggleController", DisplayChannelToggleController)
  // matches <br-display-channel-toggle>
  .directive("brDisplayChannelToggle", [displayChannelToggleDirective])
  .controller("InvertChannelButtonController", InvertChannelButtonController)
  // matches <br-invert-channel-button>
  .directive("brInvertChannelButton", [invertChannelButtonDirective])
  .controller("StripMeasurementTableController", StripMeasurementTableController)
  // matches <br-strip-measurement-table>
  .directive("brStripMeasurementTable", [stripMeasurementTableDirective])
  .controller("StripToolsController", StripToolsController)
  // matches <br-strip-tools>
  .directive("brStripTools", stripToolsDirective)
  .service("Drawing", Drawing)
  .service("EcgNavigation", EcgNavigation)
  .controller("EcgViewerController", EcgViewerController)
  // matches <br-ecg-viewer>
  .directive("brEcgViewer", [ecgViewerDirective])
  .factory("EcgViewerFactory", EcgViewerFactory)
  .service("GridLines", GridLines)
  .service("Leads", Leads)
  .controller("LeadSelectMenuController", LeadSelectMenuController)
  .controller("MarkerMenuController", MarkerMenuController)
  .service("Markers", Markers)
  .service("Timeline", Timeline)
  .controller("EventClassificationOptionController", EventClassificationOptionController)
  // matches <br-event-classification-option>
  .directive("brEventClassificationOption", [eventClassificationOptionDirective])
  .controller("ArrhythmiaTimelineController", ArrhythmiaTimelineController)
  // matches <br-arrhythmia-timeline>
  .directive("brArrhythmiaTimeline", [arrhythmiaTimelineDirective])
  .controller("AverageHeartRateGraphController", AverageHeartRateGraphController)
  // matches <br-average-heart-rate-graph>
  .directive("brAverageHeartRateGraph", [averageHeartRateGraphDirective])
  .controller("DonutChartController", DonutChartController)
  // matches <br-donut-chart>
  .directive("brDonutChart", [donutChartDirective])
  .controller("HeartRateTrendController", HeartRateTrendController)
  // matches <br-heart-rate-trend>
  .directive("brHeartRateTrend", [heartRateTrendDirective])
  .controller("LeadDisconnectedTimelineController", LeadDisconnectedTimelineController)
  // matches <br-lead-disconnected-timeline>
  .directive("brLeadDisconnectedTimeline", [leadDisconnectedTimelineDirective])
  .controller("StripViewerController", StripViewerController)
  .service("ActionService", Action)
  .service("ArrhythmiaDataService", ArrhythmiaDataService)
  .service("AtrialFibrillationStatsService", AtrialFibrillationStatsService)
  .service("ChannelConnectionService", ChannelConnectionService)
  .service("DonutChartService", DonutChartService)
  .service("EnrollmentService", EnrollmentService)
  .service("EventService", EventService)
  .service("ExcludeArtifactService", ExcludeArtifactService)
  .service("Facility", Facility)
  .service("GeneratedReportService", GeneratedReportService)
  .service("HeartRateService", HeartRateService)
  .service("InboxItemSearchParamsService", InboxItemSearchParams)
  .service("ItemAssignmentService", ItemAssignmentService)
  .service("NotificationService", NotificationService)
  .factory("PaginationService", PaginationService)
  .service("PubsubMessageService", PubsubMessageService)
  .service("ReportService", ReportService)
  .service("ScrollService", ScrollService)
  .service("SearchService", SearchService)
  .factory("SortService", SortService)
  .service("StripService", StripService)
  .service("StripClassificationsService", StripClassificationsService)
  .service("StudyService", StudyService)
  .service("VentricularEctopyDataService", VentricularEctopyDataService).name;
