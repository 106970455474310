import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI
//---------------------------------------------------------------------------
import Grid from "@mui/material/Grid2";
import {useTheme} from "@mui/material/styles";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import GeneratedReportActions from "./actions/GeneratedReportActions.jsx";
import PrintedReportActions from "./actions/PrintedReportActions.jsx";
import PublishedReportActions from "./actions/PublishedReportActions.jsx";
import RejectedReportActions from "./actions/RejectedReportActions.jsx";
import SignedReportActions from "./actions/SignedReportActions.jsx";
import SubmittedReportActions from "./actions/SubmittedReportActions.jsx";

function ReportViewerSideBar({
  // Props
  report,
  study,
  item,
  eSignEnabled,
  disabled = false,
  isPdfReport,
  originalFileUrl,
  setError,
  setLoadingMessage,
  handleClose,
  setSuccessMessage,
  addCoverPageToPDF,
  setFileUrl,
  setLoadData,
  angularActions,
}) {
  //---------------------------------------------------------------------------
  // Set up hooks for confirmation dialogs and styling
  //---------------------------------------------------------------------------
  const theme = useTheme();

  return (
    <Grid
      size={1}
      p={2}
      sx={{
        backgroundColor: theme.palette.background.paper,
        borderLeft: 2,
        borderLeftColor: theme.palette.background.dark,
      }}
      display="inline-flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      {report?.state === "generated" && (
        <GeneratedReportActions
          report={report}
          study={study}
          item={item}
          eSignEnabled={eSignEnabled}
          disabled={disabled}
          isPdfReport={isPdfReport}
          setError={setError}
          setLoadingMessage={setLoadingMessage}
          handleClose={handleClose}
          setSuccessMessage={setSuccessMessage}
          setLoadData={setLoadData}
          angularActions={angularActions}
        />
      )}

      {report?.state === "submitted" && (
        <SubmittedReportActions
          report={report}
          study={study}
          eSignEnabled={eSignEnabled}
          disabled={disabled}
          isPdfReport={isPdfReport}
          originalFileUrl={originalFileUrl}
          setError={setError}
          handleClose={handleClose}
          setSuccessMessage={setSuccessMessage}
          setLoadingMessage={setLoadingMessage}
          addCoverPageToPDF={addCoverPageToPDF}
          setFileUrl={setFileUrl}
          setLoadData={setLoadData}
        />
      )}

      {report?.state === "published" && (
        <PublishedReportActions
          report={report}
          study={study}
          disabled={disabled}
          setError={setError}
          handleClose={handleClose}
          setSuccessMessage={setSuccessMessage}
          setLoadingMessage={setLoadingMessage}
          setLoadData={setLoadData}
        />
      )}

      {report?.state === "signed" && (
        <SignedReportActions
          report={report}
          study={study}
          disabled={disabled}
          setSuccessMessage={setSuccessMessage}
          setError={setError}
          handleClose={handleClose}
        />
      )}

      {report?.state === "printed" && <PrintedReportActions report={report} study={study} />}

      {report?.state?.startsWith("rejected") && (
        <RejectedReportActions
          report={report}
          study={study}
          disabled={disabled}
          eSignEnabled={eSignEnabled}
          setSuccessMessage={setSuccessMessage}
          handleClose={handleClose}
          setError={setError}
        />
      )}
    </Grid>
  );
}

ReportViewerSideBar.propTypes = {
  report: PropTypes.object.isRequired,
  study: PropTypes.object.isRequired,
  item: PropTypes.object,
  eSignEnabled: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  isPdfReport: PropTypes.bool.isRequired,
  originalFileUrl: PropTypes.string,
  setError: PropTypes.func.isRequired,
  setLoadingMessage: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  addCoverPageToPDF: PropTypes.func.isRequired,
  setFileUrl: PropTypes.func.isRequired,
  setLoadData: PropTypes.func.isRequired,
  angularActions: PropTypes.object.isRequired,
};

export default React.memo(ReportViewerSideBar);
