import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

function StripIndexHeader({
  // Props
  continued = false,
}) {
  //---------------------------------------------------------------------------
  // Global Variables
  //---------------------------------------------------------------------------
  const theme = useTheme();
  const cellStyling = React.useMemo(() => ({...theme.typography.reportText}), [theme]);
  const borderStyling = React.useMemo(() => `2px solid ${theme.palette.background.main}`, [theme]);

  return (
    <Box
      sx={{
        mt: 1,
        pt: 1,
        px: 1,
        borderTop: borderStyling,
        borderRight: borderStyling,
        borderLeft: borderStyling,
      }}
      className="report-element"
      id={continued ? "strip-index-header-continued" : "strip-index-header"}
    >
      {continued && <Typography variant="subtitle2">Strip Index (continued)</Typography>}
      {!continued && (
        <Typography variant="subtitle2" section-header="Strip Index" section-parent="">
          Strip Index
        </Typography>
      )}

      <Table size="small" padding="none">
        <TableHead>
          <TableRow>
            <TableCell width="5%" sx={cellStyling}>
              Order
            </TableCell>
            <TableCell width="20%" sx={cellStyling}>
              Timestamp
            </TableCell>
            <TableCell width="20%" sx={cellStyling}>
              Classification
            </TableCell>
            <TableCell width="10%" sx={cellStyling}>
              Trigger
            </TableCell>
            <TableCell width="30%" sx={cellStyling}>
              Findings
            </TableCell>
            <TableCell width="10%" sx={cellStyling}>
              HR
            </TableCell>
            <TableCell width="5%" sx={cellStyling}>
              Page
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </Box>
  );
}

StripIndexHeader.propTypes = {
  continued: PropTypes.bool,
};

export default React.memo(StripIndexHeader);
