//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";

export async function getFacilityLogoWithFallback(filename) {
  let logoFile;
  let logoFallback = false;

  try {
    logoFile = await getLogoFile(filename);
  } catch (err) {
    logoFallback = true;
    filename = "bitrhythmLogo.svg";

    logoFile = await getLogoFile(filename);
  }

  const logoFileExtension = getLogoFileExtension(filename);

  return {logoFile, logoFileExtension, logoFallback};
}

export async function createAndFormatLogo(page, logoFile, logoFileExtension) {
  const maxWidth = 2.5; // Logos over 2.5" wide will interfere with the title

  let src = logoFile;
  let format = logoFileExtension;
  let ratio;
  let width;
  let height = page.headerHeight;

  if (["WEBP", "PNG", "JPEG"].includes(logoFileExtension)) {
    const {w, h} = await getImageDimensions(logoFileExtension, logoFile);
    ({src, ratio} = await imageToCanvas(logoFileExtension, logoFile, w, h));
  } else if (logoFileExtension?.match(/svg/i)) {
    ({src, ratio} = await svgToPng(atob(logoFile)));
    format = "PNG";
  } else {
    throw new Error(`Cannot add logo of type ${logoFileExtension}`);
  }

  // Calculate width and fit to area
  width = height * ratio;
  if (width > maxWidth) {
    width = maxWidth;
    height = width / ratio;
  }

  return {
    src,
    format,
    x: page.rightMargin - width,
    y: page.margin + (page.headerHeight - height) / 2, // Vertically center it in the header
    w: width,
    h: height,
  };
}

async function getLogoFile(filename) {
  const {data} = await axios({
    method: "get",
    url: `/facilities/logo/${filename}`,
    headers: {"Content-Type": "application/json"},
    responseType: "arraybuffer",
  });

  const typedArray = new Uint8Array(data);
  const stringChar = String.fromCharCode.apply(null, typedArray);
  const base64String = btoa(stringChar);

  return fixSVGEncoding(base64String, getLogoFileExtension(filename));
}

function getLogoFileExtension(filename) {
  const filenameSplit = filename.split(".");
  let extension = filenameSplit[filenameSplit.length - 1].toUpperCase();

  if (extension === "SVG") {
    extension = "svg+xml";
  }
  return extension;
}

function fixSVGEncoding(image, fileExtension) {
  let result = image;
  if (fileExtension === "svg+xml") {
    try {
      const originalFile = atob(image);
      const originalXML = new DOMParser().parseFromString(originalFile, "text/xml");
      const originalSVG = originalXML.getElementsByTagName("svg")[0];

      let height = originalSVG.getAttribute("height");
      let width = originalSVG.getAttribute("width");

      if (height === null && width === null) {
        // Set the height of SVG from the viewBox if there is no height or width
        // (affects originalXML via nested reference)
        height = originalSVG.viewBox?.baseVal?.height || 50;
        width = originalSVG.viewBox?.baseVal?.width || 50;
      }

      // Scale the size down large SVGs, so that it won't be memory intensive when rasterized on a report
      // This has to be done to the decoded svg rather than canvas size otherwise the svg will overflow the canvas
      const scaledHeight = Math.min(height, 360); // max 360px height
      const scaledWidth = (scaledHeight / height) * width;

      originalSVG.setAttribute("height", `${scaledHeight}px`);
      originalSVG.setAttribute("width", `${scaledWidth}px`);
      const updatedFile = new XMLSerializer().serializeToString(originalXML);
      result = btoa(updatedFile);
    } catch (e) {
      result = image;
    }
  }
  return result;
}

function getImageDimensions(format, base64Image) {
  return new Promise((resolve, reject) => {
    const inputImage = new Image();
    inputImage.onload = function onImgLoad() {
      resolve({w: this.width, h: this.height, ratio: this.width / this.height});
    };
    inputImage.onerror = function onImgError() {
      reject(new Error(`Cannot load ${format} image`));
    };
    inputImage.src = `data:image/${format};base64,${base64Image}`;
  });
}

/**
 * Converts a base64 Image to canvas; without this, transparent pixels are not displayed correctly
 *
 * @param {String} format JPEG, WEBP, PNG
 * @param {String} base64Image
 * @param {Number} width
 * @param {Number} height
 * @return {Promise<Object>}
 */
function imageToCanvas(format, base64Image, width, height) {
  // convert an svg text to png using the browser
  return new Promise((resolve, reject) => {
    try {
      // create a canvas element to pass through
      const canvas = document.createElement("canvas");
      canvas.height = height;
      canvas.width = width;
      const ctx = canvas.getContext("2d");

      // load the image
      const img = new Image();
      img.src = `data:image/${format};base64,${base64Image}`;

      // when the image is loaded we can get it as base64 url
      img.onload = function onImgLoad() {
        // draw it to the canvas
        ctx.drawImage(this, 0, 0);

        // now we can resolve the promise, passing the base64 url
        resolve({src: canvas.toDataURL(), ratio: width / height});
      };
    } catch (err) {
      reject(err);
    }
  });
}

/**
 * Converts an SVG string to base64 png using the domUrl
 *
 * Adapted from Bruce Mcpherson
 *
 * @param {String} svgText
 * @return {Promise} Resolves to the base64 png image
 */
function svgToPng(svgText) {
  // convert an svg text to png using the browser
  return new Promise((resolve, reject) => {
    try {
      const domUrl = window.URL || window.webkitURL || window;

      // figure out the height and width from svg text
      let matches = svgText.match(/height="(?<value>[\d.]+)(?<units>[a-zA-Z]*)"/);
      const height = convertToPixels(matches?.groups) || 100;
      matches = svgText.match(/width="(?<value>[\d.]+)(?<units>[a-zA-Z]*)"/);
      const width = convertToPixels(matches?.groups) || 100;

      // create a canvas element to pass through
      const canvas = document.createElement("canvas");
      canvas.height = height;
      canvas.width = width;
      const ctx = canvas.getContext("2d");

      // make a blob from the svg
      const svg = new Blob([svgText], {
        type: "image/svg+xml;charset=utf-8",
      });

      // create a dom object for that image
      const url = domUrl.createObjectURL(svg);

      // create a new image to hold it the converted type
      const img = new Image();

      // when the image is loaded we can get it as base64 url
      img.onload = function onImgLoad() {
        // draw it to the canvas
        ctx.drawImage(this, 0, 0);

        // we don't need the original any more
        domUrl.revokeObjectURL(url);
        // now we can resolve the promise, passing the base64 url
        resolve({src: canvas.toDataURL(), ratio: width / height});
      };

      // load the image
      img.src = url;
    } catch (err) {
      reject(err);
    }
  });
}

function convertToPixels(groups) {
  let result = null;
  if (!groups) {
    return result;
  }
  const value = Number(groups.value);
  const {units} = groups;

  switch (units.toUpperCase()) {
    case "MM":
      result = value * (96 / 25.4); // 96 dpi / 25.4mm per in
      break;
    case "CM":
      result = value * (96 / 2.54); // 96 dpi / 2.54cm per in
      break;
    case "IN":
      result = value * 96; // 96 dpi
      break;
    case "PC":
      result = value * 16; // 16 px per pica
      break;
    case "PT":
      result = (value * 4) / 3; // 4px = 3pt
      break;
    default:
      // Handle "PX", "", and anything else
      result = value;
      break;
  }
  return result;
}
