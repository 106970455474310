import React from "react";
import {scaleSymlog} from "d3";
import {DateTime, IANAZone, Interval} from "luxon";
import PropTypes from "prop-types";
import {
  CartesianGrid,
  Label,
  Line,
  LineChart,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip as ChartTooltip,
  XAxis,
  YAxis,
} from "recharts";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import useCustomTheme from "../../customizeReactTheme.jsx";
import TableLoading from "../../shared/react/TableLoading.jsx";
import {formatDateAndTime} from "../DateAndTime/DateAndTime.jsx";

function OffsetLabel({value, viewBox}) {
  return (
    <text fill="rgb(118, 133, 140)" fontSize={14} offset={5} x={viewBox.x + 17} y={15} textAnchor="left">
      <tspan x={viewBox.x} dy="0em">
        {value}
      </tspan>
    </text>
  );
}
OffsetLabel.propTypes = {
  value: PropTypes.string,
  viewBox: PropTypes.object,
};

function PvcBurdenGraph({
  // Props
  preloadedPvcBurden,
  study,
  setError,
  checked,
  startTime,
  endTime,
  clickedZoomOut,
  setClickedZoomOut,
  setZoomedIn,
  isDailyTrend,
}) {
  const isGeneratedReport = !!preloadedPvcBurden;

  //---------------------------------------------------------------------------
  // Load data from the API
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(!isGeneratedReport);
  const [pvcBurden, setPvcBurden] = React.useState(preloadedPvcBurden || []); // Static data
  const [data, setData] = React.useState(preloadedPvcBurden || []); // Changes depending on zoom
  const getPvcBurden = React.useCallback(async () => {
    try {
      const {data: pvcData} = await axios({
        method: "get",
        url: `/reports/pvcBurden/${study.studyId || study.id}`,
        params: {
          startTime: startTime.toUTC().toISO(),
          endTime: endTime.toUTC().toISO(),
        },
      });

      setPvcBurden(pvcData);
      setData(pvcData);
    } catch (err) {
      setError(err.message);
    }

    setLoading(false);
  }, [setPvcBurden, setError, endTime, startTime, study.studyId, study.id]);

  React.useEffect(() => {
    if (loading) {
      getPvcBurden();
    }
  }, [loading, getPvcBurden]);

  //---------------------------------------------------------------------------
  // Configure the axis tick marks and tooltip formatting
  //---------------------------------------------------------------------------
  const ticks = Interval.fromDateTimes(startTime, endTime)
    .splitBy({hours: isDailyTrend ? 1 : 4})
    .map((i) => i.start.startOf("hour").toMillis())
    .filter((i) => i > startTime.toMillis());
  ticks.push(endTime.startOf("hour").toMillis());

  const days = Interval.fromDateTimes(startTime, endTime)
    .splitBy({days: 1})
    .map((i) => (isDailyTrend ? i.end.startOf("day") : i.start));

  const yScale = scaleSymlog([0, 100]);

  // For daily trends, display every fourth tick label; Otherwise don't display any tick labels
  const timeFormatter = React.useCallback(
    (unixTime) => {
      const timeZone = IANAZone.isValidZone(study.timeZone) ? study.timeZone : DateTime.local().toFormat("z");
      const isEveryFourthTick = DateTime.fromMillis(unixTime).setZone(timeZone).hour % 4 === 0;

      return isDailyTrend && isEveryFourthTick
        ? formatDateAndTime({datetime: unixTime, zone: study.timeZone, format: "HH:mm"})
        : "";
    },
    [isDailyTrend, study.timeZone]
  );
  const percentFormatter = React.useCallback(
    (percent) => `${Math.round(percent * 100) / 100}%`, // Round to two decimal places if necessary
    []
  );
  const tooltipTimeFormatter = React.useCallback(
    (unixTime) => formatDateAndTime({datetime: unixTime, zone: study.timeZone}),
    [study.timeZone]
  );
  const tooltipPercentFormatter = React.useCallback(
    (percent) => [percentFormatter(percent), "Burden"],
    [percentFormatter]
  );

  //---------------------------------------------------------------------------
  // Zoom state management
  //---------------------------------------------------------------------------
  const [left, setLeft] = React.useState(startTime.toMillis());
  const [right, setRight] = React.useState(endTime.toMillis());
  const [refAreaLeft, setRefAreaLeft] = React.useState("");
  const [refAreaRight, setRefAreaRight] = React.useState("");

  //---------------------------------------------------------------------------
  // Event callbacks
  //---------------------------------------------------------------------------
  const handleOnMouseDown = React.useCallback((e) => setRefAreaLeft(e?.activeLabel), []);
  const handleOnMouseMove = React.useCallback((e) => setRefAreaRight(e?.activeLabel), []);

  const zoom = React.useCallback(() => {
    if (isGeneratedReport || !checked) {
      return;
    }

    if (refAreaLeft === refAreaRight || refAreaRight === "") {
      setRefAreaLeft("");
      setRefAreaRight("");
      return;
    }

    if (refAreaLeft > refAreaRight) {
      setLeft(refAreaRight);
      setRight(refAreaLeft);
    } else {
      setLeft(refAreaLeft);
      setRight(refAreaRight);
    }

    setRefAreaLeft("");
    setRefAreaRight("");
    setData((prevData) => prevData.slice());

    setZoomedIn(true);
  }, [isGeneratedReport, checked, refAreaLeft, refAreaRight, setZoomedIn]);

  React.useEffect(() => {
    // If the Zoom Out button has been clicked, reset the graph
    if (clickedZoomOut) {
      setData(pvcBurden);
      setRefAreaLeft("");
      setRefAreaRight("");
      setLeft(startTime.toMillis());
      setRight(endTime.toMillis());

      setClickedZoomOut(false);
      setZoomedIn(false);
    }
  }, [
    clickedZoomOut,
    setData,
    pvcBurden,
    setRefAreaLeft,
    setRefAreaRight,
    setLeft,
    startTime,
    setRight,
    endTime,
    setClickedZoomOut,
    setZoomedIn,
  ]);

  //---------------------------------------------------------------------------
  // Get the theme so we can use its palette
  //---------------------------------------------------------------------------
  const theme = useCustomTheme();

  //---------------------------------------------------------------------------
  // Render the graph
  //---------------------------------------------------------------------------
  return (
    <>
      {loading && (
        <Box sx={{pb: 10}}>
          <TableLoading />
        </Box>
      )}
      {!loading && (
        <ResponsiveContainer width="95%" height={250} className="marginAuto">
          <LineChart
            id="pvc-burden"
            data={data}
            margin={{top: 20, right: 16}}
            onMouseDown={handleOnMouseDown}
            onMouseMove={handleOnMouseMove}
            onMouseUp={zoom}
          >
            <CartesianGrid />
            <XAxis
              dataKey="mt"
              type="number"
              scale="time"
              domain={[left, right]}
              ticks={ticks}
              interval={0}
              allowDataOverflow
              tick={{fontSize: 10}}
              tickFormatter={timeFormatter}
              tickLine={false}
              tickMargin={0}
            />
            <YAxis
              tick={{fontSize: 10}}
              type="number"
              domain={[0, 100]}
              ticks={[1, 5, 10, 20, 40, 60, 80, 100]}
              interval={0}
              scale={yScale}
              tickFormatter={percentFormatter}
            />
            <Line
              type="monotone"
              dataKey="percent"
              stroke={checked ? theme.palette.primary.dark : "#62727b"}
              dot={false}
              isAnimationActive={false}
            />
            {checked && !isGeneratedReport && (
              <ChartTooltip
                offset={20}
                contentStyle={{borderRadius: 4}}
                labelFormatter={tooltipTimeFormatter}
                formatter={tooltipPercentFormatter}
              />
            )}

            {days.map((day) => (
              <ReferenceLine
                key={day.toMillis()}
                x={day.toMillis()}
                stroke="rgb(118, 133, 140)"
                strokeWidth={2}
                isFront={false}
              >
                <Label
                  value={formatDateAndTime({datetime: day, zone: study.timeZone, format: "LLL dd"})}
                  position="top"
                  fill="rgb(118, 133, 140)"
                  fontSize={14}
                  content={OffsetLabel}
                />
              </ReferenceLine>
            ))}

            {!isGeneratedReport && checked && refAreaLeft && refAreaRight ? (
              <ReferenceArea x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />
            ) : null}
          </LineChart>
        </ResponsiveContainer>
      )}
    </>
  );
}

PvcBurdenGraph.propTypes = {
  preloadedPvcBurden: PropTypes.array,
  study: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  startTime: PropTypes.any,
  endTime: PropTypes.any,
  clickedZoomOut: PropTypes.bool,
  setClickedZoomOut: PropTypes.func,
  setZoomedIn: PropTypes.func,
  isDailyTrend: PropTypes.bool,
};

export default PvcBurdenGraph;
