/* eslint-env browser */
import React from "react";
import {useForm} from "react-hook-form";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import FindInPage from "@mui/icons-material/FindInPage";
import LibraryBooks from "@mui/icons-material/LibraryBooks";
import Person from "@mui/icons-material/Person";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import {useReportsDispatch} from "../../contexts/ReportsContext.jsx";
import Alert from "../../shared/react/Alert.jsx";
import DialogTitleBar from "../../shared/react/DialogTitleBar.jsx";
import FormStringInput from "../../shared/react/FormStringInput.jsx";
import IconWithText from "../../shared/react/IconWithText.jsx";
import useJwt from "../hooks/useJwt.jsx";

function RejectConfirmation({
  // Props
  open,
  handleCancel,
  handleClose,
  report,
  setError,
  setSuccessMessage,
}) {
  //---------------------------------------------------------------------------
  // Global Variables
  //---------------------------------------------------------------------------
  const {isInAnyRole, fullName} = useJwt();
  const dispatch = useReportsDispatch();

  //---------------------------------------------------------------------------
  // Form Submission
  //---------------------------------------------------------------------------
  const {handleSubmit, control} = useForm({defaultValues: {reasonForRejection: ""}});
  const [submitting, setSubmitting] = React.useState(false);

  const onSubmit = React.useCallback(
    async (data) => {
      setSubmitting(true);

      try {
        const state = isInAnyRole(["clinicalStaff", "physician"]) ? "rejectedByPhysician" : "rejectedByTech";
        const propertiesToUpdate = {
          state,
          reasonForRejection: data.reasonForRejection,
        };

        const {data: updatedReport} = await axios({
          method: "patch",
          url: `/generatedReports/${report.id}`,
          data: propertiesToUpdate,
        });

        // Set the success message before the dispatcher in case filters hide this report state
        setSuccessMessage("Successfully rejected");

        // Update the local displayed report
        dispatch({
          type: "updated",
          updatedElement: {...report, ...updatedReport, rejectedByUser: {fullName}},
        });

        handleClose();
      } catch (err) {
        setError(err.message);
      }

      setSubmitting(false);
    },
    [dispatch, isInAnyRole, report, handleClose, setSuccessMessage, fullName, setError]
  );

  return (
    <Dialog
      open={open}
      data-cy="reject-confirmation-dialog"
      PaperProps={{
        component: "form",
        onSubmit: handleSubmit(onSubmit),
        noValidate: true,
      }}
    >
      <DialogTitleBar
        icon={<LibraryBooks color="secondary" />}
        title={`Reject ${report.reportType} Report?`}
      />

      <DialogContent>
        <Alert
          message="Are you sure you want to reject this report?"
          level="warning"
          otherProps={{sx: {mb: 1}}}
        />

        <Grid container sx={{pl: 2}}>
          <Grid size={12}>
            <IconWithText
              icon={<FindInPage color="secondary" fontSize="small" />}
              text={<Typography variant="body2">Study ID: {report.studyId}</Typography>}
            />
          </Grid>
          <Grid size={12} sx={{display: "inline-flex", alignItems: "center"}}>
            <IconWithText
              icon={<Person color="secondary" fontSize="small" />}
              text={
                <Typography variant="body2">{report.studyDetails?.patientName || report.tzSerial}</Typography>
              }
            />
          </Grid>
          <Grid size={12} sx={{display: "inline-flex", alignItems: "center"}}>
            <IconWithText
              icon={<LibraryBooks color="secondary" fontSize="small" />}
              text={<Typography variant="body2">Report Number: {report.reportNumber}</Typography>}
            />
          </Grid>
        </Grid>

        <Box sx={{mt: 3}}>
          <FormStringInput
            control={control}
            name="reasonForRejection"
            label="Reason"
            defaultValue=""
            disabled={submitting}
            data-cy="reason-for-rejection-input"
            otherProps={{variant: "outlined", multiline: true, rows: 4}}
            rules={{required: "Reasoning is required"}}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCancel} color="secondary" data-cy="cancel-confirmation-button">
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          color="secondary"
          variant="contained"
          data-cy="confirm-button"
          loading={submitting}
          disabled={submitting}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

RejectConfirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  report: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
};

export default RejectConfirmation;
