import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../../axiosClient.js";
import AddProviders from "../../../shared/react/AddProviders.jsx";
import Alert from "../../../shared/react/Alert.jsx";
import TableLoading from "../../../shared/react/TableLoading.jsx";
import DonutChart from "../DonutChart.jsx";

function ArrhythmiaEpisodes({
  // Props
  id,
  study,
  start,
  end,
  toggles,
  arrhythmiaEpisodes,
  patientActivatedCount,
}) {
  //---------------------------------------------------------------------------
  // The following variables are the prop names that _should be_ if we weren't
  // using pug templates to connect this to angularjs.
  //---------------------------------------------------------------------------
  const reportId = id;
  const studyId = study;
  const startTime = start;
  const endTime = end;
  const chartToggles = toggles;

  //---------------------------------------------------------------------------
  // Error handling state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Fetch data from API
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);
  const [episodes, setEpisodes] = React.useState(arrhythmiaEpisodes);
  const [paeCount, setPaeCount] = React.useState(patientActivatedCount);

  const getArrhythmiaEpisodesData = React.useCallback(async () => {
    setLoading(true);

    try {
      const {data: arrhythmiaEpisodesData} = await axios({
        method: "get",
        url: `/reports/arrhythmia-episodes/${studyId}`,
        params: {
          startTime: startTime.toUTC().toISO(),
          endTime: endTime.toUTC().toISO(),
        },
      });

      setEpisodes(arrhythmiaEpisodesData.events);
      setPaeCount(arrhythmiaEpisodesData.patientActivatedEvents);
    } catch (err) {
      setError(err.message);
    }

    setLoading(false);
  }, [endTime, startTime, studyId]);

  React.useEffect(() => {
    // If we weren't supplied the data for the chart, fetch it
    if ([null, undefined].includes(arrhythmiaEpisodes) && !error) {
      getArrhythmiaEpisodesData();
    }
  }, [arrhythmiaEpisodes, error, getArrhythmiaEpisodesData]);

  //---------------------------------------------------------------------------
  // Data formatting
  //---------------------------------------------------------------------------
  const formatter = React.useCallback((payload) => payload.count, []);
  const additionalData = React.useMemo(() => {
    return [{name: "Patient Activated", value: paeCount}];
  }, [paeCount]);

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <AddProviders>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />

      {loading && <TableLoading />}

      {!loading && (
        <DonutChart
          title="Arrhythmia Episodes"
          reportId={reportId}
          chartId="arrhythmiaEpisode"
          data={episodes}
          dataKey="percent"
          additionalData={additionalData}
          additionalDataPosition="top"
          legendFormatter={formatter}
          chartToggles={chartToggles}
        />
      )}
    </AddProviders>
  );
}

ArrhythmiaEpisodes.propTypes = {
  id: PropTypes.string.isRequired,
  study: PropTypes.number.isRequired,
  start: PropTypes.object,
  end: PropTypes.object,
  toggles: PropTypes.object,
  arrhythmiaEpisodes: PropTypes.array,
  patientActivatedCount: PropTypes.number,
};
export default ArrhythmiaEpisodes;
