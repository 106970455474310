class PaginationService {
  constructor(pageSize = 50, totalCount = 0) {
    this._totalCount = totalCount;
    this._currentPage = 0;
    this.pageSize = pageSize;
  }

  get totalCount() {
    return this._totalCount;
  }

  set totalCount(total) {
    this._totalCount = total;
  }

  get currentPage() {
    return this._currentPage;
  }

  set currentPage(pageNumber) {
    this._currentPage = pageNumber;
  }

  get lastPage() {
    return Math.floor(this.totalCount / this.pageSize);
  }

  get firstPage() {
    return 0;
  }

  get isCurrentPageLast() {
    return this.currentPage === this.lastPage;
  }

  get isCurrentPageFirst() {
    return this.currentPage === this.firstPage;
  }

  get offset() {
    return this.pageSize * this.currentPage;
  }

  isPreviousPageAvailable() {
    return this._currentPage > 0;
  }

  isNextPageAvailable() {
    const result =
      this._currentPage !== Math.ceil(this._totalCount / this.pageSize) - 1 &&
      this._totalCount > this.pageSize;
    return result;
  }

  goToPreviousPage() {
    if (this.isPreviousPageAvailable()) {
      this._currentPage -= 1;
    }
  }

  goToNextPage() {
    if (this.isNextPageAvailable()) {
      this._currentPage++;
    }
  }

  goToFirstPage() {
    this._currentPage = 0;
  }

  goToLastPage() {
    this._currentPage = Math.ceil(this._totalCount / this.pageSize) - 1;
  }

  incrementTotalCount() {
    this._totalCount++;
  }

  decrementTotalCount() {
    this._totalCount -= 1;
  }

  getEndingPointForPage() {
    let endLimit = this._currentPage * this.pageSize + this.pageSize;
    if (this._totalCount < endLimit) {
      endLimit = this._totalCount;
    }
    return endLimit;
  }

  getStartingPointForPage() {
    return this._currentPage * this.pageSize + 1;
  }
}

export default function returnPaginationService() {
  return PaginationService;
}
