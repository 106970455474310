import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Grid from "@mui/material/Grid2";
import {useTheme} from "@mui/material/styles";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import OrderingPhysicianSummary from "./OrderingPhysicianSummary.jsx";
import PatientSummary from "./PatientSummary.jsx";
import StudySummary from "./StudySummary.jsx";

function Summary({
  // Props
  report,
  study,
}) {
  const theme = useTheme();
  const borderStyling = React.useMemo(() => `2px solid ${theme.palette.background.main}`, [theme]);

  return (
    <Grid
      container
      size={12}
      className="report-element"
      id="summary"
      sx={{mt: 1, border: borderStyling}}
      section-header="Report Summary"
      section-parent=""
    >
      <Grid size={4} sx={{p: 1, borderRight: borderStyling}}>
        <PatientSummary report={report} />
      </Grid>

      <Grid size={4} sx={{p: 1, borderRight: borderStyling}}>
        <StudySummary report={report} study={study} />
      </Grid>

      <Grid size={4} sx={{p: 1}}>
        <OrderingPhysicianSummary report={report} />
      </Grid>
    </Grid>
  );
}

Summary.propTypes = {
  report: PropTypes.object.isRequired,
  study: PropTypes.object.isRequired,
};

export default React.memo(Summary);
