import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import {ZoomProvider} from "../../contexts/ZoomContext.jsx";
import Alert from "../../shared/react/Alert.jsx";
import TableLoading from "../../shared/react/TableLoading.jsx";
import useHeartRateTrendData from "../hooks/useHeartRateTrendData.jsx";
import TimelineChartWrapper from "./TimelineChartWrapper.jsx";

function HeartRateTrend({
  // Props
  study,
  startTime,
  endTime,
  data,
}) {
  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Data state management
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);
  const charts = useHeartRateTrendData({study, data, startTime, endTime, setLoading, setError});

  return (
    <ZoomProvider>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />

      {loading && <TableLoading />}

      {!loading && <TimelineChartWrapper>{charts}</TimelineChartWrapper>}
    </ZoomProvider>
  );
}
HeartRateTrend.propTypes = {
  study: PropTypes.object.isRequired,
  startTime: PropTypes.object.isRequired,
  endTime: PropTypes.object.isRequired,
  data: PropTypes.object,
};

export default HeartRateTrend;
