import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------

function MenuButton({
  // Props
  children,
  id,
  disabled,
  anchorEl,
  setAnchorEl,
  disableTabbing = false,
}) {
  const handleButtonClick = React.useCallback(
    (event) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = React.useCallback(
    (event) => {
      event.stopPropagation();
      setAnchorEl(null);
    },
    [setAnchorEl]
  );

  return (
    <>
      <Button
        id={id}
        data-cy={id}
        variant="contained"
        color="secondary"
        disabled={disabled}
        style={{padding: "0px", minWidth: "0px"}}
        onClick={handleButtonClick}
        tabIndex={disableTabbing ? -1 : 0}
      >
        <ArrowDropDown />
      </Button>
      <Menu
        id={`${id}-menu`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{role: "listbox"}}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {children}
      </Menu>
    </>
  );
}

MenuButton.propTypes = {
  /**
   * The node displayed on the button
   */
  children: PropTypes.node.isRequired,

  /**
   * The id of the button
   */
  id: PropTypes.string,

  /**
   * If `true` the component is disabled
   */
  disabled: PropTypes.bool,

  anchorEl: PropTypes.node,
  setAnchorEl: PropTypes.func,

  /**
   * Disables tabbing to the menu button
   */
  disableTabbing: PropTypes.bool,
};

export default MenuButton;
