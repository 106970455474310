import React from "react";
import findIndex from "lodash/findIndex.js";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import DateAndTime from "../../DateAndTime/DateAndTime.jsx";

function MinMaxChip({label}) {
  const theme = useTheme();

  return (
    <span
      style={{
        fontSize: "0.6875rem",
        color: "white",
        backgroundColor: theme.palette.primary.dark,
        marginLeft: theme.spacing(0.5),
        padding: `0px ${theme.spacing(0.75)}`,
        borderRadius: theme.spacing(1.5),
        display: "inline-block",
      }}
      data-cy="min-max-chip"
    >
      {label}
    </span>
  );
}
MinMaxChip.propTypes = {
  label: PropTypes.string.isRequired,
};

function StripIndexRow({
  // Props
  strip,
  stripOrder,
  study,
  heartRateStatistics,
  sheets,
  isLastStripInTable = false,
}) {
  //---------------------------------------------------------------------------
  // Global Variables
  //---------------------------------------------------------------------------
  const theme = useTheme();
  const cellStyling = React.useMemo(
    () => ({py: 0.5, verticalAlign: "top", ...theme.typography.reportText}),
    [theme]
  );
  const borderStyling = React.useMemo(() => `2px solid ${theme.palette.background.main}`, [theme]);

  //---------------------------------------------------------------------------
  // Determine displayed values for the strip
  //---------------------------------------------------------------------------
  const stripTimestamp = React.useMemo(() => {
    if (!strip.validStartTime) {
      return "Unknown Timestamp";
    }

    return <DateAndTime datetime={strip.startTime} zone={study.timeZone} seconds />;
  }, [strip.startTime, strip.validStartTime, study.timeZone]);

  const triggerType = React.useMemo(() => {
    const isRhythmChange = strip.event.rhythm !== null;
    const isPatientActivated = strip.event.symptom !== null;
    const isEcgDataRequest = strip.event.comment !== null;
    const isBaseline = strip.event.settingsFileId !== null;

    if (isRhythmChange || isBaseline) {
      return "Automatic";
    }
    if (isPatientActivated || isEcgDataRequest) {
      return "Manual";
    }
    return "Other";
  }, [strip.event.comment, strip.event.rhythm, strip.event.settingsFileId, strip.event.symptom]);

  const pageNumber = React.useMemo(() => {
    return findIndex(sheets, (sheet) => sheet.some((elementId) => elementId === `strip-${strip.id}`)) + 1;
  }, [sheets, strip.id]);

  return (
    <Box
      sx={{
        pl: 1,
        pr: 1,
        borderRight: borderStyling,
        borderLeft: borderStyling,
        // we need to add a bottom border and padding if its the last strip row
        ...(isLastStripInTable && {
          borderBottom: borderStyling,
          pb: 1,
        }),
      }}
      className="report-element"
      id={`strip-index-row-${strip.id}`}
    >
      <Table size="small" padding="none">
        <TableBody>
          <TableRow>
            <TableCell width="5%" sx={cellStyling} data-cy="strip-index-order">
              <a href={`#strip-${strip.id}`}>{stripOrder}</a>
            </TableCell>

            <TableCell width="20%" sx={cellStyling} data-cy="strip-index-timestamp">
              {stripTimestamp}
            </TableCell>

            <TableCell width="20%" sx={cellStyling} data-cy="strip-index-classification">
              {strip.userClassification}
            </TableCell>

            <TableCell width="10%" sx={cellStyling} data-cy="strip-index-trigger">
              {triggerType}
            </TableCell>

            <TableCell width="30%" sx={cellStyling} data-cy="strip-index-comment">
              {strip.comment}
            </TableCell>

            <TableCell width="10%" sx={cellStyling}>
              {strip.measurements.HR?.mean || "-"}

              {strip.id === heartRateStatistics.min.stripId && <MinMaxChip label="Min" />}
              {strip.id === heartRateStatistics.max.stripId && <MinMaxChip label="Max" />}
            </TableCell>

            <TableCell width="5%" sx={cellStyling} data-cy="strip-index-page-number">
              {pageNumber}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}

StripIndexRow.propTypes = {
  strip: PropTypes.object.isRequired,
  stripOrder: PropTypes.number.isRequired,
  study: PropTypes.object.isRequired,
  heartRateStatistics: PropTypes.object.isRequired,
  sheets: PropTypes.array.isRequired,
  isLastStripInTable: PropTypes.bool,
};

export default React.memo(StripIndexRow);
