import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import MdnCriteriaTooltip from "../../MdnCriteriaTooltip/MdnCriteriaTooltip.jsx";

function PrintedReportActions({
  // Props
  report,
  study,
}) {
  return <MdnCriteriaTooltip criteria={study.studyDetails?.physicianNotes} met={!!report.meetsMdnCriteria} />;
}

PrintedReportActions.propTypes = {
  report: PropTypes.object.isRequired,
  study: PropTypes.object.isRequired,
};

export default React.memo(PrintedReportActions);
