import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Error from "@mui/icons-material/Error";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import styled from "@mui/system/styled";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import AddProviders from "../../shared/react/AddProviders.jsx";
import LinkifyText from "../../shared/react/LinkifyText.jsx";

// eslint-disable-next-line react/jsx-props-no-spreading
const LightTooltip = styled(({className, ...props}) => <Tooltip {...props} classes={{popper: className}} />)(
  ({theme}) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      padding: "12px",
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 14,
      fontWeight: 400,
    },
  })
);

function MdnCriteriaTooltip({
  // Props
  criteria,
  met = false,
  toggle = false,
}) {
  //---------------------------------------------------------------------------
  // The following variables are the prop names that _should be_ if we weren't
  // using pug templates to connect this to angularjs.
  //---------------------------------------------------------------------------
  const notificationCriteria = criteria;
  const metMdnCriteria = met;

  //---------------------------------------------------------------------------
  // Render the text and tooltip
  //---------------------------------------------------------------------------
  return (
    <AddProviders>
      <Box sx={{display: "inline-flex", alignItems: "center"}} data-cy="mdn-criteria-message">
        {metMdnCriteria && !toggle && (
          <>
            <Error color="error" />
            &nbsp;
          </>
        )}
        <Typography sx={{fontSize: 14}}>
          {metMdnCriteria || toggle ? "Meets" : "Does not meet"}&nbsp;
        </Typography>

        <LightTooltip
          title={
            <LinkifyText
              text={notificationCriteria}
              fallback="No MDN Criteria have been provided for this study"
              data-cy="mdn-criteria-tooltip"
            />
          }
          arrow
          leaveDelay={300}
        >
          <Typography
            variant="button"
            color="secondary"
            data-cy="mdn-criteria"
            sx={{fontSize: 14, fontWeight: "bold", lineHeight: 1.5, cursor: "pointer"}}
          >
            MDN Criteria
          </Typography>
        </LightTooltip>
      </Box>
    </AddProviders>
  );
}

MdnCriteriaTooltip.propTypes = {
  criteria: PropTypes.string,
  met: PropTypes.bool,
  toggle: PropTypes.bool,
};

export default MdnCriteriaTooltip;
