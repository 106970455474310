import React from "react";
import * as d3 from "d3";
import {DateTime, Interval} from "luxon";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import DateAndTime from "../DateAndTime/DateAndTime.jsx";

//---------------------------------------------------------------------------
// Constants
//---------------------------------------------------------------------------
const NUMBER_OF_MARKS = 5;

function FullViewer({
  // Props
  startTime,
  totalSamples,
  samplePeriod,
  contextCenteredSample,
  setContextCenteredSample,
  width,
  height,
}) {
  //---------------------------------------------------------------------------
  // Calculate the tick marks for the displayed timestamps
  //---------------------------------------------------------------------------
  const samplesPerSecond = React.useMemo(() => 1000000 / samplePeriod, [samplePeriod]);

  const timestamps = React.useMemo(() => {
    const durationInSeconds = totalSamples / samplesPerSecond;
    const pixelsPerSecond = width / durationInSeconds;

    const start = DateTime.fromISO(startTime);
    const end = start.plus({seconds: durationInSeconds});

    // Get 5 evenly spaced dateTimes from the domain
    const dateTimes = Interval.fromDateTimes(start, end)
      .divideEqually(NUMBER_OF_MARKS - 1)
      .flatMap((interval, index) => {
        if (index === 0) {
          return [interval.start, interval.end];
        }
        return interval.end;
      });

    return dateTimes.map((datetime, index) => {
      const {seconds: secondsFromStart} = datetime.diff(start, "seconds").toObject();
      const xCoordinate = secondsFromStart * pixelsPerSecond;

      if (index === 0) {
        return {value: datetime, xOffset: xCoordinate + 1, textAnchor: "start"};
      }

      if (index === dateTimes.length - 1) {
        return {value: datetime, xOffset: xCoordinate - 1, textAnchor: "end"};
      }

      return {value: datetime, xOffset: xCoordinate, textAnchor: "middle"};
    });
  }, [samplesPerSecond, startTime, totalSamples, width]);

  //---------------------------------------------------------------------------
  // Full viewer strip brush state management
  //---------------------------------------------------------------------------
  const pixelsPerSample = React.useMemo(() => width / totalSamples, [totalSamples, width]);

  const selection = React.useMemo(() => {
    const contextLengthInSeconds = 60;
    const totalContextSamples = samplesPerSecond * contextLengthInSeconds;

    // Calculate the starting and ending sample of the context viewer
    let contextStartSample = contextCenteredSample - totalContextSamples / 2;
    let contextEndSample = contextCenteredSample + totalContextSamples / 2;

    if (contextStartSample < 0) {
      contextStartSample = 0;
      contextEndSample = totalContextSamples;
    }

    // Convert samples into coordinates (pixels) on the viewer
    const startCoordinate = Math.round(contextStartSample * pixelsPerSample);
    const endCoordinate = Math.round(contextEndSample * pixelsPerSample);

    return [startCoordinate, endCoordinate];
  }, [contextCenteredSample, pixelsPerSample, samplesPerSecond]);

  const brushRef = React.useRef();
  const memoizedDrawCallback = React.useCallback(() => {
    const brush = d3
      .select("#full-viewer-brush")
      .call(brushRef.current)
      .call(brushRef.current.move, selection);

    const brushed = (event) => {
      if (event.selection) {
        const [startCoordinate, endCoordinate] = event.selection;

        if (startCoordinate !== endCoordinate) {
          const centeredCoordinate = (startCoordinate + endCoordinate) / 2;

          // Convert the coordinates (pixels) into samples so that the other
          // viewers can adjust
          setContextCenteredSample(Math.round(centeredCoordinate / pixelsPerSample));
        }
      }
    };
    const brushEnded = (event) => {
      if (!event.selection) {
        brush.call(brushRef.current.move, selection);
      }
    };

    brushRef.current = d3
      .brushX()
      .extent([
        [0, 1],
        [width, height + 1],
      ])
      .on("brush", brushed)
      .on("end", brushEnded);

    // Remove support for changing the width of the selection box (default d3 behavior)
    d3.selectAll(".handle").remove();
    d3.selectAll(".overlay").remove();
  }, [width, height, selection, pixelsPerSample, setContextCenteredSample]);

  React.useEffect(() => {
    memoizedDrawCallback();
  }, [brushRef, width, height, memoizedDrawCallback]);

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <svg width={width} height={height} style={{position: "absolute"}} data-cy="full">
      <g id="full-viewer-brush" />

      <line x1={0} x2={width} y1={1} y2={1} stroke="black" strokeWidth={2} />

      {timestamps.map(({value, xOffset, textAnchor}) => (
        <g key={value} transform={`translate(${xOffset}, 0)`}>
          <line y2="6" stroke="black" strokeWidth={2} />

          <text style={{fontSize: 12, textAnchor, transform: "translateY(17px)"}}>
            <DateAndTime datetime={value} format="HH:mm:ss" />
          </text>
        </g>
      ))}
    </svg>
  );
}

FullViewer.propTypes = {
  startTime: PropTypes.string.isRequired,
  totalSamples: PropTypes.number.isRequired,
  samplePeriod: PropTypes.number.isRequired,
  contextCenteredSample: PropTypes.number.isRequired,
  setContextCenteredSample: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default React.memo(FullViewer);
