import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import {useTheme} from "@mui/material/styles";
import Typography from "@mui/material/Typography";

function CollapsibleContent({
  // Props
  title,
  "data-cy": dataCy,
  children,
}) {
  //---------------------------------------------------------------------------
  // Expansion state management
  //---------------------------------------------------------------------------
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = React.useCallback(() => setExpanded((prev) => !prev), []);

  const theme = useTheme();

  return (
    <Accordion
      expanded={expanded}
      disableGutters
      TransitionProps={{unmountOnExit: true}}
      onChange={handleChange}
      sx={{border: `1px solid ${theme.palette.divider}`, "&:not(:last-child)": {borderBottom: 0}}}
      elevation={0}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        data-cy={dataCy}
        sx={{px: 4, backgroundColor: theme.palette.background.main}}
      >
        <Typography variant="subtitle1">{title}</Typography>
      </AccordionSummary>

      <AccordionDetails sx={{borderTop: `1px solid ${theme.palette.divider}`}}>
        {expanded && children}
      </AccordionDetails>
    </Accordion>
  );
}

CollapsibleContent.propTypes = {
  title: PropTypes.string.isRequired,
  "data-cy": PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default CollapsibleContent;
