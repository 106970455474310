/* @ngInject */
export default class StripClassificationsService {
  constructor($injector, backendConfig, $http, Authentication) {
    this._$injector = $injector;
    this._backendConfig = backendConfig;
    this._$http = $http;
    this._Authentication = Authentication;
  }

  /**
   * Gets all message options
   * @param {Object} params
   * @returns {Promise.<Object[], Object>} Promise that will be resolved with array of
   *                                          strip classification objects or rejected
   *                                          with response object
   */
  getAll(params) {
    const url = "/stripClassifications";
    return this._httpGet(url, params).then((response) => {
      return response.data.map((stripClassification) => stripClassification);
    });
  }

  _httpGet(url, params) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.get(`${baseUrl}${url}`, {
      headers: {
        Authorization: authHeader,
      },
      params,
    });
  }
}
