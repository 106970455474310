import React from "react";
import {DateTime, IANAZone} from "luxon";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import useArrhythmiaTimelineData from "../hooks/useArrhythmiaTimelineData.jsx";
import useHeartRateTrendData from "../hooks/useHeartRateTrendData.jsx";
import usePvcBurdenData from "../hooks/usePvcBurdenData.jsx";
import DonutCharts from "./elements/DonutCharts.jsx";
import HeartRateStatistics from "./elements/HeartRateStatistics.jsx";
import ReadingPhysicianComments from "./elements/ReadingPhysicianComments.jsx";
import ReportStrip from "./elements/ReportStrip.jsx";
import Sheet from "./elements/Sheet.jsx";
import StripIndexHeader from "./elements/StripIndexHeader.jsx";
import StripIndexRow from "./elements/StripIndexRow.jsx";
import Summary from "./elements/Summary.jsx";
import TechnicianFindings from "./elements/TechnicianFindings.jsx";

function onNextFrame(callback) {
  // Wait one event cycle to execute the callback before the browser's next repaint
  setTimeout(() => {
    requestAnimationFrame(callback);
  });
}

function GeneratedReport({
  // Props
  report,
  chartToggles,
  graphData,
  logo,
  item,
  study,
  strips,
  heartRateStatistics,
  setLoadingMessage,
}) {
  //---------------------------------------------------------------------------
  // Global variables and context
  //---------------------------------------------------------------------------
  const theme = useTheme();

  //---------------------------------------------------------------------------
  // Calculate the start and end times for the timeline charts
  //---------------------------------------------------------------------------
  const timelineStart = React.useMemo(() => {
    let startDate =
      report.reportType === "Daily Trend"
        ? DateTime.fromMillis(graphData.heartRateTrend?.boxPlots[0]?.startTime)
        : DateTime.fromISO(study.studyStartDate);

    // We need to use the study's time zone when using `useLocaleWeeks`
    if (study.timeZone && IANAZone.isValidZone(study.timeZone)) {
      startDate = startDate.setZone(study.timeZone);
    }

    if (report.reportType === "Summary") {
      return startDate.startOf("week", {useLocaleWeeks: true});
    }
    return startDate;
  }, [report.reportType, graphData.heartRateTrend, study.studyStartDate, study.timeZone]);

  const timelineEnd = React.useMemo(() => {
    let endDate =
      report.reportType === "Summary" ? DateTime.fromISO(study.studyEndDate) : timelineStart.plus({days: 1});

    // We need to use the study's time zone when using `useLocaleWeeks`
    if (study.timeZone && IANAZone.isValidZone(study.timeZone)) {
      endDate = endDate.setZone(study.timeZone);
    }

    if (report.reportType === "Summary") {
      return endDate.endOf("week", {useLocaleWeeks: true});
    }
    return endDate;
  }, [report.reportType, study.studyEndDate, study.timeZone, timelineStart]);

  //---------------------------------------------------------------------------
  // Charts
  //---------------------------------------------------------------------------
  const heartRateTrendCharts = useHeartRateTrendData({
    study,
    data: {
      arrhythmiaData: graphData.arrhythmiaData,
      averageHeartRates: graphData.heartRateTrend?.averageHeartRates,
      qrsExclusionRegions: graphData.qrsExclusionRegions,
    },
    startTime: timelineStart,
    endTime: timelineEnd,
    reportType: report.reportType,
  });

  const pvcBurdenCharts = usePvcBurdenData({
    study,
    data: {
      arrhythmiaData: graphData.arrhythmiaData,
      pvcData: graphData.pvcBurden,
      qrsExclusionRegions: graphData.qrsExclusionRegions,
    },
    startTime: timelineStart,
    endTime: timelineEnd,
    reportType: report.reportType,
  });

  const arrhythmiaTimelineCharts = useArrhythmiaTimelineData({
    study,
    data: {
      arrhythmiaData: graphData.arrhythmiaData,
      qrsExclusionRegions: graphData.qrsExclusionRegions,
    },
    startTime: timelineStart,
    endTime: timelineEnd,
    reportType: report.reportType,
  });

  const formatTimelineChartsForWrapping = React.useCallback(
    (charts, chartType) => {
      const border = `1px solid ${theme.palette.primary.dark}`;

      const title = {
        "heart-rate-trend": "Heart Rate Trend",
        "pvc-burden": "PVC Burden",
        "arrhythmia-timeline": "Arrhythmia Timeline",
      };

      return charts.reduce((formattedCharts, chart, index) => {
        let formattedChart = null;
        const id = `${chartType}-${index}`;

        if (index === 0) {
          // the first chart in the timeline needs a top and side borders
          formattedChart = (
            <Box
              sx={{
                mt: 1,
                p: 1,
                borderTop: border,
                borderRight: border,
                borderLeft: border,
                // if there is only one chart being displayed, add the bottom border
                ...(charts.length === 1 && {borderBottom: border}),
              }}
              className="report-element"
              id={id}
              key={id}
            >
              <Typography variant="subtitle2" section-header={title[chartType]}>
                {title[chartType]}
              </Typography>
              {chart}
            </Box>
          );
        } else if (index === charts.length - 1) {
          // the last chart in the timeline needs a bottom and side borders
          formattedChart = (
            <Box
              sx={{p: 1, borderBottom: border, borderRight: border, borderLeft: border}}
              className="report-element"
              id={id}
              key={id}
            >
              {chart}
            </Box>
          );
        } else {
          // all other charts (ones between the first and last chart in the timeline)
          // need borders on the sides
          formattedChart = (
            <Box
              sx={{p: 1, borderRight: border, borderLeft: border}}
              className="report-element"
              id={id}
              key={id}
            >
              {chart}
            </Box>
          );
        }

        return {...formattedCharts, [id]: formattedChart};
      }, {});
    },
    [theme]
  );

  //---------------------------------------------------------------------------
  // Generate the full list of elements that this report needs
  //---------------------------------------------------------------------------
  const [rendering, setRendering] = React.useState(true);
  const ref = React.useRef(null);
  const [sheets, setSheets] = React.useState([]);
  const elements = React.useMemo(
    () => ({
      summary: <Summary report={report} study={study} key="summary" />,

      "technician-findings": <TechnicianFindings report={report} study={study} key="technician-findings" />,

      "reading-physician-comments": (
        <ReadingPhysicianComments report={report} study={study} key="reading-physician-comments" />
      ),

      ...(["Summary", "Daily Trend"].includes(report.reportType) && {
        "donut-charts": (
          <DonutCharts
            report={report}
            study={study}
            chartToggles={chartToggles}
            graphData={graphData}
            key="donut-charts"
          />
        ),
      }),

      ...(chartToggles?.heartRateTrend &&
        ["Summary", "Daily Trend"].includes(report.reportType) &&
        report.studyType !== "cem" &&
        formatTimelineChartsForWrapping(heartRateTrendCharts, "heart-rate-trend")),

      ...(chartToggles?.pvcBurden &&
        ["Summary", "Daily Trend"].includes(report.reportType) &&
        report.studyType !== "cem" &&
        formatTimelineChartsForWrapping(pvcBurdenCharts, "pvc-burden")),

      ...(chartToggles?.arrhythmiaTimeline &&
        ["Summary", "Daily Trend"].includes(report.reportType) &&
        report.studyType !== "cem" &&
        formatTimelineChartsForWrapping(arrhythmiaTimelineCharts, "arrhythmia-timeline")),

      ...(chartToggles?.heartRateSummary &&
        ["Summary", "Daily Trend"].includes(report.reportType) && {
          "heart-rate-statistics": (
            <HeartRateStatistics
              heartRateStatistics={heartRateStatistics}
              study={study}
              key="heart-rate-statistics"
            />
          ),
        }),

      ...(["Summary", "Daily Trend"].includes(report.reportType) &&
        strips?.length > 0 && {
          ...strips.reduce(
            (stripElements, strip, index) => {
              const id = `strip-index-row-${strip.id}`;
              return {
                ...stripElements,
                [id]: (
                  <StripIndexRow
                    key={id}
                    strip={strip}
                    stripOrder={index + 1}
                    study={study}
                    heartRateStatistics={heartRateStatistics}
                    sheets={sheets}
                    isLastStripInTable={index === strips.length - 1}
                  />
                ),
              };
            },
            {
              "strip-index-header": <StripIndexHeader key="strip-index-header" />,
              "strip-index-header-continued": (
                <StripIndexHeader continued key="strip-index-header-continued" />
              ),
            }
          ),
        }),

      ...strips?.reduce((stripElements, strip, index) => {
        const id = `strip-${strip.id}`;
        return {
          ...stripElements,
          [id]: (
            <ReportStrip
              key={id}
              strip={strip}
              stripIndex={index + 1}
              study={study}
              beatMarkers={graphData.beatMarkers}
            />
          ),
        };
      }, {}),
    }),
    [
      report,
      study,
      chartToggles,
      graphData,
      formatTimelineChartsForWrapping,
      heartRateTrendCharts,
      pvcBurdenCharts,
      arrhythmiaTimelineCharts,
      heartRateStatistics,
      strips,
      sheets,
    ]
  );

  React.useEffect(() => {
    const sheetRef = ref.current;

    if (sheetRef && rendering) {
      setLoadingMessage("Rendering report");

      // Ensure that the paint cycle of the frame has completed before we calculate
      // the heights of each report element
      onNextFrame(() => {
        const sheetMaxHeight = 11 * 96; // 11in with 96dpi
        const sheetPadding = 24;
        const headerHeight = 65;
        const footerHeight = 21;

        const pageElements = [...sheetRef.getElementsByClassName("report-element")];

        // Need this element on hand for manually attaching it when wrapping strip index table
        const stripIndexHeaderElement = pageElements.find(
          (element) => element.id === "strip-index-header-continued"
        );

        const tempSheets = [];
        let currentSheet = [];
        let height = sheetPadding * 2 + headerHeight + footerHeight;

        // For each report element, calculate its height and add it to the current
        // sheet if there is enough room on that page
        pageElements.forEach((element) => {
          // We manually put on Strip Index (continued) header for continuing strips on the next page
          if (element.id && element.id !== "strip-index-header-continued") {
            const elementStyle = window.getComputedStyle(element);
            const elementHeight = element.offsetHeight + parseInt(elementStyle["margin-top"], 10);

            if (height + elementHeight < sheetMaxHeight) {
              // If this element fits on the current sheet, add it
              currentSheet.push(element.id);
              height += elementHeight;
            } else {
              // Add the filled sheet to the list of sheets
              tempSheets.push(currentSheet);

              // Move on to the next empty sheet
              currentSheet = [];
              height = sheetPadding * 2 + headerHeight + footerHeight;

              // When continuing the Strip Index table onto the next page, attach the table header first
              if (element.id.contains("strip-index-row-")) {
                const stripIndexHeaderStyle = window.getComputedStyle(stripIndexHeaderElement);
                const stripIndexHeaderHeight =
                  stripIndexHeaderElement.offsetHeight + parseInt(stripIndexHeaderStyle["margin-top"], 10);

                // push on the strip header first and then the strip row
                currentSheet.push(stripIndexHeaderElement.id, element.id);
                // take both elements into consideration for height
                height += stripIndexHeaderHeight + elementHeight;
              } else {
                currentSheet.push(element.id);
                height += elementHeight;
              }
            }
          }
        });
        tempSheets.push(currentSheet);

        setSheets(tempSheets);
        setRendering(false);
        setLoadingMessage("");
      });
    }
  }, [rendering, setLoadingMessage]);

  return (
    <Box ref={ref} width="8.5in" margin="auto">
      {/* Place elements on the DOM to determine their height during the rendering phase */}
      {rendering && (
        <Sheet report={report} logo={logo} item={item} study={study} hidden>
          {Object.values(elements)}
        </Sheet>
      )}

      {/* Once the initial rendering has completed, display the finalized report */}
      {!rendering &&
        sheets.length > 0 &&
        sheets.map((sheet, index) => (
          <Sheet
            key={sheet}
            report={report}
            logo={logo}
            item={item}
            study={study}
            pageNumber={index + 1}
            pages={sheets.length}
            margin
          >
            {sheet.map((elementId) => elements[elementId])}
          </Sheet>
        ))}
    </Box>
  );
}

GeneratedReport.propTypes = {
  report: PropTypes.object.isRequired,
  chartToggles: PropTypes.object,
  graphData: PropTypes.object.isRequired,
  logo: PropTypes.object.isRequired,
  item: PropTypes.object,
  study: PropTypes.object.isRequired,
  strips: PropTypes.array,
  heartRateStatistics: PropTypes.object,
  setLoadingMessage: PropTypes.func.isRequired,
};

export default GeneratedReport;
