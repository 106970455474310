//------------------------------------------------------------------------------
//             __             __   ___  __
//     | |\ | /  ` |    |  | |  \ |__  /__`
//     | | \| \__, |___ \__/ |__/ |___ .__/
//
//------------------------------------------------------------------------------

import React from "react";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import {blue, blueGrey, lightBlue} from "@mui/material/colors";
import {createTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import useEnvironmentVariables from "./components/hooks/useEnvironmentVariables.jsx";

//------------------------------------------------------------------------------
//                __          __        ___  __
//     \  /  /\  |__) |  /\  |__) |    |__  /__`
//      \/  /~~\ |  \ | /~~\ |__) |___ |___ .__/
//
//------------------------------------------------------------------------------

//------------------------------------------------------------------------------
//      __   __              ___  ___
//     |__) |__) | \  /  /\   |  |__
//     |    |  \ |  \/  /~~\  |  |___
//
//------------------------------------------------------------------------------

//-----------------------------------------------------------------------------
//      __        __          __
//     |__) |  | |__) |    | /  `
//     |    \__/ |__) |___ | \__,
//
//-----------------------------------------------------------------------------

// =============================================================================
const useCustomTheme = () => {
  //---------------------------------------------------------------------------
  // Global variables
  //---------------------------------------------------------------------------
  const {features} = useEnvironmentVariables();

  //---------------------------------------------------------------------------
  // Check the OS settings to see if we need to use Dark Mode
  //---------------------------------------------------------------------------
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)") && features.supportDarkMode;

  //---------------------------------------------------------------------------
  // Return a usable theme object each time the prefers-color-scheme value changes
  //---------------------------------------------------------------------------
  return React.useMemo(() => {
    return createTheme({
      palette: {
        mode: prefersDarkMode ? "dark" : "light",
        primary: {
          light: "#58a5ef",
          main: lightBlue[800],
          dark: "#004c8b",
          contrastText: "#ffffff",
        },
        secondary: {
          light: "#80d6ff",
          main: blue[400],
          dark: "#0077c2",
          contrastText: "#ffffff",
        },
        tertiary: {
          light: "#62727b",
          main: blueGrey[800],
          dark: "#102027",
          contrastText: "#ffffff",
        },
        error: {
          main: "#c62828",
        },
        warning: {
          main: "#ffab00",
        },
        info: {
          main: blue[400],
        },
        background: {
          main: "#f5f5f5",
          dark: "#eeeeee",
          gray: "#e9eaec",
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
      },
      typography: {
        cardRow: {
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          fontSize: "0.9375rem",
          lineHeight: "3rem",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
        h5: {
          fontWeight: "500",
        },
        reportText: {
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          fontSize: "0.75rem",
          wordBreak: "break-word",
        },
      },
      components: {
        MuiTypography: {
          defaultProps: {
            variantMapping: {
              cardRow: "div",
              reportText: "div",
            },
          },
        },
        MuiFormControlLabel: {
          styleOverrides: {
            label: {
              width: "100%",
            },
          },
        },
      },
    });
  }, [prefersDarkMode]);
};

export default useCustomTheme;
