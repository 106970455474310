import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import useEnvironmentVariables from "../hooks/useEnvironmentVariables.jsx";

//---------------------------------------------------------------------------
// Constants
//---------------------------------------------------------------------------
const MM_PER_SECOND = 25;
const MM_PER_SMALL_BOX = 1;
const MM_PER_LARGE_BOX = 5;
const MM_PER_TIME_MARKER = 25;

function GridLines({
  // Props
  width,
  height,
}) {
  //---------------------------------------------------------------------------
  // Global variables
  //---------------------------------------------------------------------------
  const {defaultStripLengthInSeconds} = useEnvironmentVariables();

  //---------------------------------------------------------------------------
  // Calculate the pixel distance between each grid line
  //---------------------------------------------------------------------------
  const pixelsPerMillimeter = React.useMemo(
    () => width / (defaultStripLengthInSeconds * MM_PER_SECOND),
    [width, defaultStripLengthInSeconds]
  );
  const minorGridLineWidth = React.useMemo(
    () => pixelsPerMillimeter * MM_PER_SMALL_BOX,
    [pixelsPerMillimeter]
  );
  const majorGridLineWidth = React.useMemo(
    () => pixelsPerMillimeter * MM_PER_LARGE_BOX,
    [pixelsPerMillimeter]
  );
  const timeMarkerWidth = React.useMemo(
    () => pixelsPerMillimeter * MM_PER_TIME_MARKER,
    [pixelsPerMillimeter]
  );

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <svg width={width} height={height} style={{position: "absolute"}}>
      <defs>
        <pattern
          id="minor-grid-lines"
          patternUnits="userSpaceOnUse"
          width={minorGridLineWidth}
          height={minorGridLineWidth}
        >
          <path
            d={`M ${minorGridLineWidth} 0 L 0 0 0 ${minorGridLineWidth}`}
            fill="none"
            strokeWidth="2"
            stroke="rgb(255, 245, 245)"
          />
        </pattern>

        <pattern
          id="major-grid-lines"
          patternUnits="userSpaceOnUse"
          width={majorGridLineWidth}
          height={majorGridLineWidth}
        >
          <path
            d={`M ${majorGridLineWidth} 0 L 0 0 0 ${majorGridLineWidth}`}
            fill="none"
            strokeWidth="2"
            stroke="rgb(255, 225, 225)"
          />
        </pattern>

        <pattern
          id="time-marker-grid-lines"
          patternUnits="userSpaceOnUse"
          width={timeMarkerWidth}
          height={timeMarkerWidth}
        >
          <path d={`M 0 ${timeMarkerWidth} L 0 0`} fill="none" strokeWidth="2" stroke="rgb(212, 138, 138)" />
        </pattern>
      </defs>

      <rect width={width} height={height} x="0" y="0" fill="url(#minor-grid-lines)" />
      <rect width={width} height={height} x="0" y="0" fill="url(#major-grid-lines)" />
      <rect width={width} height={height} x="0" y="0" fill="url(#time-marker-grid-lines)" />
    </svg>
  );
}

GridLines.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default React.memo(GridLines);
