import React from "react";
import findIndex from "lodash/findIndex";
import {DateTime} from "luxon";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import ArrhythmiaBurden from "../../study-graphs/ArrhythmiaBurden/ArrhythmiaBurden.jsx";
import ArrhythmiaEpisodes from "../../study-graphs/ArrhythmiaEpisodes/ArrhythmiaEpisodes.jsx";
import AtrialFibrillationStats from "../../study-graphs/AtrialFibrillationStats/AtrialFibrillationStats.jsx";
import VentricularEctopy from "../../study-graphs/VentricularEctopy/VentricularEctopy.jsx";

function getDisplayedRhythm(eventName) {
  if (eventName === null) {
    return "Unclassified";
  }

  if (eventName === "Artifact" || eventName === "Unreadable ECG Data") {
    return "Artifact/Unreadable";
  }

  return eventName;
}

function organizeRhythmTypes(arrhythmiaTypes) {
  const order = [
    // Instantaneous events
    "Patient Activated",
    "Pause",

    // Other events (sorted alphabetically)
    "AF",
    "Brady",
    "Other",
    "Tachy",
    "VTach",

    // Normal events
    "Normal",

    // Unreadable events
    "Lead Off",
    "Artifact/Unreadable",
    "Unclassified",
  ];

  return arrhythmiaTypes.sort((a, b) => {
    const aIndex = findIndex(order, (type) => a.name.startsWith(type));
    const bIndex = findIndex(order, (type) => b.name.startsWith(type));
    return aIndex - bIndex;
  });
}
function DonutCharts({
  // Props
  report,
  study,
  chartToggles,
  graphData,
}) {
  //---------------------------------------------------------------------------
  // Calculate data for Arrhythmia Episodes chart
  // @TODO study-trove should be doing these calculations
  //---------------------------------------------------------------------------
  const {arrhythmiaEpisodes = [], patientActivatedCount = 0} = React.useMemo(() => {
    // If the arrhythmia episodes chart is excluded from the report, don't bother
    // calculating the data
    if (!chartToggles?.arrhythmiaEpisode) {
      return {};
    }

    // Filter out classifications
    const classificationsToExclude = ["Artifact/Unreadable", "Normal", "Patient Activated", "Lead Off"];
    const filteredEvents = graphData.arrhythmiaData
      ?.map(({eventName}) => getDisplayedRhythm(eventName))
      .filter((event) => !classificationsToExclude.some((prefix) => event.startsWith(prefix)));
    const patientActivated = graphData.arrhythmiaData?.filter(
      ({eventName}) => eventName === "Patient Activated"
    );

    // Sum up the number of each classification and the total number of events
    const totalCount = filteredEvents.length;
    const countedEventsByName = filteredEvents.reduce((events, event) => {
      events[event] = (events[event] || 0) + 1;
      return events;
    }, {});

    const events = Object.entries(countedEventsByName).map(([name, count]) => ({
      name,
      count,
      percent: Math.round((count / totalCount) * 100),
    }));

    return {arrhythmiaEpisodes: organizeRhythmTypes(events), patientActivatedCount: patientActivated.length};
  }, [chartToggles?.arrhythmiaEpisode, graphData.arrhythmiaData]);

  //---------------------------------------------------------------------------
  // Calculate data for Arrhythmia Burden chart
  // @TODO study-trove should be doing these calculations
  //---------------------------------------------------------------------------
  const startTime = React.useMemo(() => {
    if (report.reportType === "Summary") {
      return DateTime.fromISO(study.studyStartDate);
    }
    return DateTime.fromISO(report.timestamp).minus({days: 1});
  }, [report.reportType, report.timestamp, study.studyStartDate]);
  const endTime = React.useMemo(() => DateTime.fromISO(report.timestamp), [report.timestamp]);

  const eventBurdens = React.useMemo(() => {
    // If the arrhythmia burden chart is excluded from the report, don't bother
    // calculating the data
    if (!chartToggles?.arrhythmiaBurden) {
      return [];
    }

    let {milliseconds: totalDuration} = endTime.diff(startTime).toObject();

    // For each event, calculate the total duration for that rhythm type while
    // accounting for artifact regions
    const arrhythmiaBurdenData = graphData.arrhythmiaData?.reduce(
      (burden, event) => {
        const eventStart = DateTime.fromISO(event.startTime);
        const eventEnd = DateTime.fromISO(event.endTime);
        let {milliseconds: eventDuration} = eventEnd.diff(eventStart).toObject();

        // Recalculate the event's duration to account for excluded regions
        graphData.qrsExclusionRegions?.forEach((region) => {
          const regionStart = DateTime.fromISO(region.startTime);
          const regionEnd = DateTime.fromISO(region.endTime);

          // Check whether the region overlaps with the event
          const eventOverlapsRegion = eventStart <= regionEnd && eventEnd >= regionStart;
          if (eventOverlapsRegion) {
            // Because the overlap duration between event and artifact region can never be greater than event duration,
            // calculate the overlap and use the value to update the event and artifact durations
            const maxStart = Math.max(regionStart.toMillis(), eventStart.toMillis());
            const minEnd = Math.min(regionEnd.toMillis(), eventEnd.toMillis());
            eventDuration -= minEnd - maxStart;
            burden["Artifact/Unreadable"] += minEnd - maxStart;
          }
        });

        // Don't need event for calculation unless duration is greater than 0
        if (eventDuration > 0) {
          const name = getDisplayedRhythm(event.eventName);
          burden[name] = (burden[name] || 0) + eventDuration;
        }

        return burden;
      },
      {"Artifact/Unreadable": 0}
    );

    // Don't need artifact regions for calculation unless duration is greater than 0
    if (arrhythmiaBurdenData["Artifact/Unreadable"] === 0) {
      delete arrhythmiaBurdenData["Artifact/Unreadable"];
    }

    // This fixes a bug when the amount of arrhythmias exceeds the duration of a report item
    const totalEventDuration = Object.values(arrhythmiaBurdenData).reduce(
      (sum, duration) => sum + duration,
      0
    );
    if (totalDuration < totalEventDuration) {
      totalDuration = totalEventDuration;
    }

    const burdenPercentages = Object.entries(arrhythmiaBurdenData).map(([name, duration]) => {
      const percent = (duration / totalDuration) * 100;
      let displayedPercent = null;

      // Format the displayed percentage (rounded to one decimal spot)
      if (percent < 0.05 && percent !== 0) {
        displayedPercent = "<0.1";
      } else {
        displayedPercent = percent.toFixed(1);
      }

      return {name, duration, percent: displayedPercent};
    });

    return organizeRhythmTypes(burdenPercentages);
  }, [
    chartToggles?.arrhythmiaBurden,
    endTime,
    graphData.arrhythmiaData,
    graphData.qrsExclusionRegions,
    startTime,
  ]);

  //---------------------------------------------------------------------------
  // Calculate data for Ventricular Ectopy chart
  // @TODO study-trove should be doing these calculations
  //---------------------------------------------------------------------------
  const {veGroupings = [], percent = ""} = React.useMemo(() => {
    // If the ventricular ectopy chart is excluded from the report, don't bother
    // calculating the data
    if (!chartToggles?.ventricularEctopy) {
      return {};
    }

    const {singles, couplets, triplets, runs, total, totalQrsLocations} = graphData.ventricularEctopy;

    // Calculate the percentages of each group of ventricular ectopy beats
    const totalGroupsOfVBeats = singles + couplets + triplets + runs;
    const groupingsWithPercentage = [
      {name: "Single V Beats", count: singles},
      {name: "Couplets", count: couplets},
      {name: "Triplets", count: triplets},
      {name: "Runs over 3", count: runs},
    ].map((grouping) => ({
      ...grouping,
      percent: Math.round((grouping.count / totalGroupsOfVBeats) * 100) || 0,
    }));

    // Calculate the percentage of ventricular ectopy beats from total beats
    let percentOfTotalBeats = null;
    if (totalQrsLocations > 0) {
      const percentNum = (total / totalQrsLocations) * 100;

      // Format the displayed percentage (rounded to one decimal spot)
      if (percentNum < 0.05 && percentNum !== 0) {
        percentOfTotalBeats = "<0.1";
      } else {
        percentOfTotalBeats = percentNum.toFixed(1);
      }
    }

    return {veGroupings: groupingsWithPercentage, percent: percentOfTotalBeats};
  }, [chartToggles?.ventricularEctopy, graphData.ventricularEctopy]);

  //---------------------------------------------------------------------------
  // Calculate data for AF Stats chart
  // @TODO study-trove should be doing these calculations
  //---------------------------------------------------------------------------
  const {
    afDurations = [],
    highestHr = 0,
    lowestHr = 0,
    longestDuration = 0,
  } = React.useMemo(() => {
    // If the AF stats chart is excluded from the report, don't bother
    // calculating the data
    if (!chartToggles?.atrialFibrillationStats) {
      return {};
    }

    const eventTypesToExclude = ["Unreadable ECG Data", "Artifact", "Lead Off"];

    // Calculate the AF stats by looping through each event
    const aFibStats = graphData.arrhythmiaData?.reduce(
      (stats, event) => {
        const eventStart = DateTime.fromISO(event.startTime);
        const eventEnd = DateTime.fromISO(event.endTime);
        let {milliseconds: eventDuration} = eventEnd.diff(eventStart).toObject();

        // Ignore all impulse and unreadable events from the stats
        if (eventDuration > 0 && !eventTypesToExclude.includes(event.eventName)) {
          stats.totalDuration += eventDuration;

          // Recalculate the event's duration to account for excluded regions
          graphData.qrsExclusionRegions?.forEach((region) => {
            const regionStart = DateTime.fromISO(region.startTime);
            const regionEnd = DateTime.fromISO(region.endTime);

            // Check whether the region overlaps with the event
            const eventOverlapsRegion = eventStart <= regionEnd && eventEnd >= regionStart;
            if (eventOverlapsRegion) {
              // Because the overlap duration between event and artifact region can never be greater than event duration,
              // calculate the overlap and use the value to update the event and artifact durations
              const maxStart = Math.max(regionStart.toMillis(), eventStart.toMillis());
              const minEnd = Math.min(regionEnd.toMillis(), eventEnd.toMillis());
              eventDuration -= minEnd - maxStart;
            }
          });

          // Compile AF stats (ignore AF events that are completely overlapped by artifact regions)
          if (eventDuration > 0 && (event.eventName === "Atrial Fibrillation" || event.eventName === "AF")) {
            stats.afDuration += eventDuration;

            // Highest Median Heart Rate
            if (
              typeof event.highestMedianHeartRate === "number" &&
              (stats.highestMedianHeartRate < event.highestMedianHeartRate ||
                stats.highestMedianHeartRate === null)
            ) {
              stats.highestMedianHeartRate = event.highestMedianHeartRate;
            }

            // Lowest Median Heart Rate
            if (
              typeof event.lowestMedianHeartRate === "number" &&
              (stats.lowestMedianHeartRate > event.lowestMedianHeartRate ||
                stats.lowestMedianHeartRate === null)
            ) {
              stats.lowestMedianHeartRate = event.lowestMedianHeartRate;
            }

            // Longest AF event
            if (eventDuration > stats.longestAfDuration) {
              stats.longestAfDuration = eventDuration;
            }
          }
        }

        return stats;
      },
      {
        totalDuration: 0,
        afDuration: 0,
        highestMedianHeartRate: null,
        lowestMedianHeartRate: null,
        longestAfDuration: 0,
      }
    );

    const durations = [
      {name: "In AF", duration: aFibStats.afDuration},
      {name: "Not in AF", duration: aFibStats.totalDuration - aFibStats.afDuration},
    ].map(({name, duration}) => ({
      name,
      duration,
      percent: Math.round((duration / aFibStats.totalDuration) * 100),
    }));

    return {
      afDurations: durations,
      highestHr: aFibStats.highestMedianHeartRate,
      lowestHr: aFibStats.lowestMedianHeartRate,
      longestDuration: aFibStats.longestAfDuration,
    };
  }, [chartToggles?.atrialFibrillationStats, graphData.arrhythmiaData, graphData.qrsExclusionRegions]);

  if (
    !chartToggles?.arrhythmiaEpisode &&
    !chartToggles?.arrhythmiaBurden &&
    ((!chartToggles?.ventricularEctopy && !chartToggles?.atrialFibrillationStats) ||
      report.studyType === "cem")
  ) {
    return null;
  }

  return (
    <Box
      sx={{mt: 1, p: 1, border: (theme) => `1px solid ${theme.palette.primary.dark}`}}
      className="report-element"
      id="donut-charts"
    >
      <Grid container>
        {chartToggles?.arrhythmiaEpisode && (
          <Grid size={6} data-cy="arrhythmia-episodes-donut-chart">
            <ArrhythmiaEpisodes
              id={report.id}
              study={study.id}
              arrhythmiaEpisodes={arrhythmiaEpisodes}
              patientActivatedCount={patientActivatedCount}
            />
          </Grid>
        )}

        {chartToggles?.arrhythmiaBurden && (
          <Grid size={6} data-cy="arrhythmia-burden-donut-chart">
            <ArrhythmiaBurden
              id={report.id}
              study={study.id}
              eventBurdens={eventBurdens}
              start={startTime}
              end={endTime}
            />
          </Grid>
        )}

        {chartToggles?.ventricularEctopy && report.studyType !== "cem" && (
          <Grid size={6} data-cy="ventricular-ectopy-donut-chart">
            <VentricularEctopy id={report.id} study={study.id} veGroupings={veGroupings} percent={percent} />
          </Grid>
        )}

        {chartToggles?.atrialFibrillationStats && report.studyType !== "cem" && (
          <Grid size={6} data-cy="af-stats-donut-chart">
            <AtrialFibrillationStats
              id={report.id}
              study={study.id}
              afDurations={afDurations}
              highestHr={highestHr}
              lowestHr={lowestHr}
              longestDuration={longestDuration}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

DonutCharts.propTypes = {
  report: PropTypes.object.isRequired,
  study: PropTypes.object.isRequired,
  chartToggles: PropTypes.object.isRequired,
  graphData: PropTypes.object.isRequired,
};

export default DonutCharts;
