import React from "react";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import {useRespectFilters} from "../../contexts/RespectFiltersContext.jsx";

function useArrayReducer(filters = []) {
  const {respectFilters} = useRespectFilters();

  return React.useCallback(
    (elements, action) => {
      switch (action.type) {
        case "init": {
          if (!Array.isArray(action.elements)) {
            throw Error("Elements must be initialized as an array");
          }

          return action.elements;
        }

        case "created": {
          if (!action.newElement || typeof action.newElement !== "object") {
            throw Error("Missing new element object");
          }

          return [...elements, action.newElement];
        }

        case "updated": {
          if (
            !action.updatedElement ||
            typeof action.updatedElement !== "object" ||
            !action.updatedElement?.id
          ) {
            throw Error("Missing updated element object");
          }

          const matchesFilterSelection = !filters.some(({filter, filterBy}) => {
            // this needs to be compared to false in case the value returned is undefined
            return filter && filter?.[action.updatedElement[filterBy]] === false;
          });

          const updatedElementArray = [];
          elements.forEach((element) => {
            if (element.id === action.updatedElement.id) {
              // If this element is included in the selected filters or we are ignoring all filters,
              // include this element
              if (matchesFilterSelection || !respectFilters) {
                updatedElementArray.push(action.updatedElement);
              }
            } else {
              updatedElementArray.push(element);
            }
          });

          return updatedElementArray;
        }

        case "deleted": {
          if (!action.id) {
            throw Error("Missing ID of deleted element");
          }

          return elements.filter((element) => element.id !== action.id);
        }

        default: {
          throw Error(`Unknown action: ${action.type}`);
        }
      }
    },
    [filters, respectFilters]
  );
}

export default useArrayReducer;
