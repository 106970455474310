import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";

function IconMenuButton({
  // Props
  children,
  icon,
  buttonSx,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpen = React.useCallback(
    (event) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );
  const handleClose = React.useCallback(
    (event) => {
      event.stopPropagation();
      setAnchorEl(null);
    },
    [setAnchorEl]
  );

  return (
    <>
      <IconButton sx={buttonSx} size="small" onClick={handleOpen} tabIndex={-1}>
        {icon}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{role: "listbox"}}
        anchorOrigin={{vertical: "top", horizontal: "right"}}
        transformOrigin={{vertical: "top", horizontal: "left"}}
      >
        {children}
      </Menu>
    </>
  );
}

IconMenuButton.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
  buttonSx: PropTypes.object,
};

export default React.memo(IconMenuButton);
