import React from "react";
import {useForm, useFormState} from "react-hook-form";
import {DateTime} from "luxon";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Close from "@mui/icons-material/Close";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import Save from "@mui/icons-material/Save";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid2";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import DateAndTime from "../../components/DateAndTime/DateAndTime.jsx";
import CancelButton from "./CancelButton.jsx";
import FormDateTimeInput from "./FormDateTimeInput.jsx";
import FormStringInput from "./FormStringInput.jsx";
import IconButtonWithTooltip from "./IconButtonWithTooltip.jsx";

function PatientDiaryNote({
  // Props
  study,
  index,
  note,
  onSave,
  onDelete,
  onUpdate,
  setError,
}) {
  //---------------------------------------------------------------------------
  // Modal state management
  //---------------------------------------------------------------------------
  const {handleSubmit, control} = useForm();
  const [editing, setEditing] = React.useState(!note.id);
  const [loadingOperation, setLoadingOperation] = React.useState(null);
  const {isDirty} = useFormState({control});
  const studyId = study.studyId || study.id;

  const handleEdit = React.useCallback(() => {
    setEditing(true);
  }, []);

  const handleDelete = React.useCallback(async () => {
    setLoadingOperation("delete");
    try {
      await onDelete(note.id, note.tempId);
    } catch (err) {
      setError(err.response?.data?.message || err.response?.data?.error || err.message);
    }
    setLoadingOperation(null);
  }, [onDelete, setError, setLoadingOperation, note]);

  const handleCancel = React.useCallback(async () => {
    setEditing(false);
  }, [setEditing]);

  const handleSave = React.useCallback(
    async (data) => {
      setLoadingOperation("save");
      try {
        const noteToSave = {
          studyId,
          timestamp: data.timestamp,
          symptom: data.symptom,
          activity: data.activity,
          comments: data.comments,
        };
        if (!note.id) {
          await onSave(noteToSave, note.tempId);
        } else {
          const propertiesToUpdate = {};
          if (data.timestamp !== note.timestamp) {
            propertiesToUpdate.timestamp = data.timestamp;
          }
          if (data.activity !== note.activity) {
            propertiesToUpdate.activity = data.activity;
          }
          if (data.symptom !== note.symptom) {
            propertiesToUpdate.symptom = data.symptom;
          }
          if (data.comments !== note.comments) {
            propertiesToUpdate.comments = data.comments;
          }
          if (Object.keys(propertiesToUpdate).length !== 0) {
            try {
              await onUpdate(note.id, propertiesToUpdate);
            } catch (err) {
              if (err.response?.status === 404) {
                await onSave(noteToSave, note.tempId);
              } else {
                throw err;
              }
            }
          }
        }
        setEditing(false);
      } catch (err) {
        setError(err.response?.data?.message || err.response?.data?.error || err.message);
      }
      setLoadingOperation(null);
    },
    [onUpdate, onSave, setError, setLoadingOperation, note, studyId]
  );

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  if (editing) {
    return (
      <Grid
        container
        columns={20}
        columnSpacing={2}
        rowSpacing={{xs: 1, md: 0}}
        data-cy={`patient-diary-note-editable-${index}`}
        sx={{pr: 2}}
      >
        {/* DATE AND TIME */}
        <Grid size={{xs: 20, md: 5}}>
          <FormDateTimeInput
            control={control}
            defaultValue={DateTime.fromISO(note.timestamp)}
            name="timestamp"
            label="Date and Time"
            id={`edit-timestamp-input-${index}`}
            required
            disabled={!!loadingOperation}
            timeZone={study.timeZone}
            rules={{
              required: "Date and Time is required",
              validate: (value) => {
                if (value.invalid) {
                  return "Please enter a valid date";
                }
                if (value > DateTime.now().setZone(study.timeZone)) {
                  return "Date cannot be in the future";
                }
                return true;
              },
            }}
            size="small"
            variant="outlined"
            otherProps={{
              autoFocus: true,
              timeSteps: {minutes: 1, seconds: 1},
              views: ["year", "day", "hours", "minutes", "seconds"],
              ampm: false,
              disableFuture: true,
            }}
          />
        </Grid>

        {/* SYMPTOM */}
        <Grid size={{xs: 20, md: 4}}>
          <FormStringInput
            control={control}
            defaultValue={note.symptom}
            name="symptom"
            label="Symptom"
            disabled={!!loadingOperation}
            id={`edit-symptom-input-${index}`}
            rules={{required: "Symptom is required"}}
            size="small"
            otherProps={{variant: "outlined"}}
          />
        </Grid>

        {/* ACTIVITY */}
        <Grid size={{xs: 20, md: 4}}>
          <FormStringInput
            control={control}
            defaultValue={note.activity}
            name="activity"
            label="Activity"
            disabled={!!loadingOperation}
            id={`edit-activity-input-${index}`}
            rules={{required: "Activity is required"}}
            size="small"
            otherProps={{variant: "outlined"}}
          />
        </Grid>

        {/* ADDITIONAL COMMENTS */}
        <Grid size="grow">
          <FormStringInput
            control={control}
            defaultValue={note.comments}
            name="comments"
            label="Additional Comments"
            disabled={!!loadingOperation}
            id={`edit-comments-input-${index}`}
            required={false}
            size="small"
            otherProps={{variant: "outlined", multiline: true, maxRows: 3}}
          />
        </Grid>

        {/* ACTION BUTTONS */}
        <Grid size="auto">
          {loadingOperation === "save" && <CircularProgress color="secondary" size={25} />}
          {loadingOperation !== "save" && (
            <IconButtonWithTooltip
              title="Save Diary Note"
              color="secondary"
              data-cy={`save-diary-note-button-${index}`}
              onClick={handleSubmit(handleSave)}
              otherProps={{disabled: !!loadingOperation, size: "small"}}
            >
              <Save />
            </IconButtonWithTooltip>
          )}
          {loadingOperation === "delete" && <CircularProgress color="secondary" size={25} />}
          {loadingOperation !== "delete" && !note.id && (
            <CancelButton
              color="secondary"
              isDirty={isDirty}
              onClick={handleDelete}
              disabled={!!loadingOperation}
              buttonClass="noMinWidth"
              key={`delete-diary-note-button-${index}`}
              data-cy={`delete-diary-note-button-${index}`}
              title="Delete Diary Note?"
              buttonText="Delete"
            >
              <Tooltip title="Delete Diary Note">
                <Delete />
              </Tooltip>
            </CancelButton>
          )}
          {loadingOperation !== "delete" && note.id && (
            <CancelButton
              color="secondary"
              isDirty={isDirty}
              onClick={handleCancel}
              disabled={!!loadingOperation}
              buttonClass="noMinWidth"
              key={`cancel-edit-diary-note-button-${index}`}
              data-cy={`cancel-edit-diary-note-button-${index}`}
            >
              <Tooltip title="Cancel">
                <Close />
              </Tooltip>
            </CancelButton>
          )}
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      columnSpacing={2}
      columns={20}
      rowSpacing={{xs: 1, md: 0}}
      container
      data-cy={`patient-diary-note-${index}`}
      sx={{pr: 2}}
    >
      {/* DATE AND TIME */}
      <Grid size={{xs: 20, md: 5}} data-cy={`timestamp-field-${index}`}>
        <Typography variant="body2">
          <DateAndTime datetime={note.timestamp} zone={study.timeZone} seconds />
        </Typography>
      </Grid>

      {/* SYMPTOM */}
      <Grid size={{xs: 20, md: 4}} data-cy={`symptom-field-${index}`}>
        <Typography variant="body2">{note.symptom}</Typography>
      </Grid>

      {/* ACTIVITY */}
      <Grid size={{xs: 20, md: 4}} data-cy={`activity-field-${index}`}>
        <Typography variant="body2">{note.activity}</Typography>
      </Grid>

      {/* ADDITIONAL COMMENTS */}
      <Grid size="grow" data-cy={`comments-field-${index}`} sx={{width: "25%"}}>
        <Typography variant="body2">{note.comments}</Typography>
      </Grid>

      {/* ACTION BUTTONS */}
      <Grid size="auto">
        <IconButtonWithTooltip
          title="Edit Diary Note"
          color="secondary"
          data-cy={`edit-diary-note-button-${index}`}
          onClick={handleEdit}
          otherProps={{
            disabled: !!loadingOperation,
            tabIndex: -1,
            size: "small",
          }}
        >
          <Edit />
        </IconButtonWithTooltip>
        {loadingOperation === "delete" && <CircularProgress color="secondary" size={25} />}
        {loadingOperation !== "delete" && (
          <CancelButton
            disabled={!!loadingOperation}
            color="secondary"
            isDirty
            onClick={handleDelete}
            buttonClass="noMinWidth"
            data-cy={`delete-diary-note-button-${index}`}
            title="Delete Diary Note?"
            warningMessage="This action cannot be undone."
            buttonText="Delete"
          >
            <Tooltip title="Delete Diary Note">
              <Delete />
            </Tooltip>
          </CancelButton>
        )}
      </Grid>
    </Grid>
  );
}

PatientDiaryNote.propTypes = {
  study: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  note: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
};

export default PatientDiaryNote;
