import React from "react";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import AddProviders from "../../shared/react/AddProviders.jsx";
import FacilityNotification from "./FacilityNotification.jsx";

function FacilityNotificationWrapper() {
  //---------------------------------------------------------------------------
  // NOTE: The only reason that this file is separate from FacilityNotification.jsx is because of Angular.
  //  Once we switch completely from Angular to React, AddProviders can be moved to an App.jsx so
  //  that it encapsulates the entire application. Then this file can be deleted.
  //---------------------------------------------------------------------------
  return (
    <AddProviders>
      <FacilityNotification />
    </AddProviders>
  );
}

export default FacilityNotificationWrapper;
