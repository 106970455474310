import React from "react";
import {DateTime} from "luxon";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import AddCircle from "@mui/icons-material/AddCircle";
import Backspace from "@mui/icons-material/Backspace";
import ChangeCircle from "@mui/icons-material/ChangeCircle";
import Dangerous from "@mui/icons-material/Dangerous";
import EditCalendar from "@mui/icons-material/EditCalendar";
import Message from "@mui/icons-material/Message";
import MonitorHeart from "@mui/icons-material/MonitorHeart";
import MoreVert from "@mui/icons-material/MoreVert";
import PlayCircle from "@mui/icons-material/PlayCircle";
import Settings from "@mui/icons-material/Settings";
import StopCircle from "@mui/icons-material/StopCircle";
import SwapHorizontalCircle from "@mui/icons-material/SwapHorizontalCircle";
import SyncProblem from "@mui/icons-material/SyncProblem";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import useEnvironmentVariables from "../../../../components/hooks/useEnvironmentVariables.jsx";
import useJwt from "../../../../components/hooks/useJwt.jsx";
import InboxEntityContext from "../../../../contexts/InboxEntityContext.jsx";
import StudyDetailsDialog from "../../StudyDetails/StudyDetailsDialog.jsx";
import ArchiveStudyDialog from "../ArchiveStudyDialog.jsx";
import AssignStudyDialog from "../AssignStudyDialog.jsx";
import DeleteStudyDialog from "../DeleteStudyDialog.jsx";
import StudyActionsDialog from "../StudyActionsDialog.jsx";

function StudyActionsMenu({
  // Props
  study,
}) {
  //---------------------------------------------------------------------------
  // Global variables
  //---------------------------------------------------------------------------
  const {isInAnyRole} = useJwt();
  const {features} = useEnvironmentVariables();

  const {type: parentType = "inbox-item"} = React.useContext(InboxEntityContext) || {};

  //---------------------------------------------------------------------------
  // State and callbacks for the menu
  //---------------------------------------------------------------------------
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpen = React.useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = React.useCallback(() => setAnchorEl(null), []);

  //---------------------------------------------------------------------------
  // Helper Function
  //---------------------------------------------------------------------------
  const status = React.useMemo(() => {
    if (study.studyState === "active" && !study.dataReceived) {
      return "pending";
    }

    if (study.studyState === "active" && study.dataReceived && !study.studyEndDate) {
      return "recording";
    }

    if (study.studyState === "active" && study.dataReceived && study.studyEndDate) {
      return "doneRecording";
    }

    return study.studyState;
  }, [study.studyState, study.dataReceived, study.studyEndDate]);

  // SECTION 1: Study operations
  const studyOperations = React.useMemo(() => {
    const operations = [];
    if (parentType === "study" && study.studyState !== "archived") {
      operations.push(
        <StudyDetailsDialog
          study={study}
          studyId={study.id}
          studyFacilityId={study.facilityId}
          key={`${study.id}-details-dialog`}
        />
      );
    }
    if (parentType === "study") {
      operations.push(<AssignStudyDialog study={study} key={`${study.id}-assign-dialog`} />);
    }
    if (parentType === "study" && ["finalized", "archived"].includes(status) && !isInAnyRole(["physician"])) {
      operations.push(<ArchiveStudyDialog study={study} key={`${study.id}-archive-dialog`} />);
    }
    return operations;
  }, [parentType, isInAnyRole, status, study]);

  // SECTION 2: "Safe" study actions; Note: array of objects to map to <StudyActionsDialog />
  const actionTypes = React.useMemo(() => {
    const types = [
      {
        name: "forceActionsFailure",
        "data-cy": `force-actions-failure-button-${study.id}`,
        readableName: "Force Actions Failure",
        restriction:
          isInAnyRole(["tzAdmin"]) &&
          !["holter", "extendedHolter"].includes(study.studyType) &&
          ["pending", "recording", "doneRecording", "finalized"].includes(status) &&
          !study.currentEnrollment?.formatted,
        icon: <SyncProblem fontSize="small" />,
      },
      {
        name: "requestEcgData",
        "data-cy": `request-ecg-data-button-${study.id}`,
        readableName: "Request ECG Data",
        restriction:
          !isInAnyRole(["physician"]) &&
          !["holter", "extendedHolter"].includes(study.studyType) &&
          ["pending", "recording", "doneRecording", "finalized"].includes(status) &&
          (!study.currentEnrollment?.formatted || study.studyType === "mctWithFullDisclosure"),
        icon: <MonitorHeart fontSize="small" />,
      },
      {
        name: "sendMessage",
        "data-cy": `send-message-button-${study.id}`,
        readableName: "Send Message to Patient",
        restriction:
          !isInAnyRole(["physician"]) &&
          !["holter", "extendedHolter"].includes(study.studyType) &&
          ["pending", "recording", "doneRecording", "finalized"].includes(status) &&
          !study.currentEnrollment?.formatted,
        icon: <Message fontSize="small" />,
      },
      {
        name: "updateStartDate",
        "data-cy": `change-study-start-date-button-${study.id}`,
        readableName: "Change Study Start Date",
        restriction:
          !isInAnyRole(["physician"]) &&
          ["holter", "extendedHolter"].includes(study.studyType) &&
          study.dataReceived,
        icon: <EditCalendar fontSize="small" />,
      },
      {
        name: "updateSettings",
        "data-cy": `change-device-settings-button-${study.id}`,
        readableName: "Change Device Settings",
        restriction:
          !isInAnyRole(["physician"]) &&
          (["pending", "recording"].includes(status) ||
            (!["holter", "extendedHolter"].includes(study.studyType) && status === "doneRecording")) &&
          !study.currentEnrollment?.formatted &&
          (status !== "pending" || !study.initialStudyAssociation),
        icon: <Settings fontSize="small" />,
      },
      {
        name: "replaceDevice",
        "data-cy": `replace-device-button-${study.id}`,
        readableName: "Replace Device on Patient",
        restriction:
          !isInAnyRole(["physician"]) &&
          !study.studyType.toLowerCase().endsWith("holter") &&
          ["recording", "doneRecording"].includes(status) &&
          !study.currentEnrollment?.formatted,
        icon: <SwapHorizontalCircle fontSize="small" />,
      },
      {
        name: "removeDowngradeAuthorization",
        "data-cy": `remove-downgrade-authorization-button-${study.id}`,
        readableName: "Remove Downgrade Authorization",
        restriction:
          !isInAnyRole(["physician"]) &&
          (study.studyType.startsWith("mct") || study.studyType.toLowerCase().includes("extendedholter")) &&
          ["pending", "recording", "doneRecording", "finalized"].includes(status) &&
          study.downgradeAuthorized === true &&
          features.downgradeAuthorized,
        icon: <Backspace fontSize="small" />,
      },
      {
        name: "addDowngradeAuthorization",
        "data-cy": `add-downgrade-authorization-button-${study.id}`,
        readableName: "Add Downgrade Authorization",
        restriction:
          !isInAnyRole(["physician"]) &&
          (study.studyType.startsWith("mct") || study.studyType.toLowerCase().includes("extendedholter")) &&
          ["pending", "recording", "doneRecording", "finalized"].includes(status) &&
          study.downgradeAuthorized === false &&
          features.downgradeAuthorized,
        icon: <AddCircle fontSize="small" />,
      },
    ];

    return types.filter((type) => type.restriction);
  }, [
    study.id,
    study.studyType,
    study.currentEnrollment?.formatted,
    study.dataReceived,
    study.initialStudyAssociation,
    study.downgradeAuthorized,
    isInAnyRole,
    status,
    features.downgradeAuthorized,
  ]);

  // SECTION 3: "Dangerous" study actions; Note: array of objects to map to <StudyActionsDialog />
  const dangerActionTypes = React.useMemo(() => {
    const enrollmentStartTime = DateTime.fromISO(
      study.allEnrollments[study.allEnrollments.length - 1].enrollmentStartTime
    );
    const elapsedStudyDays = Math.floor(DateTime.now().diff(enrollmentStartTime, ["days"]).days);
    const maxTotalStudyDays = 30;

    const types = [
      {
        name: "endStudy",
        "data-cy": `end-study-button-${study.id}`,
        readableName: study.followUpStudyAssociation ? "Transition to Follow-up" : "End Study",
        restriction:
          !isInAnyRole(["physician"]) &&
          status === "recording" &&
          !["holter", "extendedHolter"].includes(study.studyType) &&
          !study.currentEnrollment?.formatted,
        icon: <StopCircle fontSize="small" />,
      },
      {
        name: "resumeStudy",
        "data-cy": `resume-study-button-${study.id}`,
        readableName: "Resume Study",
        restriction:
          !isInAnyRole(["physician"]) &&
          !["holter", "extendedHolter"].includes(study.studyType) &&
          status === "doneRecording" &&
          !study.currentEnrollment?.formatted &&
          elapsedStudyDays < maxTotalStudyDays,
        icon: <PlayCircle fontSize="small" />,
      },
      {
        name: "markStudyAsFailed",
        "data-cy": `mark-study-as-failed-button-${study.id}`,
        readableName: "Mark Study as Failed",
        restriction:
          features.markStudyAsFailed &&
          !isInAnyRole(["physician"]) &&
          (["doneRecording", "finalized"].includes(status) ||
            (["holter", "extendedHolter"].includes(study.studyType) && status === "recording")),
        icon: <Dangerous fontSize="small" />,
      },
      {
        name: "convertToCem",
        "data-cy": `convert-to-cem-button-${study.id}`,
        readableName: "Downgrade to CEM Study",
        restriction:
          !isInAnyRole(["physician"]) &&
          study.studyType.startsWith("mct") &&
          ["pending", "recording", "doneRecording", "finalized"].includes(status) &&
          (status !== "pending" || !study.initialStudyAssociation),
        icon: <ChangeCircle fontSize="small" />,
      },
    ];

    return types.filter((type) => type.restriction);
  }, [study, isInAnyRole, status, features.markStudyAsFailed]);

  // SECTION 4: "Critical" study operations
  const criticalOperations = React.useMemo(() => {
    const types = [];
    if (status === "pending" && !study.initialStudyAssociation) {
      types.push(<DeleteStudyDialog key="delete-study" study={study} />);
    }
    return types;
  }, [status, study]);

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <>
      <IconButton data-cy={`study-actions-menu-button-${study.id}`} color="secondary" onClick={handleOpen}>
        <MoreVert />
      </IconButton>

      <Menu
        data-cy={`study-actions-menu-list-${study.id}`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        keepMounted
        onClick={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuList dense>
          {studyOperations}

          {/* show if there are more than 0 items in the section above, and more than 0 items item somewhere below */}
          {studyOperations.length > 0 &&
            actionTypes.length + dangerActionTypes.length + criticalOperations.length > 0 && <Divider />}

          {actionTypes.map((action) => (
            <StudyActionsDialog
              key={action.name}
              study={study}
              action={action}
              Icon={action.icon}
              actionTypes={[...actionTypes, ...dangerActionTypes]}
            />
          ))}

          {actionTypes.length > 0 && dangerActionTypes.length + criticalOperations.length > 0 && <Divider />}

          {dangerActionTypes.map((action) => (
            <StudyActionsDialog
              key={action.name}
              study={study}
              action={action}
              Icon={action.icon}
              actionTypes={[...actionTypes, ...dangerActionTypes]}
            />
          ))}

          {dangerActionTypes.length > 0 && criticalOperations.length > 0 && <Divider data-cy="delete" />}

          {criticalOperations}
        </MenuList>
      </Menu>
    </>
  );
}

StudyActionsMenu.propTypes = {
  study: PropTypes.object.isRequired,
};

export default StudyActionsMenu;
