import React from "react";
import {Controller} from "react-hook-form";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid2";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

function FormRadioInput({
  // Props
  control,
  name,
  children,
  options,
  id,
  disabledOption = () => false,
  row = true,
  ratio = [6, 6],
}) {
  return (
    <Grid container sx={{alignItems: "center"}}>
      <Grid size={row ? ratio[0] : 12}>
        <Grid id={id} container sx={{alignItems: "center"}}>
          {children}
        </Grid>
      </Grid>
      <Grid size={row ? ratio[1] : 12}>
        <Controller
          name={name}
          control={control}
          shouldUnregister
          render={({field: {onChange, value}}) => {
            return (
              <RadioGroup row={row} name={name} value={value} onChange={onChange}>
                {options.map((option) => (
                  <FormControlLabel
                    id={`${id}-${option.id}`}
                    disabled={disabledOption(option)}
                    key={`${id}-${option.id}`}
                    value={option.id}
                    label={option.name}
                    control={<Radio size="small" />}
                  />
                ))}
              </RadioGroup>
            );
          }}
        />
      </Grid>
    </Grid>
  );
}

FormRadioInput.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  options: PropTypes.array.isRequired,
  id: PropTypes.string,
  disabledOption: PropTypes.func,
  row: PropTypes.bool,
  ratio: PropTypes.array,
};

export default FormRadioInput;
