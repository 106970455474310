import React from "react";
import countBy from "lodash/countBy.js";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import {grey} from "@mui/material/colors";
import Grid from "@mui/material/Grid2";
import {useTheme} from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import TextWithNewlines from "../../../shared/react/TextWithNewlines.jsx";
import DateAndTime from "../../DateAndTime/DateAndTime.jsx";
import EcgViewer from "../../EcgViewer/EcgViewer.jsx";

function ReportStrip({
  // Props
  strip,
  stripIndex,
  study,
  beatMarkers = [],
}) {
  const theme = useTheme();
  const borderStyling = React.useMemo(() => `2px solid ${theme.palette.background.main}`, [theme]);

  //---------------------------------------------------------------------------
  // Determine displayed values for strip description
  //---------------------------------------------------------------------------
  const stripTimestamp = React.useMemo(() => {
    if (!strip.validStartTime) {
      return "Unknown Timestamp";
    }

    return <DateAndTime datetime={strip.startTime} zone={study.timeZone} seconds />;
  }, [strip.startTime, strip.validStartTime, study.timeZone]);

  const stripMeasurementsExist = React.useMemo(() => {
    return Object.values(strip.measurements).some(
      (measurement) => measurement.data && measurement.data.length > 0
    );
  }, [strip.measurements]);

  const measurementsTable = React.useMemo(() => {
    const columns = [
      {name: "HR", label: "HR bpm"},
      {name: "RR", label: "R-R ms"},
      {name: "PR", label: "PR ms"},
      {name: "QRS", label: "QRS ms"},
      {name: "QT", label: "QT ms"},
    ];

    return columns.map(({name, label}) => {
      const {count, min, max, mean} = strip.measurements[name];

      const header = `${label} - (${count})`;
      let value = "--";

      if (count === 1) {
        value = min;
      } else if (count > 1) {
        value = `${min} - ${mean} - ${max}`;
      }

      return {name, header, value};
    });
  }, [strip.measurements]);

  const triggerType = React.useMemo(() => {
    const isRhythmChange = strip.event.rhythm !== null;
    const isPatientActivated = strip.event.symptom !== null;
    const isEcgDataRequest = strip.event.comment !== null;
    const isBaseline = strip.event.settingsFileId !== null;

    if (isRhythmChange || isBaseline) {
      return "Automatic";
    }
    if (isPatientActivated || isEcgDataRequest) {
      return "Manual";
    }
    return "Other";
  }, [strip.event.comment, strip.event.rhythm, strip.event.settingsFileId, strip.event.symptom]);

  const triggerDescription = React.useMemo(() => {
    const isPatientActivated = strip.event.symptom !== null;
    const isEcgDataRequest =
      strip.event.comment !== null &&
      strip.event.symptom === null &&
      strip.event.settingsFileId === null &&
      strip.event.rhythm === null;
    const isBaseline = strip.event.settingsFileId !== null;

    if (isPatientActivated) {
      return [`Symptom: ${strip.event.symptom}`, `Activity: ${strip.event.activityLevel}`];
    }
    if (isEcgDataRequest) {
      return ["Remote Data Request"];
    }
    if (isBaseline) {
      return ["Baseline"];
    }
    return [];
  }, [strip.event]);

  const eventClassification = React.useMemo(() => {
    if (strip.eventClassification) {
      return strip.eventClassification;
    }

    const userClassification = strip.event.userClassification || "";
    const isRhythmChange = strip.event.rhythm !== null;

    if (isRhythmChange && !strip.event.userClassification) {
      return strip.event.rhythm;
    }
    return userClassification;
  }, [strip.event.rhythm, strip.event.userClassification, strip.eventClassification]);

  const beatMarkerCounts = React.useMemo(() => {
    const stripBeatMarkers = beatMarkers?.find((e) => e.eventId === strip.eventId) || [];

    const displayedCounts = [];
    let pauseCount;
    let pvcCount;
    let pacCount;

    if (Array.isArray(stripBeatMarkers)) {
      pauseCount = countBy(stripBeatMarkers, (b) => b.name === "Pause");
      pvcCount = countBy(stripBeatMarkers, (b) => b.name === "PVC");
      pacCount = countBy(stripBeatMarkers, (b) => b.name === "PAC");
    } else {
      pauseCount = stripBeatMarkers.Pause;
      pvcCount = stripBeatMarkers.PVC;
      pacCount = stripBeatMarkers.PAC;
    }

    if (pauseCount > 0) {
      displayedCounts.push(`Pause (${pauseCount})`);
    }
    if (pvcCount > 0) {
      displayedCounts.push(`PVC (${pvcCount})`);
    }
    if (pacCount > 0) {
      displayedCounts.push(`PAC (${pacCount})`);
    }

    return displayedCounts.join("; ");
  }, [beatMarkers, strip.eventId]);

  return (
    <Box
      sx={{mt: 1, border: `1px solid ${theme.palette.primary.dark}`}}
      className="report-element"
      id={`strip-${strip.id}`}
      data-cy={`strip-${strip.id}`}
    >
      {/* STRIP HEADER */}
      <Grid
        container
        columns={20}
        sx={{p: 0.5}}
        section-header={`${stripIndex}: ${strip.userClassification}`}
      >
        <Grid size={1}>
          <Typography variant="reportText" data-cy="strip-order">
            <b>{stripIndex}</b>
          </Typography>
        </Grid>
        <Grid size={8}>
          <Typography variant="reportText" data-cy="strip-classification">
            <b>{strip.userClassification}</b>
          </Typography>
        </Grid>
        <Grid size={11}>
          <Typography variant="reportText" data-cy="strip-timestamp">
            <b>{stripTimestamp}</b>
          </Typography>
        </Grid>
      </Grid>

      {/* STRIP */}
      <EcgViewer ecg={strip.event?.ecg} strip={strip} />

      {/* STRIP FOOTER AND DESCRIPTION */}
      <Grid container>
        {stripMeasurementsExist && (
          <Grid size={6} sx={{p: 0.5, borderRight: borderStyling}}>
            <Table size="small" padding="none" data-cy="strip-measurements-table">
              <TableHead>
                <TableRow>
                  {measurementsTable.map(({name, header}) => (
                    <TableCell key={name} align="center" data-cy={`measurement-count-${name}`}>
                      <Typography variant="reportText">{header}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  {measurementsTable.map(({name, value}, index) => (
                    <TableCell
                      key={name}
                      align="center"
                      sx={{
                        ...(index !== measurementsTable.length - 1 && {
                          borderRight: `1px solid ${grey[300]}`,
                        }),
                      }}
                      data-cy={`measurement-value-${name}`}
                    >
                      <Typography variant="reportText">{value}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        )}

        <Grid size={stripMeasurementsExist ? 3 : 6} sx={{p: 0.5, borderRight: borderStyling}}>
          <Typography variant="reportText" data-cy="strip-trigger">
            <b>Trigger:</b> {triggerType}
          </Typography>
          {triggerDescription.map((line) => (
            <Typography key={line} variant="reportText">
              {line}
            </Typography>
          ))}
        </Grid>

        <Grid size={stripMeasurementsExist ? 3 : 6} sx={{p: 0.5}}>
          <Typography variant="reportText" data-cy="event-classification">
            <b>Event Classification:</b> {eventClassification}
          </Typography>
          <Typography variant="reportText" data-cy="beat-marker-counts">
            {beatMarkerCounts}
          </Typography>
        </Grid>

        <Grid size={12} sx={{p: 0.5, borderTop: borderStyling}}>
          <TextWithNewlines
            text={
              <>
                <b>Findings:</b> {strip.comment}
              </>
            }
            variant="reportText"
            data-cy="strip-comment"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

ReportStrip.propTypes = {
  strip: PropTypes.object.isRequired,
  stripIndex: PropTypes.number.isRequired,
  study: PropTypes.object.isRequired,
  beatMarkers: PropTypes.array,
};

export default ReportStrip;
