import React from "react";

export function doesNotMeetExtendedHolterRequirements(
  studyType,
  downgradeAuthorized,
  configuredDuration,
  recordedDuration
) {
  // Sanitize inputs since they may be raw values or string input fields
  const sanitizedConfiguredDuration = Number(configuredDuration ?? NaN);
  const sanitizedRecordedDuration = Number(recordedDuration ?? NaN);
  const sanitizedDowngradeAuthorized = !`${downgradeAuthorized}`.match(/0|false/);

  const prescribedDurationInDays = Math.ceil(sanitizedConfiguredDuration / 24);

  // For an 8+ day study, there must be more than 168 hours of recorded data
  const underMediumExtendedHolterRequirements =
    prescribedDurationInDays >= 8 &&
    !Number.isNaN(sanitizedRecordedDuration) &&
    sanitizedRecordedDuration <= 168;
  // For a 3-7 day study, there must be more than 48 hours of recorded data
  const underLongExtendedHolterRequirements =
    prescribedDurationInDays >= 3 &&
    prescribedDurationInDays <= 7 &&
    !Number.isNaN(sanitizedRecordedDuration) &&
    sanitizedRecordedDuration <= 48;
  return (
    studyType?.toLowerCase().includes("extendedholter") &&
    sanitizedDowngradeAuthorized === false &&
    (underMediumExtendedHolterRequirements || underLongExtendedHolterRequirements)
  );
}

function useDoesNotMeetExtendedHolterRequirements(
  studyType,
  downgradeAuthorized,
  configuredDuration,
  recordedDuration
) {
  return React.useMemo(
    () =>
      doesNotMeetExtendedHolterRequirements(
        studyType,
        downgradeAuthorized,
        configuredDuration,
        recordedDuration
      ),
    [configuredDuration, recordedDuration, studyType, downgradeAuthorized]
  );
}

export default useDoesNotMeetExtendedHolterRequirements;
