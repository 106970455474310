import React from "react";
import PropTypes from "prop-types";

import RestartAlt from "@mui/icons-material/RestartAlt";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import ZoomContext from "../../contexts/ZoomContext.jsx";
import IconButtonWithTooltip from "../../shared/react/IconButtonWithTooltip.jsx";

function TimelineChartWrapper({
  // Props
  children,
}) {
  //---------------------------------------------------------------------------
  // Zoom state management
  //---------------------------------------------------------------------------
  const {zoomedIn, setZoomedIn} = React.useContext(ZoomContext);
  const handleClick = React.useCallback(() => setZoomedIn(false), [setZoomedIn]);

  return (
    <>
      <Box sx={{display: "flex", justifyContent: "end", mx: 2}}>
        <IconButtonWithTooltip
          title="Reset Zoom"
          onClick={handleClick}
          color="secondary"
          otherProps={{size: "small", disabled: !zoomedIn}}
        >
          <RestartAlt />
        </IconButtonWithTooltip>
      </Box>

      {children}
    </>
  );
}

TimelineChartWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TimelineChartWrapper;
