import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useTheme} from "@mui/material/styles";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import TextWithNewlines from "../../../shared/react/TextWithNewlines.jsx";
import useJwt from "../../hooks/useJwt.jsx";

function ReasonForRejection({
  // Props
  report,
  disabled = false,
  handleClickConfirmRejection,
}) {
  //---------------------------------------------------------------------------
  // Global Variables
  //---------------------------------------------------------------------------
  const {isInAnyRole} = useJwt();
  const theme = useTheme();

  //---------------------------------------------------------------------------
  // Calculated displayed values
  //---------------------------------------------------------------------------
  const rejectedByMessage = React.useMemo(() => {
    if (report.rejectedByUser?.fullName) {
      return `Rejected by ${report.rejectedByUser.fullName}`;
    }

    return "Reason for Rejection";
  }, [report.rejectedByUser?.fullName]);

  return (
    <Box mb={2} p={2} sx={{backgroundColor: theme.palette.background.main, borderRadius: "4px"}}>
      <Typography variant="subtitle2" mb={1} data-cy="rejected-by-message">
        {rejectedByMessage}
      </Typography>
      <TextWithNewlines
        text={report.reasonForRejection || ""}
        variant="body2"
        data-cy="reason-for-rejection"
      />

      {!isInAnyRole(["clinicalStaff", "physician"]) && report.state === "rejectedByPhysician" && (
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleClickConfirmRejection}
          sx={{mt: 2}}
          disabled={disabled}
          data-cy="confirm-rejection-button"
        >
          Confirm
        </Button>
      )}
    </Box>
  );
}

ReasonForRejection.propTypes = {
  report: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  handleClickConfirmRejection: PropTypes.func.isRequired,
};

export default React.memo(ReasonForRejection);
