/* eslint-env browser */
import angular from "angular";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";

import {formatDateAndTime} from "../../components/DateAndTime/DateAndTime.jsx";

/* @ngInject */
export default class StripViewerController {
  constructor($injector, strips, showPopupCounter, study) {
    this._$rootScope = $injector.get("$rootScope");
    this._$mdDialog = $injector.get("$mdDialog");
    this._EventService = $injector.get("EventService");
    this._StripService = $injector.get("StripService");
    this._StripClassificationsService = $injector.get("StripClassificationsService");
    this._Markers = $injector.get("Markers");
    this._Config = $injector.get("Config");

    this.features = this._Config.features;
    this.ecgStripViewerConfig = angular.copy(this._Config.ecgStripViewerConfig);
    this.sequenceViewerConfig = angular.copy(this._Config.sequenceViewerConfig);
    this.fullViewerConfig = angular.copy(this._Config.fullViewerConfig);

    this.strips = strips.slice();
    this.showPopupCounter = showPopupCounter;
    this.study = study;
    this.editingSavedStrip = false;
    this.selectedStripClassification = "";
    this.stripComments = "";
    this.navBoxes = [];
    this._setClassificationsForFacility();

    this._currentIndex = 0;
    this._loadStrip(this.strips[this._currentIndex]);
  }

  getDisplayedTime(stripTime) {
    return formatDateAndTime({datetime: stripTime, zone: this.study?.timeZone, seconds: true});
  }

  getStripIndex() {
    return this._currentIndex + 1;
  }

  cancel() {
    return this._$mdDialog.hide(this.strips);
  }

  includeInReport(include = true) {
    this.currentStrip.includeInReport = include;
    this.strips[this._currentIndex] = this.currentStrip;
    if (this._currentIndex < this.strips.length - 1) {
      this._currentIndex++;
      return this._loadStrip(this.strips[this._currentIndex]);
    }
    return this.cancel();
  }

  getTotalMeasurementCount() {
    let count = 0;
    this._Markers.MEASUREMENT_TYPES.forEach((measurementType) => {
      count += this._Markers.getMeasurementCount(measurementType, this.currentStrip);
    });
    return count;
  }

  editSavedStrip() {
    this.selectedStripClassification = this.currentStrip.userClassification;
    this.channels = [
      {
        display: this.ecgStripViewerConfig.displayElements.leads.I,
        invert: this.ecgStripViewerConfig.inversionStatus.leads.I,
      },
      {
        display: this.ecgStripViewerConfig.displayElements.leads.II,
        invert: this.ecgStripViewerConfig.inversionStatus.leads.II,
      },
      {
        display: this.ecgStripViewerConfig.displayElements.leads.III,
        invert: this.ecgStripViewerConfig.inversionStatus.leads.III,
      },
    ];
    this.stripComments = this.currentStrip.comment;
    this._$rootScope.$emit("ecg-navigation-scroll-updated", true);
    this._$rootScope.$emit("viewer-settings-updated");
    this.editingSavedStrip = true;
  }

  saveChangesToStrip() {
    this.strips[this._currentIndex] = this.currentStrip;
    this.editingSavedStrip = false;
    this._$rootScope.$emit("ecg-navigation-scroll-updated", this.editingSavedStrip);
    this._$rootScope.$emit("viewer-settings-updated");

    const comment = this.stripComments || this.currentStrip.comment || "";
    // Convert timeBase and gain to numbers. Sometimes they come through as numbers and sometimes they are strings.
    const timeBase = Number(this.currentStrip.event.ecg.mmPerSecond);
    const gain = Number(this.currentStrip.event.ecg.mmPerMillivolt);
    // Calculate the start and end time for the ecg strip viewer using the ecg start time,
    // the start and end strip samples, and samples per second
    const startTime = moment(this.currentStrip.event.ecg.startTime)
      .add(
        this.currentStrip.event.ecg.startStripSample / this.currentStrip.event.ecg.samplesPerSecond,
        "seconds"
      )
      .toISOString();
    const endTime = moment(this.currentStrip.event.ecg.startTime)
      .add(
        this.currentStrip.event.ecg.endStripSample / this.currentStrip.event.ecg.samplesPerSecond,
        "seconds"
      )
      .toISOString();

    const updatedStripProperties = {
      userClassification: this.selectedStripClassification,
      timeBase,
      gain,
      startTime,
      endTime,
      comment,
      displayedLeads: {
        I: this.channels[0].display,
        II: this.channels[1].display,
        III: this.channels[2].display,
      },
      invertedChannels: {
        I: this.channels[0].invert,
        II: this.channels[1].invert,
        III: this.channels[2].invert,
      },
    };

    if (this.currentStrip.sequenceLead !== null) {
      updatedStripProperties.sequenceLead = this.currentStrip.sequenceLead;
    }

    return this._StripService
      .updateStrip(this.currentStrip.id, updatedStripProperties)
      .then((updatedStripResult) => {
        Object.assign(this.currentStrip, updatedStripResult.data);
        return this._StripService.updateStripMidpoint(this.currentStrip);
      })
      .then(() => {
        const measurementsToCreate = this._Markers.MEASUREMENT_TYPES.reduce(
          (accumulator, measurementType) => {
            const measurementsByType = this.currentStrip.measurements[measurementType].data;
            accumulator.push(...measurementsByType.filter((measurement) => !measurement.id));
            return accumulator;
          },
          []
        );
        measurementsToCreate.forEach(this._Markers.sanitizeStripMeasurement);

        if (measurementsToCreate.length === 0) {
          return Promise.resolve({data: []});
        }
        return this._Markers.createStripMeasurements(this.currentStrip.id, measurementsToCreate);
      })
      .then((response) => {
        response.data.forEach((newMeasurement) => {
          this._Markers.sanitizeStripMeasurement(newMeasurement);

          // Copy the newly created measurements to the corresponding measurements on the strip
          const matchingMeasurement = this.currentStrip.measurements[newMeasurement.name].data.find(
            (measurement) => {
              return !measurement.id && this._Markers.stripMeasurementsAreEqual(newMeasurement, measurement);
            }
          );
          Object.assign(matchingMeasurement, newMeasurement);
        });
      })
      .then(() => {
        const {measurementsToDelete} = this.currentStrip;
        if (!measurementsToDelete || measurementsToDelete.length === 0) {
          return Promise.resolve();
        }
        return Promise.all(measurementsToDelete.map((id) => this._Markers.deleteStripMeasurement(id)));
      })
      .then(() => {
        delete this.currentStrip.measurementsToDelete;
      });
  }

  _loadStrip(strip) {
    this.ecgStripViewerConfig = angular.copy(this._Config.ecgStripViewerConfig);
    this.fullViewerConfig.timeZone = this.study?.timeZone;

    this._setDisplayedLeads(strip.displayedLeads);
    this._setLeadInversion(strip.invertedChannels);
    this._setSequenceLead(strip.sequenceLead);
    this._setStripLength(strip);

    this.loadingEventData = true;

    return Promise.all([
      this._StripService.getStripEvent(strip),
      this._Markers.getEventBeatMarkers({eventId: strip.eventId}),
      this._Markers.getStripMeasurements({stripId: strip.id}),
    ])
      .then((results) => {
        const event = results[0];
        const beatMarkers = results[1];
        const stripMeasurements = results[2];

        // Update/Overwrite the measurements on the strip from the GET request
        strip.measurements = this._Markers.DEFAULT_MEASUREMENTS;
        stripMeasurements.forEach((measurement) => {
          strip.measurements[measurement.name].data.push(measurement);
        });
        this._Markers.MEASUREMENT_TYPES.forEach((measurementType) => {
          this._Markers.updateMinMeanMaxForStrip(measurementType, strip);
        });

        if (event) {
          strip.event = event;
          strip.middleSample = this._StripService.getStripMidpoint(strip, strip.event.ecg);
          strip.triggerType = this._EventService.getTriggerType(strip.event);
          strip.triggerDescriptions = this._EventService.getTriggerDescriptions(strip.event);
          strip.eventClassification = this._EventService.getEventClassification(strip.event);
          strip.beatMarkerCounts = this._Markers.getBeatMarkerCountLabels(beatMarkers);
          strip.isSelected = true;
        }

        // Clone a copy of the strip so that if the dialog is closed, changes are reverted
        this.currentStrip = cloneDeep(strip);
        this.navBoxes = [this.currentStrip];

        this.loadingEventData = false;
        this._$rootScope.$apply();
      })
      .catch(() => {
        this.loadingEventData = false;
      });
  }

  _setStripLength(strip) {
    this.ecgStripViewerConfig.stripLength = this.ecgStripViewerConfig.getStripLengthAt25mm(strip);
  }

  _setDisplayedLeads(leadsToDisplay) {
    if (leadsToDisplay) {
      Object.keys(leadsToDisplay).forEach((channel) => {
        if (Object.prototype.hasOwnProperty.call(this.ecgStripViewerConfig.displayElements.leads, channel)) {
          this.ecgStripViewerConfig.displayElements.leads[channel] = leadsToDisplay[channel];
        }
      });
    }
  }

  _setLeadInversion(leadInversionStatuses) {
    if (leadInversionStatuses) {
      Object.keys(leadInversionStatuses).forEach((channel) => {
        if (Object.prototype.hasOwnProperty.call(this.ecgStripViewerConfig.inversionStatus.leads, channel)) {
          this.ecgStripViewerConfig.inversionStatus.leads[channel] = leadInversionStatuses[channel];
          this.sequenceViewerConfig.inversionStatus.leads[channel] = leadInversionStatuses[channel];
        }
      });
    }
  }

  _setSequenceLead(sequenceLead) {
    if (sequenceLead) {
      this.sequenceViewerConfig.displayElements.leads.I = false;
      this.sequenceViewerConfig.displayElements.leads.II = false;
      this.sequenceViewerConfig.displayElements.leads.III = false;
      this.sequenceViewerConfig.displayElements.leads[sequenceLead] = true;
    }
  }

  _setClassificationsForFacility() {
    return this._StripClassificationsService
      .getAll({facilityId: this.strips[0].facilityId})
      .then((classifications) => {
        this.stripClassifications = classifications;
        return classifications;
      });
  }
}
