import React from "react";
import {darken, lighten} from "polished";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Chip from "@mui/material/Chip";
import {useTheme} from "@mui/material/styles";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import useEnvironmentVariables from "../hooks/useEnvironmentVariables.jsx";

function StudyStatusChip({
  // Props
  status,
  width = "100%",
  "data-cy": dataCy,
}) {
  const theme = useTheme();
  const {features} = useEnvironmentVariables();

  const {label, color, backgroundColor} = React.useMemo(() => {
    if (status === "pending") {
      return {
        label: "Pending",
        color: darken(0.1, theme.palette.warning.main),
        backgroundColor: lighten(0.45, theme.palette.warning.main),
      };
    }
    if (status === "recording") {
      return {
        label: "Recording",
        color: darken(0.1, theme.palette.secondary.main),
        backgroundColor: lighten(0.35, theme.palette.secondary.main),
      };
    }
    if (status === "done") {
      return {
        label: "Done Recording",
        color: darken(0.1, theme.palette.primary.main),
        backgroundColor: lighten(0.65, theme.palette.primary.dark),
      };
    }
    if (status === "finalized") {
      return {
        label: features.finalizeStudy ? "Finalized" : "Completed",
        color: darken(0.1, theme.palette.success.main),
        backgroundColor: lighten(0.6, theme.palette.success.main),
      };
    }
    if (status === "archived") {
      return {
        label: "Archived",
        color: darken(0.1, theme.palette.tertiary.main),
        backgroundColor: lighten(0.6, theme.palette.tertiary.main),
      };
    }
    if (status === "failed") {
      return {
        label: "Failed",
        color: darken(0.1, theme.palette.error.main),
        backgroundColor: lighten(0.5, theme.palette.error.main),
      };
    }

    // Fallback
    return {
      label: "Failed",
      color: darken(0.1, theme.palette.error.main),
      backgroundColor: lighten(0.5, theme.palette.error.main),
    };
  }, [status, theme, features.finalizeStudy]);

  return (
    <Chip
      label={label}
      size="small"
      variant="outlined"
      sx={{width, color, borderColor: color, backgroundColor}}
      data-cy={dataCy}
    />
  );
}

StudyStatusChip.propTypes = {
  status: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  "data-cy": PropTypes.string,
};

export default StudyStatusChip;
