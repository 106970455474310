import React from "react";

function useInboxContractNames() {
  return React.useMemo(() => {
    const displayableInboxContracts = {
      none: "No Contract",
      feePerDevice: "Fee per Device",
      feePerService: "Fee per Service",
      splitBilling: "Split Billing",
      evaluation: "Evaluation",
    };

    return displayableInboxContracts;
  }, []);
}

export default useInboxContractNames;
