/* eslint-env browser */
import React from "react";
import md5 from "crypto-js/md5";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useInterval, useLocalStorage} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import DialogTitleBar from "../../shared/react/DialogTitleBar.jsx";
import LinkifyText from "../../shared/react/LinkifyText.jsx";
import useJwt from "../hooks/useJwt.jsx";

function FacilityNotification() {
  const {userFacilityId, username} = useJwt();

  const [notification, setNotification] = useLocalStorage(`${username}.notification`, null);
  const [noShowAgain, setNoShowAgain] = React.useState(false);
  const [message, setMessage] = React.useState(null);
  const [title, setTitle] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const getFacilityNotification = React.useCallback(async () => {
    try {
      const {data} = await axios({
        method: "get",
        url: `/facilities/${userFacilityId}/notifications`,
      });

      if (data.length > 0) {
        // We currently only support one notification per facility
        const notificationHash = md5(`${data[0].id}${data[0].title}${data[0].message}`).toString();

        setTitle(data[0].title);
        setMessage(data[0].message);

        // If a notification was added or modified, add it to local storage and display the dialog
        if (notification?.hash !== notificationHash) {
          setNotification({hash: notificationHash, ack: false});
          setOpen(true);
        }
        // If the notification did not change but was never acknowledged, display the dialog
        else if (!notification?.ack) {
          setOpen(true);
        }
      }

      // If a notification was deleted, remove it from local storage
      if (!data?.length && !!notification) {
        setNotification(null);

        setTitle(null);
        setMessage(null);
      }
    } catch (err) {
      // Do nothing except log the error in the console
      console.error(err);
    }
  }, [userFacilityId, notification, setNotification]);
  useInterval(getFacilityNotification, null, true);

  const handleClose = React.useCallback(() => {
    setOpen(false);
    setNotification((prev) => ({...prev, ack: noShowAgain}));
  }, [noShowAgain, setNotification]);

  const handleNoShowAgainClicked = React.useCallback((event, checked) => {
    setNoShowAgain(checked);
  }, []);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth data-cy="facility-notification">
      <DialogTitleBar title={title || "Alert"} />
      <DialogContent sx={{overflowWrap: "anywhere"}}>
        <DialogContentText>
          <LinkifyText text={message} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container sx={{alignItems: "center"}}>
          <Checkbox checked={noShowAgain} onChange={handleNoShowAgainClicked} data-cy="no-show-checkbox" />
          <Typography>Don&apos;t show this message again</Typography>
        </Grid>
        <Box sx={{m: 2}}>
          <Button variant="contained" color="secondary" onClick={handleClose} data-cy="close-alert">
            Dismiss
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default FacilityNotification;
