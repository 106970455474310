import React from "react";
import PropType from "prop-types";

const ZoomContext = React.createContext();

export function ZoomProvider({children}) {
  const [zoomedIn, setZoomedIn] = React.useState(false);

  const value = React.useMemo(() => ({zoomedIn, setZoomedIn}), [zoomedIn]);

  return <ZoomContext.Provider value={value}>{children}</ZoomContext.Provider>;
}

export default ZoomContext;

ZoomProvider.propTypes = {
  children: PropType.node.isRequired,
};
