import React from "react";
import PropType from "prop-types";

const InboxEntityContext = React.createContext();

export function InboxEntityProvider({
  // Props
  type = "inbox-item",
  children,
}) {
  const value = React.useMemo(() => ({type}), [type]);

  return <InboxEntityContext.Provider value={value}>{children}</InboxEntityContext.Provider>;
}

InboxEntityProvider.propTypes = {
  type: PropType.oneOf(["inbox-item", "generated-report", "study"]),
  children: PropType.node.isRequired,
};

export default InboxEntityContext;
