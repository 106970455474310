import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

function OrderingPhysicianSummary({
  // Props
  report,
}) {
  return (
    <Grid container columnSpacing={1}>
      <Grid size={12} sx={{mb: 0.5}}>
        <Typography variant="subtitle2">Ordering Physician</Typography>
      </Grid>

      <Grid size={5} sx={{pr: 0.75, py: 0.25, backgroundColor: (theme) => theme.palette.background.main}}>
        <Typography align="right" variant="reportText">
          Name
        </Typography>
      </Grid>
      <Grid size={7} sx={{py: 0.25}}>
        <Typography variant="reportText" data-cy="physician-name">
          {report.studyDetails?.physicianName}
        </Typography>
      </Grid>

      <Grid size={5} sx={{pr: 0.75, py: 0.25, backgroundColor: (theme) => theme.palette.background.main}}>
        <Typography align="right" variant="reportText">
          Phone
        </Typography>
      </Grid>
      <Grid size={7} sx={{py: 0.25}}>
        <Typography variant="reportText" data-cy="physician-phone-number">
          {report.studyDetails?.physicianPhoneNumber}
        </Typography>
      </Grid>

      <Grid size={5} sx={{pr: 0.75, py: 0.25, backgroundColor: (theme) => theme.palette.background.main}}>
        <Typography align="right" variant="reportText">
          Facility
        </Typography>
      </Grid>
      <Grid size={7} sx={{py: 0.25}}>
        <Typography variant="reportText" data-cy="physician-facility">
          {report.studyDetails?.physicianFacility}
        </Typography>
      </Grid>

      <Grid size={5} sx={{pr: 0.75, py: 0.25, backgroundColor: (theme) => theme.palette.background.main}}>
        <Typography align="right" variant="reportText">
          Email
        </Typography>
      </Grid>
      <Grid size={7} sx={{py: 0.25}}>
        <Typography variant="reportText" data-cy="physician-email">
          {report.studyDetails?.physicianEmail}
        </Typography>
      </Grid>

      <Grid size={5} sx={{pr: 0.75, py: 0.25, backgroundColor: (theme) => theme.palette.background.main}}>
        <Typography align="right" variant="reportText">
          Address
        </Typography>
      </Grid>
      <Grid size={7} sx={{py: 0.25}}>
        <Typography variant="reportText" data-cy="physician-address">
          {report.studyDetails?.physicianAddress}
        </Typography>
      </Grid>
    </Grid>
  );
}

OrderingPhysicianSummary.propTypes = {
  report: PropTypes.object.isRequired,
};

export default React.memo(OrderingPhysicianSummary);
