import moment from "moment";

import logo from "../../../img/br-logo-inbox-inverted.svg";

/* @ngInject */
export default class TopNavigationController {
  constructor(
    $mdSidenav,
    Config,
    Authentication,
    $window,
    $scope,
    SocketService,
    $state,
    SearchService,
    $mdToast,
    $mdDialog,
    $rootScope
  ) {
    this._$mdSidenav = $mdSidenav;
    this._$window = $window;
    this._$scope = $scope;
    this._$state = $state;
    this._Search = SearchService;
    this._$mdToast = $mdToast;
    this._$mdDialog = $mdDialog;
    this._$rootScope = $rootScope;
    this._Authentication = Authentication;
    this._SocketService = SocketService;

    this.features = Config.features;
    this.refreshJwtThresholdSeconds = Config.refreshJwtThresholdSeconds;

    this.bitRhythmLogo = logo;

    this._$mdToast.hide(document.getElementsByClassName("md-toast-banner"));

    this.sideNavOpen = true;

    const deregister = this._$rootScope.$on("log-out", () => {
      this.logOut();
    });
    this._$scope.$on("$destroy", deregister);

    this.logoutReact = {
      emit: () => {
        this._$rootScope.$emit("log-out");
      },
    };
  }

  get currentPage() {
    const nameInPath = this._$window.location.pathname
      .split("/")[1]
      .replace("-", " ")
      .replace("physician", "")
      .replace(/\w\S*/g, function upcase(word) {
        return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
      });
    return nameInPath;
  }

  clickedMenuButton() {
    this._$mdSidenav("left").toggle();
    this.sideNavOpen = !this.sideNavOpen;

    // we need to programmatically set the padding for the main page depending on state of the
    // side nav.
    if (this.sideNavOpen) {
      // Set padding of main to 250px
      document.getElementById("mainDocument").style.paddingLeft = "225px";
    } else {
      // Set it to auto
      document.getElementById("mainDocument").style.paddingLeft = "0px";
    }

    this._$rootScope.$emit("window-resize");
  }

  pageIsSearchable() {
    let searchable;

    try {
      searchable = this._$state.current.data.searchable;
    } catch (error) {
      searchable = false;
    }
    return searchable;
  }

  async logOut() {
    await this._Authentication.logOut();
    this._SocketService.onDisconnect(() => this._SocketService.removeConnectionListeners());
    this._SocketService.disconnect();
  }

  getExampleTimestamp(isLongMode) {
    const beforeDate = moment().subtract(2, "days").format("YYYY-M-D");
    const afterDate = moment().subtract(5, "days").format("YYYY-M-D");

    if (isLongMode) {
      // `After:"1970-1-1 19:00" Before:"1970-1-3 8:00"`;
      return `after:"${afterDate} 19:00" before:"${beforeDate} 8:00"`;
    }
    // `After:1970-1-1`
    return `after:${afterDate}`;
  }

  get searchExamples() {
    let searchExamples;

    switch (this.currentPage) {
      case "Studies":
        searchExamples = {
          property: "patient:John",
          doubleQuote: 'study-type:"Mobile Cardiac Telemetry"',
          multiple: "device:H3R1234567 type:mct",
          negate: '-study-type:holter -"John Doe"',
          timeRangeLong: "start:>2022-02-01 start:<2022-03-01",
          timeRangeShort: "end:<2022-05-01",
          category: null,
          customMessage: null,
        };
        break;
      case "Analyze":
      case "Completed":
        searchExamples = {
          property: "type:Baseline",
          doubleQuote: 'type:"Normal Sinus Rhythm"',
          multiple: 'type:"Daily Trend", device:H3R1234567',
          negate: '-type:Baseline -"Unreadable ECG Data"',
          timeRangeLong: this.getExampleTimestamp(true),
          timeRangeShort: this.getExampleTimestamp(false),
          category: "is:event is:report -is:notification",
          customMessage: null,
        };
        break;
      case "Reports":
        searchExamples = {
          property: "study:12345678",
          doubleQuote: 'type:"Summary"',
          multiple: 'number:12 type:"Summary"',
          negate: '-study:12345678 -"Single Episode"',
          timeRangeLong: this.getExampleTimestamp(true),
          timeRangeShort: this.getExampleTimestamp(false),
          customMessage: null,
        };
        break;
      default:
        searchExamples = null;
    }
    return searchExamples;
  }
}
