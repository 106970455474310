import React from "react";
import {DateTime, Interval} from "luxon";
import {Bar, ReferenceArea, ReferenceDot, YAxis} from "recharts";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import {grey} from "@mui/material/colors";
import {useTheme} from "@mui/material/styles";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import InboxEntityContext from "../../contexts/InboxEntityContext.jsx";
import TimelineChart from "../study-graphs/TimelineChart.jsx";

const useArrhythmiaTimelineData = ({
  // Props
  study,
  data,
  startTime,
  endTime,
  reportType = "Summary",
  setLoading = () => {},
  setError = () => {},
}) => {
  //---------------------------------------------------------------------------
  // Determine if this component is in a generated report for data fetching
  //---------------------------------------------------------------------------
  const {type: parentType} = React.useContext(InboxEntityContext);

  //---------------------------------------------------------------------------
  // Get theme for colors
  //---------------------------------------------------------------------------
  const theme = useTheme();

  //---------------------------------------------------------------------------
  // Displayed Arrhythmia Type
  //---------------------------------------------------------------------------
  const getDisplayedArrhythmiaType = React.useCallback((type) => {
    let displayedType;
    switch (type) {
      case "Cardiac Pause":
        displayedType = "Pause";
        break;
      case "Bradycardia":
      case "Bradycardia Rate Change":
        displayedType = "Brady";
        break;
      case "Tachycardia":
      case "Tachycardia Rate Change":
        displayedType = "Tachy";
        break;
      case "Normal Sinus Rhythm":
        displayedType = "Normal";
        break;
      case "Atrial Fibrillation":
        displayedType = "AF";
        break;
      case "Lead Off":
        displayedType = "Lead Off";
        break;
      case "Artifact":
      case "Unreadable ECG Data":
        displayedType = "Unreadable";
        break;
      case null:
        displayedType = "Unclassified";
        break;
      default:
        displayedType = type;
    }
    return displayedType;
  }, []);

  //---------------------------------------------------------------------------
  // Fetch data from API
  //---------------------------------------------------------------------------
  const [arrhythmiaEvents, setArrhythmiaEvents] = React.useState(data?.arrhythmiaData || []);
  const [qrsExclusionRegions, setQrsExclusionRegions] = React.useState(data?.qrsExclusionRegions || []);

  const getArrhythmiaTimelineData = React.useCallback(async () => {
    setLoading(true);

    try {
      const [{data: arrhythmiaData}, {data: qrsExclusionRegionResponse}] = await Promise.all([
        axios({
          method: "get",
          url: `/reports/arrhythmiaTimeline/${study.id}`,
          params: {
            startTime: startTime.toUTC().toISO(),
            endTime: endTime.toUTC().toISO(),
          },
        }),
        axios({
          method: "get",
          url: `/qrsExclusionRegions/${study.id}`,
          params: {
            startTime: {$gte: startTime.toUTC().toISO()},
            endTime: {$lte: endTime.toUTC().toISO()},
          },
        }),
      ]);

      setArrhythmiaEvents(arrhythmiaData);
      setQrsExclusionRegions(qrsExclusionRegionResponse);
    } catch (err) {
      setError(err.message);
    }

    setLoading(false);
  }, [endTime, startTime, study.id, setError, setLoading]);

  React.useEffect(() => {
    // If this component is in a generated report, then we already have the data so
    // no need to fetch it
    if (parentType !== "generated-report" && !arrhythmiaEvents.length) {
      getArrhythmiaTimelineData();
    }
  }, [arrhythmiaEvents.length, parentType, getArrhythmiaTimelineData]);

  // @TODO study-trove should be doing these calculations
  const eventTypes = React.useMemo(() => {
    //---------------------------------------------------------------------------
    // Get a sorted list of all event types that need to be displayed
    //
    // Events will be sorted:
    //  - Instantaneous Events (Patient Activated, Pause)
    //  - Other Events (sorted)
    //  - Normal Events (sorted)
    //  - Unclassified
    //---------------------------------------------------------------------------
    const eventsDisplayedFirst = ["Patient Activated", "Pause"];
    const eventsDisplayedLast = ["Unclassified"];
    const rhythmTypesForEvents = [];

    arrhythmiaEvents?.forEach((eventData) => {
      const displayedName = getDisplayedArrhythmiaType(eventData.eventName);
      if (!rhythmTypesForEvents.includes(displayedName) && displayedName !== "Unreadable") {
        rhythmTypesForEvents.push(displayedName);
      }
    });

    // Other Events are the miscellaneous classifications
    const otherEvents = rhythmTypesForEvents.filter((type) => {
      const isDisplayedFirst = eventsDisplayedFirst.includes(type);
      const isDisplayedLast = eventsDisplayedLast.includes(type);
      const isNormal = type.startsWith("Normal");
      return !isDisplayedFirst && !isDisplayedLast && !isNormal;
    });
    otherEvents.sort();

    const normalEvents = rhythmTypesForEvents.filter((type) => type.startsWith("Normal"));
    normalEvents.sort();

    // Merge arrays
    const organizedTypes = [...eventsDisplayedFirst, ...otherEvents, ...normalEvents, ...eventsDisplayedLast];

    // Final filter is to get rid of Patient Activated, Pause, and Normal if they aren't in arrhythmiaTypes
    const arrhythmiaTypes = organizedTypes.filter((type) => rhythmTypesForEvents.includes(type));
    // This is so the timeline always has at least one row to prevent y coordinates from being calculated as NaN or undefined
    if (arrhythmiaTypes.length === 0) {
      arrhythmiaTypes.push("Normal");
    }
    return arrhythmiaTypes;
  }, [arrhythmiaEvents, getDisplayedArrhythmiaType]);

  // @TODO study-trove should be doing these calculations
  const exclusionRegions = React.useMemo(() => {
    //---------------------------------------------------------------------------
    // Merge overlapping exclusion regions
    //---------------------------------------------------------------------------
    qrsExclusionRegions.forEach((region) => {
      region.startTime = new Date(region.startTime).getTime();
      region.endTime = new Date(region.endTime).getTime();
    });
    qrsExclusionRegions.sort((a, b) => a.startTime - b.startTime);

    const mergedExclusionRegions = [];
    let accumulator = null;

    qrsExclusionRegions.forEach((region) => {
      if (!accumulator) {
        accumulator = region;
      } else if (region.startTime <= accumulator.endTime) {
        // If regions overlap, combine into the accumulator by setting accumulator.endTime to larger of the two
        if (region.endTime > accumulator.endTime) {
          accumulator.endTime = region.endTime;
        }
      } else {
        // Regions do not overlap so move on to the next region
        mergedExclusionRegions.push(accumulator);
        accumulator = region;
      }
    });
    if (accumulator) {
      mergedExclusionRegions.push(accumulator);
    }

    // include unreadable ECG data for exclusion regions
    const eventTypesToExclude = ["Unreadable ECG Data", "Artifact", "Lead Off"];
    const unreadableEcgData = arrhythmiaEvents
      .filter((event) => eventTypesToExclude.includes(event.eventName))
      .map((region) => ({
        startTime: new Date(region.startTime).getTime(),
        endTime: new Date(region.endTime).getTime(),
      }));

    const sectionUnreadableEcgData = unreadableEcgData.filter((event) => {
      return (
        (event.startTime >= startTime.toMillis() && event.startTime <= endTime.toMillis()) ||
        (event.endTime >= startTime.toMillis() && event.endTime <= endTime.toMillis()) ||
        (event.startTime <= startTime.toMillis() && event.endTime >= endTime.toMillis())
      );
    });

    return mergedExclusionRegions.concat(sectionUnreadableEcgData);
  }, [qrsExclusionRegions, arrhythmiaEvents, startTime, endTime]);

  const weeks = React.useMemo(() => {
    const allTheWeeks = Interval.fromDateTimes(startTime, endTime).splitBy({weeks: 1});

    return allTheWeeks.map((week) => {
      // Fetch arrhythmia data within this week
      const dataForThisWeek = arrhythmiaEvents?.filter((event) => {
        const eventStartTime = DateTime.fromISO(event.startTime);
        const eventEndTime = DateTime.fromISO(event.endTime);

        const eventFullyWithinWeek = week.start <= eventStartTime && eventEndTime <= week.end;
        const eventFullySpansWeek = eventStartTime <= week.start && week.end <= eventEndTime;
        const eventStartsWithinWeek = week.start <= eventStartTime && eventStartTime <= week.end;
        const eventEndsWithinWeek = week.start <= eventEndTime && eventEndTime <= week.end;

        return eventFullyWithinWeek || eventFullySpansWeek || eventStartsWithinWeek || eventEndsWithinWeek;
      });

      // Format the data to be easier to work with:
      // {
      //    "Brady": {
      //      fill, // color of the bars
      //      data: [[x0, x1], [x0, x1], [x0, x1]], // array of ranges that this event occurred
      //    },
      // }
      const formattedData = {};
      eventTypes.forEach((eventType) => {
        formattedData[eventType] = {
          fill: eventType === "Unclassified" ? theme.palette.tertiary.light : theme.palette.primary.dark,
          label: eventType,
          data: [],
        };
      });
      dataForThisWeek?.forEach((event) => {
        formattedData[getDisplayedArrhythmiaType(event.eventName)]?.data?.push([
          DateTime.fromISO(event.startTime).toMillis(),
          DateTime.fromISO(event.endTime).toMillis(),
        ]);
      });

      // Each individual bar for each event type needs to be associated with its own y-axis,
      // so get the largest number of entries in a single row to determine number of y-axes
      // that need to be created
      const numberOfYAxes =
        Math.max(...Object.values(formattedData).map((eventType) => eventType.data.length)) || 1;

      const yAxes = [];
      const bars = [];

      // Create y-axes and bars
      for (let i = 0; i < numberOfYAxes; i++) {
        const id = `yaxis${i}`;

        yAxes.push(
          <YAxis
            key={id}
            yAxisId={id}
            type="category"
            hide={i !== 0}
            dataKey="label"
            tick={{fontSize: 10, id: "classification"}}
            tickLine={false}
            tickMargin={0}
            id="my-axis"
          />
        );
        bars.push(<Bar key={id} yAxisId={id} dataKey={id} maxBarSize={30} />);
      }

      Object.entries(formattedData).forEach(([key, value]) => {
        if (["Patient Activated", "Pause"].includes(key)) {
          value.data.forEach((instantaneousEvent) => {
            bars.push(
              <ReferenceDot
                key={instantaneousEvent[0]}
                x={instantaneousEvent[0]}
                y={key}
                yAxisId="yaxis0"
                fill={theme.palette.primary.dark}
                stroke="none"
                r={8}
              />
            );
          });
        }
      });

      // Format data nicely for recharts to use
      const graphData = Object.values(formattedData).map((eventType) => {
        const formattedEventType = {label: eventType.label, fill: eventType.fill};

        eventType.data.forEach((bar, index) => {
          const yAxisKey = `yaxis${index}`;
          formattedEventType[yAxisKey] = bar;
        });

        return formattedEventType;
      });

      return {startTime: week.start, endTime: week.end, data: graphData, yAxes, bars};
    });
  }, [
    startTime,
    endTime,
    arrhythmiaEvents,
    eventTypes,
    theme.palette.tertiary.light,
    theme.palette.primary.dark,
    getDisplayedArrhythmiaType,
  ]);

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  const separateCharts = React.useMemo(() => {
    return weeks.map((week, index) => {
      return (
        <Box key={week.startTime.ts} data-cy={`arrhythmia-timeline-${index}`}>
          <TimelineChart
            startTime={week.startTime}
            endTime={week.endTime}
            data={week.data}
            reportType={reportType}
            study={study}
          >
            {/* -------------- YAXIS -------------- */}
            {week.yAxes}

            {/* -------------- ARRHYTHMIA TIMELINE DATA -------------- */}
            {week.bars}

            {/* -------------- EXCLUSION REGIONS -------------- */}
            {exclusionRegions.map((exclusionRegion) => (
              <ReferenceArea
                key={exclusionRegion.startTime}
                x1={exclusionRegion.startTime}
                x2={exclusionRegion.endTime}
                yAxisId="yaxis0"
                fillOpacity={1}
                fill={grey[300]}
              />
            ))}
          </TimelineChart>
        </Box>
      );
    });
  }, [exclusionRegions, reportType, study, weeks]);

  return separateCharts;
};

export default useArrhythmiaTimelineData;
