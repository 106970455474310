/* eslint-disable max-classes-per-file */
import isEqual from "lodash/isEqual";
import without from "lodash/without";
import {DateTime} from "luxon";
import moment from "moment";
import queryString from "qs";

import markerMenuPug from "./markerMenu.pug";

class Marker {
  constructor(startSampleOffset, endSampleOffset, name) {
    this.startSampleOffset = startSampleOffset;
    this.endSampleOffset = endSampleOffset;
    this.name = name;
  }
}

class MeasurementMarker extends Marker {
  constructor(startSampleOffset, endSampleOffset, name, ecgData) {
    super(startSampleOffset, endSampleOffset, name);
    const samplesPerMs = ecgData.samplesPerSecond / 1000;
    this.startTime = moment(ecgData.startTime)
      .add((ecgData.eventSample + startSampleOffset) / samplesPerMs, "ms")
      .toISOString();
    this.endTime = moment(ecgData.startTime)
      .add((ecgData.eventSample + endSampleOffset) / samplesPerMs, "ms")
      .toISOString();
  }
}

class BeatMarker extends Marker {
  constructor(sampleOffset, name, ecgData) {
    super(sampleOffset, sampleOffset, name);
    this.sampleOffset = sampleOffset;
    const samplesPerMs = ecgData.samplesPerSecond / 1000;
    this.timestamp = moment(ecgData.startTime)
      .add((ecgData.eventSample + sampleOffset) / samplesPerMs, "ms")
      .toISOString();
    this.startTime = this.timestamp;
    this.endTime = this.timestamp;
  }
}

class TriggerMarker extends BeatMarker {
  constructor(ecgData) {
    const name = "Trigger";
    const sampleOffset = 0;
    super(sampleOffset, name, ecgData);

    this.isTriggerMarker = true;
  }
}

/* @ngInject */
export default class Markers {
  constructor($injector) {
    this._$injector = $injector;
    this._$window = $injector.get("$window");
    this._$state = $injector.get("$state");
    this._$rootScope = $injector.get("$rootScope");
    this._Drawing = $injector.get("Drawing");
    this._$mdDialog = $injector.get("$mdDialog");
    this._$http = $injector.get("$http");
    this._Config = $injector.get("Config");
    this._Authentication = $injector.get("Authentication");
    this._EventService = $injector.get("EventService");

    this._features = this._Config.features;
    this.CANVAS_LINE_OFFSET = 0.5;

    // Used to decide if placing a beat marker or measurement marker
    this.MIN_MOUSE_MOVE = 4;

    // Used to define hitbox size on marker lines
    this.LINE_HITBOX_SIZE = 10;
    this.HALF_LINE_HITBOX_SIZE = 5;

    this.SELECTED_MARKER_LINE_WEIGHT = 2;
    this.TRIGGER_MARKER_LINE_WEIGHT = 2;
    this.MARKER_LINE_WEIGHT = 1;

    this.BEAT_NAMES = ["Pause", "PAC", "PVC"];
    this.MEASUREMENT_TYPES = ["RR", "PR", "QRS", "QT"];

    // Smaller numbers mean the label of the marker is higher on the screen (Y=0 at top)
    this.markerHeights = {
      Trigger: 15,
      Pause: 30,
      PAC: 30,
      PVC: 30,
      RR: 45,
      PR: 60,
      QRS: 75,
      QT: 90,
    };
  }

  get DEFAULT_MEASUREMENTS() {
    return {
      HR: {},
      RR: {data: []},
      PR: {data: []},
      QRS: {data: []},
      QT: {data: []},
    };
  }

  registerMouseEvents(ecgViewer) {
    const {markersCanvas} = ecgViewer;
    markersCanvas.addEventListener(
      "mousedown",
      (ecgViewer._mouseDown = (event) => this._mouseDownEvent(event, ecgViewer))
    );
    markersCanvas.addEventListener(
      "mouseup",
      (ecgViewer._mouseUp = (event) => this._mouseUpEvent(event, ecgViewer))
    );
    markersCanvas.addEventListener(
      "mousemove",
      (ecgViewer._mouseMove = (event) => this._mouseMoveEvent(event, ecgViewer))
    );
  }

  deregisterMouseEvents(ecgViewer) {
    const {markersCanvas} = ecgViewer;
    markersCanvas.removeEventListener("mousedown", ecgViewer._mouseDown);
    markersCanvas.removeEventListener("mouseup", ecgViewer._mouseUp);
    markersCanvas.removeEventListener("mousemove", ecgViewer._mouseMove);
  }

  /**
   * Counts Beat Markers of each type
   *
   * If Given an array, expects:
   * [
   *    {name: "PAC" ...},
   *    {name: "PVC" ...},
   *    ...
   * ]
   *
   * If Given an Object, expects:
   * {
   *    PAC: 3,
   *    PVC: 5,
   *    ...
   * }
   *
   * Returns:
   * [
   *    "PAC (3)",
   *    "PVC (5)",
   *    ...
   * ]
   * @param {Array<Object> | Object} beatMarkers
   * @returns {Array<String>}
   */
  getBeatMarkerCountLabels(beatMarkers) {
    const displayedCounts = [];
    let pauseCount;
    let pvcCount;
    let pacCount;
    if (Array.isArray(beatMarkers)) {
      pauseCount = beatMarkers.filter((marker) => marker.name === "Pause").length;
      pvcCount = beatMarkers.filter((marker) => marker.name === "PVC").length;
      pacCount = beatMarkers.filter((marker) => marker.name === "PAC").length;
    } else {
      pauseCount = beatMarkers.Pause;
      pvcCount = beatMarkers.PVC;
      pacCount = beatMarkers.PAC;
    }
    if (pauseCount > 0) {
      displayedCounts.push(`Pause (${pauseCount})`);
    }
    if (pvcCount > 0) {
      displayedCounts.push(`PVC (${pvcCount})`);
    }
    if (pacCount > 0) {
      displayedCounts.push(`PAC (${pacCount})`);
    }
    return displayedCounts;
  }

  createEventBeatMarkers(event, beatMarkers) {
    const eventType = this._EventService.getEcgEventType(event.type);
    const url = `/beatMarkers/bulk/${eventType}/${event.id}`;
    return this._httpPost(url, beatMarkers);
  }

  getEventBeatMarkers(params = {}) {
    const url = "/beatMarkers";
    return this._httpGet(url, params).then(({data: beatMarkers}) => {
      beatMarkers.forEach((marker) => {
        // beatMarkers that are loaded directly from the backend do not have the generic attributes
        marker.startTime = marker.timestamp;
        marker.endTime = marker.timestamp;

        marker.startSampleOffset = marker.sampleOffset;
        marker.endSampleOffset = marker.sampleOffset;
      });

      return beatMarkers;
    });
  }

  deleteEventBeatMarker(beatMarkerId) {
    const url = `/beatMarkers/${beatMarkerId}`;
    return this._httpDelete(url);
  }

  /**
   * Destructively sanitize a measurement for the backend
   * @param {Object} measurement
   * @return {Object} sanitized measurement
   */
  sanitizeStripMeasurement(measurement) {
    measurement.startTime = moment(measurement.startTime).toISOString();
    measurement.endTime = moment(measurement.endTime).toISOString();
    delete measurement.hitBoxes;
    delete measurement.selected;
    return measurement;
  }

  createStripMeasurements(stripId, stripMeasurements) {
    const url = `/stripMeasurements/bulk/${stripId}`;
    return this._httpPost(url, stripMeasurements);
  }

  getStripMeasurements(params = {}) {
    const url = "/stripMeasurements";
    return this._httpGet(url, params).then((response) => response.data);
  }

  deleteStripMeasurement(stripMeasurementId) {
    const url = `/stripMeasurements/${stripMeasurementId}`;
    return this._httpDelete(url);
  }

  stripMeasurementsAreEqual(stripMeasurementA = {}, stripMeasurementB = {}) {
    const checkStripMeasurementA = {
      name: stripMeasurementA.name || "",
      stripId: stripMeasurementA.stripId || "",
      facilityId: stripMeasurementA.facilityId || "",
      startSampleOffset: Number(stripMeasurementA.startSampleOffset),
      endSampleOffset: Number(stripMeasurementA.endSampleOffset),
      startTime: moment(stripMeasurementA.startTime).toISOString(),
      endTime: moment(stripMeasurementA.endTime).toISOString(),
    };
    const checkStripMeasurementB = {
      name: stripMeasurementB.name || "",
      stripId: stripMeasurementB.stripId || "",
      facilityId: stripMeasurementB.facilityId || "",
      startSampleOffset: Number(stripMeasurementB.startSampleOffset),
      endSampleOffset: Number(stripMeasurementB.endSampleOffset),
      startTime: moment(stripMeasurementB.startTime).toISOString(),
      endTime: moment(stripMeasurementB.endTime).toISOString(),
    };

    return isEqual(checkStripMeasurementA, checkStripMeasurementB);
  }

  acceptMouseInput(ecgViewer) {
    return ecgViewer.allowEcgNavigationScroll || ecgViewer.inEditingMode;
  }

  getHoveredMarker(markers, mousePosition, ecgViewer) {
    const selectedStrip = this.getSelectedStrip(ecgViewer);
    let foundSelectedMarker = false;
    markers.forEach((marker) => {
      marker.selected = false;
      if (marker.isTriggerMarker) {
        // Trigger Markers are not selectable or hoverable
        return;
      }
      const fullMatches = [];

      let allowHover = false;
      if (ecgViewer.allowEcgNavigationScroll) {
        // On an ECG Event, markers are hoverable for all new strips (no id) and all Beat markers (equal offsets)
        allowHover =
          (selectedStrip && (!selectedStrip.id || selectedStrip.editing)) ||
          marker.startSampleOffset === marker.endSampleOffset;
      } else {
        // In a report or strip viewer, markers are hoverable if in edit mode
        // (Strips in a report are never in edit mode)
        allowHover = ecgViewer.inEditingMode;
      }

      // Additionally, measurements are not hoverable if we are already clicking and dragging
      // or the marker's hitboxes have not been defined
      if (ecgViewer.dragMode || !marker.hitBoxes) {
        allowHover = false;
      }

      if (!foundSelectedMarker && allowHover) {
        marker.hitBoxes.forEach((hb) => {
          const hits = {
            gtXStart: false,
            ltXEnd: false,
            gtYStart: false,
            ltYEnd: false,
          };
          if (mousePosition.x >= hb.x) {
            hits.gtXStart = true;
          }
          if (mousePosition.x <= hb.x + hb.w) {
            hits.ltXEnd = true;
          }
          if (mousePosition.y >= hb.y) {
            hits.gtYStart = true;
          }
          if (mousePosition.y <= hb.y + hb.h) {
            hits.ltYEnd = true;
          }

          if (
            hits.gtXStart === true &&
            hits.ltXEnd === true &&
            hits.gtYStart === true &&
            hits.ltYEnd === true
          ) {
            fullMatches.push(true);
          } else {
            fullMatches.push(false);
          }
        });

        if (fullMatches.includes(true)) {
          marker.selected = true;
          foundSelectedMarker = true;
        }
      }
    });

    if (foundSelectedMarker) {
      ecgViewer.markersCanvas.classList.remove("crosshair");
      ecgViewer.markersCanvas.classList.add("pointer");
    } else {
      ecgViewer.markersCanvas.classList.remove("pointer");
      ecgViewer.markersCanvas.classList.add("crosshair");
    }
  }

  drawMarkers(ecgViewer, excludedMarkerTypes = []) {
    this._Drawing.clearCanvas(ecgViewer.markersCanvas);
    const standardColor = "rgb(0, 76, 139)";
    const selectedColor = "rgb(66, 165, 245)";
    const triggerColor = "rgb(245, 131, 26)";
    const markers = this._compiledMarkersToDisplay(ecgViewer, excludedMarkerTypes);
    const measurementBounds = {};
    const markersToDraw = [];

    if (this.previewMarker) {
      // While the measurement is being created, draw a "preview" marker
      markersToDraw.push(this.previewMarker);
    }

    if (markers.length > 0) {
      // minMaxUsingReduce returns an object with a min and max property
      this.MEASUREMENT_TYPES.forEach((measurementType) => {
        measurementBounds[measurementType] = this.minMaxUsingReduce(markers, measurementType);
      });
    }

    markers.forEach((marker) => {
      const {leftX, centerX, rightX} = this._getAdjustedCoords(ecgViewer, marker);
      const durationMs = _getDuration(marker);
      const {selected} = marker;

      let markerHeight = this._canvasAdjust(this.markerHeights[marker.name] || 25);
      let color;
      let lineWeight;
      if (marker.isTriggerMarker) {
        // Trigger Markers have custom rules
        color = triggerColor;
        lineWeight = this.TRIGGER_MARKER_LINE_WEIGHT;
      } else {
        color = selected ? selectedColor : standardColor;
        lineWeight = selected ? this.SELECTED_MARKER_LINE_WEIGHT : this.MARKER_LINE_WEIGHT;
      }

      // On the sequence viewer, the trigger marker should start at the top of the canvas
      if (marker.isTriggerMarker && ecgViewer.config.type === "sequence") {
        markerHeight = 0;
      }

      marker.hitBoxes = [];
      markersToDraw.push({
        leftX,
        rightX,
        color,
        markerHeight,
        lineWeight,
        selected,
        isTriggerMarker: marker.isTriggerMarker,
      });

      /*
       * Left Vertical Line
       * x: x - half the hitbox to account for hitbox
       * y: height to provide space for label above
       * w: easier to click several pixels than 1px
       * h: height of canvas minus space for label
       */
      marker.hitBoxes.push({
        x: leftX - this.HALF_LINE_HITBOX_SIZE,
        y: markerHeight,
        w: this.LINE_HITBOX_SIZE,
        h: ecgViewer.markersCanvas.clientHeight - markerHeight,
      });

      if (durationMs > 0) {
        /*
         * Right Vertical Line
         */
        marker.hitBoxes.push({
          x: rightX - this.HALF_LINE_HITBOX_SIZE,
          y: markerHeight,
          w: this.LINE_HITBOX_SIZE,
          h: ecgViewer.markersCanvas.clientHeight - markerHeight,
        });

        /*
         * Horizontal Line
         * x: Left side of marker
         * y: markerHeight - half the hitbox to account for hitbox
         * w: width of marker (0 for beat markers)
         * h: height of hitbox
         */
        marker.hitBoxes.push({
          x: leftX,
          y: markerHeight - this.HALF_LINE_HITBOX_SIZE,
          w: rightX - leftX,
          h: this.LINE_HITBOX_SIZE,
        });
      }

      if (ecgViewer.config.type === "strip") {
        // Text Label (On Strip Viewer)
        const textSettings = {
          fontSize: 12,
          fontFamily: "Roboto",
          color,
          textAlign: "center",
          fontWeight: selected ? "bold" : "",
        };
        if (marker.isTriggerMarker) {
          // the text of trigger markers will be kept on screen
          textSettings.screenBounds = [0, ecgViewer.markersCanvas.clientWidth];
        }
        let caliperText = "";
        if (selected && durationMs !== 0) {
          // Draw Text indicating the length of the measurement
          caliperText = ` (${durationMs} ms)`;
        }
        const minMaxText = this.getMinMaxDisplayedText(durationMs, measurementBounds[marker.name]);
        const displayedText = `${marker.name}${minMaxText}${caliperText}`;
        const textY = markerHeight - 12;

        // Trigger Marker Labels will not be drawn if they are off screen (to always display the label neatly)
        if (!marker.isTriggerMarker || (centerX >= 0 && centerX <= ecgViewer.markersCanvas.clientWidth)) {
          const textHitbox = this._Drawing.drawText(
            ecgViewer.markersCanvas,
            centerX,
            textY,
            displayedText,
            textSettings
          );
          marker.hitBoxes.push(textHitbox);
        }
      } else if (ecgViewer.config.type === "sequence") {
        // Triangle (On Sequence Viewer)
        // Trigger Markers are not selectable so the hitbox can be ignored.
        const triangleHeight = 9;
        const triangleWidth = 12;
        const polygon = {
          vertices: [
            {x: centerX + triangleWidth / 2, y: 0},
            {x: centerX, y: triangleHeight},
            {x: centerX - triangleWidth / 2, y: 0},
          ],
          fillStyle: triggerColor,
        };
        this._Drawing.drawPolygon(ecgViewer.markersCanvas, polygon);
      }
    });

    // Sort the Markers so that any selected markers are at the bottom of the array, and will be drawn last.
    markersToDraw.sort((a, b) => {
      if (a.selected || b.isTriggerMarker) {
        return 1;
      }
      if (b.selected || a.isTriggerMarker) {
        return -1;
      }
      return 0;
    });

    markersToDraw.forEach((marker) => {
      if (!marker.color) {
        marker.color = selectedColor;
      }
      if (!marker.rightX) {
        marker.rightX = marker.leftX;
      }
      if (!marker.lineWeight) {
        marker.lineWeight = this.SELECTED_MARKER_LINE_WEIGHT;
      }

      // Left Line
      let line = {
        startX: marker.leftX,
        startY: marker.markerHeight,
        endX: marker.leftX,
        endY: ecgViewer.markersCanvas.clientHeight - this.CANVAS_LINE_OFFSET,
        color: marker.color,
        width: marker.lineWeight,
      };
      this._Drawing.drawLine(ecgViewer.markersCanvas, line);

      if (marker.rightX - marker.leftX > 0) {
        // Right Line
        line = {
          startX: marker.rightX,
          startY: marker.markerHeight,
          endX: marker.rightX,
          endY: ecgViewer.markersCanvas.clientHeight - this.CANVAS_LINE_OFFSET,
          color: marker.color,
          width: marker.lineWeight,
        };
        this._Drawing.drawLine(ecgViewer.markersCanvas, line);

        // Top Line
        line = {
          startX: marker.leftX - marker.lineWeight / 2,
          startY: marker.markerHeight,
          endX: marker.rightX + marker.lineWeight / 2,
          endY: marker.markerHeight,
          color: marker.color,
          width: marker.lineWeight,
        };
        this._Drawing.drawLine(ecgViewer.markersCanvas, line);

        if (marker.withPreviewCaliper) {
          const textSettings = {
            fontSize: 12,
            fontFamily: "Roboto",
            color: selectedColor,
            textAlign: "center",
            fontWeight: "bold",
          };
          const caliperLength = this._getDurationFromCoords(ecgViewer, marker.leftX, marker.rightX);
          const caliperText = ` (${caliperLength} ms)`;
          const centerX = (marker.leftX + marker.rightX) / 2;
          const textY = marker.markerHeight - 14;
          this._Drawing.drawText(ecgViewer.markersCanvas, centerX, textY, caliperText, textSettings);
        }
      }
    });
  }

  addTriggerMarker(ecgViewer) {
    ecgViewer.beatMarkers.push(new TriggerMarker(ecgViewer.ecgData));
  }

  minMaxUsingReduce(markers, markerType) {
    const markersByType = markers.filter((marker) => marker.name === markerType);
    // If there are zero or one markers, we don't care about a max and min
    if (markersByType.length <= 1) {
      return {};
    }

    const initialMinMax = _getDuration(markersByType[0]);
    return markersByType.reduce(
      ({min, max}, currentValue) => {
        const durationMs = _getDuration(currentValue);
        return {
          min: Math.min(min, durationMs),
          max: Math.max(max, durationMs),
        };
      },
      {min: initialMinMax, max: initialMinMax}
    );
  }

  getMinMaxDisplayedText(durationMs, boundsByMarkerType = {}) {
    let displayedText = "";

    // If the min and max are equal, we don't want to display anything besides the label name
    if (boundsByMarkerType.min === boundsByMarkerType.max) {
      displayedText = "";
    } else if (boundsByMarkerType.min === durationMs) {
      displayedText = " - min";
    } else if (boundsByMarkerType.max === durationMs) {
      displayedText = " - max";
    }
    return displayedText;
  }

  getSelectedStrip(ecgViewer) {
    let selectedStrip = ecgViewer.navBoxes.find((box) => box.isSelected);
    if (!selectedStrip && ecgViewer.navBoxes.length > 0) {
      [selectedStrip] = ecgViewer.navBoxes;
    }
    return selectedStrip;
  }

  getMeasurementCount(measurementType, strip) {
    let count = 0;
    if (strip?.measurements) {
      // HR uses the data array from RR
      if (measurementType === "HR") {
        count = strip.measurements.RR.data.length;
      } else {
        count = strip.measurements[measurementType].data.length;
      }
    }
    return count;
  }

  getMeasurementMinMeanMax(measurementType, strip) {
    let minMeanMaxString = "--";
    let measurement = {};
    let measurementCount = 0;

    if (strip?.measurements) {
      measurement = strip.measurements[measurementType];
      // HR uses the data array from RR
      if (measurementType === "HR") {
        measurementCount = strip.measurements.RR.data.length;
      } else {
        measurementCount = measurement.data.length;
      }
    }

    switch (measurementCount) {
      case 0:
        minMeanMaxString = "--";
        break;
      case 1:
        minMeanMaxString = `${measurement.min}`;
        break;
      default:
        minMeanMaxString = `${measurement.min} - ${measurement.mean} - ${measurement.max}`;
    }
    return minMeanMaxString;
  }

  updateMinMeanMaxForStrip(measurementType, strip) {
    let min;
    let max;
    let mean = 0;
    let sum = 0;
    const measurementByType = strip.measurements[measurementType];
    const measurementData = measurementByType.data;

    if (measurementData.length === 0) {
      // no measurements so delete min mean max properties
      delete measurementByType.min;
      delete measurementByType.mean;
      delete measurementByType.max;
    } else {
      min = _getDuration(measurementData[0]);
      max = min;

      // Use the array to calculate the duration for each measurement and sum them as well as find the min and max
      for (let i = 0; i < measurementData.length; i++) {
        const durationMs = _getDuration(measurementData[i]);
        sum += durationMs;

        if (durationMs < min) {
          min = durationMs;
        }
        if (durationMs > max) {
          max = durationMs;
        }
      }
      mean = Math.round(sum / measurementData.length);
      measurementByType.min = min;
      measurementByType.mean = mean;
      measurementByType.max = max;
    }

    // Calculate HR if measurementType is 'RR'
    if (measurementType === "RR") {
      this.updateHrMeasurement(measurementData.length, min, mean, max, strip);
    }
  }

  updateHrMeasurement(dataLength, minRR, meanRR, maxRR, strip) {
    const hrMeasurement = strip.measurements.HR;
    if (dataLength === 0) {
      // no measurements so delete min mean max properties
      delete hrMeasurement.min;
      delete hrMeasurement.mean;
      delete hrMeasurement.max;

      // Set meanHR for sorting; set displayedMeanHR for filtering
      strip.meanHR = 0;
      strip.displayedMeanHR = "N/A";
    } else {
      // To calculate HR from ms (1000 / rrDuration) * 60 and set those three attributes
      // Note: minRR correlates to maxHR and maxRR correlates to minHR
      hrMeasurement.min = Math.round((1000 / maxRR) * 60);
      hrMeasurement.mean = Math.round((1000 / meanRR) * 60);
      hrMeasurement.max = Math.round((1000 / minRR) * 60);

      // Set meanHR for sorting; set displayedMeanHR for filtering
      strip.meanHR = hrMeasurement.mean;
      strip.displayedMeanHR = `${hrMeasurement.mean} bpm`;
    }
  }

  _httpGet(url, params) {
    const urlQuery = queryString.stringify(params);
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._Config.apiUrl}`;
    return this._$http.get(`${baseUrl}${url}?${urlQuery}`, {
      headers: {
        Authorization: authHeader,
      },
    });
  }

  _httpPost(url, body) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._Config.apiUrl}`;
    return this._$http.post(`${baseUrl}${url}`, body, {
      headers: {
        Authorization: authHeader,
      },
    });
  }

  _httpDelete(url) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._Config.apiUrl}`;
    return this._$http.delete(`${baseUrl}${url}`, {
      headers: {
        Authorization: authHeader,
      },
    });
  }

  _mouseDownEvent(event, ecgViewer) {
    // event.button:0 indicates primary mouse button
    if (!this.acceptMouseInput(ecgViewer) || event.button !== 0) {
      return;
    }
    const mousePosition = this._getMousePosition(ecgViewer, event);
    this.mouseXStart = mousePosition.x;
    delete this.previewMarker;
    ecgViewer.dragMode = true;
  }

  _mouseUpEvent(event, ecgViewer) {
    // event.button:0 indicates primary mouse button
    if (!this.acceptMouseInput(ecgViewer) || !ecgViewer.dragMode || event.button !== 0) {
      return;
    }
    ecgViewer.dragMode = false;
    const mousePosition = this._getMousePosition(ecgViewer, event);
    this.mouseXEnd = mousePosition.x;
    // The menu item is absolute positioning, not relative to the canvas
    const position = {
      clientY: event.clientY - this._Config.topNavOffset,
      clientX: event.clientX,
    };

    const markerMenuWidth = 150;
    // Display the menu on the left of the mouse if the user clicked too close to the right side of the canvas
    if (this.mouseXEnd > ecgViewer.width - markerMenuWidth) {
      position.clientX -= markerMenuWidth;
    }

    const selectedStrip = this.getSelectedStrip(ecgViewer);
    const allowCreateMeasurement =
      ecgViewer.inEditingMode || (selectedStrip && (!selectedStrip.id || selectedStrip.editing));

    let markerType;
    let menuOptions;
    let deleteEmitMessage;
    let selectedMarker = this._compiledMarkersToDisplay(ecgViewer).find((marker) => marker.selected);

    if (allowCreateMeasurement && Math.abs(this.mouseXEnd - this.mouseXStart) >= this.MIN_MOUSE_MOVE) {
      markerType = MeasurementMarker;
      selectedMarker = undefined;
    } else if (!selectedMarker) {
      // Can't create Beat Marker if not inside an ECG Event
      if (!ecgViewer.allowEcgNavigationScroll) {
        delete this.mouseXStart;
        delete this.mouseXEnd;
        delete this.previewMarker;
        this.drawMarkers(ecgViewer);
        return;
      }

      markerType = BeatMarker;
      this.previewMarker = {
        leftX: this.mouseXEnd,
        markerHeight: this.markerHeights.Pause,
      };
      this.drawMarkers(ecgViewer);
    } else if (selectedMarker.endTime === selectedMarker.startTime) {
      markerType = BeatMarker;
    } else {
      markerType = MeasurementMarker;
    }

    switch (markerType) {
      case MeasurementMarker:
        menuOptions = this.MEASUREMENT_TYPES;
        this._instantiateMarker = this._instantiateMeasurementMarker;
        deleteEmitMessage = "measurement-deleted";
        break;
      case BeatMarker:
        menuOptions = this.BEAT_NAMES;
        this._instantiateMarker = this._instantiateBeatMarker;
        deleteEmitMessage = "beat-marker-deleted";
        break;
      default:
        break;
    }

    this._showMarkerMenu(ecgViewer.markersCanvas, menuOptions, position, selectedMarker)
      .then((selectedOption) => {
        if (selectedMarker) {
          // if marker is selected, delete the selected marker and create its replacement
          ecgViewer.beatMarkers = without(ecgViewer.beatMarkers, selectedMarker); // This will have no effect if the selected marker is a measurement
          this._$rootScope.$emit(deleteEmitMessage, selectedMarker);
          if (selectedOption !== "delete") {
            // Create a new Marker with the same data as the old one except for the name.
            this._instantiateMarker(event, ecgViewer, selectedOption, selectedMarker);
          }
        } else {
          // Create a new marker
          this._instantiateMarker(event, ecgViewer, selectedOption);
        }
      })
      .catch((err) => {
        // When the Marker Menu is closed by clicking outside, an error is thrown but err is undefined.
        if (err) {
          throw err;
        }
      })
      .finally(() => {
        delete this.mouseXStart;
        delete this.mouseXEnd;
        delete this.previewMarker;
        ecgViewer.dragMode = false;
        this.drawMarkers(ecgViewer);
      });
  }

  _mouseMoveEvent(event, ecgViewer) {
    if (!this.acceptMouseInput(ecgViewer)) {
      return;
    }
    if (ecgViewer.dragMode && event.buttons !== 1 && !this._$window.Cypress) {
      // End the drag event if the held buttons are something other than "primary mouse button"
      // This situation arises when the user clicks and drags the mouse outside the canvas, then releases the click and moves the mouse back onto the canvas
      ecgViewer.dragMode = false;
      delete this.previewMarker;
    }
    const mousePosition = this._getMousePosition(ecgViewer, event);
    this.getHoveredMarker(this._compiledMarkersToDisplay(ecgViewer), mousePosition, ecgViewer);

    const selectedStrip = this.getSelectedStrip(ecgViewer);

    // Preview is only shown on an editable stripViewer or on newly created strips
    const allowPreviewMeasurement =
      ecgViewer.inEditingMode || (selectedStrip && (!selectedStrip.id || selectedStrip.editing));

    if (allowPreviewMeasurement && ecgViewer.dragMode) {
      if (Math.abs(mousePosition.x - this.mouseXStart) >= this.MIN_MOUSE_MOVE) {
        this.previewMarker = {
          leftX: Math.min(this.mouseXStart, mousePosition.x),
          rightX: Math.max(this.mouseXStart, mousePosition.x),
          markerHeight: this.markerHeights.RR,
          withPreviewCaliper: true,
        };
      } else {
        this.previewMarker = {
          leftX: mousePosition.x,
          markerHeight: this.markerHeights.RR,
        };
      }
    }

    this.drawMarkers(ecgViewer);
  }

  _showMarkerMenu(canvas, options, position, selectedMarker) {
    return this._$mdDialog.show({
      controller: "MarkerMenuController",
      controllerAs: "markers",
      locals: {options, position, selectedMarker},
      template: markerMenuPug(),
      fullscreen: false,
      clickOutsideToClose: true,
      hasBackdrop: false,
      disableParentScroll: false,
      multiple: true, // This must be included on the child mdDialog when there are nested mdDialogs
    });
  }

  _instantiateBeatMarker(event, ecgViewer, markerName, markerToCopy) {
    const {ecgData} = ecgViewer;
    let sampleOffset;
    if (markerToCopy && (markerToCopy.sampleOffset || markerToCopy.sampleOffset === 0)) {
      sampleOffset = markerToCopy.sampleOffset;
    } else {
      const mousePosition = this._getMousePosition(ecgViewer, event);
      const totalPixels = ecgViewer.width;
      const {startSample} = ecgViewer;
      const totalSamples = ecgData.samplesPerSecond * ecgViewer.secondsToDisplay;
      const sample = this._getSampleFromXCoord(mousePosition.x, totalSamples, totalPixels, startSample);
      sampleOffset = sample - ecgData.eventSample;
    }
    const beatMarker = new BeatMarker(sampleOffset, markerName, ecgData);
    this._$rootScope.$emit("beat-marker-created", beatMarker);
    ecgViewer.beatMarkers.push(beatMarker);
    return beatMarker;
  }

  _instantiateMeasurementMarker(event, ecgViewer, markerName, markerToCopy) {
    const {ecgData} = ecgViewer;
    let startSampleOffset;
    let endSampleOffset;
    if (
      markerToCopy &&
      (markerToCopy.startSampleOffset || markerToCopy.startSampleOffset === 0) &&
      (markerToCopy.endSampleOffset || markerToCopy.endSampleOffset === 0)
    ) {
      startSampleOffset = markerToCopy.startSampleOffset;
      endSampleOffset = markerToCopy.endSampleOffset;
    } else {
      const mousePosition = this._getMousePosition(ecgViewer, event);
      const totalPixels = ecgViewer.width;
      const {startSample} = ecgViewer;
      const totalSamples = ecgData.samplesPerSecond * ecgViewer.secondsToDisplay;
      const startX = Math.min(this.mouseXStart, mousePosition.x);
      const endX = Math.max(this.mouseXStart, mousePosition.x);
      const markerStartSample = this._getSampleFromXCoord(startX, totalSamples, totalPixels, startSample);
      const markerEndSample = this._getSampleFromXCoord(endX, totalSamples, totalPixels, startSample);
      startSampleOffset = markerStartSample - ecgData.eventSample;
      endSampleOffset = markerEndSample - ecgData.eventSample;
    }
    const measurementMarker = new MeasurementMarker(startSampleOffset, endSampleOffset, markerName, ecgData);
    this._$rootScope.$emit("measurement-created", measurementMarker);
    return measurementMarker;
  }

  _getMousePosition(ecgViewer, event) {
    const canvas = ecgViewer.markersCanvas;
    const rect = canvas.getBoundingClientRect();

    // Correct for DPI scaling
    const canvasWidth = ecgViewer.width;
    const containerWidth = ecgViewer.navigationCanvas.scrollWidth;
    const correctedX = Math.round(((event.clientX - rect.left) * canvasWidth) / containerWidth);
    const canvasHeight = ecgViewer.height;
    const containerHeight = ecgViewer.navigationCanvas.scrollHeight;
    const correctedY = Math.round(((event.clientY - rect.top) * canvasHeight) / containerHeight);

    return {
      x: correctedX,
      y: correctedY,
    };
  }

  _getAdjustedCoords(ecgViewer, marker) {
    const leftX = this._getXCoordFromTimestamp(ecgViewer, marker.startTime);
    const rightX = this._getXCoordFromTimestamp(ecgViewer, marker.endTime);
    const centerX = (leftX + rightX) / 2;

    return {
      leftX: this._canvasAdjust(leftX),
      centerX: this._canvasAdjust(centerX),
      rightX: this._canvasAdjust(rightX),
    };
  }

  _getXCoordFromTimestamp(ecgViewer, timestamp) {
    const {ecgData, startSample, secondsToDisplay, width} = ecgViewer;
    const secondsFromEcgStart = _getDuration({startTime: ecgData.startTime, endTime: timestamp}, "seconds");
    const obscuredSeconds = startSample / ecgData.samplesPerSecond;
    const secondsFromLeft = secondsFromEcgStart - obscuredSeconds;

    return (secondsFromLeft / secondsToDisplay) * width;
  }

  _getSampleFromXCoord(xCoord, totalSamples, totalPixels, startSample) {
    return startSample + Math.round((xCoord * totalSamples) / totalPixels);
  }

  // This has to convert pixels to samples, then to duration
  _getDurationFromCoords(ecgViewer, startX, endX) {
    const totalPixels = ecgViewer.width;
    const {ecgData, startSample} = ecgViewer;
    const totalSamples = ecgData.samplesPerSecond * ecgViewer.secondsToDisplay;
    const startXSample = this._getSampleFromXCoord(startX, totalSamples, totalPixels, startSample);
    const endXSample = this._getSampleFromXCoord(endX, totalSamples, totalPixels, startSample);

    const secondsDuration = (endXSample - startXSample) / ecgData.samplesPerSecond;
    return Math.round(secondsDuration * 1000);
  }

  _compiledMarkersToDisplay(ecgViewer, excludedMarkerTypes = []) {
    const includeBeatMarkers = !excludedMarkerTypes.includes("beatMarkers");
    const includeTrigger = !excludedMarkerTypes.includes("trigger");
    const includeMeasurements = !excludedMarkerTypes.includes("measurements");

    const markersToDisplay = [];
    if (includeBeatMarkers) {
      const beatMarkers = ecgViewer.beatMarkers.filter((marker) => !marker.isTriggerMarker);
      markersToDisplay.push(...beatMarkers);
    }
    if (includeTrigger) {
      const triggerMarkers = ecgViewer.beatMarkers.filter((marker) => marker.isTriggerMarker);
      markersToDisplay.push(...triggerMarkers);
    }

    // Accumulate measurements from selected strip onto markers array
    const selectedStrip = this.getSelectedStrip(ecgViewer);
    if (selectedStrip && includeMeasurements) {
      this.MEASUREMENT_TYPES.forEach((measurementType) => {
        const measurementsFound = selectedStrip.measurements[measurementType].data;
        markersToDisplay.push(...measurementsFound);
      });
    }
    return markersToDisplay;
  }

  _canvasAdjust(value) {
    return this._roundToNearestDecimalAmount(value, this.CANVAS_LINE_OFFSET);
  }

  _roundToNearestDecimalAmount(value, decimalAmount) {
    return Math.round(value - decimalAmount) + decimalAmount;
  }
}

/**
 * Calculates Duration from start and end time
 *
 * @param {String} measurement.startTime in ISO format
 * @param {String} measurement.endTime in ISO format
 * @param {String} unit e.g. "milliseconds" or "seconds"
 * @returns {Number}
 */
function _getDuration({startTime, endTime}, unit = "milliseconds") {
  return Math.abs(DateTime.fromISO(endTime).diff(DateTime.fromISO(startTime)).as(unit));
}
