import queryString from "qs";

/* @ngInject */
export default class ChannelConnectionService {
  constructor($injector) {
    this._$injector = $injector;
    this._backendConfig = $injector.get("backendConfig");
    this._$http = $injector.get("$http");
    this._Authentication = $injector.get("Authentication");
    this.features = this._backendConfig.features;
  }

  /**
   * @param {Object} params - properties to limit getChannelConnections request by
   * @returns {Promise<Object[]>} connectionHistory property of the returned channelConnection
   *
   * @see SRS: BR-3401
   */
  getConnectionHistory(params = {}) {
    const url = "/channelConnections";
    return this._httpGet(url, params)
      .then((response) => response.data)
      .then((channelConnections) => {
        return {
          connectionHistory: channelConnections[0].connectionHistory,
          activeChannels: channelConnections[0].activeChannels,
        };
      });
  }

  /**
   * @param {string} url   - the url to make a request to
   * @param {Object} params - the query parameters to attach to the request
   * @returns {Promise} get request
   * @see SRS: BR-2455
   */
  _httpGet(url, params) {
    const urlQuery = queryString.stringify(params);
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.get(`${baseUrl}${url}?${urlQuery}`, {
      headers: {
        Authorization: authHeader,
      },
    });
  }
}
