import React from "react";
import axios from "axios"; // NOTE: do NOT use axiosClient.js here!
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useInterval} from "@tzmedical/react-hooks";

function BuildNotifier({
  // Props
  intervalSeconds,
}) {
  //---------------------------------------------------------------------------
  // Global variables & state
  //---------------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);

  //---------------------------------------------------------------------------
  // Monitor the buildIdentifier for updates
  //---------------------------------------------------------------------------
  const handler = React.useCallback(async () => {
    try {
      const {data: buildIdentifier} = await axios({
        method: "get",
        url: "/buildIdentifier",
        headers: {"cache-control": "no-store"},
        params: {"no-cache": `${Date.now()}`},
        // The axios default of JSON coerces numeric strings to numbers...
        responseType: "text",
      });

      if (`${buildIdentifier}` !== `${window.globalConfig.buildIdentifier}`) {
        setOpen(true);
      }
    } catch (err) {
      // do nothing
    }
  }, [setOpen]);

  useInterval(handler, intervalSeconds * 1000);

  const onClick = () => {
    setOpen(false);
    window.location.reload();
  };

  //---------------------------------------------------------------------------
  // Render the snackbar if needed
  //---------------------------------------------------------------------------
  return (
    <Snackbar
      className="buildNotifierWarning"
      open={open}
      anchorOrigin={{vertical: "top", horizontal: "right"}}
      sx={{mt: 6.5}}
    >
      <SnackbarContent
        id="app-build-notifier"
        message="A new version is available, refresh the page."
        action={
          <Button
            id="app-build-notifier-refresh"
            aria-label="close"
            color="inherit"
            size="small"
            onClick={onClick}
          >
            Refresh
          </Button>
        }
      />
    </Snackbar>
  );
}

BuildNotifier.propTypes = {
  intervalSeconds: PropTypes.number.isRequired,
};

export default BuildNotifier;
