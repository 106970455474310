import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import TextWithNewlines from "../../../shared/react/TextWithNewlines.jsx";
import DateAndTime from "../../DateAndTime/DateAndTime.jsx";

function TechnicianFindings({
  // Props
  report,
  study,
}) {
  const technicianSignature = React.useMemo(() => {
    if (report.technicianSignedBy && report.technicianSignedAt) {
      return `Electronically signed by ${report.technicianSignedBy}`;
    }

    return "";
  }, [report.technicianSignedAt, report.technicianSignedBy]);

  return (
    <Box
      sx={{mt: 1, p: 1, border: (theme) => `2px solid ${theme.palette.background.main}`}}
      className="report-element"
      id="technician-findings"
    >
      <Typography variant="subtitle2" mb={1}>
        Technician Findings
      </Typography>

      <Box minHeight={30}>
        <TextWithNewlines text={report.comment} variant="reportText" data-cy="technician-findings" />
      </Box>

      <Grid container columns={10} mt={2} alignItems="flex-end">
        <Grid size="auto">
          <Typography variant="reportText">
            <b>Technician Name:</b>
          </Typography>
        </Grid>
        <Grid size={2} sx={{borderBottom: "1px solid black"}}>
          <Typography variant="reportText" align="center" data-cy="technician-signed-by">
            {report.technicianSignedBy}
          </Typography>
        </Grid>

        <Grid size="auto">
          <Typography variant="reportText">
            <b>Technician Signature:</b>
          </Typography>
        </Grid>
        <Grid size="grow" sx={{borderBottom: "1px solid black"}}>
          <Typography variant="reportText" align="center" data-cy="technician-signature">
            {technicianSignature}
          </Typography>
        </Grid>

        <Grid size="auto">
          <Typography variant="reportText">
            <b>Date:</b>
          </Typography>
        </Grid>
        <Grid size={2} sx={{borderBottom: "1px solid black"}}>
          <Typography variant="reportText" align="center" data-cy="technician-signed-at">
            {report.technicianSignedAt && (
              <DateAndTime datetime={report.technicianSignedAt} zone={study.timeZone} />
            )}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

TechnicianFindings.propTypes = {
  report: PropTypes.object.isRequired,
  study: PropTypes.object.isRequired,
};

export default React.memo(TechnicianFindings);
