import {useRef} from "react";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

const useDeepCompareMemoize = (value) => {
  const ref = useRef();

  if (!isEqual(value, ref.current)) {
    ref.current = cloneDeep(value);
  }

  return ref.current;
};

export default useDeepCompareMemoize;
