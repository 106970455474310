import React from "react";
import {scaleSymlog} from "d3";
import {Interval} from "luxon";
import {Label, Line, ReferenceArea, YAxis} from "recharts";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import {grey} from "@mui/material/colors";
import {useTheme} from "@mui/material/styles";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import InboxEntityContext from "../../contexts/InboxEntityContext.jsx";
import TimelineChart from "../study-graphs/TimelineChart.jsx";

const usePvcBurdenData = ({
  // Props
  study,
  data,
  startTime,
  endTime,
  reportType = "Summary",
  setLoading = () => {},
  setError = () => {},
} = {}) => {
  //---------------------------------------------------------------------------
  // Determine if this component is in a generated report for data fetching
  //---------------------------------------------------------------------------
  const {type: parentType} = React.useContext(InboxEntityContext);

  //---------------------------------------------------------------------------
  // Get theme for colors
  //---------------------------------------------------------------------------
  const theme = useTheme();

  //---------------------------------------------------------------------------
  // Fetch data from API
  //---------------------------------------------------------------------------
  const [pvcData, setPvcData] = React.useState(data?.pvcData || []);
  const [qrsExclusionRegions, setQrsExclusionRegions] = React.useState(data?.qrsExclusionRegions || []);
  const [arrhythmiaData, setArrhythmiaData] = React.useState(data?.arrhythmiaData || []);

  const getPvcBurdenData = React.useCallback(async () => {
    setLoading(true);

    try {
      const [{data: pvcDataResponse}, {data: qrsExclusionRegionResponse}, {data: arrhythmiaDataResponse}] =
        await Promise.all([
          axios({
            method: "get",
            url: `/reports/pvcBurden/${study.id}`,
            params: {
              startTime: startTime.toUTC().toISO(),
              endTime: endTime.toUTC().toISO(),
            },
          }),
          axios({
            method: "get",
            url: `/qrsExclusionRegions/${study.id}`,
            params: {
              startTime: {$gte: startTime.toUTC().toISO()},
              endTime: {$lte: endTime.toUTC().toISO()},
            },
          }),
          axios({
            method: "get",
            url: `/reports/arrhythmiaTimeline/${study.id}`,
            params: {
              startTime: startTime.toUTC().toISO(),
              endTime: endTime.toUTC().toISO(),
            },
          }),
        ]);

      setPvcData(pvcDataResponse);
      setQrsExclusionRegions(qrsExclusionRegionResponse);
      setArrhythmiaData(arrhythmiaDataResponse);
    } catch (err) {
      setError(err.message);
    }

    setLoading(false);
  }, [startTime, endTime, study.id, setError, setLoading]);

  React.useEffect(() => {
    // If this component is in a generated report, then we already have the data so
    // no need to fetch it
    if (parentType !== "generated-report" && !pvcData.length) {
      getPvcBurdenData();
    }
  }, [parentType, pvcData.length, getPvcBurdenData]);

  const weeks = React.useMemo(() => {
    const usableData = pvcData.filter((point) => point.percent !== null);

    const interval = Interval.fromDateTimes(startTime, endTime);
    if (interval.length("days") <= 1) {
      return [
        {
          startTime,
          endTime,
          data: usableData,
        },
      ];
    }

    return interval.splitBy({weeks: 1}).map((week) => {
      return {
        startTime: week.start,
        endTime: week.end,
        data: usableData?.filter(
          (point) => week.start.toMillis() <= point.mt && point.mt < week.end.toMillis()
        ),
      };
    });
  }, [endTime, pvcData, startTime]);

  // @TODO study-trove should be doing these calculations
  const exclusionRegions = React.useMemo(() => {
    //---------------------------------------------------------------------------
    // Merge overlapping exclusion regions
    //---------------------------------------------------------------------------
    qrsExclusionRegions.forEach((region) => {
      region.startTime = new Date(region.startTime).getTime();
      region.endTime = new Date(region.endTime).getTime();
    });
    qrsExclusionRegions.sort((a, b) => a.startTime - b.startTime);

    const mergedExclusionRegions = [];
    let accumulator = null;

    qrsExclusionRegions.forEach((region) => {
      if (!accumulator) {
        accumulator = region;
      } else if (region.startTime <= accumulator.endTime) {
        // If regions overlap, combine into the accumulator by setting accumulator.endTime to larger of the two
        if (region.endTime > accumulator.endTime) {
          accumulator.endTime = region.endTime;
        }
      } else {
        // Regions do not overlap so move on to the next region
        mergedExclusionRegions.push(accumulator);
        accumulator = region;
      }
    });
    if (accumulator) {
      mergedExclusionRegions.push(accumulator);
    }

    // include unreadable ECG data for exclusion regions
    const eventTypesToExclude = ["Unreadable ECG Data", "Artifact", "Lead Off"];
    const unreadableEcgData = arrhythmiaData
      .filter((event) => eventTypesToExclude.includes(event.eventName))
      .map((region) => ({
        startTime: new Date(region.startTime).getTime(),
        endTime: new Date(region.endTime).getTime(),
      }));

    const sectionUnreadableEcgData = unreadableEcgData.filter((event) => {
      return (
        (event.startTime >= startTime.toMillis() && event.startTime <= endTime.toMillis()) ||
        (event.endTime >= startTime.toMillis() && event.endTime <= endTime.toMillis()) ||
        (event.startTime <= startTime.toMillis() && event.endTime >= endTime.toMillis())
      );
    });

    return mergedExclusionRegions.concat(sectionUnreadableEcgData);
  }, [startTime, endTime, qrsExclusionRegions, arrhythmiaData]);

  //---------------------------------------------------------------------------
  // Configure y-axis scale and tooltip formatting
  //---------------------------------------------------------------------------
  const yScale = scaleSymlog([0, 100]);
  const percentFormatter = React.useCallback((percent) => `${Math.round(percent * 100) / 100}%`, []); // Round to two decimal places if necessary
  const tooltipFormatter = React.useCallback(
    (percent) => [percentFormatter(percent), "PVC Burden"],
    [percentFormatter]
  );

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  const separateCharts = React.useMemo(() => {
    return weeks.map((week, index) => {
      return (
        <Box key={week.startTime.ts} data-cy={`pvc-burden-${index}`}>
          <TimelineChart
            startTime={week.startTime}
            endTime={week.endTime}
            data={week.data}
            tooltipFormatter={tooltipFormatter}
            reportType={reportType}
            study={study}
          >
            {/* -------------- YAXIS -------------- */}
            <YAxis
              yAxisId="pvc-burden"
              tick={{fontSize: 10}}
              type="number"
              domain={[0, 100]}
              ticks={[1, 5, 10, 20, 40, 60, 80, 100]}
              interval={0}
              scale={yScale}
              tickFormatter={percentFormatter}
            >
              <Label
                value="PVC Burden (%)"
                angle={-90}
                fontSize={10}
                position="insideLeft"
                offset={20}
                style={{textAnchor: "middle"}}
              />
            </YAxis>
            {/* -------------- PVC BURDEN DATA -------------- */}
            <Line
              yAxisId="pvc-burden"
              type="monotone"
              dataKey="percent"
              stroke={theme.palette.primary.dark}
              dot={false}
              isAnimationActive={false}
            />

            {/* -------------- EXCLUSION REGIONS -------------- */}
            {exclusionRegions.map((exclusionRegion) => (
              <ReferenceArea
                key={exclusionRegion.startTime}
                x1={exclusionRegion.startTime}
                x2={exclusionRegion.endTime}
                yAxisId="pvc-burden"
                fillOpacity={1}
                fill={grey[300]}
              />
            ))}
          </TimelineChart>
        </Box>
      );
    });
  }, [
    exclusionRegions,
    percentFormatter,
    reportType,
    study,
    theme.palette.primary.dark,
    tooltipFormatter,
    weeks,
    yScale,
  ]);

  return separateCharts;
};

export default usePvcBurdenData;
