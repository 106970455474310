import React from "react";
import PropType from "prop-types";

const RespectFiltersContext = React.createContext();

export function RespectFiltersProvider({children}) {
  const [respectFilters, setRespectFilters] = React.useState(true);

  const value = React.useMemo(() => ({respectFilters, setRespectFilters}), [respectFilters]);

  return <RespectFiltersContext.Provider value={value}>{children}</RespectFiltersContext.Provider>;
}
RespectFiltersProvider.propTypes = {
  children: PropType.node.isRequired,
};

export function useRespectFilters() {
  return React.useContext(RespectFiltersContext);
}
