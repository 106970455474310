import React from "react";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {ResizeObserver} from "@juggle/resize-observer";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowUpward from "@mui/icons-material/ArrowUpward";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import InboxEntityContext from "../../contexts/InboxEntityContext.jsx";
import FormStringInput from "../../shared/react/FormStringInput.jsx";
import SwitchInput from "../../shared/react/SwitchInput.jsx";
import useDeepCompareMemoize from "../hooks/useDeepCompareMemoize.jsx";
import ContextViewer from "./ContextViewer.jsx";
import FullViewer from "./FullViewer.jsx";
import GridLines from "./GridLines.jsx";
import StripViewer from "./StripViewer.jsx";

//---------------------------------------------------------------------------
// Constants
//---------------------------------------------------------------------------
const CONTEXT_VIEWER_HEIGHT = 37; // pixels
const FULL_VIEWER_HEIGHT = 20; // pixels
const MINIMUM_STRIP_HEIGHT = 171; // pixels

const DEFAULT_TIME_BASE = 25;
const DEFAULT_GAIN = 10;

function EcgViewer({
  // Props
  ecg,
  strip,
}) {
  //---------------------------------------------------------------------------
  // Global variables
  //---------------------------------------------------------------------------
  const {type: parentType} = React.useContext(InboxEntityContext);
  const isGeneratedReport = React.useMemo(() => parentType === "generated-report", [parentType]);

  //---------------------------------------------------------------------------
  // Calculate width and height of viewer for responsive sizing
  //---------------------------------------------------------------------------
  const viewerRef = React.useRef(null);
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    const ecgViewer = viewerRef.current;

    // All ECG strip viewers are 3 times as wide as they are tall, 4.5 if a generated report
    const aspectRatio = isGeneratedReport ? 4.5 : 3;

    // Use a Resize Observe to re-calculate the dimensions if the element changes size
    const resizeObserver = new ResizeObserver((entries) => {
      if (!Array.isArray(entries)) {
        return;
      }
      if (!entries.length) {
        return;
      }

      const entry = entries[0];

      if (width !== entry.contentRect.width) {
        setWidth(entry.contentRect.width);
        setHeight(entry.contentRect.width / aspectRatio);
      }
    });
    resizeObserver.observe(ecgViewer);

    return () => resizeObserver.unobserve(ecgViewer);
  }, [isGeneratedReport, width]);

  //---------------------------------------------------------------------------
  // Strip Tools
  //---------------------------------------------------------------------------
  const defaultValues = React.useMemo(() => {
    const display = {};
    const invert = {};

    ecg.leads.forEach(({leadName}) => {
      display[leadName] = strip?.displayedLeads[leadName.replace("Lead ", "")] ?? true;
      invert[leadName] = strip?.invertedChannels[leadName.replace("Lead ", "")] ?? false;
    });

    return {
      display,
      invert,
      timeBase: strip?.timeBase || DEFAULT_TIME_BASE,
      gain: strip?.gain || DEFAULT_GAIN,
      selectedLead: strip ? `Lead ${strip.sequenceLead}` : ecg.leads[1]?.leadName,
    };
  }, [ecg.leads, strip]);

  const methods = useForm({defaultValues});
  const {control, watch} = methods;

  // Only allow the user to select displayed leads for the context viewer
  const availableLeads = watch("display");
  const memoizedAvailableLeads = useDeepCompareMemoize(availableLeads);

  const selectedLeadOptions = React.useMemo(() => {
    return Object.entries(memoizedAvailableLeads)
      .filter(([, display]) => display)
      .map(([lead]) => ({id: lead, name: lead}));
  }, [memoizedAvailableLeads]);

  //---------------------------------------------------------------------------
  // Manage the centered sample of the current strip
  //---------------------------------------------------------------------------
  const [stripCenteredSample, setStripCenteredSample] = React.useState(
    strip?.centeredSample || ecg.eventSample
  );
  const [contextCenteredSample, setContextCenteredSample] = React.useState(
    strip?.centeredSample || ecg.eventSample
  );

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <Box data-cy="ecg-viewer">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...methods} defaultValues={defaultValues}>
        <Box
          ref={viewerRef}
          width="100%"
          sx={{display: "block", position: "relative", height, minHeight: MINIMUM_STRIP_HEIGHT}}
        >
          <GridLines width={width} height={height} />

          <StripViewer
            leads={ecg.leads}
            samplePeriod={ecg.samplePeriod}
            avm={ecg.avm}
            eventSample={ecg.eventSample}
            stripCenteredSample={stripCenteredSample}
            width={width}
            height={height}
          />
        </Box>

        <Divider />

        <Box width="100%" sx={{display: "block", position: "relative", height: CONTEXT_VIEWER_HEIGHT}}>
          <ContextViewer
            leads={ecg.leads}
            samplePeriod={ecg.samplePeriod}
            avm={ecg.avm}
            eventSample={ecg.eventSample}
            contextCenteredSample={contextCenteredSample}
            stripCenteredSample={stripCenteredSample}
            setStripCenteredSample={setStripCenteredSample}
            width={width}
            height={CONTEXT_VIEWER_HEIGHT}
          />
        </Box>

        <Divider />

        {!isGeneratedReport && (
          <>
            <Box width="100%" sx={{display: "block", position: "relative", height: FULL_VIEWER_HEIGHT}}>
              <FullViewer
                startTime={ecg.startTime}
                totalSamples={ecg.leads[0].totalSamples}
                samplePeriod={ecg.samplePeriod}
                contextCenteredSample={contextCenteredSample}
                setContextCenteredSample={setContextCenteredSample}
                width={width}
                height={FULL_VIEWER_HEIGHT}
              />
            </Box>

            <Divider />

            {/* STRIP TOOLS */}
            <Grid container sx={{p: 1}}>
              {/* SELECTED LEAD */}
              <Grid size={2}>
                <Box display="inline-flex" alignItems="center">
                  <Typography variant="body2" mr={1}>
                    Selected Lead
                  </Typography>

                  <FormStringInput
                    control={control}
                    name="selectedLead"
                    defaultValue={defaultValues.selectedLead}
                    options={selectedLeadOptions}
                    otherProps={{variant: "outlined", size: "small", hiddenLabel: true}}
                  />
                </Box>
              </Grid>

              {/* LEAD TOGGLE AND INVERSION */}
              {ecg.leads.map(({leadName}) => (
                <Grid size={2} key={leadName}>
                  <Box display="inline-flex" alignItems="center">
                    <Typography variant="body2">{leadName}</Typography>

                    <SwitchInput
                      control={control}
                      name={`display.[${leadName}]`}
                      defaultValue={defaultValues.display[leadName]}
                      color="secondary"
                    />

                    <Controller
                      name={`invert.[${leadName}]`}
                      control={control}
                      defaultValue={defaultValues.invert[leadName]}
                      shouldUnregister
                      render={({field: {onChange, value}}) => (
                        <Tooltip title="Invert channel">
                          <IconButton onClick={() => onChange(!value)} color="secondary" size="small">
                            {value ? <ArrowUpward /> : <ArrowDownward />}
                          </IconButton>
                        </Tooltip>
                      )}
                    />
                  </Box>
                </Grid>
              ))}

              {/* TIME BASE */}
              <Grid size={2}>
                <Box display="inline-flex" alignItems="center">
                  <Typography variant="body2" mr={1}>
                    Time
                  </Typography>

                  <FormStringInput
                    control={control}
                    name="timeBase"
                    defaultValue={defaultValues.timeBase}
                    options={[
                      {id: "12.5", name: "12.5"},
                      {id: "25", name: "25"},
                      {id: "50", name: "50"},
                    ]}
                    otherProps={{variant: "outlined", size: "small", hiddenLabel: true}}
                  />

                  <Typography variant="body2" ml={1}>
                    mm/s
                  </Typography>
                </Box>
              </Grid>

              {/* GAIN */}
              <Grid size={2}>
                <Box display="inline-flex" alignItems="center">
                  <Typography variant="body2" mr={1}>
                    Gain
                  </Typography>

                  <FormStringInput
                    control={control}
                    name="gain"
                    defaultValue={defaultValues.gain}
                    options={[
                      {id: "5", name: "5"},
                      {id: "10", name: "10"},
                      {id: "20", name: "20"},
                    ]}
                    otherProps={{variant: "outlined", size: "small", hiddenLabel: true}}
                  />

                  <Typography variant="body2" ml={1}>
                    mm/mV
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Divider />
          </>
        )}
      </FormProvider>
    </Box>
  );
}

EcgViewer.propTypes = {
  ecg: PropTypes.object.isRequired,
  strip: PropTypes.object,
};

export default EcgViewer;
