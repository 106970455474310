import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Typography from "@mui/material/Typography";

function TextWithNewlines({
  // Props
  text,
  variant = "body1",
  "data-cy": dataCy,
}) {
  return (
    <Typography variant={variant} data-cy={dataCy} sx={{whiteSpace: "pre-wrap"}}>
      {text}
    </Typography>
  );
}

TextWithNewlines.propTypes = {
  text: PropTypes.node,
  variant: PropTypes.string,
  "data-cy": PropTypes.string,
};

export default TextWithNewlines;
