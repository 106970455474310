/* @ngInject */
/**
 * @class activeStudiesController
 * @memberof module:inbox/activeStudies
 * @param {service}  SearchService    search service
 */
export default class ActiveStudiesController {
  constructor($window, SearchService, Config) {
    this._$window = $window;
    this._Search = SearchService;
    this.features = Config.features;

    // WARNING! Make sure this matches the searchFields in StudiesTable.jsx
    // (Omit values here that are handled by a filter button)
    this._Search.autoFillValues = [
      "patient:",
      "device:",
      "id:",
      "study:",
      "order:",
      "indication:",
      "is:unconfigured",
      "start:",
      "end:",
      "finalized:",
      "facility:",
      ...(this.features.evaluationContract
        ? ["contract:none", "contract:device", "contract:service", "contract:split", "contract:evaluation"]
        : []),
      "recording:",
      "dob:",
      "language:",
      "gender:",
      "height:",
      "weight:",
      "phone:",
      "emergencyContact:",
      "emergencyPhone:",
      "address:",
      "physician:",
      "physician-name:",
      "physician-phone:",
      "physician-facility:",
      "physician-email:",
      "physician-address:",
      "insurance:",
      ...(this.features.downgradeAuthorized ? ["is:downgradeAuthorized", "is:meetingReportCriteria"] : []),
    ];

    const searchFromStorage = this._$window.localStorage.getItem("search");
    if (searchFromStorage) {
      const currentTime = new Date().getTime();
      const search = JSON.parse(searchFromStorage);

      if (currentTime < search.timeExpired) {
        this._Search.searchText = search.text;
      }
      this._$window.localStorage.removeItem("search");
    }
  }
}
