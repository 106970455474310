//------------------------------------------------------------------------------
//             __             __   ___  __
//     | |\ | /  ` |    |  | |  \ |__  /__`
//     | | \| \__, |___ \__/ |__/ |___ .__/
//
//------------------------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";

import AddProviders from "../../shared/react/AddProviders.jsx";
import ResetAccount from "./ResetAccount.jsx";

//------------------------------------------------------------------------------
//                __          __        ___  __
//     \  /  /\  |__) |  /\  |__) |    |__  /__`
//      \/  /~~\ |  \ | /~~\ |__) |___ |___ .__/
//
//------------------------------------------------------------------------------

//------------------------------------------------------------------------------
//      __   __              ___  ___
//     |__) |__) | \  /  /\   |  |__
//     |    |  \ |  \/  /~~\  |  |___
//
//------------------------------------------------------------------------------

//-----------------------------------------------------------------------------
//      __        __          __
//     |__) |  | |__) |    | /  `
//     |    \__/ |__) |___ | \__,
//
//-----------------------------------------------------------------------------

// =============================================================================
function ResetAccountWrapper({
  // Props
  username,
}) {
  //---------------------------------------------------------------------------
  // NOTE: The only reason that this file is separate from ResetAccount.jsx is because of Angular.
  //  Once we switch completely from Angular to React, ConfirmProvider can be moved to an app.js so
  //  that it encapsulates the entire application. Then these two files can be combined.
  //---------------------------------------------------------------------------

  return (
    <AddProviders>
      <ResetAccount username={username} />
    </AddProviders>
  );
}

ResetAccountWrapper.propTypes = {
  username: PropTypes.string,
};

export default ResetAccountWrapper;
