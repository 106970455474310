import React from "react";
import {useFormContext} from "react-hook-form";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import DoDisturbOn from "@mui/icons-material/DoDisturbOn";
import Print from "@mui/icons-material/Print";

//---------------------------------------------------------------------------
// MUI
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../../axiosClient.js";
import {useReportsDispatch} from "../../../contexts/ReportsContext.jsx";
import MdnCriteriaTooltip from "../../MdnCriteriaTooltip/MdnCriteriaTooltip.jsx";
import RejectConfirmation from "../RejectConfirmation.jsx";

function PublishedReportActions({
  // Props
  report,
  study,
  disabled = false,
  setError,
  handleClose,
  setSuccessMessage,
  setLoadingMessage,
  setLoadData,
}) {
  //---------------------------------------------------------------------------
  // Form submission
  //---------------------------------------------------------------------------
  const {handleSubmit} = useFormContext();
  const [submitting, setSubmitting] = React.useState(false);
  const dispatch = useReportsDispatch();

  const onPrint = React.useCallback(async () => {
    setSubmitting(true);

    try {
      //---------------------------------------------------------------------------
      // Update the generated report
      //---------------------------------------------------------------------------
      const {data: updatedReport} = await axios({
        method: "patch",
        url: `/generatedReports/${report.id}`,
        data: {state: "printed"},
      });

      // Set the success message before the dispatcher in case filters hide this report state
      setSuccessMessage("Successfully marked as printed"); // @TODO `Successfully marked ${title} as printed`

      dispatch({type: "updated", updatedElement: {...report, ...updatedReport}});

      setSubmitting(false);
      handleClose();
    } catch (err) {
      setError(err.message);
      setSubmitting(false);
    }
  }, [report, handleClose, setError, dispatch, setSuccessMessage]);

  //---------------------------------------------------------------------------
  // Rejecting
  //---------------------------------------------------------------------------
  const [openRejectConfirmation, setOpenRejectConfirmation] = React.useState(false);
  const handleClickReject = React.useCallback(() => setOpenRejectConfirmation(true), []);
  const handleCancelRejectConfirmation = React.useCallback(() => setOpenRejectConfirmation(false), []);
  const handleCloseRejectConfirmation = React.useCallback(() => {
    setOpenRejectConfirmation(false);
    handleClose();

    // Reload after rejecting a report that will become raw
    if (report.reportType !== "Uploaded") {
      setLoadingMessage("Reloading report");
      setLoadData(true);
    }
  }, [handleClose, report.reportType, setLoadData, setLoadingMessage]);

  return (
    <>
      <Box>
        <MdnCriteriaTooltip criteria={study.studyDetails?.physicianNotes} met={!!report.meetsMdnCriteria} />
      </Box>

      <form onSubmit={handleSubmit(onPrint)} noValidate>
        <Box>
          <LoadingButton
            variant="contained"
            color="secondary"
            fullWidth
            startIcon={<Print />}
            loading={submitting}
            disabled={disabled || submitting}
            data-cy="print-button"
            type="submit"
          >
            Mark as Printed
          </LoadingButton>

          <Button
            variant="outlined"
            color="tertiary"
            fullWidth
            startIcon={<DoDisturbOn />}
            disabled={disabled || submitting}
            data-cy="reject-button"
            sx={{mt: 2}}
            onClick={handleClickReject}
          >
            Reject
          </Button>
        </Box>
      </form>

      <RejectConfirmation
        open={openRejectConfirmation}
        handleCancel={handleCancelRejectConfirmation}
        handleClose={handleCloseRejectConfirmation}
        report={report}
        setError={setError}
        setSuccessMessage={setSuccessMessage}
      />
    </>
  );
}

PublishedReportActions.propTypes = {
  report: PropTypes.object.isRequired,
  study: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  setError: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  setLoadingMessage: PropTypes.func.isRequired,
  setLoadData: PropTypes.func.isRequired,
};

export default PublishedReportActions;
