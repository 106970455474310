/* @ngInject */
export default class VentricularEctopyDataService {
  /**
   * Splits off, calculates percentage and returns VE groupings in array format
   *
   * @param {Object} ventricularEctopyData - Object containing groupings and total VE beats
   * @returns {Array} Groupings of consecutive VE beat counts
   *
   * @memberOf module:app.shared/ventricularEctopyDataService
   * @see SRS: BR-1168
   */
  getGroupings(ventricularEctopyData) {
    const totalGroupsOfVBeats =
      ventricularEctopyData.singles +
      ventricularEctopyData.couplets +
      ventricularEctopyData.triplets +
      ventricularEctopyData.runs;
    const groupings = [
      {category: "Single V Beats", count: ventricularEctopyData.singles},
      {category: "Couplets", count: ventricularEctopyData.couplets},
      {category: "Triplets", count: ventricularEctopyData.triplets},
      {category: "Runs over 3", count: ventricularEctopyData.runs},
    ];

    groupings.forEach((grouping) => {
      grouping.percent = Math.round((grouping.count / totalGroupsOfVBeats) * 100);
    });

    return groupings;
  }

  /**
   * Calculates the Percent of QRS Locations that are V beats (total/totalQrsLocations)
   *
   * @param {Object} ventricularEctopyData - Object containing groupings and total VE beats
   * @returns {String} Percentage to display
   */
  getPercentOfTotal(ventricularEctopyData) {
    let result = null;

    if (ventricularEctopyData.totalQrsLocations > 0) {
      const percent = (ventricularEctopyData.total / ventricularEctopyData.totalQrsLocations) * 100;

      // Format the displayed percentage
      if (percent < 0.05 && percent !== 0) {
        // if less than rounded up 1% but greater than 0, don't display 0
        result = "<0.1";
      } else {
        result = percent.toFixed(1);
      }
    }

    return result;
  }

  formatAdditionalData(ventricularEctopyTotal) {
    const formattedData = {
      description: "Percent of Total Beats: ",
      value: `${ventricularEctopyTotal}%`,
      idPrefix: "ventricularEctopyTotal-",
    };

    if ((!ventricularEctopyTotal && ventricularEctopyTotal !== 0) || Number.isNaN(ventricularEctopyTotal)) {
      formattedData.value = "N/A";
    }

    return [formattedData];
  }
}
