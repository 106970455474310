import {DateTime} from "luxon";

import axios from "../../../axiosClient.js";

/* @ngInject */
export default class ReportItemController {
  constructor($scope, $mdDialog, $injector, $state, $window) {
    this._$scope = $scope;
    this._$mdDialog = $mdDialog;
    this._$state = $state;
    this._$window = $window;

    this.features = $injector.get("Config").features;
    this._backendConfig = $injector.get("backendConfig");
    this._DonutChartService = $injector.get("DonutChartService");
    this._ArrhythmiaDataService = $injector.get("ArrhythmiaDataService");
    this._veDataService = $injector.get("VentricularEctopyDataService");
    this._afStatsService = $injector.get("AtrialFibrillationStatsService");
    this._EnrollmentService = $injector.get("EnrollmentService");
    this._StudyService = $injector.get("StudyService");
    this._GeneratedReportService = $injector.get("GeneratedReportService");
    this._InboxItemService = $injector.get("InboxItemService");
    this._StripService = $injector.get("StripService");
    this._Markers = $injector.get("Markers");
    this._Facility = $injector.get("Facility");
    this._ReportService = $injector.get("ReportService");
    this._ExcludeArtifactService = $injector.get("ExcludeArtifactService");
    this._EventService = $injector.get("EventService");
    this._WorkflowsService = $injector.get("WorkflowsService");
    this._Authentication = $injector.get("Authentication");
    this._$rootScope = $injector.get("$rootScope");

    this.graphsDisplayed = false;

    this.technicianFindingsModel = "";
    this.meetsMdnCriteriaModel = false;
    this.chartToggles = {
      // Heart Rate Summary
      heartRateSummary: true,

      // Graphs
      heartRateTrend: true,
      pvcBurden: true,
      arrhythmiaTimeline: true,

      // Donut Charts
      arrhythmiaEpisode: true,
      arrhythmiaBurden: true,
      ventricularEctopy: true,
      atrialFibrillationStats: true,
    };

    this.$onInit = this._init;
  }

  /// Public Functions ///

  get displayDownloadDataButton() {
    const isValidStudyType = [
      "mctWithFullDisclosure",
      "wirelessHolter",
      "wirelessExtendedHolter",
      "holter",
      "extendedHolter",
    ].includes(this.item.studyType);
    const isSummary = this.item.type === "Summary";

    return isValidStudyType && isSummary && !this.isSummaryOutdated && !this.editingReport;
  }

  get displayPatientDiarySection() {
    return ["holter", "extendedHolter"].includes(this.item.studyType) && !this.editingReport;
  }

  get isSummaryOutdated() {
    return (
      this.item.type === "Summary" &&
      (this.item.studyEndDate === null || this.item.studyEndDate !== this.item.timestamp)
    );
  }

  get pendingFullDisclosureFilesMessage() {
    let message = `There are still ${this.pendingFullDisclosureFiles} device files pending processing for this Summary item.`;
    if (this.pendingFullDisclosureFiles === 1) {
      message = `There is still ${this.pendingFullDisclosureFiles} device file pending processing for this Summary item.`;
    }
    return message;
  }

  updateTechnicianInputs() {
    this.technicianFindings = this.technicianFindingsModel;
    this.meetsMdnCriteria = this.meetsMdnCriteriaModel;
  }

  async saveChanges(event, endingAction) {
    this.item.loading = true;

    const propertiesToUpdate = {
      technicianFindings: this.technicianFindings,
      meetsMdnCriteria: this.meetsMdnCriteria,
    };

    if (endingAction === "markAsCompleted") {
      propertiesToUpdate.completed = true;
    }

    if (!this.features.saveInProgressChanges) {
      delete propertiesToUpdate.meetsMdnCriteria;
      delete propertiesToUpdate.technicianFindings;
    }

    try {
      await this._ReportService.updateReport(this.item, propertiesToUpdate);

      if (endingAction === "generateReport") {
        await this.clickedGenerateReport();
      } else if (endingAction === "editReport") {
        await this.clickedSaveChangesToGeneratedReport();
      } else {
        await this.closeItem();
      }

      this.item.loading = false;
    } catch (err) {
      this.item.loading = false;
      this.loadingReport = false;

      let popupTitle = "Failed to save changes";
      let errorMessage = "Unable to save changes for the item.";

      if (endingAction === "markAsCompleted") {
        popupTitle = "Failed to Complete Report";
      } else if (endingAction === "generateReport") {
        popupTitle = "Failed to Generate Report";
      }

      if (err?.message) {
        errorMessage = err.message;
      }

      // display error dialog
      await this._$mdDialog.show(
        this._$mdDialog
          .alert()
          .title(popupTitle)
          .htmlContent(
            `<p class="warningMessage"><i class="material-icons dialogErrorIcon"> error </i> ` +
              `${errorMessage}</p>`
          )
          .ok("Ok")
          .multiple(true)
      );
    }
  }

  closeItem() {
    return this._$mdDialog.hide().then(() => this._InboxItemService.deselectItem());
  }

  async generateReportData() {
    const stripIds = this._determineIncludedStripIds(this.item.listedStrips);

    const maxNumStrips = 150;
    if (stripIds.length > maxNumStrips) {
      this.loadingReport = false;
      throw new Error(`Generated reports may not include more than ${maxNumStrips} strips`);
    }

    const logoFilename = await this._Facility.getLogoFilename(this.item.facilityId);

    return {stripIds, logoFilename};
  }

  async clickedSaveChangesToGeneratedReport() {
    this.loadingReport = true;

    const {stripIds, logoFilename} = await this.generateReportData();
    const propertiesToUpdate = {
      meetsMdnCriteria: this.meetsMdnCriteria,
      comment: this.technicianFindings,
      stripIds,
      heartRateTrend: this.heartRateTrend,
      pvcBurden: this.pvcBurden,
      arrhythmiaData: this.arrhythmiaData,
      ventricularEctopy: this.ventricularEctopy,
      qrsExclusionRegions: this.artifactRegions,
      chartToggles: this.chartToggles,
      logoFilename,
    };

    // Only upload the recorded duration for Summary items if it's different than the original value
    const updateRecordedDuration =
      this.item.type === "Summary" && this.item?.recordedDuration !== this.recordingDuration;
    if (updateRecordedDuration) {
      await this._StudyService.updateStudy(this.item.studyId, {
        recordedDuration: Number(this.recordingDuration) * 24,
      });
    }

    await this._GeneratedReportService.saveChangesToReport(this.generatedReportId, propertiesToUpdate);

    this.loadingReport = false;
    await this.closeItem();

    // If the report was edited via the report dialog, open the updated report in a new tab
    if (this._$state.current.name === "reports") {
      const type = this.item.type === "Summary" ? "summary" : "daily-trend";

      this._$state.go("Generated Report", {type, reportId: this.generatedReportId});
    }
  }

  async clickedGenerateReport() {
    let reportId;
    this.loadingReport = true;

    const reportItem = {
      ...this.item,
      arrhythmiaData: this.arrhythmiaData,
      artifactRegions: this.artifactRegions,
      ventricularEctopy: this.ventricularEctopy,
      heartRateTrend: this.heartRateTrend,
      pvcBurden: this.pvcBurden,
      chartToggles: this.chartToggles,
    };
    this._StripService.orderStripList(reportItem.listedStrips, "startTime");

    try {
      // Only upload the recorded duration for Summary items if it's different than the original value
      const updateRecordedDuration =
        this.item.type === "Summary" && this.item?.recordedDuration !== this.recordingDuration;
      if (updateRecordedDuration) {
        await this._StudyService.updateStudy(this.item.studyId, {
          recordedDuration: Number(this.recordingDuration) * 24,
        });
      }

      const {stripIds, logoFilename} = await this.generateReportData();

      const reportResponse = await this._GeneratedReportService.generateReport(
        reportItem,
        stripIds,
        logoFilename,
        this.technicianFindings,
        this.meetsMdnCriteria
      );

      reportId = reportResponse.data.id;
      if (!this.selectedItem.completed) {
        await this._InboxItemService.complete();
      }

      let type;
      switch (this.reportType) {
        case "Daily Trend":
          type = "daily-trend";
          break;
        case "Summary":
          type = "summary";
          break;
        default:
          throw new Error("Unknown report type");
      }
      this.loadingReport = false;
      this._$state.go("Generated Report", {type, reportId});
    } catch (err) {
      let errorMessage;

      if (typeof err === "string") {
        errorMessage = err;
      } else if (err.message) {
        errorMessage = err.message;
      } else {
        errorMessage = "Report could not be generated";
      }

      this.loadingReport = false;
      this._$mdDialog.show(
        this._$mdDialog
          .alert()
          .title("Error creating report")
          .htmlContent(
            `<p class="warningMessage"><i class="material-icons dialogErrorIcon"> error </i> ` +
              `${errorMessage}</p>`
          )
          .ok("Ok")
      );
    }
  }

  clickDownloadHolterData() {
    this.downloadingHolterData = true;

    return this._EnrollmentService
      .getHolterData(this.item.enrollmentId)
      .then(() => {
        this.downloadingHolterData = false;
      })
      .catch((err) => {
        this.downloadingHolterData = false;

        const popupTitle = "Failed to download holter data";
        const defaultMessage = `Unable to download holter data for study. Status Code: ${err.status}`;
        return this._displayErrorMessage(popupTitle, defaultMessage, err);
      });
  }

  getDownloadDataButtonText(studyType) {
    let userFriendlyButtonText = "Download Holter Data";
    if (studyType === "mctWithFullDisclosure") {
      userFriendlyButtonText = "Download Full Disclosure Data";
    }
    return userFriendlyButtonText;
  }

  async completeReportItem() {
    try {
      if (!this.selectedItem.completed) {
        await this._InboxItemService.complete();
      }
    } catch (err) {
      const errorMessage = "Report could not be completed. Please contact support if the issue persists.";
      this.loadingReport = false;
      this._$mdDialog.show(
        this._$mdDialog
          .alert()
          .title("Error completing report")
          .htmlContent(
            `<p class="warningMessage"><i class="material-icons dialogErrorIcon"> error </i> ` +
              `${errorMessage}</p>`
          )
          .ok("Ok")
      );
    }
  }

  redrawGraph(chartType) {
    this._$rootScope.$emit(`redraw-chart-${chartType}`);
  }

  /// Private Functions ///

  async _init() {
    const isMct = this.item.studyType.startsWith("mct");
    const isCem = this.item.studyType === "cem";

    this.eSignEnabled = this._WorkflowsService.workflowSettings[this.item.facilityId]?.eSignEnabled;
    this.parentElementId = this.item.id;
    this.reportId = this.item.id;
    this.reportType = this.item.type;
    this.studyStartDate = this.item?.studyStartDate;
    this.studyEndDate = this.item?.studyEndDate;
    this.configuredDurationToDisplay = `${Math.ceil(this.item.configuredDuration / 24)} day${Math.ceil(this.item.configuredDuration / 24) === 1 ? "" : "s"}`;

    // Use the recorded duration from the study, if present
    let durationToUse = this.item?.recordedDuration;

    // Otherwise, use a calculated value from the start and end time
    if (!durationToUse) {
      durationToUse = DateTime.fromISO(this.studyEndDate)
        .diff(DateTime.fromISO(this.studyStartDate), "hours")
        .as("hours");
    }

    // As a last option, use the configured duration for the study
    if (!durationToUse && this.item?.configuredDuration) {
      durationToUse = this.item.configuredDuration;
    }

    this.recordingDuration = Math.ceil(durationToUse / 24);

    if (this.configuredChartToggles) {
      this.chartToggles = this.configuredChartToggles;
    }

    if (this.features.saveInProgressChanges) {
      this.technicianFindings = this.item.technicianFindings;
      this.meetsMdnCriteria = this.item.meetsMdnCriteria;

      // these variables are being shared from the parent component we need to manually handle the on change event
      this.technicianFindingsModel = this.item.technicianFindings;
      this.meetsMdnCriteriaModel = this.item.meetsMdnCriteria;
    }

    this.displayCemMctGraphs = isMct || isCem;

    this._DonutChartService.init();
    const deregister = this._$rootScope.$on("artifact-regions-updated", () => {
      this._loadData();
    });
    this._$scope.$on("$destroy", deregister);

    await this._loadData();
  }

  async _loadData() {
    const itemTimestamp = DateTime.fromISO(this.item.timestamp).toUTC();
    const isSummary = this.item.type === "Summary";

    // Defaults for Daily Trend, using item timestamp as end time and 24 hours prior as start time
    const endReportPeriod = itemTimestamp.toJSDate();
    let startReportPeriod = itemTimestamp.minus({days: 1}).toJSDate();
    if (this.reportType === "Summary") {
      startReportPeriod = DateTime.fromISO(this.studyStartDate).toUTC().toJSDate();
    }

    // Create Datetime objects of the start and end times to pass to the React charts so that
    // they can fetch their own data
    this.startTime = DateTime.fromJSDate(startReportPeriod);
    this.endTime = DateTime.fromJSDate(endReportPeriod);

    this.pendingFullDisclosureFiles = 0;
    if (this.displayDownloadDataButton) {
      try {
        const {data: pendingDeviceFiles} = await axios({
          method: "get",
          url: `/studies/${this.item.studyId}/pendingDeviceFiles`,
        });
        pendingDeviceFiles.forEach(({files}) => {
          this.pendingFullDisclosureFiles += files.length;
        });
      } catch (err) {
        this.pendingFullDisclosureFiles = 0;
        console.error(err);
      }
    }

    if (this.displayCemMctGraphs) {
      const queryPromises = [
        this._ReportService.getArrhythmiaEventsData(this.item.studyId, startReportPeriod, endReportPeriod),
        this._ReportService.getHeartRateTrend(
          isSummary,
          this.item.studyId,
          startReportPeriod,
          endReportPeriod
        ),
        this._ReportService.getVentricularEctopy(this.item.studyId, startReportPeriod, endReportPeriod),
        this._ReportService.getPvcBurden(this.item.studyId, startReportPeriod, endReportPeriod),
        this._ExcludeArtifactService.getArtifactRegionsForStudy(this.item.studyId, {
          startTime: {$gte: startReportPeriod},
          endTime: {$lte: endReportPeriod},
        }),
      ];

      const graphDataResults = await Promise.all(queryPromises);
      this._setGraphData(...graphDataResults);
    }
  }

  _determineIncludedStripIds(strips) {
    // This will filter and map strips
    return strips.reduce((acc, strip) => {
      const include = strip.includeInReport === true || strip.includeInReport === undefined;

      if (include) {
        acc.push(strip.id);
      }

      return acc;
    }, []);
  }

  _setGraphData(
    arrhythmiaEventsData,
    heartRateTrend = null,
    ventricularEctopy = null,
    pvcBurden = null,
    artifactRegions = []
  ) {
    /* The if-guarded assignment in this function is used so the data can be assigned without changing the
     * object/array references, which are passed through directives and cannot be overwritten without some large
     * refactoring
     */

    let useOldHeartRateTrend = !heartRateTrend;
    let useOldVentricularEctopy = !ventricularEctopy;

    // If the dynamic heart rate trend is "empty" (i.e. all averages are -1), use the one saved on the item
    if (heartRateTrend) {
      useOldHeartRateTrend = heartRateTrend.averageHeartRates.every(({avg}) => avg === -1);
    }

    // If the dynamic ventricular ectopy is "empty" (i.e. all of its data fields are 0), use the one saved
    // on the item
    if (ventricularEctopy) {
      useOldVentricularEctopy = true;
      Object.keys(ventricularEctopy).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(ventricularEctopy, key) && ventricularEctopy[key] !== 0) {
          useOldVentricularEctopy = false;
        }
      });
    }

    if (useOldHeartRateTrend) {
      heartRateTrend = JSON.parse(this.item.heartRateTrend);
    }
    if (useOldVentricularEctopy) {
      ventricularEctopy = JSON.parse(this.item.ventricularEctopy);
    }

    // PVC Burden (Array)
    // Agnostic of artifact regions
    if (!this.pvcBurden) {
      this.pvcBurden = pvcBurden;
    } else {
      this.pvcBurden.length = 0;
      this.pvcBurden.push(...pvcBurden);
    }

    // Arrhythmia Events (Array)
    // Agnostic of artifact regions
    if (!arrhythmiaEventsData || arrhythmiaEventsData.length === 0) {
      arrhythmiaEventsData = JSON.parse(this.item.arrhythmiaData);
    }
    if (!this.arrhythmiaData) {
      this.arrhythmiaData = arrhythmiaEventsData;
    } else {
      this.arrhythmiaData.length = 0;
      this.arrhythmiaData.push(...arrhythmiaEventsData);
    }

    // Ventricular Ectopy (Array)
    // Artifact regions are incorporated on the backend
    this.ventricularEctopy = ventricularEctopy;
    const veTotal = this._veDataService.getPercentOfTotal(ventricularEctopy);
    const veGroupings = this._veDataService.getGroupings(ventricularEctopy);
    if (!this.veGroupings) {
      this.veGroupings = veGroupings;
    } else {
      this.veGroupings.length = 0;
      this.veGroupings.push(...veGroupings);
    }
    const veAdditionalData = this._veDataService.formatAdditionalData(veTotal);
    if (!this.veAdditionalData) {
      this.veAdditionalData = veAdditionalData;
    } else {
      this.veAdditionalData.length = 0;
      this.veAdditionalData.push(...veAdditionalData);
    }

    // Heart Rate Trend (Object)
    // Artifact regions are incorporated on the backend
    if (!this.heartRateTrend) {
      this.heartRateTrend = heartRateTrend;
    } else {
      Object.assign(this.heartRateTrend, heartRateTrend);
    }

    artifactRegions.forEach((region) => {
      region.startTime = new Date(region.startTime).getTime();
      region.endTime = new Date(region.endTime).getTime();
    });
    artifactRegions = this._ExcludeArtifactService.mergeRegions(artifactRegions);
    if (!this.artifactRegions) {
      this.artifactRegions = artifactRegions;
    } else {
      this.artifactRegions.length = 0;
      this.artifactRegions.push(...artifactRegions);
    }

    // Arrhythmia Episodes (Array)
    const arrhythmiaEpisodesData = this._ArrhythmiaDataService.getArrhythmiaEpisodesData(this.arrhythmiaData);
    if (!this.arrhythmiaEpisodesData) {
      this.arrhythmiaEpisodesData = arrhythmiaEpisodesData;
    } else {
      this.arrhythmiaEpisodesData.length = 0;
      this.arrhythmiaEpisodesData.push(...arrhythmiaEpisodesData);
    }
    const aeAdditionalData = this._ArrhythmiaDataService.formatAdditionalData(this.arrhythmiaData);
    if (!this.aeAdditionalData) {
      this.aeAdditionalData = aeAdditionalData;
    } else {
      this.aeAdditionalData.length = 0;
      this.aeAdditionalData.push(...aeAdditionalData);
    }

    // Arrhythmia Burden (Array)
    // ArrhythmiaDataService uses Artifact regions to calculate durations
    const duration = this._ArrhythmiaDataService.getDuration(
      this.item.type,
      this.item.studyStartDate,
      this.item.studyEndDate
    );
    const arrhythmiaBurdenData = this._ArrhythmiaDataService.getArrhythmiaBurdenData(
      this.arrhythmiaData,
      this.artifactRegions,
      duration
    );
    if (!this.arrhythmiaBurdenData) {
      this.arrhythmiaBurdenData = arrhythmiaBurdenData;
    } else {
      this.arrhythmiaBurdenData.length = 0;
      this.arrhythmiaBurdenData.push(...arrhythmiaBurdenData);
    }

    // Atrial Fibrillation Stats (Object)
    // AtrialFibrillationStatsService uses Artifact regions to calculate durations
    const afStats = this._afStatsService.getStats(this.arrhythmiaData, this.artifactRegions);
    if (!this.afStats) {
      this.afStats = afStats;
    } else {
      this.afStats.durations.length = 0;
      const durationsArrayReference = this.afStats.durations;
      Object.assign(this.afStats, afStats);
      this.afStats.durations = durationsArrayReference;
      this.afStats.durations.push(...afStats.durations);
    }
    const afAdditionalData = this._afStatsService.formatAdditionalData(this.afStats);
    if (!this.afAdditionalData) {
      this.afAdditionalData = afAdditionalData;
    } else {
      this.afAdditionalData.length = 0;
      this.afAdditionalData.push(...afAdditionalData);
    }

    this._$scope.$apply();
    this._$rootScope.$emit("report-graph-data-updated", this.item.id);
  }

  _displayErrorMessage(popupTitle, defaultMessage, error) {
    // Format error message
    let errorMessage = defaultMessage;
    if (error?.data?.detail?.message) {
      errorMessage = error.data.detail.message.replace(/\n/g, "<br />");
    }
    // display error dialog
    return this._$mdDialog.show(
      this._$mdDialog
        .alert()
        .title(popupTitle)
        .htmlContent(
          `<p class="warningMessage"><i class="material-icons dialogErrorIcon"> error </i> ` +
            `${errorMessage}</p>`
        )
        .ok("Ok")
    );
  }
}
