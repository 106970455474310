import React from "react";
import {Controller} from "react-hook-form";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import CheckBox from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";

function AutocompleteInput({
  // Props
  variant = "multiple",
  name,
  control,
  defaultValue,
  id,
  "data-cy": dataCy,
  options,
  getOptionLabel = (option) => option.name,
  label,
  required = true,
  disabled = false,
  size = "small",
  textFieldVariant = "standard",
  rules,
  otherProps,
}) {
  if (variant === "single") {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        shouldUnregister
        render={({field: {onChange, value}, fieldState: {error}}) => (
          <Autocomplete
            id={id}
            data-cy={dataCy}
            options={options}
            disabled={disabled}
            size={size}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...otherProps}
            getOptionLabel={getOptionLabel}
            renderOption={(props, option) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <li {...props} data-cy={`${id || dataCy}-${option.id}`}>
                {getOptionLabel(option)}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                variant={textFieldVariant}
                label={label}
                margin="normal"
                error={!!error}
                helperText={error ? error.message : null}
                sx={{mt: 0}}
                required={required}
              />
            )}
            value={value}
            onChange={(e, data) => onChange(data)}
            isOptionEqualToValue={(option, val) =>
              val === undefined || val === "" || (option.id || option) === (val.id || val)
            }
          />
        )}
        rules={rules}
      />
    );
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      shouldUnregister
      render={({field: {onChange, value}, fieldState: {error}}) => (
        <Autocomplete
          id={id}
          data-cy={dataCy}
          options={options}
          disabled={disabled}
          multiple
          size="small"
          disableCloseOnSelect
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...otherProps}
          getOptionLabel={getOptionLabel}
          renderOption={(props, option, {selected}) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <li {...props} data-cy={`${id || dataCy}-${option.id}`} value={getOptionLabel(option)}>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox fontSize="small" />}
                sx={{my: 0, mr: 2, ml: 0, p: 0}}
                checked={selected}
              />
              {getOptionLabel(option)}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              variant="standard"
              label={label}
              margin="normal"
              error={!!error}
              helperText={error ? error.message : null}
              sx={{mt: 0}}
              required={required}
            />
          )}
          value={value}
          onChange={(e, data) => onChange(data)}
          isOptionEqualToValue={(option, val) => {
            const optionId = typeof option === "string" ? option : option.id;
            const valueId = typeof val === "string" ? val : val.id;

            return val === undefined || val === "" || optionId === valueId;
          }}
        />
      )}
      rules={rules}
    />
  );
}

AutocompleteInput.propTypes = {
  variant: PropTypes.string,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  id: PropTypes.string,
  "data-cy": PropTypes.string,
  options: PropTypes.array.isRequired,
  getOptionLabel: PropTypes.func,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  textFieldVariant: PropTypes.string,
  rules: PropTypes.object,
  otherProps: PropTypes.object,
};

export default AutocompleteInput;
