import React from "react";
import {range} from "d3";
import maxBy from "lodash/maxBy.js";
import {Interval} from "luxon";
import {Label, Line, ReferenceArea, ReferenceLine, YAxis} from "recharts";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import {grey} from "@mui/material/colors";
import {useTheme} from "@mui/material/styles";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import InboxEntityContext from "../../contexts/InboxEntityContext.jsx";
import TimelineChart from "../study-graphs/TimelineChart.jsx";

const useHeartRateTrendData = ({
  // Props
  study,
  data,
  startTime,
  endTime,
  reportType = "Summary",
  setLoading = () => {},
  setError = () => {},
} = {}) => {
  //---------------------------------------------------------------------------
  // Determine if this component is in a generated report for data fetching
  //---------------------------------------------------------------------------
  const {type: parentType} = React.useContext(InboxEntityContext);

  //---------------------------------------------------------------------------
  // Get theme for colors
  //---------------------------------------------------------------------------
  const theme = useTheme();

  //---------------------------------------------------------------------------
  // Fetch data from API
  //---------------------------------------------------------------------------
  const [averageHeartRates, setAverageHeartRates] = React.useState(data?.averageHeartRates || []);
  const [qrsExclusionRegions, setQrsExclusionRegions] = React.useState(data?.qrsExclusionRegions || []);
  const [arrhythmiaData, setArrhythmiaData] = React.useState(data?.arrhythmiaData || []);

  const getHeartRateTrendData = React.useCallback(async () => {
    setLoading(true);

    try {
      const [
        {data: averageHeartRateResponse},
        {data: qrsExclusionRegionResponse},
        {data: arrhythmiaDataResponse},
      ] = await Promise.all([
        axios({
          method: "get",
          url: `/reports/heartRateTrend/summary/${study.id}`,
          params: {
            startTime: startTime.toUTC().toISO(),
            endTime: endTime.toUTC().toISO(),
          },
        }),
        axios({
          method: "get",
          url: `/qrsExclusionRegions/${study.id}`,
          params: {
            startTime: {$gte: startTime.toUTC().toISO()},
            endTime: {$lte: endTime.toUTC().toISO()},
          },
        }),
        axios({
          method: "get",
          url: `/reports/arrhythmiaTimeline/${study.id}`,
          params: {
            startTime: startTime.toUTC().toISO(),
            endTime: endTime.toUTC().toISO(),
          },
        }),
      ]);

      setAverageHeartRates(averageHeartRateResponse.averageHeartRates);
      setQrsExclusionRegions(qrsExclusionRegionResponse);
      setArrhythmiaData(arrhythmiaDataResponse);
    } catch (err) {
      setError(err.message);
    }

    setLoading(false);
  }, [startTime, endTime, study.id, setError, setLoading]);

  React.useEffect(() => {
    // If this component is in a generated report, then we already have the data so
    // no need to fetch it
    if (parentType !== "generated-report" && !averageHeartRates.length) {
      getHeartRateTrendData();
    }
  }, [averageHeartRates.length, parentType, getHeartRateTrendData]);

  const maxHeartRate = React.useMemo(() => {
    const max = maxBy(averageHeartRates, (hr) => hr.avg)?.avg || 0;
    const maxBy50 = Math.ceil(max / 50) * 50; // Ensure that the max is always an increment of 50

    if (maxBy50 < 100) {
      return 100;
    }
    return maxBy50;
  }, [averageHeartRates]);

  const weeks = React.useMemo(() => {
    const usableHeartRateData = averageHeartRates.filter((hr) => hr.avg >= 0);

    const interval = Interval.fromDateTimes(startTime, endTime);
    if (interval.length("days") <= 1) {
      return [
        {
          startTime,
          endTime,
          data: usableHeartRateData,
        },
      ];
    }

    return interval.splitBy({weeks: 1}).map((week) => {
      return {
        startTime: week.start,
        endTime: week.end,
        data: usableHeartRateData.filter(
          (point) => week.start.toMillis() <= point.mt && point.mt < week.end.toMillis()
        ),
      };
    });
  }, [averageHeartRates, endTime, startTime]);

  const exclusionRegions = React.useMemo(() => {
    qrsExclusionRegions.forEach((region) => {
      region.startTime = new Date(region.startTime).getTime();
      region.endTime = new Date(region.endTime).getTime();
    });
    qrsExclusionRegions.sort((a, b) => a.startTime - b.startTime);

    const mergedExclusionRegions = [];
    let accumulator = null;

    qrsExclusionRegions.forEach((region) => {
      if (!accumulator) {
        accumulator = region;
      } else if (region.startTime <= accumulator.endTime) {
        // If regions overlap, combine into the accumulator by setting accumulator.endTime to larger of the two
        if (region.endTime > accumulator.endTime) {
          accumulator.endTime = region.endTime;
        }
      } else {
        // Regions do not overlap so move on to the next region
        mergedExclusionRegions.push(accumulator);
        accumulator = region;
      }
    });
    if (accumulator) {
      mergedExclusionRegions.push(accumulator);
    }

    // include unreadable ECG data for exclusion regions
    const eventTypesToExclude = ["Unreadable ECG Data", "Artifact", "Lead Off"];
    const unreadableEcgData = arrhythmiaData
      .filter((event) => eventTypesToExclude.includes(event.eventName))
      .map((region) => ({
        startTime: new Date(region.startTime).getTime(),
        endTime: new Date(region.endTime).getTime(),
      }));

    const sectionUnreadableEcgData = unreadableEcgData.filter((event) => {
      return (
        (event.startTime >= startTime.toMillis() && event.startTime <= endTime.toMillis()) ||
        (event.endTime >= startTime.toMillis() && event.endTime <= endTime.toMillis()) ||
        (event.startTime <= startTime.toMillis() && event.endTime >= endTime.toMillis())
      );
    });

    return mergedExclusionRegions.concat(sectionUnreadableEcgData);
  }, [qrsExclusionRegions, arrhythmiaData, endTime, startTime]);

  //---------------------------------------------------------------------------
  // Configure y-axis ticks and tooltip formatting
  //---------------------------------------------------------------------------
  const ticks = range(0, maxHeartRate + 20, 20); // tick every 20bpm
  const tooltipFormatter = (value) => [`${value}bpm`, "Heart Rate"];

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  const separateCharts = React.useMemo(() => {
    return weeks.map((week, index) => {
      return (
        <Box key={week.startTime.ts} data-cy={`heart-rate-trend-${index}`}>
          <TimelineChart
            data={week.data}
            startTime={week.startTime}
            endTime={week.endTime}
            tooltipFormatter={tooltipFormatter}
            reportType={reportType}
            study={study}
          >
            {/* -------------- YAXIS -------------- */}
            <YAxis
              yAxisId="heart-rate-trend"
              tick={{fontSize: 10}}
              type="number"
              domain={[0, maxHeartRate]}
              ticks={ticks}
              interval={0}
            >
              <Label
                value="Heart Rate (bpm)"
                angle={-90}
                fontSize={10}
                position="insideLeft"
                offset={20}
                style={{textAnchor: "middle"}}
              />
            </YAxis>

            {/* -------------- HR DATA -------------- */}
            <Line
              yAxisId="heart-rate-trend"
              type="monotone"
              dataKey="avg"
              stroke={theme.palette.primary.dark}
              dot={false}
              isAnimationActive={false}
            />

            {/* -------------- EXCLUSION REGIONS -------------- */}
            {exclusionRegions.map((exclusionRegion) => (
              <ReferenceArea
                key={exclusionRegion.startTime}
                x1={exclusionRegion.startTime}
                x2={exclusionRegion.endTime}
                yAxisId="heart-rate-trend"
                fillOpacity={1}
                fill={grey[300]}
              />
            ))}

            {/* -------------- BRADY/TACHY REFERENCE LINES -------------- */}
            <ReferenceLine
              yAxisId="heart-rate-trend"
              y={60}
              strokeDasharray="3 3"
              stroke={theme.palette.tertiary.main}
            />
            <ReferenceLine
              yAxisId="heart-rate-trend"
              y={100}
              strokeDasharray="3 3"
              stroke={theme.palette.tertiary.main}
            />
          </TimelineChart>
        </Box>
      );
    });
  }, [
    exclusionRegions,
    maxHeartRate,
    reportType,
    study,
    theme.palette.primary.dark,
    theme.palette.tertiary.main,
    ticks,
    weeks,
  ]);

  return separateCharts;
};

export default useHeartRateTrendData;
