const compareDeviceTypeVersions = (version1, version2) => {
  const version1Parts = version1
    .toString()
    .split(".")
    .map((number) => Number.parseInt(number, 10));
  const version2Parts = version2
    .toString()
    .split(".")
    .map((number) => Number.parseInt(number, 10));
  const shorterLength = Math.min(version1Parts.length, version2Parts.length);
  const longerLength = Math.max(version1Parts.length, version2Parts.length);
  let comparison = 0;

  // If one of the versions is not a number, cannot compare
  if (version1Parts.includes(NaN) || version2Parts.includes(NaN)) {
    return null;
  }

  // For each part of the version, compare them and return the difference if they are not equal
  for (let i = 0; i < shorterLength && !comparison; i++) {
    comparison = version2Parts[i] - version1Parts[i];
  }

  // No difference has been found yet, so compare the version by which has more parts
  if (!comparison && longerLength - shorterLength > 0) {
    comparison = longerLength - shorterLength;

    if (longerLength === version1Parts.length) {
      comparison *= -1;
    }
  }

  return comparison;
};

export function getFirmwareReleasesByDeviceType(firmwareReleases, deviceTypes, allowBeta = false) {
  return deviceTypes.reduce((deviceTypeObject, deviceType) => {
    deviceTypeObject[deviceType.id] = firmwareReleases
      .filter((firmwareRelease) => {
        const matchingDeviceType =
          !!deviceType.firmwareId && firmwareRelease.id.includes(deviceType.firmwareId);
        let matchingMinVersion = compareDeviceTypeVersions(
          deviceType.minFirmwareVersion,
          firmwareRelease.version
        );
        matchingMinVersion = matchingMinVersion !== null && matchingMinVersion >= 0;

        const isBeta = firmwareRelease.version.includes("Pre-release");
        const matchingBetaRestriction = !isBeta || allowBeta;

        return matchingDeviceType && matchingMinVersion && matchingBetaRestriction;
      })
      .map((firmwareRelease) => ({
        id: firmwareRelease.id,
        name: `${deviceType.name} - ${firmwareRelease.version}`,
      }));

    deviceTypeObject[deviceType.id].sort((a, b) => (a.name > b.name ? -1 : 1));

    return deviceTypeObject;
  }, {});
}

export function parseVersionFromFileName(path) {
  const fileName = path.substring(path.lastIndexOf("/") + 1, path.lastIndexOf("."));
  let version = fileName;

  // All Firmware >= 2.3 will display the patch as 0 if it is not specified
  const INCLUDE_PATCH_VERSION = 23;

  // if the fileName matches our typical file format, parse the version out of the file name
  const VERSION_REGEX = /^(h3r|hpr)_update_(?<major>\d{2})(?<minor>\d)((_(?<patch>\d+))|(_(?<dirt>d)))?$/;
  const matches = fileName.match(VERSION_REGEX);
  if (matches !== null) {
    version = "";
    const {major, minor, patch, dirt} = matches.groups;

    // Only use the leading digit if it is not a zero
    version += `${Number(major)}`;

    if (dirt) {
      version += `.${Number(minor) + 1} Pre-release`;
    } else {
      version += `.${minor}`;
      if (patch || Number(`${major}${minor}`) >= INCLUDE_PATCH_VERSION) {
        version += `.${patch || 0}`;
      }
    }
  }
  return version;
}
