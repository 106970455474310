import React from "react";
import {useFormContext, useWatch} from "react-hook-form";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import useEnvironmentVariables from "../hooks/useEnvironmentVariables.jsx";
import useLeadCoordinates from "../hooks/useLeadCoordinates.jsx";
import LeadLabel from "./LeadLabel.jsx";
import ScaleLegend from "./ScaleLegend.jsx";
import TriggerMarker from "./TriggerMarker.jsx";

function StripViewer({
  // Props
  leads,
  samplePeriod,
  avm,
  eventSample,
  stripCenteredSample,
  width,
  height,
}) {
  //---------------------------------------------------------------------------
  // Global variables
  //---------------------------------------------------------------------------
  const {defaultStripLengthInSeconds} = useEnvironmentVariables();

  //---------------------------------------------------------------------------
  // Watch the time base, gain, and toggled lead input values
  //---------------------------------------------------------------------------
  const {control, defaultValues} = useFormContext();
  const [timeBase, gain] = useWatch({control, name: ["timeBase", "gain"], defaultValue: defaultValues});

  //---------------------------------------------------------------------------
  // Constants
  //---------------------------------------------------------------------------
  const samplesPerSecond = React.useMemo(() => 1000000 / samplePeriod, [samplePeriod]);

  const pixelsPerSample = React.useMemo(() => {
    const secondsToDisplay = (defaultStripLengthInSeconds * 25) / Number(timeBase);
    const pixelsPerSecond = width / secondsToDisplay;

    return pixelsPerSecond / samplesPerSecond;
  }, [defaultStripLengthInSeconds, samplesPerSecond, timeBase, width]);

  const [startSample, endSample] = React.useMemo(() => {
    const stripLengthInSeconds = (defaultStripLengthInSeconds * 25) / Number(timeBase);
    const totalStripSamples = samplesPerSecond * stripLengthInSeconds;

    // Calculate the starting and ending sample of this strip based on the selected time base
    const start = stripCenteredSample - totalStripSamples / 2;
    const end = stripCenteredSample + totalStripSamples / 2;

    return [start, end];
  }, [stripCenteredSample, samplesPerSecond, defaultStripLengthInSeconds, timeBase]);

  //---------------------------------------------------------------------------
  // Convert the sliced lead data into coordinates in pixels
  //
  // This calculation is dependent on the height (pixels), width (pixels),
  // and length (seconds) of this viewer
  //---------------------------------------------------------------------------
  const slicedLeads = React.useMemo(
    () =>
      leads.map((lead) => {
        // Create a shallow copy of lead with a shallow copy of sliced data
        const data = lead.data.slice(startSample, endSample + 1);
        return {...lead, data};
      }),
    [leads, endSample, startSample]
  );
  const calculateOffsetYCoordinate = React.useCallback(
    (index) => Math.round(((index + 1) * height) / 4),
    [height]
  );

  const displayedLeads = useLeadCoordinates({
    leads: slicedLeads,
    pixelsPerSample,
    samplePeriod,
    avm,
    getOffsetY: calculateOffsetYCoordinate,
    timeBase: Number(timeBase),
    gain: Number(gain),
  });

  //---------------------------------------------------------------------------
  // Calculate the x-coordinate (pixels) of the event trigger marker
  //---------------------------------------------------------------------------
  const triggerMarkerX = React.useMemo(() => {
    const adjustedTriggerSample = eventSample - startSample;

    return Math.round(adjustedTriggerSample * pixelsPerSample);
  }, [eventSample, pixelsPerSample, startSample]);

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <svg width={width} height={height} style={{position: "absolute"}} data-cy="strip-viewer">
      {displayedLeads.map(({label, path}) => (
        <path key={label} fill="none" stroke="black" strokeWidth="1" d={path} />
      ))}

      {displayedLeads.map(({label, offsetY}) => (
        <LeadLabel key={label} y={offsetY} offset={Math.round(height / 8)}>
          {label}
        </LeadLabel>
      ))}

      <ScaleLegend width={width} height={height} />

      <TriggerMarker width={width} height={height} x={triggerMarkerX} />
    </svg>
  );
}

StripViewer.propTypes = {
  leads: PropTypes.array.isRequired,
  samplePeriod: PropTypes.number.isRequired,
  avm: PropTypes.number.isRequired,
  eventSample: PropTypes.number.isRequired,
  stripCenteredSample: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default React.memo(StripViewer);
