import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import Footer from "./Footer.jsx";
import Header from "./Header.jsx";

function Sheet({
  // Props
  report,
  logo,
  item,
  study,
  pageNumber = 1,
  pages = 1,
  hidden = false,
  margin = false,
  children,
}) {
  return (
    <Box sx={{...(hidden && {visibility: "hidden"}), ...(margin && {mt: 2})}}>
      <Paper
        square
        elevation={6}
        sx={{width: "8.5in", height: "11in"}}
        className="report-sheet"
        data-cy={`sheet-${pageNumber}`}
      >
        <Box
          sx={{
            p: 3,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            boxSizing: "border-box",
          }}
        >
          <Box>
            {/* HEADER */}
            <Header report={report} logo={logo} item={item} study={study} />

            {/* PAGE CONTENTS */}
            {children}
          </Box>

          {/* FOOTER */}
          <Box>
            <Footer report={report} study={study} pages={pages} pageNumber={pageNumber} />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

Sheet.propTypes = {
  report: PropTypes.object.isRequired,
  logo: PropTypes.object.isRequired,
  item: PropTypes.object,
  study: PropTypes.object.isRequired,
  pageNumber: PropTypes.number,
  pages: PropTypes.number,
  hidden: PropTypes.bool,
  margin: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default React.memo(Sheet);
