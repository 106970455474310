const KILO = 1024;
const MEGA = KILO * KILO;
const GIGA = MEGA * KILO;

const ONE_SECOND = 1000;
const ONE_MINUTE = 60 * ONE_SECOND;
const ONE_HOUR = 60 * ONE_MINUTE;

export function getDisplayedBits(bitCount = 0) {
  if (bitCount >= GIGA) {
    return `${(bitCount / GIGA).toFixed(2)} gb`;
  }
  if (bitCount >= MEGA) {
    return `${(bitCount / MEGA).toFixed(2)} mb`;
  }
  if (bitCount >= KILO) {
    return `${(bitCount / KILO).toFixed(2)} kb`;
  }
  return `${bitCount} b`;
}

export function getDisplayedBytes(byteCount = 0) {
  if (byteCount >= GIGA) {
    return `${(byteCount / GIGA).toFixed(2)}GB`;
  }
  if (byteCount >= MEGA) {
    return `${(byteCount / MEGA).toFixed(2)}MB`;
  }
  if (byteCount >= KILO) {
    return `${(byteCount / KILO).toFixed(2)}KB`;
  }
  return `${byteCount}B`;
}

export function getDisplayedTime(ms = 0) {
  if (ms >= ONE_HOUR) {
    const hours = Math.floor(ms / ONE_HOUR);
    const minutes = Math.floor((ms % ONE_HOUR) / ONE_MINUTE);
    return `${hours} hour${hours === 1 ? "" : "s"}, ${minutes} minute${minutes === 1 ? "" : "s"}`;
  }
  if (ms >= ONE_MINUTE) {
    const minutes = Math.floor(ms / ONE_MINUTE);
    const seconds = Math.floor((ms % ONE_MINUTE) / ONE_SECOND);
    return `${minutes} minute${minutes === 1 ? "" : "s"}, ${seconds} second${seconds === 1 ? "" : "s"}`;
  }
  if (ms >= 10 * ONE_SECOND) {
    const seconds = Math.floor(ms / ONE_SECOND);
    return `${seconds} second${seconds === 1 ? "" : "s"}`;
  }
  const seconds = (ms / ONE_SECOND).toFixed(1);
  return `${seconds} second${seconds === "1.0" ? "" : "s"}`;
}
