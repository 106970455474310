import React from "react";
import {Controller} from "react-hook-form";
import {DateTime} from "luxon";
import PropTypes from "prop-types";

import {DesktopDateTimePicker} from "@mui/x-date-pickers/DesktopDateTimePicker";

function FormDateTimeInput({
  // Props
  control,
  name,
  label,
  id,
  defaultValue,
  "data-cy": dataCy,
  size = "medium",
  variant = "standard",
  required = true,
  disabled = false,
  timeZone,
  rules,
  otherProps,
}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      shouldUnregister
      rules={rules} // Ensures that the form cannot be submitted if a rule is violated
      render={({field: {onChange, value}, fieldState: {error}}) => (
        <DesktopDateTimePicker
          label={label}
          value={value}
          disabled={disabled}
          onChange={onChange}
          data-cy={dataCy}
          timezone={timeZone || DateTime.local().toFormat("z")}
          slotProps={{
            textField: {
              id,
              "data-cy": dataCy,
              variant,
              size,
              fullWidth: true,
              required, // makes the * appear in the text field
              error: !!error, // sets the error appearance of the text field;
              helperText: error ? error.message : null,
            },
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...otherProps}
        />
      )}
    />
  );
}

FormDateTimeInput.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  id: PropTypes.string,
  "data-cy": PropTypes.string,
  defaultValue: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  timeZone: PropTypes.string,
  rules: PropTypes.object,
  size: PropTypes.string,
  variant: PropTypes.string,
  otherProps: PropTypes.object,
};

export default FormDateTimeInput;
