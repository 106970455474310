import {DateTime} from "luxon";
import queryString from "qs";

import axios from "../axiosClient.js";

/* @ngInject */
export default class GeneratedReportService {
  constructor($injector, Config) {
    this._Config = Config;
    this._$injector = $injector;
    this._Authentication = $injector.get("Authentication");
    this._$http = $injector.get("$http");
    this._StripService = $injector.get("StripService");
    this.features = this._Config.features;
  }

  generateSingleEpisodeReport(ecgEventItem, stripIds, logoFilename, comment = "", meetsMdnCriteria = false) {
    const report = {
      comment,
      reportType: "Single Episode",
      facilityName: ecgEventItem.facilityName,
      facilityId: ecgEventItem.facilityId,
      tzSerial: ecgEventItem.tzSerial,
      studyId: ecgEventItem.studyId,
      studyType: ecgEventItem.studyType,
      itemId: ecgEventItem.id,
      eventClassification: ecgEventItem.userClassification,
      stripIds,
      meetsMdnCriteria,
      logoFilename,
      timestamp: DateTime.utc().toFormat("yyyy-MM-dd hh:mm:ssZZ"),
    };
    const url = "/generatedReports";
    return this._httpPut(url, report);
  }

  generateReport(reportItem, stripIds, logoFilename, comment, meetsMdnCriteria = false) {
    const report = {
      comment,
      reportType: reportItem.type,
      facilityId: reportItem.facilityId,
      facilityName: reportItem.facilityName,
      tzSerial: reportItem.tzSerial,
      studyId: reportItem.studyId,
      studyType: reportItem.studyType,
      itemId: reportItem.id,
      stripIds,
      meetsMdnCriteria,
      heartRateTrend: reportItem.heartRateTrend,
      pvcBurden: reportItem.pvcBurden,
      arrhythmiaData: reportItem.arrhythmiaData,
      ventricularEctopy: reportItem.ventricularEctopy,
      chartToggles: reportItem.chartToggles,
      logoFilename,
      timestamp: DateTime.utc().toFormat("yyyy-MM-dd hh:mm:ssZZ"),
      qrsExclusionRegions: this._sanitizeArtifactRegions(reportItem.artifactRegions),
    };
    const url = "/generatedReports";
    return this._httpPut(url, report);
  }

  getGeneratedReports(params = {}) {
    const url = "/generatedReports";

    return this._httpGet(url, params).then((response) => {
      return {reports: response.data, totalCount: response.headers("count")};
    });
  }

  async updateReport(reportId, propertiesToUpdate) {
    const {data} = await axios({
      method: "patch",
      url: `/generatedReports/${reportId}`,
      data: propertiesToUpdate,
    });
    return data;
  }

  async saveChangesToReport(reportId, propertiesToUpdate) {
    if (propertiesToUpdate.artifactRegions) {
      propertiesToUpdate.qrsExclusionRegions = propertiesToUpdate.artifactRegions;
      delete propertiesToUpdate.artifactRegions;
    }
    if (propertiesToUpdate.qrsExclusionRegions) {
      propertiesToUpdate.qrsExclusionRegions = this._sanitizeArtifactRegions(
        propertiesToUpdate.qrsExclusionRegions
      );
    }
    const {data} = await axios({
      method: "put",
      url: `/generatedReports`,
      data: {id: reportId, ...propertiesToUpdate},
    });
    return data;
  }

  _sanitizeArtifactRegions(regions) {
    return regions.map((region) => {
      return {
        startTime: new Date(region.startTime),
        endTime: new Date(region.endTime),
      };
    });
  }

  _httpGet(url, params, options = {}) {
    const urlQuery = queryString.stringify(params);
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._Config.apiUrl}`;
    options.headers = {
      Authorization: authHeader,
    };
    return this._$http.get(`${baseUrl}${url}?${urlQuery}`, options);
  }

  _httpPut(url, report) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._Config.apiUrl}`;
    return this._$http.put(`${baseUrl}${url}`, report, {
      headers: {
        Authorization: authHeader,
      },
    });
  }
}
