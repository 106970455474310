import moment from "moment";

/* @ngInject */

export default class ArrhythmiaDataService {
  /**
   * Calculates and returns the data for the arrhythmia burden donut charts.
   *
   * @param {Array} arrhythmiaData All of the data used in the arrhythmia timeline
   * @param {Array} artifactRegions The artifact regions excluded in the arrhythmia timeline
   * @param {Number} durationMs The number of milliseconds the arrhythmia data spans
   *
   * @returns {Array} Arrhythmia burden data with the calculated percentages for each category
   *
   * @see SRS: BR-1173
   */
  getArrhythmiaBurdenData(arrhythmiaData, artifactRegions, durationMs) {
    const classificationsToExclude = ["Pause", "Patient Activated"];
    const arrhythmias = [];
    let totalEventDuration = 0;

    const artifactTimes = {
      eventName: "Artifact",
      duration: 0,
    };
    const filteredArrhythmiaData = arrhythmiaData.reduce((filteredData, event) => {
      let {endTime, startTime} = event;
      if (typeof endTime === "string") {
        endTime = new Date(endTime);
      }
      if (typeof startTime === "string") {
        startTime = new Date(startTime);
      }

      const checkedEvent = {...event, duration: endTime - startTime};
      delete checkedEvent.startTime;
      delete checkedEvent.endTime;

      // Recalculate event duration to account for excluded regions
      artifactRegions.forEach((region) => {
        // Check whether the region overlaps with the event, i.e. the event starts in the period of the region or
        // ends in the period of the region
        const eventOverlapsRegion = startTime <= region.endTime && endTime >= region.startTime;

        if (eventOverlapsRegion && checkedEvent.duration > 0) {
          // Because the overlap duration between event and artifact region can never be greater than event duration,
          // calculate the overlap and use the value to update the event and artifact durations
          const maxStart = Math.max(region.startTime, startTime);
          const minEnd = Math.min(region.endTime, endTime);
          checkedEvent.duration -= minEnd - maxStart;
          artifactTimes.duration += minEnd - maxStart;
        }
      });

      // Don't need event for calculation unless duration is greater than 0
      if (checkedEvent.duration > 0) {
        filteredData.push(checkedEvent);
      }
      return filteredData;
    }, []);

    // Don't need artifact regions for calculation unless duration is greater than 0
    if (artifactTimes.duration > 0) {
      filteredArrhythmiaData.push(artifactTimes);
    }

    this._getRhythmTypes(filteredArrhythmiaData, classificationsToExclude).forEach((type) => {
      arrhythmias.push({
        name: type,
        eventName: type,
        elapsedTime: 0,
        percent: 0,
        displayedPercent: "0",
      });
    });

    arrhythmias.forEach((arrhythmia) => {
      filteredArrhythmiaData
        .filter((ecgEvent) => this._getDisplayedArrhythmiaType(ecgEvent.eventName) === arrhythmia.eventName)
        .forEach((event) => {
          arrhythmia.elapsedTime += event.duration;
          totalEventDuration += event.duration;
        });
    });

    // This fixes a bug when the amount of arrhythmias exceeds the duration of a report item
    if (durationMs < totalEventDuration) {
      durationMs = totalEventDuration;
    }

    arrhythmias.forEach((arrhythmia) => {
      // Calculate actual percentage
      arrhythmia.percent = (arrhythmia.elapsedTime / durationMs) * 100;

      // Format the displayed percentage
      if (arrhythmia.percent < 0.05 && arrhythmia.percent !== 0) {
        // if less than rounded up 1% but greater than 0, don't display 0
        arrhythmia.displayedPercent = "<0.1%";
      } else {
        // Round to one decimal spot
        const precision = 1;
        const multiplier = 10 ** (precision || 0);
        arrhythmia.displayedPercent = Math.round(arrhythmia.percent * multiplier) / multiplier;
        arrhythmia.displayedPercent += "%";
      }
    });

    return arrhythmias;
  }

  /**
   * Calculates and returns the data for the arrhythmia episodes donut charts.
   *
   * @param {Array} arrhythmiaData All of the data used in the arrhythmia timeline
   * @returns {Array} Arrhythmia episode data with the counts for each category
   *
   * @see SRS: BR-1172
   */
  getArrhythmiaEpisodesData(arrhythmiaData) {
    const classificationsToExclude = ["Patient Activated", "Lead Off", "Artifact", "Artifact/Unreadable"];
    const prefixesToExclude = ["Normal"];
    const arrhythmiaEpisodes = [];
    let totalCount = 0;
    this._getRhythmTypes(arrhythmiaData, classificationsToExclude, prefixesToExclude).forEach((type) => {
      arrhythmiaEpisodes.push({
        name: type,
        eventName: type,
        count: 0,
        percent: 0,
      });
    });

    arrhythmiaEpisodes.forEach((episode) => {
      const episodeCount = arrhythmiaData.filter(
        (ecgEvent) => this._getDisplayedArrhythmiaType(ecgEvent.eventName) === episode.eventName
      ).length;
      episode.count = episodeCount;
      totalCount += episodeCount;
    });

    arrhythmiaEpisodes.forEach((episode) => {
      episode.percent = Math.round((episode.count / totalCount) * 100);
    });

    return arrhythmiaEpisodes;
  }

  formatAdditionalData(arrhythmiaData) {
    let formattedData = [];
    if (arrhythmiaData.length > 0) {
      const patientActivated = {
        description: "Patient Activated:",
        value: 0,
        idPrefix: "patientActivated-",
      };
      patientActivated.value = arrhythmiaData.filter(
        (ecgEvent) => this._getDisplayedArrhythmiaType(ecgEvent.eventName) === "Patient Activated"
      ).length;
      formattedData = [patientActivated];
    }
    return formattedData;
  }

  _getRhythmTypes(data, classificationsToExclude, prefixesToExclude = []) {
    // Iterate through data and pull out each new type
    const arrhythmiaTypes = [];
    data.forEach((eventData) => {
      const displayedName = this._getDisplayedArrhythmiaType(eventData.eventName);
      if (
        !arrhythmiaTypes.includes(displayedName) &&
        !classificationsToExclude.includes(displayedName) &&
        !prefixesToExclude.some((prefix) => displayedName.startsWith(prefix))
      ) {
        arrhythmiaTypes.push(displayedName);
      }
    });
    return this._organizeRhythmTypes(arrhythmiaTypes);
  }

  _organizeRhythmTypes(arrhythmiaTypes) {
    // Events will be sorted:
    //  - Instantaneous Events (Patient Activated, Pause)
    //  - Other Events (sorted)
    //  - Normal Events (sorted)
    //  - Lead Off
    //  - Artifact
    //  - Artifact/Unreadable
    //  - Unclassified
    const eventsDisplayedFirst = ["Patient Activated", "Pause"];
    const eventsDisplayedLast = ["Lead Off", "Artifact", "Artifact/Unreadable", "Unclassified"];

    // Other Events are the miscellaneous classifications
    const otherEvents = arrhythmiaTypes.filter((type) => {
      const isDisplayedFirst = eventsDisplayedFirst.includes(type);
      const isDisplayedLast = eventsDisplayedLast.includes(type);
      const isNormal = type.startsWith("Normal");
      return !isDisplayedFirst && !isDisplayedLast && !isNormal;
    });
    otherEvents.sort();

    const normalEvents = arrhythmiaTypes.filter((type) => type.startsWith("Normal"));
    normalEvents.sort();

    // Merge arrays
    const organizedTypes = [...eventsDisplayedFirst, ...otherEvents, ...normalEvents, ...eventsDisplayedLast];

    // Final filter is to get rid of Patient Activated, Pause, Normal, Lead Off, and Artifact if they aren't in arrhythmiaTypes
    return organizedTypes.filter((type) => arrhythmiaTypes.includes(type));
  }

  _getDisplayedArrhythmiaType(type) {
    let displayedType;
    switch (type) {
      case "Cardiac Pause":
        displayedType = "Pause";
        break;
      case "Bradycardia":
      case "Bradycardia Rate Change":
        displayedType = "Brady";
        break;
      case "Tachycardia":
      case "Tachycardia Rate Change":
        displayedType = "Tachy";
        break;
      case "Normal Sinus Rhythm":
        displayedType = "Normal";
        break;
      case "Atrial Fibrillation":
        displayedType = "AF";
        break;
      case "Lead Off":
        displayedType = "Lead Off";
        break;
      case "Artifact":
      case "Unreadable ECG Data":
        displayedType = "Artifact/Unreadable";
        break;
      case null:
        displayedType = "Unclassified";
        break;
      default:
        displayedType = type;
    }
    return displayedType;
  }

  getDuration(reportType, studyStartDate, studyEndDate) {
    // Unix = 24 hours
    let duration = 86400000;
    if (reportType === "Summary" && studyEndDate) {
      duration = moment.duration(moment(studyEndDate).diff(studyStartDate)).as("milliseconds");
    }
    return duration;
  }
}
