import React from "react";
import {useFormContext, useWatch} from "react-hook-form";
import {DateTime} from "luxon";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import CloudUpload from "@mui/icons-material/CloudUpload";
import DoDisturbOn from "@mui/icons-material/DoDisturbOn";
import Edit from "@mui/icons-material/Edit";
import MarkEmailUnread from "@mui/icons-material/MarkEmailUnread";

//---------------------------------------------------------------------------
// MUI
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../../axiosClient.js";
import {useReportsDispatch} from "../../../contexts/ReportsContext.jsx";
import AssignStudyDialog from "../../../dialogs/StudyDialogs/StudyActions/AssignStudyDialog.jsx";
import useConvertToPDF from "../../hooks/useConvertToPDF.jsx";
import useDoesNotMeetExtendedHolterRequirements from "../../hooks/useDoesNotMeetExtendedHolterRequirements.jsx";
import useEnvironmentVariables from "../../hooks/useEnvironmentVariables.jsx";
import useJwt from "../../hooks/useJwt.jsx";
import DurationOfRecordingInput from "../inputs/DurationOfRecordingInput.jsx";
import MarkAsFinalizedToggle from "../inputs/MarkAsFinalizedToggle.jsx";
import MdnCriteriaToggle from "../inputs/MdnCriteriaToggle.jsx";
import RejectConfirmation from "../RejectConfirmation.jsx";

function GeneratedReportActions({
  // Props
  report,
  study,
  item,
  eSignEnabled,
  disabled = false,
  isPdfReport,
  setError,
  setLoadingMessage,
  handleClose,
  setSuccessMessage,
  setLoadData,
  angularActions,
}) {
  //---------------------------------------------------------------------------
  // Global Variables
  //---------------------------------------------------------------------------
  const {features} = useEnvironmentVariables();
  const {fullName} = useJwt();
  const dispatch = useReportsDispatch();

  //---------------------------------------------------------------------------
  // Fire an Angular event when clicking Edit so that the Angular dialog
  // containing the item appears
  //---------------------------------------------------------------------------
  const handleClickEdit = React.useCallback(() => {
    handleClose();
    if (angularActions.editReport) {
      angularActions.editReport(report, item);
    }
  }, [angularActions, report, handleClose, item]);

  //---------------------------------------------------------------------------
  // Study Assignment state management
  //---------------------------------------------------------------------------
  const [assignStudyRequired, setAssignStudyRequired] = React.useState(null);

  const confirmAssignedPhysicians = React.useCallback(() => {
    return new Promise((resolve, reject) => {
      setAssignStudyRequired({resolve, reject});
    });
  }, []);

  const handleConfirmStudyAssignment = React.useCallback(() => {
    setAssignStudyRequired((prevState) => {
      if (prevState) {
        prevState.resolve();
      }
      return null;
    });
  }, []);

  const handleCancelStudyAssignment = React.useCallback(() => {
    setAssignStudyRequired((prevState) => {
      if (prevState) {
        prevState.reject();
      }
      return null;
    });
  }, []);

  //---------------------------------------------------------------------------
  // Form submission
  //---------------------------------------------------------------------------
  const {handleSubmit, control} = useFormContext();
  const [submitting, setSubmitting] = React.useState(false);

  const updateStudy = React.useCallback(
    async (recordedDuration, markAsFinalized) => {
      const studyRequests = [];

      // Update the study's recorded duration if it was changed
      if (Number.isInteger(recordedDuration) && recordedDuration !== study.recordedDuration) {
        const durationInHours =
          report.reportType === "Summary" ? Math.round(recordedDuration * 24) : recordedDuration;

        studyRequests.push(
          axios({
            method: "patch",
            url: `/studies/${study.id}`,
            data: {recordedDuration: durationInHours},
          })
        );
      }

      // Finalize the study if it was toggled on
      if (markAsFinalized) {
        studyRequests.push(axios({method: "post", url: `/studies/${study.id}/finalize`}));
      }

      await Promise.all(studyRequests);
    },
    [report.reportType, study.id, study.recordedDuration]
  );

  //---------------------------------------------------------------------------
  // Submitting for signature
  //---------------------------------------------------------------------------
  const onSubmit = React.useCallback(
    async (data) => {
      setSubmitting(true);

      try {
        //---------------------------------------------------------------------------
        // If e-sign is enabled but there are no physicians assigned to this study, display
        // the Assign Physicians popup
        //---------------------------------------------------------------------------
        if (eSignEnabled && !study.assignedUsers?.length) {
          try {
            await confirmAssignedPhysicians();
          } catch (err) {
            // If the confirmation was cancelled, do nothing
            setSubmitting(false);
            return;
          }
        }

        //---------------------------------------------------------------------------
        // Update the generated report
        //---------------------------------------------------------------------------
        const {data: updatedReport} = await axios({
          method: "patch",
          url: `/generatedReports/${report.id}`,
          data: {
            state: "submitted",
            technicianSignedBy: fullName,
            technicianSignedAt: DateTime.now().toMillis(),
            meetsMdnCriteria: data.meetsMdnCriteria,
          },
        });

        //---------------------------------------------------------------------------
        // Update the study based on the form inputs
        //---------------------------------------------------------------------------
        await updateStudy(Number(data.recordedDuration), data.markAsFinalized);

        // Set the success message before the dispatcher in case filters hide this report state
        setSuccessMessage("Successfully submitted");

        dispatch({type: "updated", updatedElement: {...report, ...updatedReport}});

        setSubmitting(false);
        handleClose();

        // If Submitting an uploaded report, Reload the Data in order to refresh the PDF and populate the cover page
        if (report.reportType === "Uploaded") {
          setLoadingMessage("Reloading report");
          setLoadData(true);
        }
      } catch (err) {
        setError(err.message);
        setSubmitting(false);
      }
    },
    [
      confirmAssignedPhysicians,
      dispatch,
      eSignEnabled,
      fullName,
      handleClose,
      report,
      setError,
      setLoadData,
      setLoadingMessage,
      setSuccessMessage,
      study.assignedUsers?.length,
      updateStudy,
    ]
  );

  //---------------------------------------------------------------------------
  // Publishing
  //---------------------------------------------------------------------------
  const setPublishingMessage = React.useCallback(
    (currentPage, totalPages) => {
      setLoadingMessage(`Publishing page ${currentPage} of ${totalPages}`);
    },
    [setLoadingMessage]
  );

  const convertPublishedReportToPDF = useConvertToPDF(setPublishingMessage);

  const onPublish = React.useCallback(
    async (data) => {
      setSubmitting(true);

      try {
        //---------------------------------------------------------------------------
        // If a raw generated report, convert the report to a PDF and upload
        //---------------------------------------------------------------------------
        if (!isPdfReport) {
          // Scroll to the top so that there are not any partially-visible loading circles
          document.querySelector('[data-cy="pdf-viewer"]').scrollTo(0, 0);

          // Update the technician name on the DOM so that it is included in the PDF
          dispatch({type: "updated", updatedElement: {...report, technicianSignedBy: fullName}});

          //---------------------------------------------------------------------------
          // Upload the PDF
          //---------------------------------------------------------------------------
          const fileBuffer = await convertPublishedReportToPDF("report-sheet");

          setLoadingMessage("Uploading published report");
          const formData = new FormData();
          const file = new Blob([fileBuffer]);
          formData.append("file", file);

          await axios({
            method: "post",
            url: `/generatedReports/pdf/${report.id}`,
            data: formData,
          });
        }

        //---------------------------------------------------------------------------
        // Update the generated report
        //---------------------------------------------------------------------------
        const {data: updatedReport} = await axios({
          method: "patch",
          url: `/generatedReports/${report.id}`,
          data: {
            state: "published",
            technicianSignedBy: fullName,
            technicianSignedAt: null,
            meetsMdnCriteria: data.meetsMdnCriteria,
          },
        });

        //---------------------------------------------------------------------------
        // Update the study based on the form inputs
        //---------------------------------------------------------------------------
        await updateStudy(Number(data.recordedDuration), data.markAsFinalized);

        // Set the success message before the dispatcher in case filters hide this report state
        setSuccessMessage("Successfully published");

        dispatch({type: "updated", updatedElement: {...report, ...updatedReport}});

        setSubmitting(false);
        handleClose();

        // Reload after Publishing a raw report, to transition to PDF
        if (report.reportType !== "Uploaded") {
          setLoadingMessage("Reloading report");
          setLoadData(true);
        }
      } catch (err) {
        setError(err.message);
        setSubmitting(false);
      }
    },
    [
      convertPublishedReportToPDF,
      dispatch,
      fullName,
      handleClose,
      isPdfReport,
      report,
      setError,
      setLoadData,
      setLoadingMessage,
      setSuccessMessage,
      updateStudy,
    ]
  );

  //---------------------------------------------------------------------------
  // Rejecting
  //---------------------------------------------------------------------------
  const [openRejectConfirmation, setOpenRejectConfirmation] = React.useState(false);
  const handleClickReject = React.useCallback(() => setOpenRejectConfirmation(true), []);
  const handleCancelRejectConfirmation = React.useCallback(() => setOpenRejectConfirmation(false), []);
  const handleCloseRejectConfirmation = React.useCallback(() => {
    setOpenRejectConfirmation(false);
    handleClose();
  }, [handleClose]);

  //---------------------------------------------------------------------------
  // Determine if a Holter report can be published or submitted based on
  // downgrade authorization requirements
  //---------------------------------------------------------------------------
  const liveRecordedDuration = useWatch({control, name: "recordedDuration"});
  const doesNotMeetExtendedHolterRequirements = useDoesNotMeetExtendedHolterRequirements(
    study.studyType,
    study.downgradeAuthorized,
    study.configuredDuration,
    liveRecordedDuration
  );

  return (
    <>
      <Box>
        {features.correctReport && !isPdfReport && item && (
          <Tooltip title={item?.lockedAt ? "This item is currently being reviewed by another user" : ""}>
            <span>
              <LoadingButton
                color="secondary"
                startIcon={<Edit />}
                disabled={disabled || submitting || !!item.lockedAt}
                fullWidth
                variant="outlined"
                onClick={handleClickEdit}
                sx={{mb: 2}}
                data-cy="edit-button"
              >
                Edit
              </LoadingButton>
            </span>
          </Tooltip>
        )}

        <MdnCriteriaToggle report={report} study={study} disabled={disabled || submitting} />

        {["Uploaded", "Summary"].includes(report.reportType) && (
          <DurationOfRecordingInput
            recordedDuration={study.recordedDuration}
            configuredDuration={study.configuredDuration}
            reportType={report.reportType}
            disabled={disabled || submitting}
          />
        )}
      </Box>

      <form onSubmit={handleSubmit(eSignEnabled ? onSubmit : onPublish)} noValidate>
        <Box>
          <MarkAsFinalizedToggle
            finalizedAt={study.finalizedAt}
            reportType={report.reportType}
            disabled={disabled || submitting}
          />

          {eSignEnabled && (
            <Typography variant="body2" align="right" sx={{mb: 2}} data-cy="consent-message">
              By clicking &quot;Submit for Signature&quot;, I consent to signing this document electronically.
            </Typography>
          )}

          <Tooltip
            title={
              doesNotMeetExtendedHolterRequirements
                ? `Report does not meet criteria to ${eSignEnabled ? "submit" : "publish"} without downgrade authorization`
                : ""
            }
          >
            <span>
              <LoadingButton
                variant="contained"
                color="secondary"
                fullWidth
                startIcon={eSignEnabled ? <MarkEmailUnread /> : <CloudUpload />}
                loading={submitting}
                disabled={disabled || submitting || doesNotMeetExtendedHolterRequirements}
                data-cy={`${eSignEnabled ? "submit" : "publish"}-button`}
                type="submit"
              >
                {eSignEnabled ? "Submit for Signature" : "Publish"}
              </LoadingButton>
            </span>
          </Tooltip>

          <Button
            variant="outlined"
            color="tertiary"
            fullWidth
            startIcon={<DoDisturbOn />}
            disabled={disabled || submitting}
            data-cy="reject-button"
            sx={{mt: 2}}
            onClick={handleClickReject}
          >
            Reject
          </Button>
        </Box>
      </form>

      <RejectConfirmation
        open={openRejectConfirmation}
        handleCancel={handleCancelRejectConfirmation}
        handleClose={handleCloseRejectConfirmation}
        report={report}
        setError={setError}
        setSuccessMessage={setSuccessMessage}
      />

      {assignStudyRequired !== null && (
        <AssignStudyDialog
          study={study}
          variant="reports"
          handleConfirm={handleConfirmStudyAssignment}
          handleCancel={handleCancelStudyAssignment}
        />
      )}
    </>
  );
}

GeneratedReportActions.propTypes = {
  report: PropTypes.object.isRequired,
  study: PropTypes.object.isRequired,
  item: PropTypes.object,
  eSignEnabled: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  isPdfReport: PropTypes.bool.isRequired,
  setError: PropTypes.func.isRequired,
  setLoadingMessage: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  setLoadData: PropTypes.func.isRequired,
  angularActions: PropTypes.object.isRequired,
};

export default GeneratedReportActions;
