import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import FindInPage from "@mui/icons-material/FindInPage";
import Person from "@mui/icons-material/Person";
import Smartphone from "@mui/icons-material/Smartphone";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

function CompactStudyInfo({id = "study-info-text", study}) {
  const tzSerial = study.currentEnrollment?.tzSerial || study.tzSerial;
  const patientName = study.studyDetails?.patientName || study.patientName;
  const displayPatientName = patientName && patientName !== tzSerial;

  return (
    <Grid container data-cy={id} id={id}>
      <Grid size={12} sx={{display: "inline-flex", alignItems: "center"}}>
        <FindInPage color="secondary" fontSize="small" />
        &nbsp;
        <Typography sx={{fontSize: 14}}>Study ID: {study.studyId || study.id}</Typography>
      </Grid>
      <Grid size={12} sx={{display: "inline-flex", alignItems: "center"}}>
        <Smartphone color="secondary" fontSize="small" />
        &nbsp;
        <Typography sx={{fontSize: 14}}>{tzSerial}</Typography>
      </Grid>
      {displayPatientName && (
        <Grid size={12} sx={{display: "inline-flex", alignItems: "center"}}>
          <Person color="secondary" fontSize="small" />
          &nbsp;
          <Typography sx={{fontSize: 14}}>{patientName}</Typography>
        </Grid>
      )}
    </Grid>
  );
}

CompactStudyInfo.propTypes = {
  id: PropTypes.string,
  study: PropTypes.object.isRequired,
};

export default CompactStudyInfo;
