/* eslint-env browser */
import React from "react";
import {useForm, useFormState} from "react-hook-form";
import axios from "axios";
import PropTypes from "prop-types";
import isEmail from "validator/es/lib/isEmail";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PersonIcon from "@mui/icons-material/Person";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid2";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import Alert from "../../shared/react/Alert.jsx";
import CancelButton from "../../shared/react/CancelButton.jsx";
import DialogTitleBar from "../../shared/react/DialogTitleBar.jsx";
import FormStringInput from "../../shared/react/FormStringInput.jsx";
import useEnvironmentVariables from "../hooks/useEnvironmentVariables.jsx";
import useValidatePasswordStrength from "../hooks/useValidatePasswordStrength.jsx";

const usernameMessage = (
  <Typography>
    <b>Forgot your username?</b> Contact your admin for assistance.
  </Typography>
);

const newPasswordMessage = (
  <Typography>Please check your email and enter the code you received below.</Typography>
);

function ResetAccount({
  // Props
  username,
}) {
  const {apiUrl} = useEnvironmentVariables();

  //---------------------------------------------------------------------------
  // State Management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [userId, setUserId] = React.useState(null);
  const [secret, setSecret] = React.useState(null);
  const [emailSent, setEmailSent] = React.useState(false);
  const [score, setScore] = React.useState(0);
  const [suggestionList, setSuggestionList] = React.useState([]);
  const [message, setMessage] = React.useState(null);
  const [usernameToUse, setUsernameToUse] = React.useState(null);
  const [emailToUse, setEmailToUse] = React.useState(null);

  //---------------------------------------------------------------------------
  // Modal state management
  //---------------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);
  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = React.useCallback(() => {
    setOpen(false);
    setError(null);
    setLoading(false);
    setUserId(null);
    setSecret(null);
    setEmailSent(false);
    setScore(0);
    setSuggestionList([]);
    setUsernameToUse(null);
    setEmailToUse(null);
  }, []);

  //---------------------------------------------------------------------------
  // Form Submission
  //---------------------------------------------------------------------------
  const {handleSubmit, control, watch} = useForm();
  const {isDirty} = useFormState({control});

  //---------------------------------------------------------------------------
  // Submit Handler
  //---------------------------------------------------------------------------
  const onSubmit = React.useCallback(
    async (formData) => {
      // Disable the submit button and display the spinner
      setLoading(true);
      setError(null);

      if (!emailSent) {
        // STEP 1: Verify user exists
        try {
          const response = await axios({
            method: "get",
            url: `${apiUrl}/users/generateResetString`,
            params: {username: formData.username, email: formData.email},
          });

          setUserId(response.data.userId);
          setSecret(response.data.resetPasswordString);

          // STEP 2: Send email with reset code
          await axios({
            method: "post",
            url: `${apiUrl}/users/${response.data.userId}/sendResetCode`,
            params: {username: formData.username, email: formData.email},
            data: {
              resetPasswordString: response.data.resetPasswordString,
            },
          });

          setEmailSent(true);
          setUsernameToUse(formData.username);
          setEmailToUse(formData.email);
        } catch (err) {
          setError(err.response?.data?.error || err.response?.data?.message || "Invalid username or email");
        }
      } else {
        try {
          // STEP 3: Enter emailed code and set new password
          await axios({
            method: "patch",
            url: `${apiUrl}/users/${userId}/resetPassword`,
            params: {username: formData.username, email: formData.email},
            data: {
              password: formData.password,
              resetPasswordString: secret,
              code: formData.code,
            },
          });

          handleClose();
          setMessage("Your password has been changed.");
        } catch (err) {
          setError(err.response?.data?.error || err.response?.data?.message || "Invalid code or email");
        }
      }
      setLoading(false);
    },
    [apiUrl, emailSent, handleClose, setMessage, secret, userId]
  );

  const password = watch("password");
  const banList = React.useMemo(() => {
    const emailSplit = emailToUse?.split(/[@+.]/) || [];
    const usernameSplit = usernameToUse?.split(/[ 0-9,.]/) || [];
    return [...emailSplit, ...usernameSplit];
  }, [emailToUse, usernameToUse]);
  const validatePasswordStrength = useValidatePasswordStrength(setScore, setSuggestionList, banList);
  const validatePasswordConfirm = React.useCallback(
    (value) => value === password || "Passwords don't match.",
    [password]
  );
  const scoreColor = React.useMemo(() => {
    if (score < 40) {
      return "error";
    }
    if (score < 60) {
      return "warning";
    }
    return "success";
  }, [score]);

  const formTitle = React.useMemo(() => {
    if (!emailSent) {
      return "Request Password Reset";
    }
    return "Select New Password";
  }, [emailSent]);

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <Box sx={{textAlign: "center", p: 2}}>
      <Alert message={message} setMessage={setMessage} level="info" variant="snackbar" />
      <Button variant="text" data-cy="forgot-username-or-password" onClick={handleOpen}>
        Forgot Username or Password?
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        data-cy="reset-account-form"
        PaperProps={{
          component: "form",
          onSubmit: handleSubmit(onSubmit),
          noValidate: true,
        }}
      >
        <Alert message={error} setMessage={setError} level="error" />
        <DialogTitleBar title={formTitle} icon={<PersonIcon color="secondary" />} />
        <DialogContent>
          {!emailSent && <Alert message={usernameMessage} level="info" />}
          {emailSent && <Alert message={newPasswordMessage} level="success" />}
          <br />
          <Grid container spacing={5}>
            <Grid size={{xs: 12, sm: 6}}>
              <Stack direction="column" spacing={2}>
                <Typography variant="h6" sx={{mb: 1}}>
                  Instructions:
                </Typography>
                {!emailSent && (
                  <Stack direction="row" spacing={1}>
                    <InfoOutlinedIcon color="secondary" fontSize="small" />
                    <Typography variant="caption" data-cy="email-instructions">
                      Enter your username and email to receive a security reset code.
                    </Typography>
                  </Stack>
                )}
                {emailSent && (
                  <Stack direction="row" spacing={1}>
                    <InfoOutlinedIcon color="secondary" fontSize="small" />
                    <Typography variant="caption" data-cy="password-instructions">
                      Enter the security reset code you received and a new password.
                    </Typography>
                  </Stack>
                )}
                {suggestionList?.map((suggestion) => (
                  <Stack key={suggestion} direction="row" spacing={1}>
                    <InfoOutlinedIcon color="secondary" fontSize="small" />
                    <Typography variant="caption">{suggestion}</Typography>
                  </Stack>
                ))}
              </Stack>
            </Grid>
            {!emailSent && (
              <Grid size={{xs: 12, sm: 6}}>
                <Stack direction="column" spacing={2}>
                  {/* Username */}
                  <FormStringInput
                    control={control}
                    defaultValue={username || ""}
                    label="Username"
                    name="username"
                    type="username"
                    data-cy="reset-account-username-input"
                    rules={{
                      required: "Please enter your username to reset your account.",
                    }}
                  />
                  {/* Email */}
                  <FormStringInput
                    control={control}
                    defaultValue=""
                    label="Email"
                    name="email"
                    data-cy="reset-account-email-input"
                    rules={{
                      required: "Please enter your email to reset your account.",
                      validate: {
                        isValidEmail: (email) =>
                          isEmail(email) ? true : "Please enter a valid email address",
                      },
                    }}
                  />
                </Stack>
              </Grid>
            )}
            {emailSent && (
              <Grid size={{xs: 12, sm: 6}}>
                <Stack direction="column" spacing={2}>
                  <FormStringInput
                    control={control}
                    defaultValue=""
                    label="Security Code"
                    name="code"
                    type="text"
                    data-cy="security-code-input"
                    rules={{
                      required: "Please enter the security code from your email.",
                    }}
                  />
                  <Box>
                    <FormStringInput
                      control={control}
                      defaultValue=""
                      label="New Password"
                      name="password"
                      type="password"
                      data-cy="new-password-input"
                      rules={{
                        // The validatePasswordStrength hook also checks the required and minLength rules
                        // This is done to set the color and length of the "score" bar
                        validate: {
                          validatePasswordStrength,
                        },
                      }}
                    />
                    <Grid container spacing={2} sx={{alignItems: "center"}}>
                      <Grid size="auto">
                        <Typography variant="caption">Strength:</Typography>
                      </Grid>
                      <Grid size="grow">
                        <LinearProgress
                          variant="determinate"
                          value={score}
                          color={scoreColor}
                          sx={{borderRadius: "5px"}}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <FormStringInput
                    control={control}
                    defaultValue=""
                    label="Confirm Password"
                    name="password-confirm"
                    type="password"
                    data-cy="password-confirm-input"
                    rules={{
                      required: "Please confirm your password.",
                      validate: {
                        validatePasswordConfirm,
                      },
                    }}
                  />
                </Stack>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box sx={{m: 2}}>
            <CancelButton color="secondary" isDirty={isDirty} onClick={handleClose}>
              Cancel
            </CancelButton>
          </Box>
          <Box sx={{m: 2}}>
            <LoadingButton
              data-cy="reset-submit-button"
              disabled={loading}
              variant="contained"
              color="secondary"
              loading={loading}
              type="submit"
            >
              Submit
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

ResetAccount.propTypes = {
  username: PropTypes.string,
};

export default ResetAccount;
