import angular from "angular";

import sharedModule from "../../shared/index.js";
import generatedReportComponent from "./generatedReport.component.js";
import GeneratedReportController from "./generatedReport.controller.js";
import routes from "./generatedReport.routes.js";

export default angular
  .module("app.pages.generatedReports", [sharedModule])
  .controller("GeneratedReportController", GeneratedReportController)
  // matches <generated-report>
  .component("generatedReport", generatedReportComponent)
  .config(routes).name;
