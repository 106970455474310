import React from "react";
import {useFormContext} from "react-hook-form";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import FormStringInput from "../../../shared/react/FormStringInput.jsx";

function ReadingPhysicianCommentsInput({
  // Props
  originalFileUrl,
  study,
  isPdfReport = false,
  disabled = false,
  addCoverPageToPDF,
  setFileUrl,
}) {
  const {getValues} = useFormContext();

  //---------------------------------------------------------------------------
  // Handle updating the cover page preview if physician comments are edited
  // on an Uploaded report
  //---------------------------------------------------------------------------
  const [displayedPhysicianComments, setDisplayedPhysicianComments] = React.useState("");
  const handleUpdateCoverPage = React.useCallback(async () => {
    if (!isPdfReport) {
      return;
    }

    const livePhysicianComments = getValues("physicianComment");
    if (displayedPhysicianComments !== livePhysicianComments) {
      const reportWithUpdatedCoverPage = await addCoverPageToPDF(
        originalFileUrl,
        study,
        null,
        livePhysicianComments
      );
      setFileUrl(URL.createObjectURL(reportWithUpdatedCoverPage));

      setDisplayedPhysicianComments(livePhysicianComments);
    }
  }, [
    addCoverPageToPDF,
    displayedPhysicianComments,
    getValues,
    isPdfReport,
    originalFileUrl,
    setFileUrl,
    study,
  ]);

  return (
    <Box sx={{mt: 2}}>
      <FormStringInput
        name="physicianComment"
        label="Reading Physician Comments"
        required={false}
        size="small"
        otherProps={{
          variant: "outlined",
          multiline: true,
          minRows: 12,
          maxRows: 18,
          onBlur: handleUpdateCoverPage,
        }}
        rules={{
          maxLength: {
            value: 5000,
            message: "Reading Physician comments are limited to 5000 characters",
          },
        }}
        disabled={disabled}
        data-cy="physician-comments-input"
        defaultValue=""
      />
    </Box>
  );
}

ReadingPhysicianCommentsInput.propTypes = {
  originalFileUrl: PropTypes.string,
  study: PropTypes.object,
  isPdfReport: PropTypes.bool,
  disabled: PropTypes.bool,
  setFileUrl: PropTypes.func.isRequired,
  addCoverPageToPDF: PropTypes.func.isRequired,
};

export default ReadingPhysicianCommentsInput;
