import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import {useTheme} from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import DateAndTime from "../../DateAndTime/DateAndTime.jsx";

function HeartRateStatistics({
  // Props
  heartRateStatistics,
  study,
}) {
  //---------------------------------------------------------------------------
  // Global Variables
  //---------------------------------------------------------------------------
  const theme = useTheme();

  const {min, max, mean, count} = React.useMemo(() => heartRateStatistics, [heartRateStatistics]);
  const minTimestamp = React.useMemo(
    () => <DateAndTime datetime={min.timestamp} zone={study.timeZone} seconds />,
    [min.timestamp, study]
  );
  const maxTimestamp = React.useMemo(
    () => <DateAndTime datetime={max.timestamp} zone={study.timeZone} seconds />,
    [max.timestamp, study]
  );

  return (
    <Box
      sx={{mt: 1, p: 1, border: `2px solid ${theme.palette.background.main}`}}
      className="report-element"
      id="heart-rate-statistics"
      data-cy="heart-rate-statistics"
    >
      <Typography variant="subtitle2" section-header="HR Statistics">
        HR Statistics - Saved Strips
      </Typography>

      <Grid container columnSpacing={2} sx={{mt: 1}}>
        <Grid size="auto">
          <Typography variant="reportText">Strip Heart Rate</Typography>
        </Grid>

        <Grid size="auto">
          <Table size="small" padding="none">
            <TableBody>
              <TableRow>
                <TableCell sx={{px: 1, ...theme.typography.reportText}}>Min</TableCell>
                <TableCell sx={{px: 1, ...theme.typography.reportText}} data-cy="min-heart-rate">
                  {!count && "N/A"}

                  {!!count && (
                    <>
                      <b>{min.value}bpm</b> at <a href={min.href}>{minTimestamp}</a>
                      {min.numTied ? ` and ${min.numTied} other strip${min.numTied === 1 ? "" : "s"}` : ""}
                    </>
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{px: 1, ...theme.typography.reportText}}>Avg</TableCell>
                <TableCell sx={{px: 1, ...theme.typography.reportText}} data-cy="avg-heart-rate">
                  {!count && "N/A"}

                  {!!count && <b>{mean}bpm</b>}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{px: 1, ...theme.typography.reportText}}>Max</TableCell>
                <TableCell sx={{px: 1, ...theme.typography.reportText}} data-cy="max-heart-rate">
                  {!count && "N/A"}

                  {!!count && (
                    <>
                      <b>{max.value}bpm</b> at <a href={max.href}>{maxTimestamp}</a>
                      {max.numTied ? ` and ${max.numTied} other strip${max.numTied === 1 ? "" : "s"}` : ""}
                    </>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Box>
  );
}

HeartRateStatistics.propTypes = {
  heartRateStatistics: PropTypes.object,
  study: PropTypes.object,
};

export default React.memo(HeartRateStatistics);
