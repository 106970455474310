import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import SwitchInput from "../../../shared/react/SwitchInput.jsx";
import MdnCriteriaTooltip from "../../MdnCriteriaTooltip/MdnCriteriaTooltip.jsx";

function MdnCriteriaToggle({
  // Props
  report,
  study,
  disabled = false,
}) {
  return (
    <Box sx={{display: "inline-flex", justifyContent: "space-between"}} width="100%">
      <MdnCriteriaTooltip
        criteria={study.studyDetails?.physicianNotes}
        met={!!report.meetsMdnCriteria}
        toggle
      />

      <SwitchInput
        name="meetsMdnCriteria"
        data-cy="mdn-criteria-toggle"
        color="error"
        otherProps={{edge: "end"}}
        disabled={disabled}
        defaultValue={!!report.meetsMdnCriteria}
      />
    </Box>
  );
}

MdnCriteriaToggle.propTypes = {
  report: PropTypes.object.isRequired,
  study: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default React.memo(MdnCriteriaToggle);
