import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import AddProviders from "../../shared/react/AddProviders.jsx";
import GeneratedReportPage from "./GeneratedReportPage.jsx";

function GeneratedReportPageWrapper({
  // Props
  id,
  actions,
  saved,
}) {
  return (
    <AddProviders>
      <GeneratedReportPage reportId={id} angularActions={actions} changesWereSaved={saved} />
    </AddProviders>
  );
}

GeneratedReportPageWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  saved: PropTypes.bool.isRequired,
};

export default GeneratedReportPageWrapper;
