/* eslint-env browser */
import React from "react";
import {FormProvider, useForm, useFormState} from "react-hook-form";
import keyBy from "lodash/keyBy";
import {useConfirm} from "material-ui-confirm";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Add from "@mui/icons-material/Add";
import AssignmentInd from "@mui/icons-material/AssignmentInd";
import Close from "@mui/icons-material/Close";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid2";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Tooltip from "@mui/material/Tooltip";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../../axiosClient.js";
import useEnvironmentVariables from "../../../components/hooks/useEnvironmentVariables.jsx";
import useJwt from "../../../components/hooks/useJwt.jsx";
import {useStudiesDispatch} from "../../../contexts/StudiesContext.jsx";
import Alert from "../../../shared/react/Alert.jsx";
import AutocompleteInput from "../../../shared/react/AutocompleteInput.jsx";
import CancelButton from "../../../shared/react/CancelButton.jsx";
import FormStringInput from "../../../shared/react/FormStringInput.jsx";
import IconWithText from "../../../shared/react/IconWithText.jsx";
import TableLoading from "../../../shared/react/TableLoading.jsx";
import confirmSubscriberInfo from "../confirmSubscriberInfo.jsx";
import handleAddressSubmit from "../handleAddressSubmit.jsx";
import InsuranceInfo from "../InsuranceInfo.jsx";
import PatientInfo from "../PatientInfo.jsx";
import PhysicianInfo from "../PhysicianInfo.jsx";
import Authorization from "./Authorization.jsx";
import StudyInfo from "./StudyInfo.jsx";

function NewStudyDialog({
  // Props
  device,
}) {
  //---------------------------------------------------------------------------
  // Global Variables
  //---------------------------------------------------------------------------
  const {features} = useEnvironmentVariables();

  //---------------------------------------------------------------------------
  // Set up hook for confirmation dialogs
  //---------------------------------------------------------------------------
  const confirm = useConfirm();

  //---------------------------------------------------------------------------
  // Error management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  //---------------------------------------------------------------------------
  // Form management
  //---------------------------------------------------------------------------
  const methods = useForm();
  const {isDirty} = useFormState({control: methods.control});
  const watchAllFields = methods.watch();

  //---------------------------------------------------------------------------
  // API state management
  //---------------------------------------------------------------------------
  const {isInAnyRole, userFacilityId, fullName, userId, facilityDisabled} = useJwt();
  const [selectedFacility, setSelectedFacility] = React.useState("");
  const [facilities, setFacilities] = React.useState({});
  const [devices, setDevices] = React.useState([]);
  const [deviceConfigurations, setDeviceConfigurations] = React.useState([]);
  const [arrhythmiaSettings, setArrhythmiaSettings] = React.useState([]);
  const [insuranceTypes, setInsuranceTypes] = React.useState([]);
  const [workflowSettings, setWorkflowSettings] = React.useState({
    eSignEnabled: false,
  });
  const [physicianUsers, setPhysicianUsers] = React.useState([]);
  const [requiredFields, setRequiredFields] = React.useState({});
  const [allowedStudyTypes, setAllowedStudyTypes] = React.useState({});
  const [message, setMessage] = React.useState(null);

  //---------------------------------------------------------------------------
  // Stepper management
  //---------------------------------------------------------------------------
  const defaultSteps = React.useMemo(
    () => [
      "Select Facility",
      "Study Info",
      "Patient Info",
      "Ordering Physician Info",
      "Insurance Info",
      "Confirm Device",
    ],
    []
  );
  const [steps, setSteps] = React.useState(defaultSteps);
  const [activeStep, setActiveStep] = React.useState(0);

  //---------------------------------------------------------------------------
  // Dialog state management
  //---------------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);
  const handleOpen = React.useCallback(() => {
    setLoading(true);
    setOpen(true);
  }, []);
  const handleClose = React.useCallback(
    (event, reason) => {
      if (reason === "backdropClick") {
        return;
      }
      setOpen(false);
      setSteps(defaultSteps);
      setActiveStep(0);
      setError(null);
      setSelectedFacility("");
      setFacilities({});
    },
    [defaultSteps]
  );

  //---------------------------------------------------------------------------
  // Load data from API
  //---------------------------------------------------------------------------
  const getStudyData = React.useCallback(async () => {
    // When we reload the dialog, we lose the inputted data on the facility selection step
    // So we need to manually save it in case the user goes back to that step
    const currentSelectedFacility = methods.getValues("facilityId");
    if (currentSelectedFacility) {
      if (selectedFacility !== currentSelectedFacility) {
        setSelectedFacility(currentSelectedFacility);
      } else {
        return;
      }
    }

    setLoading(true);

    try {
      const facilityId = currentSelectedFacility || device?.facilityId || userFacilityId;
      const devicesQuery = {
        facilityId,
        operationalState: "active",
        availableForStudy: true,
        forwardingDestination: "BitRhythm Inbox",
        order: [["tzSerial", "ASC"]],
      };
      const ancestorFacilityIds = facilityId
        .split("_")
        .map((id, index, ids) => ids.slice(0, index + 1).join("_"));
      const physicianUserQuery = {
        facilityId: {$or: ancestorFacilityIds},
        state: {$notIn: ["pending", "disabled"]},
        role: "physician",
      };

      const [
        {data: devicesResponse},
        {data: deviceConfigurationsResponse},
        {data: arrhythmiaSettingsResponse},
        {data: workflowSettingsResponse},
        {data: physicianUsersResponse},
        {data: insuranceTypesResponse},
        {
          data: [studyConfigurationsResponse],
        },
      ] = await Promise.all([
        axios({method: "get", url: "/devices", params: devicesQuery}),
        axios({method: "get", url: "/facilityDeviceConfigurations", params: {facilityId}}),
        axios({method: "get", url: "/facilityArrhythmiaSettings", params: {facilityId}}),
        axios({method: "get", url: `/workflowSettings/${facilityId}`}),
        axios({method: "get", url: "/users?", params: physicianUserQuery}),
        axios({method: "get", url: `/facilities/${facilityId}/insuranceTypes`}),
        axios({method: "get", url: "/facilityStudyConfigurations", params: {facilityId}}),
      ]);

      if (studyConfigurationsResponse.allowedStudyTypes) {
        setAllowedStudyTypes(studyConfigurationsResponse.allowedStudyTypes);
      }

      // Convert pause duration to seconds and hpFilter to Hz
      const formattedArrhythmiaSettings = keyBy(
        arrhythmiaSettingsResponse
          .map((setting) => {
            const pauseDuration = setting.pauseDuration / 1000;
            const hpFilter = setting.hpFilter / 100;

            return {...setting, pauseDuration, hpFilter};
          })
          .sort((a, b) => {
            // XOR between a and b, i.e. only one is default.
            if ((a.isDefault || b.isDefault) && (!a.isDefault || !b.isDefault)) {
              return a.isDefault ? -1 : 1;
            }
            if (a.name === b.name) {
              return 0;
            }
            return a.name < b.name ? -1 : 1;
          }),
        "id"
      );
      const formattedDeviceConfigurations = keyBy(
        Array.from(deviceConfigurationsResponse).sort((a, b) => {
          // XOR between a and b, i.e. only one is default.
          if ((a.isDefault || b.isDefault) && (!a.isDefault || !b.isDefault)) {
            return a.isDefault ? -1 : 1;
          }
          if (a.name === b.name) {
            return 0;
          }
          return a.name < b.name ? -1 : 1;
        }),
        "id"
      );

      setDevices(devicesResponse);
      setDeviceConfigurations(formattedDeviceConfigurations);
      setArrhythmiaSettings(formattedArrhythmiaSettings);
      setWorkflowSettings(workflowSettingsResponse);
      setPhysicianUsers(physicianUsersResponse);
      setInsuranceTypes(insuranceTypesResponse);
      setRequiredFields(studyConfigurationsResponse.requiredStudyFields);
    } catch (err) {
      console.error(err);
      setError(err.message);
    }

    setLoading(false);
  }, [methods, selectedFacility, device?.facilityId, userFacilityId]);
  const getFacilities = React.useCallback(async () => {
    const facilityQuery = {
      disabled: false,
      inboxAccess: true,
      attributes: ["id", "name"],
      order: [["name", "ASC"]],
    };
    if (isInAnyRole(["tzAdmin"])) {
      facilityQuery.id = {$or: [{$like: `${userFacilityId}_%`}, {$eq: userFacilityId}]};
    }

    try {
      // Get all facilities the current user has access to (including descendants and delegates)
      const {data: facilitiesResponse} = await axios({
        method: "get",
        url: "/facilities",
        params: facilityQuery,
      });

      const formattedFacilities = keyBy(facilitiesResponse, "id");
      setFacilities(formattedFacilities);

      if (facilitiesResponse.length <= 1) {
        // Remove Select Facility step
        setSteps((prev) => prev.filter((step) => step !== "Select Facility"));

        await getStudyData();
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
      setError(err.message);
      setLoading(false);
    }
  }, [getStudyData, isInAnyRole, userFacilityId]);

  React.useEffect(() => {
    // If this dialog came from an unconfigured device notification, we already know the facilityId from the device
    if (loading && device) {
      // Remove Select Facility step
      setSteps((prev) => prev.filter((step) => step !== "Select Facility"));

      getStudyData();
    } else if (loading && Object.keys(facilities) < 1) {
      // Otherwise, fetch facilities if we haven't already. getFacilities will then call getStudyData if need be
      getFacilities();
    }
  }, [device, facilities, getFacilities, getStudyData, loading]);

  //---------------------------------------------------------------------------
  // Helper Functions
  //---------------------------------------------------------------------------
  const filteredDevices = React.useMemo(() => {
    if (["holter", "extendedHolter"].includes(watchAllFields.studyType)) {
      return devices.filter((d) => !d.deviceVariant.capableOfWirelessStudies);
    }
    return devices.filter((d) => d.deviceVariant.capableOfWirelessStudies);
  }, [devices, watchAllFields.studyType]);

  const currentFacility = React.useMemo(() => {
    const facilityId = selectedFacility || device?.facilityId || userFacilityId;

    return facilities[facilityId] || {};
  }, [device?.facilityId, facilities, selectedFacility, userFacilityId]);

  //---------------------------------------------------------------------------
  // Handle Form Stepping
  //---------------------------------------------------------------------------
  const validateAddressStreet = React.useCallback(
    async (address, fieldName) => {
      try {
        const {data: foundAddress} = await axios({
          method: "post",
          url: "/facilities/verifyAddress",
          data: {street: address},
        });

        methods.register(fieldName, {
          validate: {
            isValidAddress: () => {
              return foundAddress?.street !== "" || "Address could not be found";
            },
          },
        });
      } catch (err) {
        if (err.response?.status === 404) {
          methods.register(fieldName, {
            validate: {
              isValidAddress: () => {
                return "Address could not be found";
              },
            },
          });
        } else {
          methods.register(fieldName, {
            validate: {
              isValidAddress: () => {
                return "An error occurred while validating this address, please try again";
              },
            },
          });
        }
      }
    },
    [methods]
  );

  const handleNext = React.useCallback(async () => {
    setMessage(null);

    const patientFields = [
      "patientName",
      "patientId",
      "patientLanguage",
      "patientDob",
      "patientGender",
      "patientHeight",
      "patientWeight",
      "patientPhoneNumber",
      "patientAddress",
      "patientNotes",
      "emergencyContactName",
      "emergencyContactRelation",
      "emergencyContactPhone",
    ];
    const physicianFields = [
      "physicianName",
      "physicianFacility",
      "physicianPhoneNumber",
      "physicianEmail",
      "physicianAddress",
      "physicianNotes",
    ];
    const insuranceFields = [
      "insuranceType",
      "relationToPatient",
      "insuranceCompany",
      "subscriberIdNumber",
      "subscriberName",
      "subscriberDob",
      "subscriberSex",
      "subscriberAddress",
      "policyNumber",
      "groupNumber",
      "priorAuthorizationNumber",
      "claimsPhoneNumber",
      "claimsAddress",
      "additionalComments",
    ];
    let fieldsToValidate = [];
    const insurancePromises = [];

    switch (steps[activeStep]) {
      case "Select Facility":
        fieldsToValidate = ["facilityId"];
        break;
      case "Study Info": {
        fieldsToValidate = [
          "studyType",
          "device",
          "deviceConfig",
          "orderNumber",
          "studyIndication",
          ...(features.studyTimeZone ? ["timeZone"] : []),
        ];

        if (!watchAllFields.studyType?.toLowerCase().endsWith("holter")) {
          fieldsToValidate.push("arrhythmiaSetting");
        }
        if (watchAllFields.studyType !== "cardiacRehab") {
          fieldsToValidate.push("studyDays");
        }

        if (!isInAnyRole(["physician"]) && workflowSettings?.eSignEnabled && physicianUsers.length > 0) {
          fieldsToValidate.push("physicianUsers");
        }

        break;
      }
      case "Authorization": {
        fieldsToValidate = [];

        if (watchAllFields.studyType?.startsWith("mct") && features.downgradeAuthorized) {
          fieldsToValidate.push("downgradeAuthorized");
        }

        if (watchAllFields.studyType?.toLowerCase().includes("wireless") && features.followUpStudy) {
          fieldsToValidate.push("priorAuthorization");
        }

        break;
      }
      case "Follow-up Study Info": {
        fieldsToValidate = ["followUpStudyType", "followUpStudyDays", "followUpStudyIndication"];

        break;
      }
      case "Patient Info":
        fieldsToValidate = [
          "emergencyContactPhone",
          "patientPhoneNumber",
          "patientDob",
          ...patientFields.filter((field) => requiredFields?.[field]),
        ];

        if (requiredFields?.patientAddress && watchAllFields.patientAddress) {
          await validateAddressStreet(watchAllFields.patientAddress, "patientAddress");
        }
        break;
      case "Ordering Physician Info":
        fieldsToValidate = [
          "physicianPhoneNumber",
          ...physicianFields.filter((field) => requiredFields?.[field]),
        ];

        if (requiredFields?.physicianAddress && watchAllFields.physicianAddress) {
          await validateAddressStreet(watchAllFields.physicianAddress, "physicianAddress");
        }
        break;
      case "Insurance Info":
        if (requiredFields?.subscriberAddress) {
          for (let i = 0; i < watchAllFields.insurance.length; i++) {
            if (watchAllFields.insurance[i]?.subscriberAddress) {
              insurancePromises.push(
                validateAddressStreet(
                  watchAllFields.insurance[i]?.subscriberAddress,
                  `insurance[${i}].subscriberAddress`
                )
              );
            }
          }
        }

        if (requiredFields?.claimsAddress) {
          for (let i = 0; i < watchAllFields.insurance.length; i++) {
            if (watchAllFields.insurance[i]?.claimsAddress) {
              insurancePromises.push(
                validateAddressStreet(
                  watchAllFields.insurance[i].claimsAddress,
                  `insurance[${i}].claimsAddress`
                )
              );
            }
          }
        }

        await Promise.all(insurancePromises);

        methods.getValues("insurance").forEach((insurance, index) => {
          [
            "subscriberDob",
            "claimsPhoneNumber",
            ...insuranceFields.filter((field) => requiredFields?.[field]),
          ].forEach((field) => {
            fieldsToValidate.push(`insurance[${index}].${field}`);
          });
        });
        break;
      default:
    }

    const isStepValid = await methods.trigger(fieldsToValidate);

    if (isStepValid) {
      let advance = true;

      if (steps[activeStep] === "Select Facility") {
        await getStudyData();
      }

      const downgradeAuthNeeded =
        features.downgradeAuthorized &&
        (watchAllFields.studyType?.startsWith("mct") ||
          watchAllFields.studyType?.toLowerCase().includes("extendedholter")) &&
        requiredFields?.downgradeAuthorized;
      const followUpAuthNeeded =
        features.followUpStudy && watchAllFields.studyType?.toLowerCase().includes("wireless");

      const anyAuth = downgradeAuthNeeded || followUpAuthNeeded;

      // If the selected study type requires further authorization, add the authorization step to the form
      if (anyAuth && !steps.includes("Authorization")) {
        const studyInfoTabIndex = steps.indexOf("Study Info");
        setSteps((prev) => prev.toSpliced(studyInfoTabIndex + 1, 0, "Authorization"));
      }
      // If the user went back and removed the need for Authorization, remove the step from the form
      else if (!anyAuth && steps.includes("Authorization")) {
        setSteps((prev) => prev.filter((step) => step !== "Authorization"));
      }

      // If authorization for follow-up study was selected, add follow-up study step to the form
      if (methods.getValues("priorAuthorization") === "true" && !steps.includes("Follow-up Study Info")) {
        const authorizationTabIndex = steps.indexOf("Authorization");
        setSteps((prev) => prev.toSpliced(authorizationTabIndex + 1, 0, "Follow-up Study Info"));
      }
      // If the user went back and removed follow-up authorization, remove the step from the form
      else if (
        (!followUpAuthNeeded || methods.getValues("priorAuthorization") === "false") &&
        steps.includes("Follow-up Study Info")
      ) {
        setSteps((prev) => prev.filter((step) => step !== "Follow-up Study Info"));
      }

      // Confirm subscriber info if the relation to patient was set to Self
      if (steps[activeStep] === "Insurance Info") {
        try {
          await confirmSubscriberInfo(confirm, watchAllFields);
        } catch (err) {
          // If confirmation was cancelled, do not advance to the next step
          if (err) {
            setError(err);
          }
          advance = false;
        }
      }

      if (advance) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  }, [
    activeStep,
    confirm,
    features.followUpStudy,
    features.studyTimeZone,
    features.downgradeAuthorized,
    getStudyData,
    isInAnyRole,
    methods,
    physicianUsers.length,
    requiredFields,
    steps,
    validateAddressStreet,
    watchAllFields,
    workflowSettings?.eSignEnabled,
  ]);
  const handleBack = React.useCallback(() => {
    setMessage(null);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  //---------------------------------------------------------------------------
  // Handle Patient Detail Change
  //---------------------------------------------------------------------------
  const updateSubscriberDetails = React.useCallback(
    (insuranceIndex) => {
      let detailsUpdated = false;
      const fieldsToCheck = [
        ["patientName", "subscriberName"],
        ["patientDob", "subscriberDob"],
        ["patientGender", "subscriberSex"],
        ["patientAddress", "subscriberAddress"],
      ];

      // For each field, update the subscriber info if it differs from patient info
      fieldsToCheck.forEach(([patientField, subscriberField]) => {
        const patientValue = methods.getValues(patientField);
        const subscriberValue = methods.getValues(`insurance[${insuranceIndex}].${subscriberField}`);

        const datesAreEqual =
          patientValue?.isLuxonDateTime &&
          subscriberValue?.isLuxonDateTime &&
          patientValue.equals(subscriberValue);

        if (subscriberValue !== patientValue && !datesAreEqual) {
          methods.setValue(`insurance[${insuranceIndex}].${subscriberField}`, patientValue);
          detailsUpdated = true;
        }
      });

      if (detailsUpdated) {
        setMessage("Subscriber details have been updated to match patient information");
      }
    },
    [methods]
  );

  // Watch for changes to relationToPatient selection to trigger an update
  const watchRelationSelection1 = methods.watch("insurance[0].relationToPatient") || "";
  const watchRelationSelection2 = methods.watch("insurance[1].relationToPatient") || "";
  const watchRelationSelection3 = methods.watch("insurance[2].relationToPatient") || "";

  React.useEffect(() => {
    if (watchRelationSelection1 === "Self") {
      updateSubscriberDetails(0);
    }
  }, [updateSubscriberDetails, watchRelationSelection1]);

  React.useEffect(() => {
    if (watchRelationSelection2 === "Self") {
      updateSubscriberDetails(1);
    }
  }, [updateSubscriberDetails, watchRelationSelection2]);

  React.useEffect(() => {
    if (watchRelationSelection3 === "Self") {
      updateSubscriberDetails(2);
    }
  }, [updateSubscriberDetails, watchRelationSelection3]);

  //---------------------------------------------------------------------------
  // Form Submission
  //---------------------------------------------------------------------------
  const [submitting, setSubmitting] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const dispatch = useStudiesDispatch();

  const onSubmit = React.useCallback(
    async (data) => {
      //---------------------------------------------------------------------------
      // Attach the priority to each insurance policy
      //---------------------------------------------------------------------------
      data.insurance.forEach((insurance, i) => {
        insurance.subscriberDob = insurance.subscriberDob?.toFormat("yyyy/MM/dd") || "";

        if (insurance.insuranceType === "") {
          insurance.insuranceType = null;
        }

        // if the user clears this free textfield/dropdown, it will default to null
        // we must ensure the value is of type string for schema validation
        if (insurance.relationToPatient === null) {
          insurance.relationToPatient = "";
        }

        insurance.priority = i + 1;
      });

      //---------------------------------------------------------------------------
      // Verify addresses confirmation
      //---------------------------------------------------------------------------
      try {
        data = await handleAddressSubmit(confirm, data, methods.getValues, methods.setValue);
      } catch (err) {
        // If the user cancels the address confirmation, stop the submission
        return;
      }

      //---------------------------------------------------------------------------
      // Start study submission process
      //---------------------------------------------------------------------------
      setSubmitting(true);

      //---------------------------------------------------------------------------
      // Format the data
      //---------------------------------------------------------------------------
      const defaultArrhythmiaSettings = Object.values(arrhythmiaSettings).find(
        (setting) => setting.isDefault
      );
      if (
        ["wirelessHolter", "wirelessExtendedHolter"].includes(data.studyType) &&
        defaultArrhythmiaSettings
      ) {
        data.arrhythmiaSetting = defaultArrhythmiaSettings.id;
      }

      let downgradeAuthorized = null;
      if (data.downgradeAuthorized === "true") {
        downgradeAuthorized = true;
      } else if (data.downgradeAuthorized === "false") {
        downgradeAuthorized = false;
      }

      const studyToCreate = {
        // Study Info
        orderNumber: data.orderNumber,
        facilityId: data.facilityId || data.device?.facilityId || device.facilityId,
        studyType: data.studyType,
        deviceId: data.device?.id || device.id,
        tzSerial: data.device?.tzSerial || device.tzSerial,
        deviceConfig: deviceConfigurations[data.deviceConfig],
        studyHours: (data.studyType === "cardiacRehab" ? 30 : Number(data.studyDays)) * 24, // convert to hours
        ...(!["holter", "extendedHolter"].includes(data.studyType) &&
          data.arrhythmiaSetting && {
            tachyBpm: arrhythmiaSettings[data.arrhythmiaSetting].tachyBpm,
            bradyBpm: arrhythmiaSettings[data.arrhythmiaSetting].bradyBpm,
            pauseDuration: arrhythmiaSettings[data.arrhythmiaSetting].pauseDuration * 1000, // convert to milliseconds
            episodeDuration: arrhythmiaSettings[data.arrhythmiaSetting].episodeDuration,
            hpFilter: arrhythmiaSettings[data.arrhythmiaSetting].hpFilter * 100, // convert to hundredth of Hz
            lpFilter: arrhythmiaSettings[data.arrhythmiaSetting].lpFilter,
            notchFilter: arrhythmiaSettings[data.arrhythmiaSetting].notchFilter,
          }),
        timeZone: data.timeZone.id,
        studyIndication: data.studyIndication
          .map((indication) => (typeof indication === "string" ? indication : indication.name))
          .join(";  "),
        studyNotes: data.studyNotes
          ? [{fullName, timestamp: new Date().getTime(), note: data.studyNotes}]
          : [],

        // Downgrade Authorization
        downgradeAuthorized,

        // Follow-up Study Info
        ...(data.priorAuthorization === "true" && {
          followUpStudyType: data.followUpStudyType,
          followUpStudyHours: Number(data.followUpStudyDays) * 24,
          followUpStudyIndication: data.followUpStudyIndication
            ?.map((indication) => (typeof indication === "string" ? indication : indication.name))
            ?.join(";  "),
        }),

        // Patient Info
        patientName: data.patientName || data.device?.tzSerial || device.tzSerial,
        patientId: data.patientId,
        patientLanguage: data.patientLanguage,
        patientDob: data.patientDob?.toFormat("yyyy/MM/dd") || "",
        patientGender: data.patientGender,
        patientHeight: data.patientHeight,
        patientWeight: data.patientWeight,
        patientPhoneNumber: data.patientPhoneNumber,
        patientAddress: data.patientAddress,
        patientNotes: data.patientNotes,
        emergencyContactName: data.emergencyContactName,
        emergencyContactRelation: data.emergencyContactRelation,
        emergencyContactPhone: data.emergencyContactPhone,

        // Ordering Physician Info
        physicianName: typeof data.physicianName === "string" ? data.physicianName : data.physicianName?.name,
        physicianNpiNumber:
          typeof data.physicianNpiNumber === "string"
            ? data.physicianNpiNumber
            : data.physicianNpiNumber?.npi,
        physicianType: data.physicianType,
        physicianFacility: data.physicianFacility,
        physicianPhoneNumber: data.physicianPhoneNumber,
        physicianEmail: data.physicianEmail,
        physicianAddress: data.physicianAddress,
        physicianNotes: data.physicianNotes,

        // Insurance Info
        insurance: data.insurance,
      };

      if (!features.studyTimeZone) {
        delete studyToCreate.timeZone;
      }
      if (!features.downgradeAuthorized) {
        delete studyToCreate.downgradeAuthorized;
      }

      //---------------------------------------------------------------------------
      // Make API requests
      //---------------------------------------------------------------------------
      try {
        const {data: createdStudy} = await axios({
          method: "post",
          url: "/studies/startStudy",
          data: studyToCreate,
        });

        // Assign the created study to specified physicians
        const physiciansToAssign = {
          studyId: createdStudy.id,
          facilityId: createdStudy.facilityId,
          users: data.physicianUsers?.map((user) => ({userId: user.id})),
        };
        if (isInAnyRole(["physician"])) {
          physiciansToAssign.users = [{userId}];
        }
        if (physiciansToAssign.users.length > 0) {
          await axios({
            method: "post",
            url: `/studyAssignments/${createdStudy.id}`,
            data: physiciansToAssign,
          });
        }

        // Assign the follow-up study to specified physicians
        if (data.priorAuthorization === "true" && createdStudy.followUpStudyId) {
          const physiciansToAssignToFollowUp = {
            studyId: createdStudy.followUpStudyId,
            facilityId: createdStudy.facilityId,
            users: data.followUpPhysicianUsers?.map((user) => ({userId: user.id})),
          };
          if (isInAnyRole(["physician"])) {
            physiciansToAssignToFollowUp.users = [{userId}];
          }
          if (physiciansToAssignToFollowUp.users.length > 0) {
            await axios({
              method: "post",
              url: `/studyAssignments/${createdStudy.followUpStudyId}`,
              data: physiciansToAssignToFollowUp,
            });
          }
        }

        const {
          data: [newStudy],
        } = await axios({
          method: "get",
          url: "/studies",
          params: {id: createdStudy.id},
        });
        dispatch({type: "created", newElement: newStudy});

        // If this study was created from a notification, we need to disable the Create Study
        // button since we can't close the Angular notification from this React component
        setSubmitted(true);

        handleClose();
      } catch (err) {
        console.error(err);
        setError(err.message);
      }

      setSubmitting(false);
    },
    [
      arrhythmiaSettings,
      device,
      deviceConfigurations,
      fullName,
      features,
      confirm,
      methods.getValues,
      methods.setValue,
      isInAnyRole,
      dispatch,
      handleClose,
      userId,
    ]
  );

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <>
      {!device && (
        <Tooltip
          title={
            facilityDisabled
              ? "Studies are unable to be created at this time. If you have questions, contact BitRhythm Support"
              : "Create New Study"
          }
          placement="left"
        >
          <span
            style={{
              position: "fixed",
              bottom: 24,
              right: 24,
            }}
          >
            <Fab
              color="secondary"
              data-cy="create-study-button"
              disabled={facilityDisabled}
              onClick={handleOpen}
            >
              <Add />
            </Fab>
          </span>
        </Tooltip>
      )}
      {device && (
        <Grid container direction="column" sx={{alignItems: "center", display: "flex"}}>
          <Alert
            message="BitRhythm has detected an unconfigured device that is recording patient data. Create a study to view ECG events from this recording."
            level="info"
          />

          <Tooltip
            title={
              facilityDisabled
                ? "Studies are unable to be created at this time. If you have questions, contact BitRhythm Support"
                : ""
            }
          >
            <span>
              <Button
                onClick={handleOpen}
                color="secondary"
                variant="contained"
                data-cy="create-study-from-notification-button"
                sx={{px: 12, mt: 1}}
                disabled={submitted || facilityDisabled}
              >
                Create Study
              </Button>
            </span>
          </Tooltip>
        </Grid>
      )}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...methods}>
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth data-cy="new-study-dialog">
          <Alert message={error} setMessage={setError} level="error" />
          <DialogTitle
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
            data-cy="new-study-dialog-title"
          >
            <IconWithText
              icon={<AssignmentInd color="secondary" />}
              text={`New Study${
                steps[activeStep] !== "Select Facility" && selectedFacility
                  ? ` - ${facilities[selectedFacility]?.name}`
                  : ""
              }`}
            />
            <CancelButton isDirty={isDirty} color="tertiary" id="cancelButton" onClick={handleClose}>
              <Close />
            </CancelButton>
          </DialogTitle>

          <Divider sx={{bgcolor: "secondary.main"}} />

          <DialogContent>
            {loading && <TableLoading />}
            {!loading && (
              <>
                <Stepper activeStep={activeStep} sx={{mb: 2}} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label} data-cy={`${label.replaceAll(" ", "-").toLowerCase()}-step`}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <Alert message={message} setMessage={setMessage} level="info" />

                <Box sx={{...(steps[activeStep] !== "Select Facility" && {display: "none"})}}>
                  <Grid container>
                    <Grid size={{xs: 12, md: 6}} sx={{p: 1}}>
                      <FormStringInput
                        control={methods.control}
                        defaultValue={
                          selectedFacility ||
                          (Object.keys(facilities).length <= 2 && Object.keys(facilities)[0]) ||
                          ""
                        }
                        label="Facility"
                        name="facilityId"
                        id="facilitySelect"
                        options={Object.values(facilities)}
                        required={steps.includes("Select Facility")}
                        rules={{
                          ...(steps.includes("Select Facility") && {required: "Facility is required"}),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box sx={{...(steps[activeStep] !== "Study Info" && {display: "none"})}}>
                  <StudyInfo
                    facility={currentFacility}
                    device={device}
                    devices={devices}
                    allowedStudyTypes={allowedStudyTypes}
                    deviceConfigurations={Object.values(deviceConfigurations)}
                    arrhythmiaSettings={Object.values(arrhythmiaSettings)}
                    physicianUsers={physicianUsers}
                    setError={setError}
                    eSignEnabled={workflowSettings?.eSignEnabled}
                    requiredFields={requiredFields}
                  />
                </Box>

                <Box sx={{...(steps[activeStep] !== "Authorization" && {display: "none"})}}>
                  <Authorization
                    device={device}
                    facility={currentFacility}
                    facilities={facilities}
                    requiredFields={requiredFields}
                  />
                </Box>

                {watchAllFields.priorAuthorization === "true" && (
                  <Box sx={{...(steps[activeStep] !== "Follow-up Study Info" && {display: "none"})}}>
                    <StudyInfo
                      followUp
                      facility={currentFacility}
                      device={device}
                      devices={devices}
                      allowedStudyTypes={allowedStudyTypes}
                      deviceConfigurations={Object.values(deviceConfigurations)}
                      arrhythmiaSettings={Object.values(arrhythmiaSettings)}
                      physicianUsers={physicianUsers}
                      setError={setError}
                      eSignEnabled={workflowSettings?.eSignEnabled}
                      requiredFields={requiredFields}
                    />
                  </Box>
                )}

                <Box sx={{...(steps[activeStep] !== "Patient Info" && {display: "none"})}}>
                  <PatientInfo requiredFields={requiredFields} />
                </Box>

                <Box sx={{...(steps[activeStep] !== "Ordering Physician Info" && {display: "none"})}}>
                  <PhysicianInfo requiredFields={requiredFields} />
                </Box>

                <Box sx={{...(steps[activeStep] !== "Insurance Info" && {display: "none"})}}>
                  <InsuranceInfo insuranceTypes={insuranceTypes} requiredFields={requiredFields} />
                </Box>

                <Grid container sx={{...(steps[activeStep] !== "Confirm Device" && {display: "none"})}}>
                  <Grid size={{xs: 12, md: 6}}>
                    <AutocompleteInput
                      name="deviceSerialConfirm"
                      control={methods.control}
                      defaultValue={null}
                      id="deviceConfirmSelect"
                      options={filteredDevices}
                      label="Confirm device"
                      disabled={submitting}
                      rules={{
                        required: "A device must be selected",
                        validate: {
                          matchingSerial: (selectedDevice) => {
                            if (
                              (device && selectedDevice.tzSerial !== device.tzSerial) ||
                              (!device && selectedDevice.tzSerial !== watchAllFields?.device?.tzSerial)
                            ) {
                              return "The selected device does not match an existing available device";
                            }
                            return true;
                          },
                        },
                      }}
                      variant="single"
                      getOptionLabel={(option) => option.tzSerial}
                      otherProps={{noOptionsText: "No matching devices found"}}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </DialogContent>
          <DialogActions
            style={{display: "flex", alignItems: "center", flexWrap: "wrap", justifyContent: "space-between"}}
          >
            <Box sx={{m: 2}}>
              <Button
                color="secondary"
                disabled={activeStep === 0 || loading || submitting}
                onClick={handleBack}
                data-cy="back-button"
              >
                Back
              </Button>
            </Box>
            <Box sx={{m: 2}}>
              {activeStep === steps.length - 1 ? (
                <LoadingButton
                  disabled={loading || submitting}
                  loading={loading || submitting}
                  variant="contained"
                  color="secondary"
                  onClick={methods.handleSubmit(onSubmit)}
                  data-cy="submit-button"
                >
                  Submit
                </LoadingButton>
              ) : (
                <Button
                  disabled={loading}
                  color="secondary"
                  variant="contained"
                  onClick={handleNext}
                  data-cy="next-button"
                >
                  Next
                </Button>
              )}
            </Box>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
}

NewStudyDialog.propTypes = {
  device: PropTypes.object,
};

export default React.memo(NewStudyDialog);
