import React from "react";
import {useFormContext, useWatch} from "react-hook-form";
import * as d3 from "d3";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import InboxEntityContext from "../../contexts/InboxEntityContext.jsx";
import useEnvironmentVariables from "../hooks/useEnvironmentVariables.jsx";
import useLeadCoordinates from "../hooks/useLeadCoordinates.jsx";
import LeadLabel from "./LeadLabel.jsx";
import TriggerMarker from "./TriggerMarker.jsx";

function ContextViewer({
  // Props
  leads,
  samplePeriod,
  avm,
  eventSample,
  contextCenteredSample,
  stripCenteredSample,
  setStripCenteredSample,
  width,
  height,
}) {
  //---------------------------------------------------------------------------
  // Global variables
  //---------------------------------------------------------------------------
  const {defaultStripLengthInSeconds} = useEnvironmentVariables();
  const {type: parentType} = React.useContext(InboxEntityContext);
  const isGeneratedReport = React.useMemo(() => parentType === "generated-report", [parentType]);

  //---------------------------------------------------------------------------
  // Watch the time base and toggled lead input values
  //---------------------------------------------------------------------------
  const {control} = useFormContext();
  const timeBase = useWatch({control, name: "timeBase"});

  //---------------------------------------------------------------------------
  // Constants
  //---------------------------------------------------------------------------
  const samplesPerSecond = React.useMemo(() => 1000000 / samplePeriod, [samplePeriod]);

  const secondsToDisplay = React.useMemo(() => {
    const seconds = leads[0].totalSamples / samplesPerSecond;
    return seconds > 60 ? 60 : seconds;
  }, [leads, samplesPerSecond]);

  const pixelsPerSample = React.useMemo(() => {
    const pixelsPerSecond = width / secondsToDisplay;

    return pixelsPerSecond / samplesPerSecond;
  }, [samplesPerSecond, secondsToDisplay, width]);

  const [contextStartSample, contextEndSample] = React.useMemo(() => {
    const totalContextSamples = samplesPerSecond * secondsToDisplay;
    const totalEcgSamples = leads[0].totalSamples;

    // Calculate the starting and ending sample of this strip based on the selected time base
    let startSample = contextCenteredSample - totalContextSamples / 2;
    let endSample = contextCenteredSample + totalContextSamples / 2;

    if (startSample < 0) {
      startSample = 0;
      endSample = totalContextSamples;
    } else if (endSample > totalEcgSamples) {
      startSample = totalEcgSamples - totalContextSamples;
      endSample = totalEcgSamples;
    }

    return [startSample, endSample];
  }, [leads, samplesPerSecond, secondsToDisplay, contextCenteredSample]);

  //---------------------------------------------------------------------------
  // Convert the lead data into coordinates in pixels
  //
  // This calculation is dependent on the height (pixels), width (pixels),
  // and length (seconds) of this viewer
  //---------------------------------------------------------------------------
  const slicedLeads = React.useMemo(
    () =>
      leads.map((lead) => {
        // Create a shallow copy of lead with a shallow copy of sliced data
        const data = lead.data.slice(contextStartSample, contextEndSample + 1);
        return {...lead, data};
      }),
    [leads, contextStartSample, contextEndSample]
  );
  const calculateOffsetYCoordinate = React.useCallback(() => Math.round(height / 2), [height]);

  const displayedLead = useLeadCoordinates({
    leads: slicedLeads,
    pixelsPerSample,
    samplePeriod,
    avm,
    getOffsetY: calculateOffsetYCoordinate,
    useSelectedLead: true,
  });

  //---------------------------------------------------------------------------
  // Calculate the x-coordinate (pixels) of the event trigger marker
  //---------------------------------------------------------------------------
  const triggerMarkerX = React.useMemo(() => {
    const adjustedTriggerSample = eventSample - contextStartSample;

    return Math.round(adjustedTriggerSample * pixelsPerSample);
  }, [eventSample, pixelsPerSample, contextStartSample]);

  //---------------------------------------------------------------------------
  // Context viewer strip brush state management
  //---------------------------------------------------------------------------
  const selection = React.useMemo(() => {
    const stripLengthInSeconds = (defaultStripLengthInSeconds * 25) / Number(timeBase);
    const totalStripSamples = samplesPerSecond * stripLengthInSeconds;

    // Calculate the starting and ending sample of this strip based on the selected time base
    let stripStartSample = stripCenteredSample - totalStripSamples / 2;
    let stripEndSample = stripCenteredSample + totalStripSamples / 2;

    if (stripStartSample < 0) {
      stripStartSample = 0;
      stripEndSample = totalStripSamples;
    }

    // Convert samples into coordinates (pixels) on the viewer
    const startCoordinate = Math.round((stripStartSample - contextStartSample) * pixelsPerSample);
    const endCoordinate = Math.round((stripEndSample - contextStartSample) * pixelsPerSample);

    return [startCoordinate, endCoordinate];
  }, [
    stripCenteredSample,
    defaultStripLengthInSeconds,
    pixelsPerSample,
    samplesPerSecond,
    contextStartSample,
    timeBase,
  ]);

  const brushRef = React.useRef();
  const memoizedDrawCallback = React.useCallback(() => {
    const brush = d3
      .select("#context-viewer-brush")
      .call(brushRef.current)
      .call(brushRef.current.move, selection);

    const brushed = (event) => {
      if (event.selection) {
        const [startCoordinate, endCoordinate] = event.selection;

        if (startCoordinate !== endCoordinate) {
          const centeredCoordinate = (startCoordinate + endCoordinate) / 2;

          // Convert the coordinates (pixels) into samples so that the other
          // viewers can adjust
          setStripCenteredSample(Math.round(centeredCoordinate / pixelsPerSample) + contextStartSample);
        }
      }
    };
    const brushEnded = (event) => {
      if (!event.selection) {
        brush.call(brushRef.current.move, selection);
      }
    };

    brushRef.current = d3
      .brushX()
      .extent([
        [0, 1],
        [width, height - 1],
      ])
      .on("brush", brushed)
      .on("end", brushEnded);

    // Add styling to the selection box
    d3.select(".selection")
      .style("fill", "rgba(66, 165, 245)")
      .style("stroke", "rgba(66, 165, 245)")
      .style("stroke-width", 2);

    // Remove support for changing the width of the selection box (default d3 behavior)
    d3.selectAll(".handle").remove();
    d3.selectAll(".overlay").remove();
  }, [width, height, selection, setStripCenteredSample, pixelsPerSample, contextStartSample]);

  React.useEffect(() => {
    // Disable scrolling through the context viewer on generated reports
    if (!isGeneratedReport) {
      memoizedDrawCallback();
    }
  }, [brushRef, height, width, memoizedDrawCallback, isGeneratedReport]);

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <svg
      width={width}
      height={height}
      style={{position: "absolute", display: "block"}}
      data-cy="context-viewer"
    >
      <path fill="none" stroke="black" strokeWidth="1" d={displayedLead?.path} />

      <LeadLabel y={height / 2}>{displayedLead?.label}</LeadLabel>

      <TriggerMarker width={width} height={height} x={triggerMarkerX} label={false} />

      {!isGeneratedReport && <g id="context-viewer-brush" />}
      {isGeneratedReport && (
        <rect
          x={selection[0]}
          width={selection[1] - selection[0]}
          height={height}
          fill="rgba(66, 165, 245, 0.4)"
        />
      )}
    </svg>
  );
}

ContextViewer.propTypes = {
  leads: PropTypes.array.isRequired,
  samplePeriod: PropTypes.number.isRequired,
  avm: PropTypes.number.isRequired,
  eventSample: PropTypes.number.isRequired,
  contextCenteredSample: PropTypes.number.isRequired,
  stripCenteredSample: PropTypes.number.isRequired,
  setStripCenteredSample: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default ContextViewer;
