import React from "react";
import {useFormContext, useWatch} from "react-hook-form";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import InboxEntityContext from "../../contexts/InboxEntityContext.jsx";

function ScaleLegend({
  // Props
  width,
  height,
}) {
  //---------------------------------------------------------------------------
  // Global variables
  //---------------------------------------------------------------------------
  const {type: parentType} = React.useContext(InboxEntityContext);
  const isGeneratedReport = React.useMemo(() => parentType === "generated-report", [parentType]);

  //---------------------------------------------------------------------------
  // Watch the time base and gain input values
  //---------------------------------------------------------------------------
  const {control} = useFormContext();
  const [timeBase, gain] = useWatch({control, name: ["timeBase", "gain"]});

  //---------------------------------------------------------------------------
  // Styling constants
  //---------------------------------------------------------------------------
  const fontSize = React.useMemo(() => (isGeneratedReport ? 12 : 16), [isGeneratedReport]);
  const margin = 10;

  return (
    <text x={width - margin} y={height - margin} fontSize={fontSize} textAnchor="end">
      {timeBase}mm/s {gain}mm/mV
    </text>
  );
}

ScaleLegend.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default React.memo(ScaleLegend);
