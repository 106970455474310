import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../../axiosClient.js";
import AddProviders from "../../../shared/react/AddProviders.jsx";
import Alert from "../../../shared/react/Alert.jsx";
import TableLoading from "../../../shared/react/TableLoading.jsx";
import DonutChart from "../DonutChart.jsx";

function VentricularEctopy({
  // Props
  id,
  study,
  start,
  end,
  toggles,
  veGroupings,
  percent,
}) {
  //---------------------------------------------------------------------------
  // The following variables are the prop names that _should be_ if we weren't
  // using pug templates to connect this to angularjs.
  //---------------------------------------------------------------------------
  const reportId = id;
  const studyId = study;
  const startTime = start;
  const endTime = end;
  const chartToggles = toggles;

  //---------------------------------------------------------------------------
  // Error handling state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Fetch data from API
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);
  const [groupings, setGroupings] = React.useState(veGroupings);
  const [percentOfTotal, setPercentOfTotal] = React.useState(percent);

  const getVentricularEctopyData = React.useCallback(async () => {
    setLoading(true);

    try {
      const {data: ventricularEctopyData} = await axios({
        method: "get",
        url: `/reports/ventricular-ectopy/${studyId}`,
        params: {
          startTime: startTime.toUTC().toISO(),
          endTime: endTime.toUTC().toISO(),
        },
      });

      setGroupings(ventricularEctopyData.groupings);
      setPercentOfTotal(ventricularEctopyData.percentOfTotal);
    } catch (err) {
      setError(err.message);
    }

    setLoading(false);
  }, [endTime, startTime, studyId]);

  React.useEffect(() => {
    // If we weren't supplied the data for the chart, fetch it
    if ([null, undefined].includes(veGroupings) && !error) {
      getVentricularEctopyData();
    }
  }, [getVentricularEctopyData, veGroupings, error]);

  //---------------------------------------------------------------------------
  // Data formatting
  //---------------------------------------------------------------------------
  const formatter = React.useCallback((payload) => payload.count, []);
  const additionalData = React.useMemo(() => {
    return [{name: "Percent of Total Beats", value: percentOfTotal ? `${percentOfTotal}%` : "N/A"}];
  }, [percentOfTotal]);

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <AddProviders>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />

      {loading && <TableLoading />}

      <DonutChart
        title="Ventricular Ectopy"
        reportId={reportId}
        chartId="ventricularEctopy"
        data={groupings}
        dataKey="percent"
        additionalData={additionalData}
        additionalDataPosition="top"
        legendFormatter={formatter}
        chartToggles={chartToggles}
      />
    </AddProviders>
  );
}

VentricularEctopy.propTypes = {
  id: PropTypes.string.isRequired,
  study: PropTypes.number.isRequired,
  start: PropTypes.object,
  end: PropTypes.object,
  toggles: PropTypes.object,
  veGroupings: PropTypes.array,
  percent: PropTypes.string,
};
export default VentricularEctopy;
