/* @ngInject */
export default class Authentication {
  constructor($window, $state, Config, Lock, Session) {
    this._$window = $window;
    this._$state = $state;
    this._Lock = Lock;
    this._Session = Session;
    this.features = Config.features;
  }

  /**
   * Returns boolean indicating if JWT is valid
   *
   * @param {String} jwt optional base64 encoded authorization token
   * @return {Boolean}
   */
  isAuthenticated(jwt) {
    let jwtPayload;

    try {
      jwtPayload = this.getJwtPayload(jwt);
      return this.validateJwtPayload(jwtPayload);
    } catch (e) {
      return false;
    }
  }

  /**
   * Validate JWT token and redirect to login page with error if invalid.
   * If token is valid it does nothing.
   * @param {String} jwt optional base64 encoded authorization token
   */
  authenticate(jwt) {
    let jwtPayload;

    try {
      jwtPayload = this.getJwtPayload(jwt);
      this.validateJwtPayload(jwtPayload);
    } catch (error) {
      this.removeJwt();

      if (error.name.includes("ExpirationError")) {
        this.sendToLogin("", error.message);
      } else if (error.name.includes("Unauthorized Inbox Access")) {
        this.sendToLogin(error.message);
      } else if (error.name.includes("Unauthorized")) {
        this.sendToLogin("", error.message);
      } else {
        this.sendToLogin("", "Invalid access token");
      }
    }
  }

  /**
   * Checks if user role matches any of the passed-in roles
   * @param {Array<String>} roles - the roles to check if user role matches
   * @param {String} jwt optional base64 encoded authorization token
   * @return {Boolean}
   */
  isInAnyRole(roles, jwt) {
    return this.isAuthenticated(jwt) && roles.includes(this.getJwtPayload(jwt)?.user?.role);
  }

  getInitialState(jwt) {
    let initialState = "analyze";
    if (this.isAuthenticated(jwt) && this.getJwtPayload(jwt)?.user?.role === "triageTech") {
      initialState = "Triage";
    } else if (
      this.isAuthenticated(jwt) &&
      ["clinicalStaff", "physician"].includes(this.getJwtPayload(jwt)?.user?.role)
    ) {
      initialState = "reports";
    }
    return initialState;
  }

  /**
   * Parse the JWT out of the new stringified object format
   * @param {String} storageValue the stringified object to parse
   * @return {string} Base64 encoded Jwt
   */
  parseLocalStorage(storageValue) {
    if (!storageValue) {
      return null;
    }
    let token;
    try {
      token = JSON.parse(storageValue).value;
    } catch (err) {
      console.error(err);
      token = storageValue;
    }
    return token;
  }

  /**
   * Get JWT from local storage
   * @return {string} Base64 encoded Jwt
   */
  getJwt() {
    return this.parseLocalStorage(this._$window.localStorage.jwt);
  }

  /**
   * Get decoded JWT payload object from local storage
   * @param {String} jwt optional base64 encoded authorization token
   * @return {Object} JWT payload
   */
  getJwtPayload(jwt) {
    if (!jwt) {
      jwt = this.getJwt();
    }

    if (jwt) {
      try {
        const encodedJwtPayload = jwt.split(".")[1];
        const decodedJwtPayload = this._$window.atob(encodedJwtPayload);
        return JSON.parse(decodedJwtPayload);
      } catch (e) {
        throw new Error(`Cannot parse stored JWT Payload: ${e}`);
      }
    }

    const error = new Error("No JWT found");
    error.name = "noJwtError";
    throw error;
  }

  /**
   * Store JWT in local storage
   * @param  {string} jwt base64 encoded string
   * @throws {Error} If can't access localStorage
   */
  loginStoreJwt(jwt) {
    this._$window.localStorage.jwt = JSON.stringify({value: jwt, createdAt: Date.now()});
  }

  /**
   * Remove JWT from local storage
   */
  removeJwt() {
    delete this._$window.localStorage.jwt;
  }

  /**
   * Forward user to log in page
   * @param  {string} message
   * @param  {string} errorMessage
   * @param  {string} toState
   * @returns {Promise}
   */
  sendToLogin(message, errorMessage, toState) {
    let goToLogin;

    if (typeof errorMessage === "string") {
      const encodedErrorMessage = this._$window.btoa(errorMessage);
      goToLogin = this._$state.go("Login", {error: encodedErrorMessage});
    } else if (message && toState) {
      goToLogin = this._$state.go("Login", {message, goto: toState});
    } else if (message) {
      goToLogin = this._$state.go("Login", {message});
    } else if (toState && toState !== "Login") {
      goToLogin = this._$state.go("Login", {goto: toState});
    } else {
      goToLogin = this._$state.go("Login");
    }

    return goToLogin.catch((error) => {
      if (!this._$window.navigator.onLine) {
        this._$window.location.reload();
      } else {
        throw error;
      }
    });
  }

  /**
   * Throw error if failed. Return true if succeeded.
   *
   * @param  {object} jwtPayload
   * @return {true}
   * @throws {TypeError} If payload is not an object
   * @throws {ExpirationError} If payload is expired
   * @example
   * try {
   *   Authentication.validateJwtPayload({user: "data"});
   *   // Success
   * } catch (error) {
   *   // Error
   * }
   */
  validateJwtPayload(jwtPayload) {
    let error;
    const allowedRoles = ["clinicalStaff", "facilityAdmin", "physician", "tech", "triageTech", "tzAdmin"];
    if (typeof jwtPayload !== "object") {
      throw new TypeError(`Expecting payload to be object, received type: ${typeof jwtPayload}`);
    }

    // Get expiration time and current time
    const expirationTime = jwtPayload.exp;
    const currentTime = Math.floor(Date.now() / 1000);
    const jwtIsExpired = expirationTime < currentTime;

    if (jwtIsExpired) {
      error = new Error("Your access token expired");
      error.name = "ExpirationError";

      throw error;
    } else if (!this.hasInboxAccess()) {
      error = new Error("unauthorizedInboxAccess");
      error.name = "Unauthorized Inbox Access";

      throw error;
    } else if (!allowedRoles.includes(jwtPayload.user.role)) {
      error = new Error("Unauthorized");
      error.name = "Unauthorized";

      throw error;
    }

    return true;
  }

  /**
   * Gets current user's id if a user is logged in
   *
   * @param {String} jwt optional base64 encoded authorization token
   * @return {String} User's ID
   */
  getUserId(jwt) {
    if (this.isAuthenticated(jwt)) {
      const jwtPayload = this.getJwtPayload(jwt);
      return jwtPayload?.user?.id;
    }
    return undefined;
  }

  /**
   * Gets current user's username if a user is logged in
   *
   * @param {String} jwt optional base64 encoded authorization token
   * @return {String} username
   */
  getUsername(jwt) {
    if (this.isAuthenticated(jwt)) {
      const jwtPayload = this.getJwtPayload(jwt);
      return jwtPayload?.user?.username;
    }
    return undefined;
  }

  /**
   * Gets current user's full name if a user is logged in
   *
   * @param {String} jwt optional base64 encoded authorization token
   * @return {String}  User's full name
   */
  getFullName(jwt) {
    if (this.isAuthenticated(jwt)) {
      const jwtPayload = this.getJwtPayload(jwt);
      return jwtPayload?.user?.fullName;
    }
    return undefined;
  }

  /**
   * Gets current user's role if a user is logged in
   *
   * @param {String} jwt optional base64 encoded authorization token
   * @return {String}  User's role
   */
  getUserRole(jwt) {
    if (this.isAuthenticated(jwt)) {
      const jwtPayload = this.getJwtPayload(jwt);
      return jwtPayload?.user?.role;
    }
    return undefined;
  }

  /**
   * Gets current user's email if a user is logged in
   *
   * @param {String} jwt optional base64 encoded authorization token
   * @return {String}  User's email
   */
  getEmail(jwt) {
    if (this.isAuthenticated(jwt)) {
      const jwtPayload = this.getJwtPayload(jwt);
      return jwtPayload?.user?.email;
    }
    return undefined;
  }

  /**
   * Gets current user's facilityId if a user is logged in
   *
   * @param {String} jwt optional base64 encoded authorization token
   * @return {String} User's facilityId
   */
  getFacilityId(jwt) {
    if (this.isAuthenticated(jwt)) {
      const jwtPayload = this.getJwtPayload(jwt);
      return jwtPayload?.user?.facilityId;
    }
    return undefined;
  }

  hasInboxAccess(jwt) {
    const jwtPayload = this.getJwtPayload(jwt);
    return jwtPayload?.user?.facility?.inboxAccess;
  }

  getFacilityInfo(jwt) {
    const jwtPayload = this.getJwtPayload(jwt);
    return jwtPayload?.user?.facility;
  }

  /**
   * Remove jwt and username from local storage, then authenticate
   * @param {string} message the message for the login url after logging out.
   * @param {string} toState the state to go back to if logging back in.
   *
   * @returns {Promise} state change or window location change
   * @method
   */
  async logOut(message, toState) {
    const jwt = this.getJwt();

    await this.sendToLogin(message, null, toState);
    this.removeJwt();

    // if we still have a jwt, try to unlock all user's locked items, then remove the jwt and log out
    if (jwt) {
      try {
        await this._Lock.unlockAllItemsForUser(jwt);
        this._Session.clear();
      } catch (err) {
        /* Do nothing */
      }
    }
  }

  static login(username, password, Auth) {
    return Auth.login(username, password);
  }
}
