/* eslint-disable max-classes-per-file */
import angular from "angular";

/* @ngInject */
export default class EcgViewerFactory {
  constructor($rootScope, $document, $window, GridLines, Leads, EcgNavigation, Timeline, Markers) {
    /**
     * Class for Ecg Viewer
     *
     * Instantiated once for every br-ecg-viewer directive that is created in the view
     * Instantiation is trigged when the br-ecg-viewer directive's controller is initialized
     * Configuration is passed in from the inboxController as a config attribute of the br-ecg-viewer directive
     *
     * All services used by this class are singleton services - one instance for the whole app-
     * meaning many instances of EcgViewer use the same instance of the Gridlines service.
     * Any relevant elements and data must be passed to these services
     *
     * There are 3 types of viewers: Strip(6 second), Sequence(60 second), and Full(Variable length).
     * All types of viewers are handled by this class, and the type is passed in with config.
     *
     * @param {object}      config      configuration for this viewer
     *                                  example:
     *                                  {
     *                                    displayElements: {
     *                                      leads: {
     *                                        I: true,
     *                                        II: true,
     *                                        III: true
     *                                      }
     *                                    },
     *                                    type: "strip",
     *                                    seconds: 6
     *                                  }
     * @param {object}      ecgData     60 seconds of ecg data (samples and metadata)
     *                                  Both types of viewers include 60 seconds of data
     * @param {HTMLElement} br-ecg-viewer element; canvases get attached as children of this element
     * @param {Boolean} allowEcgNavigationScroll determines if the viewers should be scrollable
     */
    class EcgViewer {
      constructor(
        scope,
        config,
        ecgData,
        element,
        allowEcgNavigationScroll = true,
        navBoxes = [],
        beatMarkers = [],
        aspectRatio = 3,
        parentType = "noParent"
      ) {
        // All ecg strip viewers are 3 times as wide as they are tall, 4.5 if a generated report
        this.STRIP_ASPECT_RATIO = aspectRatio;

        this.config = config;
        this.htmlContainerElement = element;
        this.htmlContainerElement.style.display = "block";
        this.htmlContainerElement.style.position = "relative";
        this.allowEcgNavigationScroll = allowEcgNavigationScroll;
        this.navBoxes = navBoxes;
        this.beatMarkers = beatMarkers;
        this.ecgData = ecgData;
        this.ecgData.mmPerMillivolt = this.ecgData.mmPerMillivolt || 10;
        this.ecgData.mmPerSecond = this.ecgData.mmPerSecond || 25;
        this.ecgData.samplesPerSecond = 1000000 / ecgData.samplePeriod;
        this.parentType = parentType;
        this.setBoundaries(
          this.ecgData.centeredSample ? this.ecgData.centeredSample : this.ecgData.eventSample
        );

        // On the Strip Viewer, the markers canvas needs to be drawn last.
        // On the Sequence and Full Viewers, the navigation canvas needs to be drawn last.
        this.canvases = [];
        this.gridLinesCanvas = this.createCanvas("gridLines", this.config.type);
        this.leadsCanvas = this.createCanvas("leads", this.config.type);
        this.timelineCanvas = this.createCanvas("timeline", this.config.type);

        if (this.config.type === "strip") {
          this.navigationCanvas = this.createCanvas("navigation", this.config.type);
          this.markersCanvas = this.createCanvas("markers", this.config.type);
          Markers.addTriggerMarker(this);

          if (this.parentType === "stripViewer" || this.parentType === "ecgEventItem") {
            Markers.registerMouseEvents(this);
          }
          if (this.parentType === "ecgEventItem") {
            this.markersCanvas.classList.add("crosshair");
          }
        } else if (this.config.type === "sequence") {
          // The Markers Canvas on the Sequence Viewer is only used for the Trigger Marker
          this.markersCanvas = this.createCanvas("markers", this.config.type);
          Markers.addTriggerMarker(this);
          this.navigationCanvas = this.createCanvas("navigation", this.config.type);
        } else if (this.config.type === "full") {
          this.navigationCanvas = this.createCanvas("navigation", this.config.type);
        }

        if (
          this.allowEcgNavigationScroll &&
          (this.config.type === "sequence" || this.config.type === "full")
        ) {
          EcgNavigation.registerMouseEvents(this, this.boxesToDraw);
        }
        if (this.allowEcgNavigationScroll) {
          EcgNavigation.registerScrollEvent(this, this.boxesToDraw);
        }

        // Set the sequence lead on generated reports
        if (!this.allowEcgNavigationScroll && this.navBoxes.length === 1 && this.config.type === "sequence") {
          this.config.displayElements.leads.I = false;
          this.config.displayElements.leads.II = false;
          this.config.displayElements.leads.III = false;
          this.config.displayElements.leads[this.navBoxes[0].sequenceLead] = true;
        }

        const deregisterNavScroll = $rootScope.$on(
          "ecg-navigation-scroll-updated",
          (emittedEvent, allowEcgNavigation) => {
            if (this.config.type === "full") {
              if (allowEcgNavigation) {
                this.navigationCanvas.classList.add("grabWithActive");
              } else {
                this.navigationCanvas.classList.remove("grabWithActive");
              }
            }
            if (this.config.type === "strip") {
              if (allowEcgNavigation) {
                this.markersCanvas.classList.add("crosshair");
              } else {
                this.markersCanvas.classList.remove("crosshair");
              }
            }

            this.inEditingMode = allowEcgNavigation;
            if (allowEcgNavigation && (this.config.type === "sequence" || this.config.type === "full")) {
              EcgNavigation.registerMouseEvents(this, this.boxesToDraw);
            } else if (
              !allowEcgNavigation &&
              (this.config.type === "sequence" || this.config.type === "full")
            ) {
              EcgNavigation.deregisterMouseEvents(this);
            }
            if (allowEcgNavigation) {
              EcgNavigation.registerScrollEvent(this, this.boxesToDraw);
            } else {
              EcgNavigation.deregisterScrollEvent(this);
            }
          }
        );
        scope.$on("$destroy", deregisterNavScroll);
        scope.$on("$destroy", () => {
          EcgNavigation.deregisterMouseEvents(this);
        });

        const deregisterSelectStrip = $rootScope.$on("select-strip", () => {
          const selectedBox = this.navBoxes.find((navBox) => navBox.isSelected);

          if (this.config.type === "full") {
            const canDrag = this.allowEcgNavigationScroll && !selectedBox.id;
            if (canDrag) {
              this.navigationCanvas.classList.add("grabWithActive");
            } else {
              this.navigationCanvas.classList.remove("grabWithActive");
            }
          }

          if (this.config.type === "sequence") {
            // Center the view on the selected strip
            this.setBoundaries(selectedBox.middleSample);
            $rootScope.$emit("viewer-settings-updated");
          }
        });
        scope.$on("$destroy", deregisterSelectStrip);

        const deregisterViewerSettingsUpdated = $rootScope.$on("viewer-settings-updated", () => {
          const selectedBox = this.navBoxes.find((navBox) => navBox.isSelected);

          if (this.config.type === "strip") {
            const {middleSample} = selectedBox;
            this.setBoundaries(middleSample);
            this.drawGrid();
            this.drawLeads();
            this.drawMarkers();
            $rootScope.$emit("strip-viewer-updated");
          }
          if (this.config.type === "sequence") {
            // Set the Displayed Lead
            if (selectedBox.sequenceLead !== undefined) {
              // Hide all of the leads and display one of them
              this.config.displayElements.leads.I = false;
              this.config.displayElements.leads.II = false;
              this.config.displayElements.leads.III = false;
              this.config.displayElements.leads[selectedBox.sequenceLead] = true;
            } else {
              // This is used to support strips that do not have a sequence lead value
              this.config.displayElements.leads.I = false;
              this.config.displayElements.leads.II = true;
              this.config.displayElements.leads.III = false;
            }

            this.drawLeads();
            this.drawMarkers();
            EcgNavigation.drawNavigationBoxes(this, this.boxesToDraw);

            // Update the NavBox on the Full Viewer
            const fullViewer = EcgNavigation.getFullViewer(this.ecgData.enrollmentId, this.ecgData.sequence);
            const middleX = EcgNavigation.getXCoordFromSample(
              fullViewer,
              (this.startSample + this.endSample) / 2
            );
            EcgNavigation.drawNavigationBox(fullViewer, middleX, this.secondsToDisplay);
          }
        });
        scope.$on("$destroy", deregisterViewerSettingsUpdated);

        const deregisterViewerRedraw = $rootScope.$on("window-resize", () => {
          this.setDimensionsAndDraw();
        });
        scope.$on("$destroy", deregisterViewerRedraw);

        this.setDimensionsAndDraw();
      }

      setDimensionsAndDraw() {
        this.setWidth();
        this.setHeight();

        this.setDpi(this.dpi);

        this.drawElements();
      }

      get dpi() {
        let dpi = 300;
        if (this.parentType !== "reportStripSegment") {
          // Scale DPI based on browser zoom level
          dpi = 96 * $window.devicePixelRatio;
        }
        return dpi;
      }

      get pixelsPerMillimeter() {
        return this.width / this.mmHorizontal;
      }

      get mmHorizontal() {
        return this.config.stripLength * 25;
      }

      get mmVerticalStrip() {
        return this.mmHorizontal / this.STRIP_ASPECT_RATIO;
      }

      get pixelsPerSecond() {
        return this.width / this.secondsToDisplay;
      }

      get secondsToDisplay() {
        // Total seconds
        let seconds = this.ecgData.leads[0].totalSamples / this.ecgData.samplesPerSecond;

        if (this.config.type === "strip") {
          seconds = this.mmHorizontal / this.ecgData.mmPerSecond;
        }

        if (this.config.type === "sequence" && seconds > this.config.maxSeconds) {
          seconds = this.config.maxSeconds;
        }

        return seconds;
      }

      get boxesToDraw() {
        this.navBoxes.forEach((navBox) => {
          navBox.middleX = this.getMiddleX(navBox.middleSample);
          navBox.secondsWide = this.config.getStripLength(navBox);
        });
        return this.navBoxes;
      }

      setDpi(dpi) {
        const containerWidth = this.htmlContainerElement.offsetWidth;
        this.canvases = this.canvases.map((canvas) => {
          // calculate scale factor based on the container width
          // (96 is the default DPI for canvas elements)
          const xScaleFactor = (dpi / 96) * (containerWidth / canvas.width);
          const yScaleFactor = dpi / 96;

          // Set up CSS size.
          canvas.style.width = `${containerWidth}px`;
          canvas.style.height = `${canvas.height}px`;

          // Resize canvas and scale future draws.
          canvas.width = Math.ceil(canvas.width * xScaleFactor);
          canvas.height = Math.ceil(canvas.height * yScaleFactor);

          // Apply the scale
          const ctx = canvas.getContext("2d");
          ctx.scale(xScaleFactor, yScaleFactor);

          return canvas;
        });
      }

      /**
       * Sets width of container and all child canvases
       */
      setWidth() {
        this.width = this.htmlContainerElement.offsetWidth;
        this.canvases.forEach((canvas) => {
          canvas.width = this.width;
        });
      }

      setHeight() {
        if (this.config.type === "strip") {
          const containerWidth = this.htmlContainerElement.offsetWidth;
          this.height = Math.round(containerWidth / this.STRIP_ASPECT_RATIO);
        }
        if (this.config.type === "sequence") {
          this.height = 37;
        }
        if (this.config.type === "full") {
          this.height = 20;
        }

        this.htmlContainerElement.style.height = `${this.height}px`;
        this.canvases.forEach((canvas) => {
          canvas.style.height = `${this.height}px`;
          canvas.height = this.height;
        });
      }

      /**
       * Calls the draw functions on all applicable elements of ECG.
       */
      drawElements() {
        this.drawGrid();

        if (this.ecgData !== undefined && this.config.type !== "full") {
          this.drawLeads();
        }

        if (this.config.type === "strip") {
          this.drawMarkers();
        }

        if (this.config.type === "sequence") {
          this.drawMarkers();
          EcgNavigation.drawNavigationBoxes(this, this.boxesToDraw);
        }

        if (this.config.type === "full") {
          const sequenceViewer = EcgNavigation.getSequenceViewer(
            this.ecgData.enrollmentId,
            this.ecgData.sequence
          );
          const middleX = EcgNavigation.getXCoordFromSample(
            this,
            (sequenceViewer.startSample + sequenceViewer.endSample) / 2
          );

          EcgNavigation.drawNavigationBox(this, middleX, sequenceViewer.secondsToDisplay);
          Timeline.drawTimeline(this);
        }
      }

      getMiddleX(eventSample) {
        const eventSampleCount = eventSample - this.startSample;
        const secondCount = eventSampleCount / this.ecgData.samplesPerSecond;
        const pixelCount = secondCount * this.pixelsPerSecond;
        return pixelCount;
      }

      drawMarkers() {
        const excludedMarkerTypes = new Set();

        // Beat Markers are not displayed in the Strip Viewer
        if (this.parentType === "stripViewer") {
          excludedMarkerTypes.add("beatMarkers");
        }

        // Beat Markers and Measurements are not displayed on reports
        if (this.parentType === "reportStripSegment") {
          excludedMarkerTypes.add("beatMarkers");
          excludedMarkerTypes.add("measurements");
        }

        // Only Strip Canvas can display beat markers and measurements
        if (this.config.type !== "strip") {
          excludedMarkerTypes.add("beatMarkers");
          excludedMarkerTypes.add("measurements");
        }

        Markers.drawMarkers(this, Array.from(excludedMarkerTypes));
      }

      drawLeads() {
        if (this.config.type === "full") {
          return;
        }
        const isGeneratedReport = this.parentType === "reportStripSegment";
        Leads.drawLeads(
          this.config.type,
          this.config.displayElements.leads,
          this.config.inversionStatus.leads,
          this.leadsCanvas,
          this.ecgData,
          this.pixelsPerSecond,
          this.startSample,
          this.endSample,
          this.width,
          isGeneratedReport
        );
      }

      drawGrid() {
        switch (this.config.type) {
          case "strip":
            GridLines.clearCanvas(this.gridLinesCanvas);
            GridLines.drawMinorGridLines(
              this.gridLinesCanvas,
              this.mmHorizontal,
              this.mmVerticalStrip,
              this.pixelsPerMillimeter
            );
            GridLines.drawMajorGridLines(
              this.gridLinesCanvas,
              this.mmHorizontal,
              this.mmVerticalStrip,
              this.pixelsPerMillimeter
            );
            GridLines.drawTimeMarkerGridLines(
              this.gridLinesCanvas,
              this.mmHorizontal,
              this.pixelsPerMillimeter
            );
            break;
          default:
          // do nothing
        }
      }

      /**
       * Creates a canvas
       *
       * @param      {string}      name       Name for canvas
       * @param      {string}      viewerType the type of viewer that the canvas is being created for
       * @return     {HTMLElement}         The canvas that was created
       */
      createCanvas(name, viewerType) {
        const canvas = $document[0].createElement("canvas");
        canvas.setAttribute("class", name);
        canvas.setAttribute("id", `${viewerType}-${name}-canvas-${this.parentType}`);
        angular.element(this.htmlContainerElement).append(canvas);
        canvas.style.position = "absolute";
        this.canvases.push(canvas);

        // Disable the right-click context menu
        canvas.oncontextmenu = (event) => {
          event.preventDefault();
          event.stopPropagation();
          return false;
        };

        return canvas;
      }

      /**
       * Sets the start and end sample of the section of ECG to display
       * Based on number of seconds to display and pixels per second
       *
       * @param      {number}  midpoint
       * @private
       */
      setBoundaries(midpoint) {
        let startSample;
        let endSample;

        const {samplesPerSecond} = this.ecgData;

        const samplesToDisplay = this.secondsToDisplay * samplesPerSecond;

        startSample = Math.floor(midpoint - samplesToDisplay / 2);
        endSample = Math.floor(midpoint + samplesToDisplay / 2);

        if (endSample >= this.ecgData.leads[0].totalSamples) {
          endSample = this.ecgData.leads[0].totalSamples - 1;
          startSample = endSample + 1 - samplesToDisplay;
        }

        if (startSample < 0) {
          startSample = 0;
          endSample = startSample + samplesToDisplay - 1;
        }

        this.startSample = startSample;
        this.endSample = endSample;

        if (this.config.type === "strip") {
          this.ecgData.startStripSample = startSample;
          this.ecgData.endStripSample = endSample;
        }
      }
    }

    // We probably shouldn't be doing a factory like this, but I don't want to deal with changing it right now...
    // eslint-disable-next-line no-constructor-return
    return EcgViewer;
  }

  // Required to get angular to work with this service as an es6 class
  static get $$ngIsClass() {
    return true;
  }
}
