import React from "react";
import {DateTime} from "luxon";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

function Header({
  // Props
  report,
  logo,
  item,
  study,
}) {
  const title = React.useMemo(() => {
    if (report.reportType === "Daily Trend") {
      const {days: daysSinceStudyStart} = DateTime.fromISO(item?.timestamp)
        .diff(DateTime.fromISO(study.studyStartDate), "days")
        .toObject();

      if (!Number.isNaN(daysSinceStudyStart)) {
        return `Daily Report - Day ${Math.round(daysSinceStudyStart)}`;
      }
      return "Daily Report";
    }

    return `${report.reportType} Report`;
  }, [item?.timestamp, report.reportType, study.studyStartDate]);

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-end" mb={1}>
        <Box>
          <Typography variant="reportText" color="tertiary.light">
            <b>Patient</b>: {report.studyDetails?.patientName}
          </Typography>
          <Typography variant="reportText" color="tertiary.light">
            <b>Patient ID</b>: {report.studyDetails?.patientId}
          </Typography>
          <Typography variant="reportText" color="tertiary.light" data-cy="device-serial-header">
            <b>Device</b>: {report.tzSerial}
          </Typography>
        </Box>

        <Box>
          <Typography variant="h5" align="center" color="primary.dark" data-cy="report-title">
            {title}
          </Typography>
          <Typography align="center" variant="reportText" color="tertiary.light" data-cy="study-id-header">
            <b>Study ID</b>: {study.id}
          </Typography>
        </Box>

        <Box>
          <img
            alt={`${report.facilityName} Logo`}
            crossOrigin="anonymous"
            src={`data:image/${logo.extension};base64,${logo.src}`}
            height={50}
            data-cy={`logo-${logo.filename}`}
          />
        </Box>
      </Stack>

      <Divider sx={{borderBottomWidth: "3px", borderColor: "primary.main"}} />
    </Box>
  );
}

Header.propTypes = {
  report: PropTypes.object.isRequired,
  logo: PropTypes.object.isRequired,
  item: PropTypes.object,
  study: PropTypes.object.isRequired,
};

export default React.memo(Header);
