import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import {useStudiesStatusFilter} from "../../contexts/StudiesContext.jsx";
import Filter from "../../shared/react/Filter.jsx";
import StudyStatusChip from "./StudyStatusChip.jsx";

function StudyStatusFilter({
  // Props
  setFilterLoading,
}) {
  // Status filter
  const {statusFilter, setStatusFilter} = useStudiesStatusFilter();

  //---------------------------------------------------------------------------
  // Statuses with corresponding chips
  //---------------------------------------------------------------------------
  const statuses = React.useMemo(
    () =>
      ["pending", "recording", "done", "finalized", "archived", "failed"].map((status) => ({
        id: status,
        label: <StudyStatusChip status={status} />,
      })),
    []
  );

  //---------------------------------------------------------------------------
  // Submit
  //---------------------------------------------------------------------------
  const onSubmit = React.useCallback(
    async (updatedStatuses) => {
      setStatusFilter(updatedStatuses);
      setFilterLoading(true);
    },
    [setStatusFilter, setFilterLoading]
  );

  return (
    <Filter
      data-cy="status"
      title="Filter Status"
      options={statuses}
      optionsFilter={statusFilter}
      onSubmit={onSubmit}
    />
  );
}

StudyStatusFilter.propTypes = {
  setFilterLoading: PropTypes.func.isRequired,
};

export default React.memo(StudyStatusFilter);
