import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useInterval} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";

// Unless we can get socket.io or long polling working, fetching the data
// every 15 seconds should keep things from getting "stale"
const DATA_REFRESH_INTERVAL_MS = 15000;

function SoftwareVersion({
  // Props
  setError,
}) {
  //---------------------------------------------------------------------------
  // Load data from the API
  //
  // Software Version
  //---------------------------------------------------------------------------
  const [softwareVersion, setSoftwareVersion] = React.useState(null);
  const getSoftwareVersion = React.useCallback(async () => {
    try {
      const {data: softwareVersionResponse} = await axios({
        method: "get",
        url: "/configuration/softwareVersion",
      });

      setSoftwareVersion(softwareVersionResponse);
    } catch (err) {
      setError(`Error fetching software version: ${err.message}`);
    }
  }, [setSoftwareVersion, setError]);

  useInterval(getSoftwareVersion, DATA_REFRESH_INTERVAL_MS, true);

  //---------------------------------------------------------------------------
  // Display a loading spinner if we're still waiting on the API
  //---------------------------------------------------------------------------
  if (!softwareVersion) {
    return (
      <Typography data-cy="inbox-software-version" variant="body2" sx={{padding: "14px"}}>
        Software Version: <CircularProgress color="secondary" size={15} thickness={7.5} />
      </Typography>
    );
  }

  return (
    <Typography data-cy="inbox-software-version" variant="body2" sx={{padding: "14px"}}>
      Software Version: {softwareVersion}
    </Typography>
  );
}
SoftwareVersion.propTypes = {
  setError: PropTypes.func.isRequired,
};

export default SoftwareVersion;
