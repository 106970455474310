import React from "react";
import {FormProvider, useForm, useFormState} from "react-hook-form";
import SwipeableViews from "react-swipeable-views";
import cloneDeep from "lodash/cloneDeep";
import {useConfirm} from "material-ui-confirm";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import AssignmentInd from "@mui/icons-material/AssignmentInd";
import Close from "@mui/icons-material/Close";
import Edit from "@mui/icons-material/Edit";
import Launch from "@mui/icons-material/Launch";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Tab from "@mui/material/Tab";
import Tooltip from "@mui/material/Tooltip";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../../axiosClient.js";
import useJwt from "../../../components/hooks/useJwt.jsx";
import {useStudiesDispatch} from "../../../contexts/StudiesContext.jsx";
import Alert from "../../../shared/react/Alert.jsx";
import CancelButton from "../../../shared/react/CancelButton.jsx";
import IconWithText from "../../../shared/react/IconWithText.jsx";
import TableLoading from "../../../shared/react/TableLoading.jsx";
import confirmSubscriberInfo from "../confirmSubscriberInfo.jsx";
import EditableStudyInfo from "../EditableStudyInfo.jsx";
import handleAddressSubmit from "../handleAddressSubmit.jsx";
import InsuranceInfo from "../InsuranceInfo.jsx";
import PatientInfo from "../PatientInfo.jsx";
import PhysicianInfo from "../PhysicianInfo.jsx";

function StudyDetailsDialog({
  // Props
  study,
  studyId,
  studyFacilityId,
  readOnly = false,
}) {
  //---------------------------------------------------------------------------
  // Dialog title
  //---------------------------------------------------------------------------
  const dialogTitle = readOnly ? "View Study Details" : "Update Study Details";
  const initialTabIndex = React.useMemo(() => (readOnly ? "3" : "1"), [readOnly]);

  //---------------------------------------------------------------------------
  // Update button state management
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);

  //---------------------------------------------------------------------------
  // Active tab management
  //---------------------------------------------------------------------------
  const [activeTabIndex, setActiveTabIndex] = React.useState(initialTabIndex);
  // TabContext requires 'value' to be of type string
  const handleChange = React.useCallback((event, newTab) => {
    setActiveTabIndex(newTab);
  }, []);
  // SwipeableViews requires 'index' == 'value' to be of type Number
  const handleChangeIndex = React.useCallback((index) => {
    setActiveTabIndex(`${index}`);
  }, []);

  //---------------------------------------------------------------------------
  // Modal state management
  //---------------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);
  const handleOpen = React.useCallback(() => {
    setOpen(true);
    setLoading(true);
  }, []);
  const handleClose = React.useCallback(
    (event, reason) => {
      if (reason === "backdropClick") {
        return;
      }
      setActiveTabIndex(initialTabIndex);
      setOpen(false);
    },
    [initialTabIndex]
  );

  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  //---------------------------------------------------------------------------
  // Set up hook for confirmation dialogs
  //---------------------------------------------------------------------------
  const confirm = useConfirm();

  //---------------------------------------------------------------------------
  // Load data from the API
  //---------------------------------------------------------------------------
  const [insuranceTypes, setInsuranceTypes] = React.useState(null);
  const [requiredFields, setRequiredFields] = React.useState({});

  const getStudy = React.useCallback(async () => {
    try {
      if (!readOnly) {
        const [{data: insuranceTypesResponse}, {data: studyConfigurationsResponse}] = await Promise.all([
          axios({
            method: "get",
            url: `/facilities/${studyFacilityId}/insuranceTypes`,
          }),
          axios({
            method: "get",
            url: "/facilityStudyConfigurations",
            params: {facilityId: studyFacilityId},
          }),
        ]);

        setInsuranceTypes(insuranceTypesResponse);

        setRequiredFields(studyConfigurationsResponse[0].requiredStudyFields);
      }
    } catch (err) {
      setError(err.message);
    }
  }, [readOnly, studyFacilityId]);

  React.useEffect(() => {
    if (loading) {
      getStudy().then(() => setLoading(false));
    }
  }, [getStudy, loading]);

  //---------------------------------------------------------------------------
  // Delegate Study Access
  //---------------------------------------------------------------------------
  const {userFacilityId} = useJwt();
  const disableEditButton = React.useMemo(() => {
    return !studyFacilityId.startsWith(userFacilityId);
  }, [studyFacilityId, userFacilityId]);

  //---------------------------------------------------------------------------
  // Form Submission
  //---------------------------------------------------------------------------
  const methods = useForm();
  const {isDirty, dirtyFields} = useFormState({control: methods.control});
  const watchAllFields = methods.watch();
  const dispatch = useStudiesDispatch();

  const onSubmit = React.useCallback(
    async (data) => {
      const insurancesToRemove = [];

      // if true this means we removed an insurance from this study
      if (study.insurance.length > data.insurance.length) {
        for (let i = data.insurance.length; i < study.insurance.length; i++) {
          insurancesToRemove.push(i + 1);
        }
      }

      //---------------------------------------------------------------------------
      // Format the submitted data
      //---------------------------------------------------------------------------
      // We only want to send through the values that have been updated
      let updatedFields = cloneDeep(data);

      // Convert date field to a string
      updatedFields.patientDob = updatedFields.patientDob?.toFormat("yyyy/MM/dd") || null;

      // Format physician name and NPI number from their autocomplete values, if applicable
      if (data.physicianName) {
        // If freeSolo was used, set the name to the string
        // Otherwise, since Autocomplete sets the value to the entire option object, parse out 'physicianName' from the option object
        updatedFields.physicianName =
          typeof data.physicianName === "string" ? data.physicianName : data.physicianName?.name;
      } else {
        updatedFields.physicianName = "";
      }
      if (data.physicianNpiNumber) {
        updatedFields.physicianNpiNumber =
          typeof data.physicianNpiNumber === "string"
            ? data.physicianNpiNumber
            : data.physicianNpiNumber?.npi;
      } else {
        updatedFields.physicianNpiNumber = "";
      }

      // Format time zone from its autocomplete value, if applicable
      if (data.timeZone) {
        updatedFields.timeZone = data.timeZone.id;
      }

      Object.entries(updatedFields).forEach(([prop, value]) => {
        if (prop === "insurance") {
          value.forEach((insurance, index) => {
            insurance.subscriberDob = insurance.subscriberDob?.toFormat("yyyy/MM/dd") || "";

            if (insurance.insuranceType === "") {
              insurance.insuranceType = null;
            }

            // if the user clears this free textfield/dropdown, it will default to null
            // we must ensure the value is of type string for schema validation
            if (insurance.relationToPatient === null) {
              insurance.relationToPatient = "";
            }

            if (study.insurance[index]) {
              Object.entries(insurance).forEach(([insuranceProp, insuranceValue]) => {
                if (insuranceValue === study.insurance[index][insuranceProp]) {
                  delete updatedFields.insurance[index][insuranceProp];
                }
              });
            }
          });
        } else if (value === study.studyDetails[prop]) {
          delete updatedFields[prop];
        }
      });

      // Attach the priority to each insurance policy
      updatedFields.insurance.forEach((insurance, i) => {
        insurance.priority = i + 1;
      });

      //---------------------------------------------------------------------------
      // Verify addresses confirmation
      //---------------------------------------------------------------------------
      try {
        updatedFields = await handleAddressSubmit(
          confirm,
          updatedFields,
          methods.getValues,
          methods.setValue
        );
      } catch (err) {
        // If the user cancels the address confirmation, stop the submission
        return;
      }

      //---------------------------------------------------------------------------
      // Confirm Subscriber Info
      //---------------------------------------------------------------------------
      try {
        await confirmSubscriberInfo(confirm, watchAllFields);
      } catch (err) {
        // If confirmation was cancelled, stop the submission
        if (err) {
          setError(err);
        }
        return;
      }

      // Disable the submit button and display the spinner
      setSubmitting(true);

      if (updatedFields.insurance.length < 1) {
        delete updatedFields.insurance;
      }

      //---------------------------------------------------------------------------
      // Finally, update the study
      //---------------------------------------------------------------------------

      try {
        await Promise.all([
          ...insurancesToRemove.map((priority) =>
            axios({
              method: "delete",
              url: `/studies/${studyId}/insurance/${priority}`,
            })
          ),
          axios({
            method: "patch",
            url: `/studies/${studyId}`,
            data: updatedFields,
            headers: {"Content-Type": "application/json"},
          }),
        ]);

        const {
          data: [updatedStudy],
        } = await axios({
          method: "get",
          url: "/studies",
          params: {id: studyId},
        });
        dispatch({type: "updated", updatedElement: updatedStudy});

        // Finally, close the modal
        setOpen(false);
      } catch (err) {
        setError(err.message);
      }

      setSubmitting(false);
    },
    [study, confirm, methods.getValues, methods.setValue, watchAllFields, studyId, dispatch]
  );

  //---------------------------------------------------------------------------
  // Handle Patient Detail Change
  //---------------------------------------------------------------------------
  const updateSubscriberDetails = React.useCallback(
    (insuranceIndex) => {
      let detailsUpdated = false;
      const fieldsToCheck = [
        ["patientName", "subscriberName"],
        ["patientDob", "subscriberDob"],
        ["patientGender", "subscriberSex"],
        ["patientAddress", "subscriberAddress"],
      ];

      // For each field, update the subscriber info if it differs from patient info
      fieldsToCheck.forEach(([patientField, subscriberField]) => {
        const patientValue = methods.getValues(patientField);
        const subscriberValue = methods.getValues(`insurance[${insuranceIndex}].${subscriberField}`);

        const datesAreEqual =
          patientValue?.isLuxonDateTime &&
          subscriberValue?.isLuxonDateTime &&
          patientValue.equals(subscriberValue);

        if (subscriberValue !== patientValue && !datesAreEqual) {
          methods.setValue(`insurance[${insuranceIndex}].${subscriberField}`, patientValue);
          detailsUpdated = true;
        }
      });

      if (detailsUpdated) {
        setMessage("Subscriber details have been updated to match patient information");
      }
    },
    [methods]
  );

  // Watch for changes to relationToPatient selection to trigger an update
  const watchRelationSelection1 = methods.watch("insurance[0].relationToPatient") || "";
  const watchRelationSelection2 = methods.watch("insurance[1].relationToPatient") || "";
  const watchRelationSelection3 = methods.watch("insurance[2].relationToPatient") || "";

  React.useEffect(() => {
    if (
      dirtyFields?.insurance &&
      dirtyFields?.insurance[0]?.relationToPatient &&
      watchRelationSelection1 === "Self"
    ) {
      updateSubscriberDetails(0);
    }
  }, [dirtyFields?.insurance, updateSubscriberDetails, watchRelationSelection1]);

  React.useEffect(() => {
    if (
      dirtyFields?.insurance &&
      dirtyFields?.insurance[1]?.relationToPatient &&
      watchRelationSelection2 === "Self"
    ) {
      updateSubscriberDetails(1);
    }
  }, [dirtyFields?.insurance, updateSubscriberDetails, watchRelationSelection2]);

  React.useEffect(() => {
    if (
      dirtyFields?.insurance &&
      dirtyFields?.insurance[2]?.relationToPatient &&
      watchRelationSelection3 === "Self"
    ) {
      updateSubscriberDetails(2);
    }
  }, [dirtyFields?.insurance, updateSubscriberDetails, watchRelationSelection3]);

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <>
      {!readOnly && (
        <Tooltip
          title={
            disableEditButton
              ? "This study belongs to a facility that has provided delegate access, and cannot be edited. Contact the owner of the study to request changes."
              : ""
          }
          placement="left"
        >
          <MenuItem onClick={handleOpen} data-cy={`${studyId}-update-study-button`}>
            <ListItemIcon>
              <Edit fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit Study</ListItemText>
          </MenuItem>
        </Tooltip>
      )}
      {readOnly && (
        <Tooltip title="View Study Details">
          <IconButton
            sx={{p: 0}}
            size="small"
            color="secondary"
            data-cy="view-study-details-button"
            onClick={handleOpen}
          >
            <Launch />
          </IconButton>
        </Tooltip>
      )}

      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        data-cy="study-details-dialog"
        PaperProps={{
          component: "form",
          onSubmit: methods.handleSubmit(onSubmit),
          noValidate: true,
        }}
      >
        <Alert message={error} setMessage={setError} level="error" />

        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <IconWithText icon={<AssignmentInd color="secondary" />} text={dialogTitle} />
          {readOnly && (
            <IconButton data-cy="close-button" size="small" onClick={handleClose}>
              <Close />
            </IconButton>
          )}
        </DialogTitle>

        <Divider sx={{bgcolor: "secondary.main"}} />

        <DialogContent sx={{py: 1}}>
          {loading && <TableLoading />}

          {!loading && !readOnly && !!study.followUpStudyAssociation && (
            <Alert
              level="info"
              message={`Updates will be propagated to follow-up study ID ${study.followUpStudyAssociation?.id}`}
            />
          )}
          {!loading && !readOnly && !!study.initialStudyAssociation && (
            <Alert
              level="info"
              message={`Updates will be propagated to initial wireless Holter study ID ${study.initialStudyAssociation?.id}`}
            />
          )}

          {!loading && (
            <TabContext value={activeTabIndex}>
              <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                <TabList
                  variant="fullWidth"
                  textColor="secondary"
                  indicatorColor="secondary"
                  onChange={handleChange}
                >
                  {!readOnly && <Tab label="Study Info" value="0" data-cy="study-info-tab" />}
                  <Tab label="Patient Info" value="1" data-cy="patient-info-tab" />
                  <Tab label="Ordering Physician Info" value="2" data-cy="physician-info-tab" />
                  <Tab label="Insurance Info" value="3" data-cy="insurance-info-tab" />
                </TabList>
              </Box>

              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <FormProvider {...methods}>
                <SwipeableViews
                  index={Number(activeTabIndex)}
                  onChangeIndex={handleChangeIndex}
                  animateHeight
                >
                  <TabPanel value={activeTabIndex} index={0} sx={{pb: 6}}>
                    <Grid container columnSpacing={4} rowSpacing={2}>
                      <EditableStudyInfo study={study} requiredFields={requiredFields} />
                    </Grid>
                  </TabPanel>

                  <TabPanel value={activeTabIndex} index={1} sx={{...(!readOnly && {pb: 6})}}>
                    <PatientInfo readOnly={readOnly} study={study} requiredFields={requiredFields} />
                  </TabPanel>

                  <TabPanel value={activeTabIndex} index={2} sx={{...(!readOnly && {pb: 4})}}>
                    <PhysicianInfo readOnly={readOnly} study={study} requiredFields={requiredFields} />
                  </TabPanel>

                  <TabPanel value={activeTabIndex} index={3} sx={{...(!readOnly && {pb: 18})}}>
                    <Alert message={message} setMessage={setMessage} level="info" />

                    <InsuranceInfo
                      insuranceTypes={insuranceTypes}
                      readOnly={readOnly}
                      study={study}
                      requiredFields={requiredFields}
                    />
                  </TabPanel>
                </SwipeableViews>
              </FormProvider>
            </TabContext>
          )}
        </DialogContent>

        {!readOnly && (
          <DialogActions>
            <Box sx={{m: 2}}>
              <CancelButton color="secondary" isDirty={isDirty} onClick={handleClose} id="cancel-button">
                Cancel
              </CancelButton>
            </Box>
            <Box sx={{m: 2}}>
              <LoadingButton
                data-cy="submit-button"
                disabled={loading || submitting}
                variant="contained"
                color="secondary"
                loading={submitting}
                type="submit"
              >
                Update
              </LoadingButton>
            </Box>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
}

StudyDetailsDialog.propTypes = {
  study: PropTypes.object,
  studyId: PropTypes.number.isRequired,
  studyFacilityId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

export default StudyDetailsDialog;
