import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid2";

function TableLoading({
  // Props
  margin = true,
}) {
  return (
    <Grid container>
      <Grid sx={{...(margin && {mt: 10})}} size={12} align="center">
        <CircularProgress color="secondary" size={100} data-cy="loading-circle" />
      </Grid>
    </Grid>
  );
}

TableLoading.propTypes = {
  margin: PropTypes.bool,
};

export default TableLoading;
