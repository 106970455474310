/* eslint-disable max-classes-per-file */
/* eslint-env browser */
import {DateTime} from "luxon";

class EcgEvent {
  constructor($injector, ecgEvent, study) {
    this._$http = $injector.get("$http");
    this._Authentication = $injector.get("Authentication");
    this._backendConfig = $injector.get("backendConfig");
    this._Session = $injector.get("Session");
    this.features = $injector.get("Config").features;
    Object.assign(this, ecgEvent);

    if (!this.tzSerial) {
      this.studyId = study.id;
      this.studyStartDate = study.studyStartDate;
      this.studyEndDate = study.studyEndDate;
      this.studyIndication = study.studyIndication;
      this.studyNotes = study.studyNotes;
      this.studyState = study.studyState;
      this.studyType = study.studyType;
    }

    // Valid timestamps are 2010 or later
    this.validTimestamp = DateTime.fromISO(this.timestamp).year >= 2010;
  }

  get locked() {
    // Item is locked if there is a lockedAt timestamp
    let locked = !!this.lockedAt;
    // If the item is locked by you, display it as unlocked.
    if (
      locked &&
      this.lockedBy === this._Authentication.getUserId() &&
      (this.sessionId === null || this.sessionId === this._Session.sessionId)
    ) {
      locked = false;
    }
    return locked;
  }

  get assignedToOtherUser() {
    if (!this.features.itemAssignments || this.assignedUsers?.length === 0) {
      return false;
    }

    const currentUserId = this._Authentication.getUserId();
    const currentUserIsAssigned = this.assignedUserIds.includes(currentUserId);

    return !currentUserIsAssigned;
  }

  get isUnopenable() {
    return this.locked || this.assignedToOtherUser;
  }

  get assignedUserIds() {
    return this.assignedUsers?.map((user) => user.userId) || [];
  }

  get assignedUserNames() {
    return this.assignedUsers?.map((user) => user.userFullName) || [];
  }

  /*
   * @see SRS: BR-1554
   */
  async _lockEvent(eventTypeName) {
    const {sessionId} = this._Session;
    const {data: lockedItem} = await this._httpGet(
      `/events/${eventTypeName}/lock/${this.id}?sessionId=${sessionId}`
    );

    return Object.assign(this, lockedItem);
  }

  /*
   * @see SRS: BR-1554
   */
  _unlockEvent(eventTypeName = true) {
    const userId = this._Authentication.getUserId();
    const {sessionId} = this._Session;
    const urlWithQuery = `/events/${eventTypeName}/lock/${this.id}?userId=${userId}&sessionId=${sessionId}`;
    return this._httpDeleteWithRetries(urlWithQuery);
  }

  /**
   * Re-attempts PATCH request with exponential backoff.
   *
   * Default behavior:
   *
   * Attempt 1 fails
   *    <Delay: 500ms>
   * Attempt 2 fails
   *    <Delay: 1000ms>
   * Attempt 3 fails
   *    <Delay: 2000ms>
   * Attempt 4 fails
   *    <Delay: 4000ms>
   * Attempt 5 fails
   * Then an error is thrown
   *
   * @param {String} url
   * @param {Any} data
   * @param {Number} retriesRemaining
   * @param {Number} delayMs
   * @returns {Promise<Object>}
   */
  async httpPatchWithRetries(url, data, retriesRemaining = 4, delayMs = 500) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;

    let result;
    try {
      result = await this._$http.patch(`${baseUrl}${url}`, data, {
        headers: {
          Authorization: authHeader,
        },
      });
    } catch (err) {
      if (retriesRemaining <= 0) {
        // If we're out of retries, just rethrow the error.
        throw err;
      }
      if (!(Number.isInteger(err.status) && err.status >= 500)) {
        // If the error is not a 500, just rethrow the error.
        throw err;
      }

      // Wait a short delay
      await new Promise((r) => {
        setTimeout(r, delayMs);
      });

      // Retry
      return this.httpPatchWithRetries(url, retriesRemaining - 1, delayMs * 2);
    }

    return result;
  }

  _httpGet(url, params = {}) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.get(`${baseUrl}${url}`, {
      headers: {
        Authorization: authHeader,
      },
      params,
    });
  }

  /**
   * Re-attempts DELETE request with exponential backoff.
   *
   * Default behavior:
   *
   * Attempt 1 fails
   *    <Delay: 500ms>
   * Attempt 2 fails
   *    <Delay: 1000ms>
   * Attempt 3 fails
   *    <Delay: 2000ms>
   * Attempt 4 fails
   *    <Delay: 4000ms>
   * Attempt 5 fails
   * Then an error is thrown
   *
   * @param {String} url
   * @param {Number} retriesRemaining
   * @param {Number} delayMs
   * @returns {Promise<Object>}
   */
  async _httpDeleteWithRetries(url, retriesRemaining = 4, delayMs = 500) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;

    let result;
    try {
      result = await this._$http.delete(`${baseUrl}${url}`, {
        headers: {
          Authorization: authHeader,
        },
      });
    } catch (err) {
      if (retriesRemaining <= 0) {
        // If we're out of retries, just rethrow the error.
        throw err;
      }
      if (!(Number.isInteger(err.status) && err.status >= 500)) {
        // If the error is not a 500, just rethrow the error.
        throw err;
      }

      // Wait a short delay
      await new Promise((r) => {
        setTimeout(r, delayMs);
      });

      // Retry
      return this._httpDeleteWithRetries(url, retriesRemaining - 1, delayMs * 2);
    }

    return result;
  }
}

/*
 * @see SRS: BR-17
 */
class RhythmChangeEvent extends EcgEvent {
  constructor($injector, rhythmChangeEvent, study) {
    super($injector, rhythmChangeEvent, study);
    if (this.rhythm) {
      this.type = this.rhythm;
    }
  }

  /*
   * @see SRS: BR-27
   */
  complete() {
    return this.httpPatchWithRetries(`/events/rhythmChange/${this.id}`, {completed: true}).then(
      (response) => {
        if (response.status === 200) {
          this.completed = true;
        } else {
          console.error("Event could not be completed");
        }
      }
    );
  }

  /*
   * @see SRS: BR-1554
   */
  lock() {
    return this._lockEvent("rhythmChange");
  }

  /*
   * @see SRS: BR-1554
   */
  unlock() {
    return this._unlockEvent("rhythmChange");
  }
}

class RateChangeEvent extends EcgEvent {
  constructor($injector, rateChangeEvent, study) {
    super($injector, rateChangeEvent, study);
    if (this.rhythm === "Bradycardia") {
      this.type = "Bradycardia Rate Decrease";
    } else if (this.rhythm === "Tachycardia") {
      this.type = "Tachycardia Rate Increase";
    }
  }

  complete() {
    return this.httpPatchWithRetries(`/events/rateChange/${this.id}`, {completed: true}).then((response) => {
      if (response.status === 200) {
        this.completed = true;
      } else {
        console.error("Event could not be completed");
      }
    });
  }

  lock() {
    return this._lockEvent("rateChange");
  }

  unlock() {
    return this._unlockEvent("rateChange");
  }
}

/*
 * @see SRS: BR-17
 */
class PatientActivatedEvent extends EcgEvent {
  constructor($injector, patientActivatedEvent, study) {
    super($injector, patientActivatedEvent, study);
    this.type = "Patient Activated Event";
  }

  /*
   * @see SRS: BR-27
   */
  complete() {
    return this.httpPatchWithRetries(`/events/patientActivated/${this.id}`, {completed: true}).then(
      (response) => {
        if (response.status === 200) {
          this.completed = true;
        } else {
          console.error("Event could not be completed");
        }
      }
    );
  }

  /*
   * @see SRS: BR-1554
   */
  lock() {
    return this._lockEvent("patientActivated");
  }

  /*
   * @see SRS: BR-1554
   */
  unlock() {
    return this._unlockEvent("patientActivated");
  }
}

/*
 * @see SRS: BR-2142
 */
class EcgDataRequestEvent extends EcgEvent {
  constructor($injector, ecgDataRequestEvent, study) {
    super($injector, ecgDataRequestEvent, study);
    this.type = "ECG Data Request";
  }

  /*
   * @see SRS: BR-27
   */
  complete() {
    return this.httpPatchWithRetries(`/events/ecgDataRequest/${this.id}`, {completed: true}).then(
      (response) => {
        if (response.status === 200) {
          this.completed = true;
        } else {
          console.error("Event could not be completed");
        }
      }
    );
  }

  /*
   * @see SRS: BR-1554
   */
  lock() {
    return this._lockEvent("ecgDataRequest");
  }

  /*
   * @see SRS: BR-1554
   */
  unlock() {
    return this._unlockEvent("ecgDataRequest");
  }
}

/*
 * @see SRS: BR-2450
 */
class BaselineEvent extends EcgEvent {
  constructor($injector, baselineEvent, study) {
    super($injector, baselineEvent, study);
    this.type = "Baseline";
  }

  /*
   * @see SRS: BR-27
   */
  complete() {
    return this.httpPatchWithRetries(`/events/baseline/${this.id}`, {completed: true}).then((response) => {
      if (response.status === 200) {
        this.completed = true;
      } else {
        console.error("Event could not be completed");
      }
    });
  }

  /*
   * @see SRS: BR-1554
   */
  lock() {
    return this._lockEvent("baseline");
  }

  /*
   * @see SRS: BR-1554
   */
  unlock() {
    return this._unlockEvent("baseline");
  }
}

/* @ngInject */
export default class EventService {
  constructor($injector) {
    this._$injector = $injector;
    this._backendConfig = $injector.get("backendConfig");
    this._$http = $injector.get("$http");
    this._Authentication = $injector.get("Authentication");
    this._features = this._backendConfig.features;
    this.data = {};
  }

  // Requires using inbox items with type property on them.
  instantiateEvent(event) {
    let newEvent = {};
    const rhythms = [
      "Tachycardia",
      "Bradycardia",
      "Normal Sinus Rhythm",
      "Unreadable ECG Data",
      "Cardiac Pause",
      "Atrial Fibrillation",
    ];

    if (event.eventType === "rateChange") {
      newEvent = new RateChangeEvent(this._$injector, event);
    } else if (rhythms.includes(event.type)) {
      newEvent = new RhythmChangeEvent(this._$injector, event);
    } else if (event.type === "Patient Activated Event") {
      newEvent = new PatientActivatedEvent(this._$injector, event);
    } else if (event.type === "ECG Data Request") {
      newEvent = new EcgDataRequestEvent(this._$injector, event);
    } else if (event.type === "Baseline") {
      newEvent = new BaselineEvent(this._$injector, event);
    } else {
      newEvent = new RhythmChangeEvent(this._$injector, event);
    }

    return newEvent;
  }

  getEventAndEcg(eventType, eventId) {
    // This route returns the event data with the ecg attached.
    const url = `/ecgs/${eventType}`;
    const query = {id: eventId};

    return this.httpGet(url, query).then((response) => {
      if (!response.data || response.data.length === 0) {
        console.error(`Unable to fetch Event and ECG with eventId: ${eventId} ${eventType}`);
        return null;
      }
      return response.data[0];
    });
  }

  getEcgEventType(readableType) {
    let ecgEventType;
    switch (readableType) {
      case "Patient Activated Event":
        ecgEventType = "patientActivated";
        break;
      case "Bradycardia Rate Decrease":
      case "Tachycardia Rate Increase":
        ecgEventType = "rateChange";
        break;
      case "Tachycardia":
      case "Bradycardia":
      case "Normal Sinus Rhythm":
      case "Unreadable ECG Data":
      case "Cardiac Pause":
      case "Atrial Fibrillation":
        ecgEventType = "rhythmChange";
        break;
      case "ECG Data Request":
        ecgEventType = "ecgDataRequest";
        break;
      case "Baseline":
        ecgEventType = "baseline";
        break;
      default:
        ecgEventType = readableType;
    }
    return ecgEventType;
  }

  getTriggerType(event) {
    const isRhythmChange = event.rhythm !== null;
    const isPatientActivated = event.symptom !== null;
    const isEcgDataRequest = event.comment !== null;
    const isBaseline = event.settingsFileId !== null;

    if (isRhythmChange || isBaseline) {
      return "Automatic";
    }
    if (isPatientActivated || isEcgDataRequest) {
      return "Manual";
    }
    return "Other";
  }

  getTriggerDescriptions(event) {
    const descriptions = [];
    const isPatientActivated = event.symptom !== null;
    const isEcgDataRequest =
      event.comment !== null &&
      event.symptom === null &&
      event.settingsFileId === null &&
      event.rhythm === null;
    const isBaseline = event.settingsFileId !== null;

    if (isPatientActivated) {
      descriptions.push(`Symptom: ${event.symptom}`);
      descriptions.push(`Activity: ${event.activityLevel}`);
    } else if (isEcgDataRequest) {
      descriptions.push("Remote Data Request");
    } else if (isBaseline) {
      descriptions.push("Baseline");
    }
    return descriptions;
  }

  getEventClassification(event) {
    const userClassification = event.userClassification || "";
    const isRhythmChange = event.rhythm !== null;

    if (isRhythmChange && !event.userClassification) {
      return event.rhythm;
    }

    return userClassification;
  }

  updateEvent(event, updatedProperties) {
    const eventType = this.getEcgEventType(event.type);
    const url = `/events/${eventType}/${event.id}`;

    return this.httpPatchWithRetries(url, updatedProperties);
  }

  /*
   * @see SRS: BR-17
   */
  httpGet(url, params) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.get(`${baseUrl}${url}`, {
      headers: {
        Authorization: authHeader,
      },
      params,
    });
  }

  /**
   * Re-attempts PATCH request with exponential backoff.
   *
   * Default behavior:
   *
   * Attempt 1 fails
   *    <Delay: 500ms>
   * Attempt 2 fails
   *    <Delay: 1000ms>
   * Attempt 3 fails
   *    <Delay: 2000ms>
   * Attempt 4 fails
   *    <Delay: 4000ms>
   * Attempt 5 fails
   * Then an error is thrown
   *
   * @param {String} url
   * @param {Any} data
   * @param {Number} retriesRemaining
   * @param {Number} delayMs
   * @returns {Promise<Object>}
   */
  async httpPatchWithRetries(url, data, retriesRemaining = 4, delayMs = 500) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;

    let result;
    try {
      result = await this._$http.patch(`${baseUrl}${url}`, data, {
        headers: {
          Authorization: authHeader,
        },
      });
    } catch (err) {
      if (retriesRemaining <= 0) {
        // If we're out of retries, just rethrow the error.
        throw err;
      }
      if (!(Number.isInteger(err.status) && err.status >= 500)) {
        // If the error is not a 500, just rethrow the error.
        throw err;
      }

      // Wait a short delay
      await new Promise((r) => {
        setTimeout(r, delayMs);
      });

      // Retry
      return this.httpPatchWithRetries(url, retriesRemaining - 1, delayMs * 2);
    }

    return result;
  }
}
