import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import {useReportsStatusFilter} from "../../contexts/ReportsContext.jsx";
import Filter from "../../shared/react/Filter.jsx";
import useJwt from "../hooks/useJwt.jsx";
import ReportStatusChip from "./ReportStatusChip.jsx";

function ReportStatusFilter({
  // Props
  setFilterLoading,
}) {
  // Status Filter
  const {statusFilter, setStatusFilter} = useReportsStatusFilter();

  //---------------------------------------------------------------------------
  // Allowed statuses depending on user
  //---------------------------------------------------------------------------
  const {isInAnyRole} = useJwt();
  const statuses = React.useMemo(() => {
    const allowedStatuses = [
      ...(!isInAnyRole(["physician", "clinicalStaff"]) ? ["generated"] : []),
      "submitted",
      "published",
      "signed",
      "printed",
      ...(!isInAnyRole(["physician", "clinicalStaff"]) ? ["rejectedByTech"] : []),
      "rejectedByPhysician",
    ];

    return allowedStatuses.map((status) => ({
      id: status,
      label: <ReportStatusChip status={status} />,
    }));
  }, [isInAnyRole]);

  //---------------------------------------------------------------------------
  // Submit
  //---------------------------------------------------------------------------
  const onSubmit = React.useCallback(
    async (updatedStatuses) => {
      setStatusFilter(updatedStatuses);
      setFilterLoading(true);
    },
    [setStatusFilter, setFilterLoading]
  );

  return (
    <Filter
      data-cy="status"
      title="Filter Status"
      options={statuses}
      optionsFilter={statusFilter}
      onSubmit={onSubmit}
    />
  );
}
ReportStatusFilter.propTypes = {
  setFilterLoading: PropTypes.func.isRequired,
};
export default ReportStatusFilter;
