import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import {useStudiesTypeFilter} from "../../contexts/StudiesContext.jsx";
import Filter from "../../shared/react/Filter.jsx";
import useStudyTypeNames from "../hooks/useStudyTypeNames.jsx";

function StudyTypeFilter({
  // Props
  setFilterLoading,
}) {
  // Type filter
  const {typeFilter, setTypeFilter} = useStudiesTypeFilter();

  //---------------------------------------------------------------------------
  // Allowed statuses depending on user
  //---------------------------------------------------------------------------
  const displayableStudyTypes = useStudyTypeNames("complex");
  const types = React.useMemo(
    () =>
      Object.entries(displayableStudyTypes).map(([id, name]) => ({
        id,
        label: <Typography>{name.short || name}</Typography>,
      })),
    [displayableStudyTypes]
  );

  //---------------------------------------------------------------------------
  // Submit
  //---------------------------------------------------------------------------
  const onSubmit = React.useCallback(
    async (updatedTypes) => {
      setTypeFilter(updatedTypes);
      setFilterLoading(true);
    },
    [setTypeFilter, setFilterLoading]
  );

  return (
    <Filter
      data-cy="studyType"
      title="Filter Type"
      options={types}
      optionsFilter={typeFilter}
      onSubmit={onSubmit}
    />
  );
}

StudyTypeFilter.propTypes = {
  setFilterLoading: PropTypes.func.isRequired,
};

export default React.memo(StudyTypeFilter);
