import React from "react";
import {FormProvider, useForm} from "react-hook-form";
import * as lodashMax from "lodash/max.js";
import * as lodashMean from "lodash/mean.js";
import * as lodashMin from "lodash/min.js";
import {DateTime} from "luxon";
import {useConfirm} from "material-ui-confirm";
import {PDFDocument} from "pdf-lib";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import {useTheme} from "@mui/material/styles";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import {InboxEntityProvider} from "../../contexts/InboxEntityContext.jsx";
import {useReportsDispatch} from "../../contexts/ReportsContext.jsx";
import {getFacilityLogoWithFallback} from "../../shared/react/Logo.js";
import TableLoading from "../../shared/react/TableLoading.jsx";
import GeneratedReport from "../GeneratedReport/GeneratedReport.jsx";
import useAddCoverPageToPDF from "../hooks/useAddCoverPageToPDF.jsx";
import LogoNotFoundMessage from "./LogoNotFoundMessage.jsx";
import ReportViewerSideBar from "./ReportViewerSideBar.jsx";

//---------------------------------------------------------------------------
// Define Constants
//---------------------------------------------------------------------------
const DEFAULT_MEASUREMENTS = {
  HR: {},
  RR: {data: []},
  PR: {data: []},
  QRS: {data: []},
  QT: {data: []},
};

const PDF_MODE = "PDF_MODE";
const RAW_MODE = "RAW_MODE";

//---------------------------------------------------------------------------
// Helpers
//---------------------------------------------------------------------------
function getEcgEventType(readableType) {
  let ecgEventType;

  switch (readableType) {
    case "Patient Activated Event":
      ecgEventType = "patientActivated";
      break;
    case "Bradycardia Rate Decrease":
    case "Tachycardia Rate Increase":
      ecgEventType = "rateChange";
      break;
    case "Tachycardia":
    case "Bradycardia":
    case "Normal Sinus Rhythm":
    case "Unreadable ECG Data":
    case "Cardiac Pause":
    case "Atrial Fibrillation":
      ecgEventType = "rhythmChange";
      break;
    case "ECG Data Request":
      ecgEventType = "ecgDataRequest";
      break;
    case "Baseline":
      ecgEventType = "baseline";
      break;
    default:
      ecgEventType = readableType;
  }

  return ecgEventType;
}

function getDuration({startTime, endTime}, unit = "milliseconds") {
  return Math.abs(DateTime.fromISO(endTime).diff(DateTime.fromISO(startTime)).as(unit));
}

function ReportViewer({
  // Props
  height = "100vh",
  handleClose = () => {},
  report,
  setInfoMessage,
  setError,
  angularActions,
  changesWereSaved = false,
}) {
  //---------------------------------------------------------------------------
  // Dialog Title
  //---------------------------------------------------------------------------
  const title = React.useMemo(
    () => `${report.reportType} Report ${report.studyId}-${report.reportNumber}`,
    [report.reportNumber, report.reportType, report.studyId]
  );

  //---------------------------------------------------------------------------
  // Set up hooks for confirmation dialogs and styling
  //---------------------------------------------------------------------------
  const confirm = useConfirm();
  const theme = useTheme();

  const dispatch = useReportsDispatch();

  //---------------------------------------------------------------------------
  // Fetch strips, study, PDF, and facility logo from the API
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);
  const [loadData, setLoadData] = React.useState(true);
  const [loadingMessage, setLoadingMessage] = React.useState("Loading report");

  const [study, setStudy] = React.useState({});
  const [logo, setLogo] = React.useState(null);
  const [originalItem, setOriginalItem] = React.useState({});
  const [eSignEnabled, setESignEnabled] = React.useState(false);
  const [strips, setStrips] = React.useState([]);
  const [heartRateStatistics, setHeartRateStatistics] = React.useState({});
  const [graphData, setGraphData] = React.useState({});
  const [chartToggles, setChartToggles] = React.useState({});

  // This variable should only be used for computation, not rendering
  const isPdfReport = React.useMemo(
    () => report.reportType === "Uploaded" || ["published", "signed", "printed"].includes(report.state),
    [report.reportType, report.state]
  );

  // These state variables can be used for rendering
  const [displayedReportMode, setDisplayedReportMode] = React.useState(null);

  const [originalFileUrl, setOriginalFileUrl] = React.useState(isPdfReport ? "pending" : null);
  const [fileUrl, setFileUrl] = React.useState(null);

  const testPdfOrThrow = React.useCallback(async (file) => {
    // Use pdf-lib to validate a given file pdf
    const targetDoc = await PDFDocument.create();

    const testBuffer = await (await fetch(file)).arrayBuffer();
    const testDoc = await PDFDocument.load(testBuffer);
    const testPages = await targetDoc.copyPages(testDoc, testDoc.getPageIndices());

    testPages.forEach((p) => targetDoc.addPage(p));
  }, []);

  const addCoverPageToPDF = useAddCoverPageToPDF(report, logo);

  const getData = React.useCallback(async () => {
    try {
      //---------------------------------------------------------------------------
      // If this is a raw generated report or we are viewing an Uploaded report on
      // its own page, fetch the report with graph data
      //---------------------------------------------------------------------------
      let generatedReportResponse = report;
      if (!isPdfReport || !report.studyId) {
        ({data: generatedReportResponse} = await axios({
          method: "get",
          url: `/generatedReports/${report.id}`,
        }));

        // If any edits were saved via the Angular Edit Report dialog,
        // update the report instance using the dispatcher
        // Note: This is a hack to get the Angular and the viewer to play nice
        if (changesWereSaved) {
          dispatch({type: "updated", updatedElement: {...report, ...generatedReportResponse}});
        }
      }

      //---------------------------------------------------------------------------
      // Fetch other information needed by the generated report
      //---------------------------------------------------------------------------
      let pdfUrl = report.publishedUrl || `generatedReports/pdf/${report.studyId}/${report.id}.pdf`;
      if (pdfUrl[0] !== "/") {
        // Ensure url is prepended with "/"
        pdfUrl = `/${pdfUrl}`;
      }

      const [
        {
          data: [studyResponse],
        },
        {
          data: {logoFilename},
        },
        {data: workflowSettings},
        inboxItemResponse,
        pdfResponse,
      ] = await Promise.all([
        axios({method: "get", url: "/studies", params: {id: report.studyId}}),
        axios({method: "get", url: `/facilities/logo/filename/${report.facilityId}`}),
        axios({method: "get", url: `/workflowSettings/${report.facilityId}`}),
        ...(report?.itemId
          ? [
              axios({
                method: "get",
                url: "/inboxItems",
                params: {
                  id: report.itemId,
                  completed: {$or: [true, false]},
                  flatten: true, // Necessary for the Edit Report dialog that is in Angular
                },
              }),
            ]
          : [Promise.resolve()]),
        ...(isPdfReport ? [axios({method: "get", url: pdfUrl, responseType: "arraybuffer"})] : []),
      ]);

      //---------------------------------------------------------------------------
      // If this is a PDF report, set the PDF file
      //---------------------------------------------------------------------------
      let originalFile;
      let reportWithPossibleCoverPage;

      if (pdfResponse) {
        const file = new File([pdfResponse.data], "file.pdf", {type: "application/pdf"});
        originalFile = URL.createObjectURL(file);

        await testPdfOrThrow(originalFile);

        if (report.state === "submitted") {
          reportWithPossibleCoverPage = URL.createObjectURL(
            await addCoverPageToPDF(originalFile, studyResponse)
          );
        }
      }

      //---------------------------------------------------------------------------
      // Facility Logo
      //---------------------------------------------------------------------------
      const {logoFile, logoFileExtension, logoFallback} = await getFacilityLogoWithFallback(logoFilename);
      if (logoFallback) {
        // If there was an error fetching the facility's logo, display a confirmation popup
        try {
          await confirm({
            title: "Logo Not Found",
            content: <LogoNotFoundMessage facilityName={report.facilityName} />,
            confirmationText: "Proceed",
          });
        } catch {
          // If "Cancel" was clicked, close the report viewer
          handleClose();
        }
      }

      let formattedStripsWithTheirEvent;
      let statisticsForHeartRate;
      if (!isPdfReport) {
        //---------------------------------------------------------------------------
        // Report strips with their respective event/ecg data
        //---------------------------------------------------------------------------
        const rawStrips = generatedReportResponse?.strips;
        formattedStripsWithTheirEvent = await Promise.all(
          rawStrips.map(async (strip) => {
            // Fetch the strip's ECG event
            const stripEventType = getEcgEventType(strip.deviceClassification);
            const {
              data: [stripEvent],
            } = await axios({
              method: "get",
              url: `/ecgs/${stripEventType}`,
              params: {id: strip.eventId},
            });
            strip.event = stripEvent;

            // Calculate the centered sample of the strip
            const samplesPerSecond = 1000000 / stripEvent.ecg.samplePeriod;
            const samplesPerMillisecond = samplesPerSecond / 1000;

            const stripStartTime = DateTime.fromISO(strip.startTime).toMillis();
            const stripEndTime = DateTime.fromISO(strip.endTime).toMillis();
            const ecgStartTime = DateTime.fromISO(stripEvent.ecg.startTime).toMillis();

            const stripStartSample = samplesPerMillisecond * (stripStartTime - ecgStartTime);
            const stripEndSample = samplesPerMillisecond * (stripEndTime - ecgStartTime);

            strip.centeredSample = (stripStartSample + stripEndSample) / 2;

            // Check if the strip's timestamp is valid
            strip.validStartTime = DateTime.fromISO(strip.startTime).year >= 2010;

            // If the strip has no measurements, add defaults
            if (!strip.measurements) {
              strip.measurements = DEFAULT_MEASUREMENTS;
            }

            // Calculate the min, mean, and max for each measurement type
            // @TODO study-trove should be doing all of these calculations... :(
            Object.entries(strip.measurements).forEach(([measurementType, {data = []}]) => {
              if (measurementType !== "HR") {
                const measurementDurations = data.map((d) => getDuration(d));

                const count = measurementDurations.length;
                let min = measurementDurations[0];
                let max = measurementDurations[0];
                let mean = measurementDurations[0];

                if (measurementDurations.length > 1) {
                  min = Math.round(lodashMin(measurementDurations));
                  max = Math.round(lodashMax(measurementDurations));
                  mean = Math.round(lodashMean(measurementDurations));
                }

                strip.measurements[measurementType] = {data, count, min, max, mean};

                // Calculate the min, mean, and max HR from RR measurements
                if (measurementType === "RR") {
                  strip.measurements.HR = {
                    count,

                    // To calculate HR from ms (1000 / rrDuration) * 60 and set those three attributes
                    // Note: min RR correlates to max HR and max RR correlates to min HR
                    min: Math.round((1000 / max) * 60),
                    max: Math.round((1000 / min) * 60),
                    mean: Math.round((1000 / mean) * 60),
                  };
                }
              }
            });

            return strip;
          })
        );

        //---------------------------------------------------------------------------
        // Calculate overall HR stats of all included strips
        // (Used by HR Statistics table and the Strip Index table)
        // @TODO study-trove should be doing all of these calculations... :(
        //---------------------------------------------------------------------------
        const {min, max, sum, count} = formattedStripsWithTheirEvent.reduce(
          (data, strip) => {
            const {mean} = strip.measurements.HR;

            if (mean !== undefined && !Number.isNaN(mean)) {
              data.sum += mean;
              data.count++;

              const stripHrValues = {
                value: mean,
                timestamp: strip.startTime,
                stripId: strip.id,
                href: `#strip-${strip.id}`,
                numTied: 0,
              };

              // Track the min value and the strip first strip that has the min
              if (mean < data.min.value) {
                data.min = stripHrValues;
              } else if (mean === data.min.value) {
                data.min.numTied++;
              }

              // Track the max value and the strip first strip that has the max
              if (mean > data.max.value) {
                data.max = stripHrValues;
              } else if (mean === data.max.value) {
                data.max.numTied++;
              }
            }

            return data;
          },
          {min: {value: Infinity}, max: {value: 0}, sum: 0, count: 0}
        );
        statisticsForHeartRate = {min, max, mean: Math.round(sum / count), count};
      }

      //---------------------------------------------------------------------------
      // Set state variables based on the results from above
      //
      // Note: All of these state variables are set together to take advantage of
      // React's batch updates to reduce the number of rerenders
      //---------------------------------------------------------------------------
      setOriginalFileUrl(originalFile);
      setFileUrl(reportWithPossibleCoverPage || originalFile);

      setGraphData({
        strips: generatedReportResponse.strips,
        beatMarkers: generatedReportResponse.beatMarkers,
        heartRateTrend: generatedReportResponse.heartRateTrend,
        pvcBurden: generatedReportResponse.pvcBurden,
        arrhythmiaData: generatedReportResponse.arrhythmiaData,
        ventricularEctopy: generatedReportResponse.ventricularEctopy,
        qrsExclusionRegions: generatedReportResponse.qrsExclusionRegions,
      });
      setChartToggles(generatedReportResponse.chartToggles);

      setLogo({src: logoFile, extension: logoFileExtension, filename: logoFilename});

      setStudy(studyResponse);

      if (inboxItemResponse) {
        // @TODO the only thing used from originalItem is the timestamp... see if there's a better way
        // to get this information without hitting the /inboxItems route. But, keep in mind that a joined
        // inbox item is required for the Angular Edit Report dialog
        const [inboxItem] = inboxItemResponse.data;
        setOriginalItem(inboxItem);
      }

      setESignEnabled(workflowSettings?.eSignEnabled);

      setStrips(formattedStripsWithTheirEvent);

      setHeartRateStatistics(statisticsForHeartRate);

      // If this is a PDF report, there is no rendering step
      if (isPdfReport) {
        setLoadingMessage("");
      }

      // Due to race conditions, we can only display the raw report after the data has loaded
      setDisplayedReportMode(isPdfReport ? PDF_MODE : RAW_MODE);
      setLoading(false);
    } catch (err) {
      setError(err.message);
    }
  }, [
    dispatch,
    isPdfReport,
    report,
    changesWereSaved,
    testPdfOrThrow,
    addCoverPageToPDF,
    confirm,
    handleClose,
    setError,
  ]);

  // Load the data on first render only
  React.useEffect(() => {
    if (loadData) {
      setLoadData(false);
      setLoading(true);

      getData();
    }
  }, [loadData, getData]);

  // If any edits were saved via the Angular Edit Report dialog, reload
  // Note: This is a hack to get the Angular and the viewer to play nice
  React.useEffect(() => {
    if (changesWereSaved) {
      setLoadingMessage("Loading report");
      setLoadData(true);
    }
  }, [changesWereSaved]);

  //---------------------------------------------------------------------------
  // Form Submission
  //---------------------------------------------------------------------------
  const methods = useForm();

  const setSuccessMessage = React.useCallback(
    (message) => setInfoMessage(`${message} ${title}`),
    [setInfoMessage, title]
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...methods}>
      <Grid container alignItems="stretch" columns={5}>
        {/* GENERATED REPORT VIEWER */}
        <Grid
          // This conditional needs to match the "if guard" for the sidebar
          size={displayedReportMode && !loading ? 4 : 5}
          p={2}
          sx={{
            backgroundColor: theme.palette.background.main,
            height,
            ...(!isPdfReport && {
              overflow: "hidden",
              overflowY: loadingMessage ? "hidden" : "scroll",
            }),
          }}
          data-cy="pdf-viewer"
        >
          {(loading || loadingMessage) && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                position: "static",
                height: "100%",
                width: "100%",
                margin: "auto",
                backgroundColor: theme.palette.background.main,
                zIndex: 2,
              }}
            >
              <TableLoading margin={false} />
              {loadingMessage && (
                <Typography align="center" mt={1}>
                  {loadingMessage}
                </Typography>
              )}
            </Box>
          )}

          {displayedReportMode === RAW_MODE && !loading && (
            <InboxEntityProvider type="generated-report">
              <GeneratedReport
                report={report}
                chartToggles={chartToggles}
                graphData={graphData}
                logo={logo}
                item={originalItem}
                study={study}
                strips={strips}
                heartRateStatistics={heartRateStatistics}
                setLoadingMessage={setLoadingMessage}
              />
            </InboxEntityProvider>
          )}

          {displayedReportMode === PDF_MODE && !loading && (
            <embed src={fileUrl} width="100%" height="100%" data-cy="report-pdf" />
          )}
        </Grid>

        {/* SIDEBAR */}
        {displayedReportMode && !loading && (
          <ReportViewerSideBar
            report={{...report, ...graphData, chartToggles}}
            study={study}
            item={originalItem}
            eSignEnabled={eSignEnabled}
            disabled={!!loadingMessage}
            isPdfReport={isPdfReport}
            originalFileUrl={originalFileUrl}
            setError={setError}
            setLoadingMessage={setLoadingMessage}
            handleClose={handleClose}
            setSuccessMessage={setSuccessMessage}
            addCoverPageToPDF={addCoverPageToPDF}
            setFileUrl={setFileUrl}
            setLoadData={setLoadData}
            angularActions={angularActions}
          />
        )}
      </Grid>
    </FormProvider>
  );
}

ReportViewer.propTypes = {
  height: PropTypes.string,
  handleClose: PropTypes.func,
  report: PropTypes.object.isRequired,
  setInfoMessage: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  angularActions: PropTypes.object.isRequired,
  changesWereSaved: PropTypes.bool,
};

export default ReportViewer;
