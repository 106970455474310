import React from "react";
import {ConfirmProvider} from "material-ui-confirm";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import {ThemeProvider} from "@mui/material/styles";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import {JwtProvider} from "../../components/hooks/useJwt.jsx";
import LocalizationProvider from "../../contexts/LocalizationProvider.jsx";
import {ReportsProvider} from "../../contexts/ReportsContext.jsx";
import {RespectFiltersProvider} from "../../contexts/RespectFiltersContext.jsx";
import {StudiesProvider} from "../../contexts/StudiesContext.jsx";
import useCustomTheme from "../../customizeReactTheme.jsx";

function AddProviders({children}) {
  //---------------------------------------------------------------------------
  // Customize the theme - this will move to app.js eventually
  //---------------------------------------------------------------------------
  const theme = useCustomTheme();

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <JwtProvider>
          <LocalizationProvider>
            <ConfirmProvider
              defaultOptions={{
                dialogProps: {"data-cy": "confirmation-dialog"},
                cancellationButtonProps: {color: "secondary", "data-cy": "cancel-confirmation-button"},
                confirmationButtonProps: {
                  color: "secondary",
                  variant: "contained",
                  "data-cy": "confirm-button",
                },
                allowClose: false,
              }}
            >
              <RespectFiltersProvider>
                <StudiesProvider>
                  <ReportsProvider>{children}</ReportsProvider>
                </StudiesProvider>
              </RespectFiltersProvider>
            </ConfirmProvider>
          </LocalizationProvider>
        </JwtProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
}

AddProviders.propTypes = {
  children: PropTypes.node.isRequired,
};
export default AddProviders;
