import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import InboxEntityContext from "../../contexts/InboxEntityContext.jsx";

//---------------------------------------------------------------------------
// Constants
//---------------------------------------------------------------------------
const MARGIN = 5;

function LeadLabel({
  // Props
  x = 0,
  y = 0,
  offset = 0,
  children,
}) {
  //---------------------------------------------------------------------------
  // Global variables
  //---------------------------------------------------------------------------
  const {type: parentType} = React.useContext(InboxEntityContext);
  const isGeneratedReport = React.useMemo(() => parentType === "generated-report", [parentType]);

  //---------------------------------------------------------------------------
  // Styling constants
  //---------------------------------------------------------------------------
  const fontSize = React.useMemo(() => (isGeneratedReport ? 12 : 16), [isGeneratedReport]);
  const strokeWidth = React.useMemo(() => (isGeneratedReport ? 0.5 : 1), [isGeneratedReport]);

  //---------------------------------------------------------------------------
  // Draw a rectangle around the text based on the text width
  //---------------------------------------------------------------------------
  const labelRef = React.useRef();
  const [outlineBox, setOutlineBox] = React.useState({x, y, width: 0, height: 0});
  React.useEffect(() => {
    const text = labelRef.current;

    if (text) {
      setOutlineBox(text.getBBox());
    }
  }, [labelRef, children, x, y]);

  return (
    <g>
      <rect
        style={{stroke: "black", strokeWidth}}
        x={outlineBox.x - MARGIN}
        y={outlineBox.y - MARGIN / 2}
        width={outlineBox.width + MARGIN * 2}
        height={outlineBox.height + MARGIN}
        fill="white"
        rx={2}
      />
      <text ref={labelRef} x={x + MARGIN * 2} y={y - offset} fontSize={fontSize} alignmentBaseline="middle">
        {children}
      </text>
    </g>
  );
}

LeadLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  offset: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default LeadLabel;
