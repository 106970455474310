import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import {formatDateAndTime} from "../../DateAndTime/DateAndTime.jsx";
import useEnvironmentVariables from "../../hooks/useEnvironmentVariables.jsx";

function Footer({
  // Props
  report,
  study,
  pages,
  pageNumber,
}) {
  //---------------------------------------------------------------------------
  // Global Variables
  //---------------------------------------------------------------------------
  const {softwareVersion} = useEnvironmentVariables();

  const actionTimestamp = React.useMemo(() => {
    const action = report.lastModifiedAt ? "Updated" : "Created";
    const datetime = report.lastModifiedAt || report.timestamp;

    return `${action}: ${formatDateAndTime({datetime, zone: study.timeZone})}`;
  }, [report.lastModifiedAt, report.timestamp, study.timeZone]);

  return (
    <Box>
      <Divider sx={{borderBottomWidth: "3px", borderColor: "primary.main"}} />

      <Stack direction="row" justifyContent="space-between" mt={0.5}>
        <Typography variant="reportText" color="tertiary.light" data-cy="created-at">
          {actionTimestamp} with {report.version}
        </Typography>
        <Typography variant="reportText" color="tertiary.light" data-cy="version-rendered">
          Rendered in: {softwareVersion}
        </Typography>
        <Typography variant="reportText" color="tertiary.light" data-cy="report-number">
          Report Number: {report.reportNumber}
        </Typography>
        <Typography variant="reportText" color="tertiary.light" data-cy="page-number">
          Page {pageNumber} of {pages}
        </Typography>
      </Stack>
    </Box>
  );
}

Footer.propTypes = {
  report: PropTypes.object.isRequired,
  study: PropTypes.object.isRequired,
  pages: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
};

export default React.memo(Footer);
