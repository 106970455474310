import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import MoreVertIcon from "@mui/icons-material/MoreVert";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import ExportStudiesForm from "../../dialogs/StudyDialogs/ExportStudiesForm.jsx";
import ColumnHeader from "../../shared/react/ColumnHeader.jsx";
import StudyStatusFilter from "./StudyStatusFilter.jsx";
import StudyTypeFilter from "./StudyTypeFilter.jsx";

function StudiesHeader({
  // Props
  sort,
  setSort,
  sortedStudies,
  setFilterLoading,
}) {
  //---------------------------------------------------------------------------
  // Menu state management
  //---------------------------------------------------------------------------
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = React.useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  //---------------------------------------------------------------------------
  // Render the column headers plus the filter select if needed
  //---------------------------------------------------------------------------
  return (
    <Grid container sx={{alignItems: "center"}}>
      <Grid container size={11} columns={15}>
        <Grid size={2} sx={{px: 2, display: "inline-flex", alignItems: "center"}}>
          <StudyStatusFilter setFilterLoading={setFilterLoading} />
          <ColumnHeader
            id="status"
            display="Status"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={setSort}
          />
        </Grid>
        <Grid size={2} sx={{alignItems: "center", display: {xs: "none", md: "inline-flex"}}}>
          <ColumnHeader
            id="id"
            display="Study ID"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={setSort}
          />
        </Grid>
        <Grid size={4} sx={{alignItems: "center", display: {xs: "none", md: "inline-flex"}}}>
          <ColumnHeader
            id="patientName"
            display="Patient"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={setSort}
          />
        </Grid>
        <Grid size={3} sx={{pr: 4, alignItems: "center", display: {xs: "none", md: "inline-flex"}}}>
          <StudyTypeFilter setFilterLoading={setFilterLoading} />
          <ColumnHeader
            id="studyType"
            display="Study Type"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={setSort}
          />
        </Grid>
        <Grid size={2} sx={{alignItems: "center", display: {xs: "none", md: "inline-flex"}}}>
          <ColumnHeader
            id="studyStartDate"
            display="Study Start Date"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={setSort}
          />
        </Grid>
        <Grid size={2} sx={{alignItems: "center", display: {xs: "none", md: "inline-flex"}}}>
          <ColumnHeader
            id="studyEndDate"
            display="Study End Date"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={setSort}
          />
        </Grid>
      </Grid>

      <Grid size={1} align="end" sx={{display: {xs: "none", md: "block"}}}>
        <IconButton onClick={handleClick} data-cy="studies-page-action-menu-button">
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuList dense data-cy="studies-page-action-menu">
            <ExportStudiesForm sortedStudies={sortedStudies} menuClose={handleClose} />
          </MenuList>
        </Menu>
      </Grid>
    </Grid>
  );
}
StudiesHeader.propTypes = {
  sort: PropTypes.object.isRequired,
  setSort: PropTypes.func.isRequired,
  sortedStudies: PropTypes.array.isRequired,
  setFilterLoading: PropTypes.func.isRequired,
};
export default StudiesHeader;
