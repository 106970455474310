/* eslint-disable react-hooks/rules-of-hooks */

import React from "react";
import {useDropzone} from "react-dropzone";
import {Controller} from "react-hook-form";
import {fromEvent} from "file-selector";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Clear from "@mui/icons-material/Clear";
import DriveFolderUpload from "@mui/icons-material/DriveFolderUpload";
import FolderZip from "@mui/icons-material/FolderZip";
import UploadFile from "@mui/icons-material/UploadFile";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import MenuItem from "@mui/material/MenuItem";
import {useTheme} from "@mui/material/styles";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import Alert from "./Alert.jsx";
import {getDisplayedBytes} from "./DisplayedDataText.jsx";
import IconButtonWithTooltip from "./IconButtonWithTooltip.jsx";
import MenuButton from "./MenuButton.jsx";

function FormDirectoryInput({
  // Props
  control,
  name,
  label,
  id,
  defaultValue,
  rules,
  disabled,
  directoryPickerLabel,
  filePickerLabel,
  allowFileAccept,
  accept,
  disableTabbing = false,
}) {
  //---------------------------------------------------------------------------
  // State management
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);
  const [filePickerAnchor, setFilePickerAnchor] = React.useState(null);
  const theme = useTheme();

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };
  const activeStyle = {
    borderColor: "#2196f3",
  };
  const acceptStyle = {
    borderColor: theme.palette.secondary.main,
  };
  const rejectStyle = {
    borderColor: "#ff1744",
  };

  // A second input is used to support file accept
  const fileInputId = `${id}-file-input`;
  const waitWarning = "Please wait. Files may take a moment to process";

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      shouldUnregister
      rules={{
        required: !!rules?.required?.value,
      }}
      render={({field: {onChange, value}}) => {
        const clear = () => {
          onChange(rules?.required && {error: rules.required.message});
        };
        const onDrop = async (event) => {
          // handle change & drop event manually so that we only have to call onChange once
          setLoading(true);
          await onChange({warning: waitWarning});

          try {
            const files = await fromEvent(event);
            if (!files || files.length === 0) {
              // if a directory is selected, then the input is opened again and then cancelled,
              // there will be an onChange event with no files
              clear();
            } else if (allowFileAccept && files.length === 1) {
              // try to validate the event as a single zip file
              let error;
              if (rules?.pattern?.value && !files[0].name?.match(rules.pattern.value)) {
                error = rules.pattern.message;
              } else if (rules?.validate?.single?.length > 0) {
                // exit upon error to prioritize earlier validators
                for (let i = 0; i < rules.validate.single.length && !error; i++) {
                  const validation = rules.validate.single[i];
                  const passesOrError = await validation(files[0]); // eslint-disable-line no-await-in-loop
                  if (passesOrError !== true) {
                    error = passesOrError;
                  }
                }
              }

              onChange({
                error,
                ...(!error && {
                  name: `${files[0].name} (${getDisplayedBytes(files[0].size)})`,
                  file: files[0],
                }),
              });
            } else {
              // try to validate the event as a directory
              let error;
              if (rules?.validate?.multiple?.length > 0) {
                // exit upon error to prioritize earlier validators
                for (let i = 0; i < rules.validate.multiple.length && !error; i++) {
                  const validation = rules.validate.multiple[i];
                  const passesOrError = await validation(files); // eslint-disable-line no-await-in-loop
                  if (passesOrError !== true) {
                    error = passesOrError;
                  }
                }
              }
              const totalSize = files.reduce((acc, curr) => acc + (curr.size || 0), 0);
              onChange({
                error,
                ...(!error && {
                  name: `${files.length.toLocaleString()} files selected (${getDisplayedBytes(totalSize)})`,
                  files,
                  totalSize,
                }),
              });
            }
          } catch (e) {
            await onChange({error: e.message || "An error ocurred while reading data"});
          }
          setLoading(false);
        };
        const onClick = async () => {
          if (disabled || loading) {
            return;
          }
          // handle click event manually so that the input is used rather than showOpenFilePicker()
          await onChange({warning: waitWarning});
          document.getElementById(id).value = "";
          document.getElementById(id).click();
        };
        const onClickFile = (event) => {
          setFilePickerAnchor(null);
          event.stopPropagation();
          if (disabled || loading) {
            return;
          }
          // handle click event manually so that the input is used rather than showOpenFilePicker()
          document.getElementById(fileInputId).value = "";
          document.getElementById(fileInputId).click();
        };
        const {getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject} = useDropzone({
          accept,
          noClick: true,
        });
        const style = React.useMemo(
          () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
          }),
          [isDragActive, isDragReject, isDragAccept]
        );
        return (
          <>
            <Grid size={{xs: 12, md: 6}}>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <div {...getRootProps({style, onClick, onDrop})}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <input {...getInputProps({id, webkitdirectory: "", onChange: onDrop})} />
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <input {...getInputProps({id: fileInputId, onChange: onDrop})} />
                <Box style={{display: "flex", alignItems: "center"}} sx={{px: 2}}>
                  <Box sx={{pr: 2}}>{label}</Box>
                  <Divider flexItem orientation="vertical" variant="middle" />
                  <Box sx={{pl: 2}}>
                    <ButtonGroup variant="contained" color="secondary">
                      <LoadingButton
                        id="browse-button"
                        data-cy="browse-button"
                        variant="contained"
                        color="secondary"
                        disabled={disabled}
                        loading={loading}
                        tabIndex={disableTabbing ? -1 : 0}
                      >
                        {directoryPickerLabel || "Browse"}
                      </LoadingButton>
                      {allowFileAccept && (
                        <MenuButton
                          id="select-file-button"
                          disabled={disabled || loading}
                          anchorEl={filePickerAnchor}
                          setAnchorEl={setFilePickerAnchor}
                          disableTabbing={disableTabbing}
                        >
                          <MenuItem key="select-file-option" onClick={onClickFile}>
                            <Typography sx={{display: "flex", alignItems: "center"}}>
                              <FolderZip color="secondary" sx={{mr: 2}} />
                              {filePickerLabel || "Browse files"}
                            </Typography>
                          </MenuItem>
                        </MenuButton>
                      )}
                    </ButtonGroup>
                  </Box>
                </Box>
              </div>
            </Grid>
            <Grid size={{xs: 12, md: 6}} data-cy="file-input-message">
              {value?.name && (
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "nowrap",
                    overflowWrap: "anywhere",
                  }}
                >
                  {value.files && <DriveFolderUpload color="secondary" />}
                  {!value.files && <UploadFile color="secondary" />}
                  &nbsp;{value.name}
                  <IconButtonWithTooltip
                    title="Remove Files"
                    color="secondary"
                    id="remove-file-button"
                    onClick={clear}
                    otherProps={{disabled}}
                  >
                    <Clear />
                  </IconButtonWithTooltip>
                </p>
              )}
              {(value?.error || value?.warning) && (
                <Alert
                  message={value.error || value.warning}
                  level={value.error ? "error" : "warning"}
                  otherProps={{mt: 1}}
                />
              )}
            </Grid>
          </>
        );
      }}
    />
  );
}

FormDirectoryInput.propTypes = {
  /**
   * The [control](https://react-hook-form.com/api/useform/control)
   * object provided by invoking `useForm`.
   * Optional when using `FormProvider`.
   *
   * @example
   * ```js
   * import { useForm } from 'react-hook-form';
   * const { control, handleSubmit, getValues } = useForm();
   * ```
   * pass in control
   */
  control: PropTypes.object.isRequired,

  /**
   * Unique name of your input field
   */
  name: PropTypes.string.isRequired,

  /**
   * The label content
   */
  label: PropTypes.string.isRequired,

  /**
   * The id of your input field
   */
  id: PropTypes.string,

  /**
   * The default value of the input field
   */
  defaultValue: PropTypes.object,

  /**
   * Validation rules for directory mode: validate.multiple, required,
   * Validation rules for file mode: validate.single, pattern, required,
   *
   * @example
   * ```jsx
   * rules={{
   *   required: {
   *     value: true,
   *     message: "Select a device",
   *   },
   *   pattern: {
   *     value: /\.zip$/i,
   *     message: "Must be a zip file",
   *   },
   *   validate: {
   *     multiple: [
   *       (files) => {true || "Error Message"}
   *     ],
   *     single: [
   *       (file) => {true || "Error Message"}
   *     ],
   *   }
   * }}
   * ```
   */
  rules: PropTypes.object,

  /**
   * If `true` the component is disabled
   */
  disabled: PropTypes.bool,

  /**
   * Text to display on the button. Otherwise: "BROWSE"
   */
  directoryPickerLabel: PropTypes.string,

  /**
   * Text to display on the file picking option if allowFileAccept is true.
   * Otherwise: "Browse Files"
   */
  filePickerLabel: PropTypes.string,

  /**
   * Adds a secondary button to select files
   */
  allowFileAccept: PropTypes.bool,

  /**
   * MIME type and file extension rules
   * See also:
   *    https://react-dropzone.org/#!/Accepting%20specific%20file%20types
   *    https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
   *
   * @example
   * ```jsx
   * accept={{"application/zip": [".zip"]}}
   * ```
   */
  accept: PropTypes.object,

  /**
   * Disables tabbing to browse button or menu button
   */
  disableTabbing: PropTypes.bool,
};

export default FormDirectoryInput;
