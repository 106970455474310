import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import AddProviders from "../../shared/react/AddProviders.jsx";
import SideBarChip from "./SideBarChip.jsx";

function SideBarChipWrapper({
  // Props
  color,
  url,
  params,
  tooltip,
  chipId,
  clickSearch,
  clickPage,
}) {
  //---------------------------------------------------------------------------
  // NOTE: The only reason that this file is separate from SideBarChip.jsx is because of Angular.
  //  Once we switch completely from Angular to React, AddProviders can be moved to an App.jsx so
  //  that it encapsulates the entire application. Then this file can be deleted.
  //---------------------------------------------------------------------------
  return (
    <AddProviders>
      <SideBarChip
        color={color}
        url={url}
        params={params}
        tooltip={tooltip}
        id={chipId}
        clickSearch={clickSearch}
        clickPage={clickPage}
      />
    </AddProviders>
  );
}

SideBarChipWrapper.propTypes = {
  // The color to use for the chip
  color: PropTypes.string.isRequired,

  // The URL to hit to get a count
  url: PropTypes.string.isRequired,

  // Query params to include with the request
  params: PropTypes.object,

  // Tooltip to display
  tooltip: PropTypes.string,

  // An id to use for cross-tab syncing
  chipId: PropTypes.string.isRequired,

  clickSearch: PropTypes.string,
  clickPage: PropTypes.string,
};

export default SideBarChipWrapper;
