import React from "react";
import {useFormContext, useWatch} from "react-hook-form";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import TextWithNewlines from "../../../shared/react/TextWithNewlines.jsx";
import DateAndTime from "../../DateAndTime/DateAndTime.jsx";

function ReadingPhysicianComments({
  // Props
  report,
  study,
}) {
  const {control} = useFormContext();
  const livePhysicianComments = useWatch({control, name: "physicianComment"});

  const physicianSignature = React.useMemo(() => {
    if (report.physicianSignedBy && report.signedAt) {
      return `Electronically signed by ${report.physicianSignedBy}`;
    }

    return "";
  }, [report.physicianSignedBy, report.signedAt]);

  return (
    <Box
      sx={{mt: 1, p: 1, border: (theme) => `2px solid ${theme.palette.background.main}`}}
      className="report-element"
      id="reading-physician-comments"
      data-cy="reading-physician-comments"
    >
      <Typography variant="subtitle2" mb={1}>
        Reading Physician Comments
      </Typography>

      <Box minHeight={60}>
        <TextWithNewlines
          text={report.physicianComment || livePhysicianComments || ""}
          variant="reportText"
        />
      </Box>

      <Grid container columns={10} mt={2} alignItems="flex-end">
        <Grid size="auto">
          <Typography variant="reportText">
            <b>Reading Physician Name:</b>
          </Typography>
        </Grid>
        <Grid size={2} sx={{borderBottom: "1px solid black"}}>
          <Typography variant="reportText" align="center">
            {report.physicianSignedBy}
          </Typography>
        </Grid>

        <Grid size="auto">
          <Typography variant="reportText">
            <b>Reading Physician Signature:</b>
          </Typography>
        </Grid>
        <Grid size="grow" sx={{borderBottom: "1px solid black"}}>
          <Typography variant="reportText" align="center">
            {physicianSignature}
          </Typography>
        </Grid>

        <Grid size="auto">
          <Typography variant="reportText">
            <b>Date:</b>
          </Typography>
        </Grid>
        <Grid size={2} sx={{borderBottom: "1px solid black"}}>
          <Typography variant="reportText" align="center">
            {report.signedAt && <DateAndTime datetime={report.signedAt} zone={study.timeZone} />}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

ReadingPhysicianComments.propTypes = {
  report: PropTypes.object.isRequired,
  study: PropTypes.object.isRequired,
};

export default React.memo(ReadingPhysicianComments);
