/* eslint-env browser */
import React from "react";
import {Controller, useForm} from "react-hook-form";
import {MuiOtpInput} from "mui-one-time-password-input";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Close from "@mui/icons-material/Close";
import Lock from "@mui/icons-material/Lock";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import AddProviders from "../../shared/react/AddProviders.jsx";
import Alert from "../../shared/react/Alert.jsx";
import CheckboxInput from "../../shared/react/CheckboxInput.jsx";

function AuthenticationDialog({
  // Props
  username,
  email,
  display,
}) {
  //---------------------------------------------------------------------------
  // Modal state management
  //---------------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);

  // When the Angular button is clicked, open the React dialog
  React.useEffect(() => {
    if (display?.open) {
      setOpen(true);
    }
  }, [display]);

  const handleClose = React.useCallback(() => {
    setOpen(false);
    display.open = false;
  }, [display]);

  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Form Submission
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);
  const {handleSubmit, control, reset} = useForm();

  const [codeSent, setCodeSent] = React.useState(false);
  const [resending, setResending] = React.useState(false);

  const onSubmit = React.useCallback(
    async (data) => {
      setLoading(true);
      setCodeSent(false);

      try {
        const response = await axios({
          method: "post",
          url: `/users/authenticate/${username}/${data.code}`,
          data: {trust: data.rememberDevice},
          withCredentials: true,
        });

        // When the React dialog is closed, propagate those changes to the Angular page
        handleClose();
        if (display.handleLogin) {
          display.handleLogin(response.headers["access-token"]);
        }
      } catch (err) {
        if (err.message.includes("401")) {
          setError("Authentication failed");
        } else {
          setError(err.message);
        }
        reset({
          code: "",
          rememberDevice: data.rememberDevice,
        });
      }
      setLoading(false);
    },
    [username, handleClose, display, reset]
  );

  const handleResendCode = React.useCallback(async () => {
    setResending(true);

    try {
      await axios({
        method: "post",
        url: `/users/resend-otp/${username}`,
        withCredentials: true,
      });

      setCodeSent(true);
    } catch (err) {
      setCodeSent(false);

      if (err.message.includes("401")) {
        setError("Failed to resend code");
      } else {
        setError(err.message);
      }
    }
    setResending(false);
  }, [username]);

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <AddProviders>
      <Dialog
        open={open}
        maxWidth="xs"
        fullWidth
        data-cy="two-factor-authentication-dialog"
        PaperProps={{
          component: "form",
          onSubmit: handleSubmit(onSubmit),
          noValidate: true,
        }}
      >
        <Alert message={error} setMessage={setError} level="error" />
        {codeSent && <Alert message="A new code has been sent" level="info" />}

        <DialogTitle>
          <Grid container sx={{justifyContent: "space-between", alignItems: "center"}}>
            <Grid>
              <Lock fontSize="small" color="secondary" />
            </Grid>
            <Grid>
              <Typography variant="inherit" align="center">
                Two Factor Authentication
              </Typography>
            </Grid>
            <Grid>
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleClose}
                data-cy="cancel-two-factor-dialog"
              >
                <Close fontSize="inherit" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        {loading && (
          <Box sx={{width: "100%"}}>
            <LinearProgress color="secondary" data-cy="loading-bar" />
          </Box>
        )}

        <DialogContent>
          <Box sx={{justifyContent: "center", textAlign: "center"}}>
            <Typography data-cy="email-prompt" variant="body2">
              Enter the 6-digit authentication code sent to {email || "your email"}.
            </Typography>

            <Controller
              name="code"
              control={control}
              rules={{validate: (value) => value.length === 6}}
              render={({field, fieldState}) => (
                <Box>
                  <MuiOtpInput
                    sx={{gap: 1, pt: 2}}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    length={6}
                    onComplete={handleSubmit(onSubmit)}
                    TextFieldsProps={{disabled: loading}}
                    data-cy="authentication-text-input"
                  />
                  {fieldState.invalid ? <FormHelperText error>Invalid code</FormHelperText> : null}
                </Box>
              )}
            />

            <Box sx={{alignItems: "center", display: "inline-flex"}}>
              <Typography variant="caption">Didn&apos;t receive a code?</Typography>
              <LoadingButton
                color="secondary"
                disabled={resending}
                loading={resending}
                onClick={handleResendCode}
                data-cy="resend-code-button"
              >
                Resend
              </LoadingButton>
            </Box>

            <Box sx={{pt: 2, alignItems: "center", display: "inline-flex"}}>
              <CheckboxInput
                name="rememberDevice"
                control={control}
                defaultValue={false}
                otherProps={{size: "small"}}
                data-cy="remember-device-checkbox"
              />
              <Typography variant="body2">Remember this device for 30 days</Typography>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </AddProviders>
  );
}
AuthenticationDialog.propTypes = {
  username: PropTypes.string.isRequired,
  email: PropTypes.string,
  display: PropTypes.object.isRequired,
};
export default AuthenticationDialog;
