import React from "react";
import {useFormContext, useWatch} from "react-hook-form";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import useDeepCompareMemoize from "./useDeepCompareMemoize.jsx";

//---------------------------------------------------------------------------
// Constants
//---------------------------------------------------------------------------
const DEFAULT_TIME_BASE = 25;
const DEFAULT_GAIN = 10;

const MIN_PERIOD = 1000; // microseconds (default is 4000us, which is equivalent to 250hz)
const DEFAULT_Y_AXIS_INVERSION = -1;

const useLeadCoordinates = ({
  leads,
  pixelsPerSample,
  samplePeriod,
  avm,
  getOffsetY,
  timeBase = DEFAULT_TIME_BASE,
  gain = DEFAULT_GAIN,
  useSelectedLead = false,
}) => {
  //---------------------------------------------------------------------------
  // Watch the inverted lead input values
  //---------------------------------------------------------------------------
  const {control, defaultValues} = useFormContext();
  const [invertedLeads, displayedLeads, selectedLead] = useWatch({
    control,
    name: ["invert", "display", "selectedLead"],
    defaultValue: defaultValues,
  });

  // React only uses shallow comparisons for dependencies of a useMemo - we need a deep comparison for these objects!
  const memoizedInvertedLeads = useDeepCompareMemoize(invertedLeads);
  const memoizedDisplayedLeads = useDeepCompareMemoize(displayedLeads);

  return React.useMemo(() => {
    const compressionFactor = samplePeriod < MIN_PERIOD ? Math.round(MIN_PERIOD / samplePeriod) : 1;

    const pixelsPerSecond = pixelsPerSample * (1000000 / samplePeriod);
    const pixelsPerMillimeter = pixelsPerSecond / timeBase;
    const pixelsPerMillivolt = pixelsPerMillimeter * gain;

    const leadCoordinates = leads.map((lead, index) => {
      const inversionValue = memoizedInvertedLeads[lead.leadName] ? -1 : 1;

      const coordinates = [];
      const offsetY = getOffsetY(index);

      for (let i = 0; i < lead.data.length; i += compressionFactor) {
        const x = Math.round(i * pixelsPerSample);

        const nanovolts = lead.data[i] * avm;
        const millivolts = nanovolts / 1000000;
        const y =
          offsetY + Math.round(millivolts * pixelsPerMillivolt) * (DEFAULT_Y_AXIS_INVERSION * inversionValue);

        coordinates.push({x, y});
      }

      return {
        label: lead.leadName,
        offsetY,
        path: `M 0 ${offsetY} L ${coordinates.map(({x, y}) => `${x}, ${y}`).join(" ")}`,
      };
    });

    // If only one lead is required to display, only return that lead
    if (useSelectedLead) {
      return leadCoordinates.find(({label}) => label === selectedLead);
    }

    // Otherwise, only return the leads toggled on
    return leadCoordinates.filter(({label}) => memoizedDisplayedLeads[label]);
  }, [
    avm,
    gain,
    getOffsetY,
    leads,
    memoizedDisplayedLeads,
    memoizedInvertedLeads,
    pixelsPerSample,
    samplePeriod,
    selectedLead,
    timeBase,
    useSelectedLead,
  ]);
};

export default useLeadCoordinates;
