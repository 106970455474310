class SortService {
  constructor(defaultColumn, defaultDirection) {
    this._reverseSort = defaultDirection === "DESC";
    this._defaultColumn = defaultColumn;
    this._defaultDirection = defaultDirection;

    this._orderBy = [{property: defaultColumn, direction: defaultDirection}];
  }

  get orderBy() {
    return this._orderBy;
  }

  set orderBy(orderBy) {
    this._orderBy = orderBy;
  }

  get sqlOrder() {
    return this._orderBy.map((orderObject) => {
      return [orderObject.property, orderObject.direction];
    });
  }

  sortBy(column) {
    const changingDirection = this.orderBy[0].property === column;
    this._reverseSort = changingDirection ? !this._reverseSort : false;
    const newDirection = this._reverseSort ? "DESC" : "ASC";

    const newOrder = [{property: column, direction: newDirection}];

    const sortingByDefaultColumn = column === this._defaultColumn;
    if (!sortingByDefaultColumn) {
      newOrder.push({property: this._defaultColumn, direction: this._defaultDirection});
    }

    this._orderBy = newOrder;
  }

  compare(a, b) {
    let result;
    const primarySort = this.orderBy[0];
    const secondarySort = this.orderBy[1];
    const propertyA = a[primarySort.property];
    const propertyB = b[primarySort.property];

    if (propertyA === propertyB) {
      if (secondarySort) {
        let secondarySortCompare = a[secondarySort.property] > b[secondarySort.property] ? 1 : -1;
        if (secondarySort.direction === "DESC") {
          secondarySortCompare *= -1;
        }
        result = secondarySortCompare;
      } else {
        result = 0;
      }
    } else {
      let primarySortCompare = propertyA > propertyB ? 1 : -1;

      if (primarySort.direction === "DESC") {
        primarySortCompare *= -1;
      }
      result = primarySortCompare;
    }

    return result === 0 || result === -1;
  }

  getIndexToInsertAt(item, sortedItems) {
    return sortedItems.findIndex((existingItem) => this.compare(item, existingItem));
  }

  insertItemIntoList(index, item, itemList) {
    if (index !== -1) {
      itemList.splice(index, 0, item);
    }

    return itemList;
  }
}

export default function returnSortService() {
  return SortService;
}
