import editReportPug from "../../dialogs/editReport/editReport.pug";

/* @ngInject */
export default class GeneratedReportController {
  constructor($rootScope, $scope, $stateParams, $injector) {
    this._$rootScope = $rootScope;
    this._$scope = $scope;
    this._$stateParams = $stateParams;
    this._$mdDialog = $injector.get("$mdDialog");
    this._InboxItemService = $injector.get("InboxItemService");

    const deregister = this._$rootScope.$on("clicked-edit-report", (event, generatedReport, item) => {
      this.clickEditReportButton(generatedReport, item);
    });
    this._$scope.$on("$destroy", deregister);

    this.$onInit = this._init;
  }

  async _init() {
    this.reportId = this._$stateParams.reportId;

    this.actions = {
      editReport: (generatedReport, item) => {
        this._$rootScope.$emit("clicked-edit-report", generatedReport, item);
      },
    };
    this.changesWereSaved = false;
  }

  async clickEditReportButton(generatedReport, item) {
    let selectedItem;
    this.changesWereSaved = false;

    try {
      // Obtain a lock on the item
      selectedItem = await this._InboxItemService.selectItem(item, false);
    } catch (err) {
      await this._$mdDialog.show(
        this._$mdDialog
          .alert()
          .title("Failed to load item")
          .htmlContent(
            `<p class="warningMessage"><i class="material-icons dialogErrorIcon"> error </i> ` +
              `${err.message}</p>`
          )
          .ok("Ok")
          .multiple(true)
      );

      return;
    }

    this._$scope.$apply();

    // Open the Edit Report dialog
    const error = await this._$mdDialog
      .show({
        controller: "EditReportController",
        controllerAs: "editReport",
        template: editReportPug(),
        locals: {item, selectedItem, generatedReport},
      })
      .catch((e) => e);

    // If edits were successful, let the React component know that updates have been made
    if (!error) {
      this.changesWereSaved = true;
    }

    // If the Edit Report dialog was cancelled, deselect the item
    if (error && error === "Cancelled edits") {
      await this._$mdDialog.hide();
      await this._InboxItemService.deselectItem().catch((e) => e);
    }
  }
}
