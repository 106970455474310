import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import Alert from "../../shared/react/Alert.jsx";

function LogoNotFoundMessage({facilityName = "your facility"}) {
  return (
    <>
      <Alert
        level="error"
        message="This report was generated with a facility logo that could not be found."
      />

      <Typography variant="body2" sx={{my: 2}}>
        Contact your administrator to add a valid logo for {facilityName} before generating any new reports.
      </Typography>

      <Typography variant="body2">
        <b>Would you like to continue viewing the report using the BitRhythm default logo?</b>
      </Typography>
    </>
  );
}

LogoNotFoundMessage.propTypes = {
  facilityName: PropTypes.string,
};

export default LogoNotFoundMessage;
