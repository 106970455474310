import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Link from "@mui/material/Link";

// Links must start with "http://", "https://", "http://www.", "https://www.", "www." (Case Insensitive)
const linkFinder =
  /(?<link>\b(?:https?:\/\/(?:www\.)?|www\.)(?:[a-z0-9](?:[-a-z0-9]*[a-z0-9])?\.)+[a-z]{2,3}(?:(?:\/|\?)[^\s.]*|\b))/i;

const linkParser =
  /(?<host>https?:\/\/(?:www\.)?|www\.)(?<domain>(?:[a-z0-9](?:[-a-z0-9]*[a-z0-9])?\.)+[a-z]{2,3})(?<params>(?:\/|\?)[^\s.]*|\b)/i;

//---------------------------------------------------------------------------
// Converts links within text to a clickable Link element that will open
// the link in a new tab
//---------------------------------------------------------------------------
function LinkifyText({
  // Props
  text,
  fallback = "",
  "data-cy": dataCy,
  ignoreNewlines = false,
}) {
  if (text === undefined || text === null) {
    return <span data-cy={dataCy}>{fallback}</span>;
  }

  return (
    <span data-cy={dataCy}>
      {text
        .trim()
        .split(linkFinder)
        .reduce((accumulatedElements, subtext) => {
          const {link} = subtext.match(linkFinder)?.groups || {};

          // If there are any links within the text, replace the link string with a Link component
          if (link) {
            // href must start with a protocol, else it will link to the current domain
            const href = link.startsWith("www.") ? `https://${link}` : link;

            const {host, domain, params} = link.match(linkParser)?.groups || {};

            // If params is longer than n, n-chars will be displayed with "..." in the middle
            const maxLength = 6; // must be even
            const trimmedParams = `${params.slice(0, maxLength / 2)}...${params.slice(maxLength / -2)}`;
            const displayedLink = `${host}${domain}${params.length > maxLength ? trimmedParams : params}`;

            accumulatedElements.push(
              <Link href={href} target="_blank">
                {displayedLink}
              </Link>
            );
          } else if (ignoreNewlines) {
            accumulatedElements.push(subtext);
          } else {
            // Process multiline displayed text for non-links if we are not ignoring line breaks
            accumulatedElements.push(
              ...subtext.split(/\r?\n/).reduce((accumulatedLines, line, i) => {
                if (i !== 0) {
                  // no break before the first line to display it with the previous line
                  accumulatedLines.push(<br />);
                }

                // sanitize superfluous whitespace
                accumulatedLines.push(line.replaceAll(/[ \t]+/g, " "));

                return accumulatedLines;
              }, [])
            );
          }

          return accumulatedElements;
        }, [])}
    </span>
  );
}

LinkifyText.propTypes = {
  text: PropTypes.string,
  fallback: PropTypes.string,
  "data-cy": PropTypes.string,
  ignoreNewlines: PropTypes.bool,
};

export default React.memo(LinkifyText);
