/* eslint-env browser */
import React from "react";
import {useForm} from "react-hook-form";
import {BlobReader, BlobWriter, TextWriter, ZipReader, ZipWriter} from "@zip.js/zip.js";
import {saveAs} from "file-saver";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import MobileFriendly from "@mui/icons-material/MobileFriendly";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid2";
import LinearProgress from "@mui/material/LinearProgress";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../../axiosClient.js";
import useJwt from "../../../components/hooks/useJwt.jsx";
import PatientDiaryTable from "../../../components/PatientDiaryTable/PatientDiaryTable.jsx";
import {useStudiesDispatch} from "../../../contexts/StudiesContext.jsx";
import Alert from "../../../shared/react/Alert.jsx";
import CompactStudyInfo from "../../../shared/react/CompactStudyInfo.jsx";
import DialogTitleBar from "../../../shared/react/DialogTitleBar.jsx";
import {getDisplayedBits, getDisplayedTime} from "../../../shared/react/DisplayedDataText.jsx";
import FormDirectoryInput from "../../../shared/react/FormDirectoryInput.jsx";
import FormRadioInput from "../../../shared/react/FormRadioInput.jsx";
import FormStringInput from "../../../shared/react/FormStringInput.jsx";
import IconButtonWithTooltip from "../../../shared/react/IconButtonWithTooltip.jsx";

function CheckInDeviceDialog({study}) {
  //---------------------------------------------------------------------------
  // State management
  //---------------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [loadingPercent, setLoadingPercent] = React.useState(0);
  const [loadingMessage, setLoadingMessage] = React.useState("");
  const [formProgress, setFormProgress] = React.useState(0);
  const [actionResponse, setActionResponse] = React.useState(null);
  const [hasDownloadedActions, setHasDownloadedActions] = React.useState(false);
  const [combinedZipBlob, setCombinedZipBlob] = React.useState(null);
  const [pendingDeviceEnrollmentId, setPendingDeviceEnrollmentId] = React.useState("");
  const importAbortController = React.useRef();

  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  const {isInAnyRole} = useJwt();

  //---------------------------------------------------------------------------
  // Form Submission
  //---------------------------------------------------------------------------
  const defaultValues = React.useMemo(() => ({deviceCheckInPhysical: ""}), []);
  const {handleSubmit, watch, control, reset} = useForm({defaultValues});
  const dispatch = useStudiesDispatch();

  //---------------------------------------------------------------------------
  // Handle State Functions
  //---------------------------------------------------------------------------
  const handleClickOpen = React.useCallback(() => {
    // the importAbortController is used to terminate the import process when the popup is cancelled
    importAbortController.current = new AbortController();
    reset(defaultValues);
    setLoadingPercent(0);
    setError(null);
    setOpen(true);
  }, [defaultValues, reset]);

  const handleClose = React.useCallback(
    (event, reason) => {
      if (reason === "backdropClick") {
        return;
      }
      if (formProgress === 1 && !hasDownloadedActions) {
        // don't allow the popup to be closed if the user has not clicked Download Action
        return;
      }
      if (importAbortController.current) {
        importAbortController.current.abort();
      }

      setOpen(false);
    },
    [formProgress, hasDownloadedActions]
  );

  const handleDone = React.useCallback(async () => {
    const {
      data: [updatedStudy],
    } = await axios({
      method: "get",
      url: "/studies",
      params: {id: study.id},
    });
    dispatch({
      type: "updated",
      updatedElement: {
        ...updatedStudy,
        ...(!["holter", "extendedHolter"].includes(study.studyType) && {checkInDeviceInProgress: true}),
      },
    });

    handleClose();
  }, [dispatch, handleClose, study.id, study.studyType]);

  const clickedDownloadActions = React.useCallback(() => {
    if (!actionResponse) {
      return;
    }
    const dispositionHeader = actionResponse.headers["content-disposition"];
    const matches = dispositionHeader?.match(/filename="(?<filename>.+?)"/);
    const filename = matches?.groups?.filename || `${study.currentEnrollment.tzSerial}_format_device.tza`;
    saveAs(new Blob([actionResponse.data]), filename);
    setHasDownloadedActions(true);
  }, [actionResponse, study]);

  const clickedDownloadZip = React.useCallback(async () => {
    if (!combinedZipBlob) {
      return;
    }

    const safeDeviceEnrollmentId = pendingDeviceEnrollmentId.replaceAll(/\//g, "_");
    const filename = `Enrollments_${study.currentEnrollment.tzSerial}_${safeDeviceEnrollmentId}.zip`;
    saveAs(combinedZipBlob, filename);
  }, [combinedZipBlob, pendingDeviceEnrollmentId, study]);

  const compressZip = React.useCallback(async (files, chunkSize = 25) => {
    const currentZip = new ZipWriter(new BlobWriter("application/zip"));
    const startTimeMs = new Date().getTime();

    // the time estimate is usually low by 10% per 30,000 files, so adjust it
    const timeAdjustment = 1 + files.length / 300000;

    let previousFileIndex = 0;
    let previousPercent = 0;
    for (let i = 0; i < files.length && !importAbortController.current?.signal.aborted; i += chunkSize) {
      // We can't parallelize the entire zip, but each chunk will be parallelized
      // eslint-disable-next-line no-await-in-loop
      await Promise.all(
        files.slice(i, i + chunkSize).map((file) =>
          // zip.js will automatically structure the directory based on each file path
          currentZip.add(
            // remove the first folder (drive name) from the path
            // this will convert "Y:/config.tzs" AND "/Y_drive/config.tzs" to "config.tzs"
            file.path.replace(/.+?\//, ""),
            new BlobReader(file)
          )
        )
      );

      // update loading bar every 200 files or every 1%
      const percent = ((i + 1) / files.length) * 100;
      if (i - previousFileIndex >= 200 || percent - previousPercent >= 1) {
        previousFileIndex = i;
        previousPercent = percent;

        const elapsedMs = new Date().getTime() - startTimeMs;
        const estimatedRemainingMs = (elapsedMs * (100 - percent) * timeAdjustment) / percent;
        const estimatedMessage = ` (${getDisplayedTime(estimatedRemainingMs)} remaining)`;
        setLoadingMessage(`Compression: ${Math.round(percent)}%${estimatedMessage}`);
        setLoadingPercent(percent);
      }
    }
    return currentZip.close();
  }, []);

  const uploadZip = React.useCallback(
    async (deviceEnrollmentId, blob) => {
      if (importAbortController.current?.signal.aborted) {
        return;
      }

      try {
        const requestData = new FormData();
        requestData.append("file", blob);

        // Encode the deviceEnrollmentId because it could contain illegal characters
        const safeDeviceEnrollmentId = encodeURIComponent(deviceEnrollmentId);
        const {
          enrollmentId,
          currentEnrollment: {tzSerial},
        } = study;

        await axios({
          method: "post",
          url: `/devices/${tzSerial}/recording/${safeDeviceEnrollmentId}`,
          params: {enrollmentId},
          data: requestData,
          signal: importAbortController.current?.signal,
          onUploadProgress: ({progress, rate: byteRate, estimated: estimatedSeconds}) => {
            const messages = [];
            if (estimatedSeconds) {
              messages.push(`${getDisplayedTime(Math.round(estimatedSeconds * 1000))} remaining`);
            }
            if (byteRate) {
              messages.push(`${getDisplayedBits(byteRate * 8)}/s`);
            }
            if (progress === 1) {
              // ignore the other messages
              messages.length = 0;
              messages.push("finalizing check in");
            }

            const secondaryMessage = messages.length ? ` (${messages.join(", ")})` : "";
            const percent = progress * 100;
            setLoadingPercent(percent);
            setLoadingMessage(`Upload: ${Math.round(percent)}%${secondaryMessage}`);
          },
        });
      } catch (err) {
        // Do nothing on cancellation via controller.abort()
        if (err.code !== "ERR_CANCELED") {
          throw err;
        }
      }
    },
    [study]
  );

  const checkInHolter = React.useCallback(
    async (data) => {
      const hasFile = !!data.upload?.file;
      const hasFileArray = Array.isArray(data.upload?.files) && data.upload.files.length > 0;
      if (!hasFile && !hasFileArray) {
        return;
      }
      setLoadingPercent(0);

      if (!pendingDeviceEnrollmentId) {
        throw new Error("Error: No patient identifier was present when attempting to upload data");
      }

      if (hasFileArray) {
        // Directory Mode (compress zip)
        setLoadingMessage("Compression: 0%");
        const blob = await compressZip(data.upload.files);
        await uploadZip(pendingDeviceEnrollmentId, blob);
        setCombinedZipBlob(blob);
      } else {
        // File Mode (use pre-compressed zip)
        setLoadingMessage("");
        await uploadZip(pendingDeviceEnrollmentId, data.upload.file);
      }

      if (!importAbortController.current?.signal.aborted) {
        // Wait between zip upload and device check-in to avoid
        // SQL deadlock on the enrollment
        await new Promise((resolve) => {
          setTimeout(resolve, 500);
        });

        const response = await axios({
          method: "post",
          url: `/devices/${study.currentEnrollment.tzSerial}/check-in`,
          params: {patientId: pendingDeviceEnrollmentId},
          responseType: "arraybuffer",
        });

        // set state variables so the click handlers can perform actions
        setActionResponse(response);

        // advance the form to the next page (close is handled by the button)
        setFormProgress(1);
      }
    },
    [compressZip, pendingDeviceEnrollmentId, study, uploadZip]
  );

  const checkInRemote = React.useCallback(async () => {
    await axios({
      method: "post",
      url: `/devices/${study.currentEnrollment.tzSerial}/check-in`,
      params: {remote: true, patientId: study.currentEnrollment.deviceEnrollmentId},
    });
  }, [study]);

  const onSubmit = React.useCallback(
    async (data) => {
      setError(null);
      setSubmitting(true);
      try {
        if (["holter", "extendedHolter"].includes(study.studyType)) {
          await checkInHolter(data);
        } else {
          await checkInRemote();
          await handleDone();
        }
      } catch (err) {
        console.error(err);
        setError(err.response?.data?.title || err.message);
      }
      setSubmitting(false);
    },
    [checkInHolter, checkInRemote, handleDone, study.studyType]
  );

  //---------------------------------------------------------------------------
  // Validation Functions
  //---------------------------------------------------------------------------
  const hasConfig = async (files, isDrive = true) => {
    const MIN_CONFIG_SIZE = 64;
    const MAX_CONFIG_SIZE = 2048;

    const missingConfigError = isDrive
      ? "Selected drive must be a supported device"
      : "Selected zip file must contain files from a supported device";
    const mismatchedDeviceError = isDrive
      ? "Selected drive does not match the device on this study"
      : "Selected file does not match the device on this study";
    const configParseError = isDrive
      ? "An error occurred while reading the configuration file in the selected drive"
      : "An error ocurred while reading the configuration file in the selected zip file";

    const configFile = files.find((file) => {
      const path = file.path?.replace(/.+?\//, "") || file.filename || "";
      const size = file.size || file.uncompressedSize || 0;
      return path.match(/^config.tzs$/i) && size >= MIN_CONFIG_SIZE && size <= MAX_CONFIG_SIZE;
    });
    if (!configFile) {
      return {error: missingConfigError};
    }

    try {
      let contents = "";
      if (typeof configFile.text === "function") {
        // directory-mode (File API)
        contents = await configFile.text();
      } else if (typeof configFile.getData === "function") {
        // pre-zipped (zip.js API)
        contents = await configFile.getData(new TextWriter());
      }
      if (!contents.match(study.currentEnrollment.tzSerial)) {
        return {error: mismatchedDeviceError};
      }
    } catch (e) {
      console.error(e);
      return {error: configParseError};
    }
    return {pass: true};
  };

  const hasECG = async (files, isDrive = true) => {
    const MIN_ECG_SIZE = 64;
    const PATIENT_ID_START = 161;
    const PATIENT_ID_LENGTH = 40;

    const missingDataError = isDrive
      ? "Selected device must contain patient data"
      : "Selected zip file must contain patient data";
    const ecgParseError = isDrive
      ? "An error occurred while parsing the patient identifier on the selected device"
      : "An error occurred while parsing the patient identifier in the selected zip file";

    // Sample ecg paths:
    //   "Z:/ecgs/00/00/00/00.scp"
    //   "/Z_drive/ecgs/00/00/00/00.scp"
    //   "ecgs/00/00/00/00.scp"
    //   "ecgs/scpecg.bin"
    const ecgFile = files.find((file) => {
      const path = file.path?.replace(/.+?\//, "") || file.filename || "";
      const size = file.size || file.uncompressedSize || 0;
      return path?.match(/ecgs\/((.*\.scp)|(scpecg\.bin))$/i) && size >= MIN_ECG_SIZE;
    });
    if (!ecgFile) {
      return {error: missingDataError};
    }

    let patientId;
    try {
      let ecgBuffer;
      if (typeof ecgFile.arrayBuffer === "function") {
        // directory-mode (File API)
        ecgBuffer = await ecgFile.arrayBuffer();
      } else if (typeof ecgFile.getData === "function") {
        // pre-zipped (zip.js API)
        const ecgBlob = await ecgFile.getData(new BlobWriter());
        ecgBuffer = await new Response(ecgBlob).arrayBuffer();
      } else {
        throw new Error("arrayBuffer is not a function, getData is not a function");
      }

      // slice the bytes for the patientId and filter out all control characters
      const patientIdByteArray = new Uint8Array(
        ecgBuffer.slice(PATIENT_ID_START, PATIENT_ID_START + PATIENT_ID_LENGTH)
      ).filter((byte) => byte >= 33 && byte <= 126);

      if (patientIdByteArray.length === 0) {
        throw new Error("No readable patient identifier was present in data");
      }

      // convert all readable ascii codes to string
      patientId = String.fromCharCode.apply(null, patientIdByteArray);
      setPendingDeviceEnrollmentId(patientId);
    } catch (e) {
      console.error(e);
      return {error: ecgParseError};
    }
    return {pass: true, patientId};
  };

  const uniqueDeviceEnrollmentId = async (patientId) => {
    const patientDataConflictError = "An existing enrollment has already received this patient data";

    if (!patientId) {
      return {error: patientDataConflictError};
    }

    const enrollmentsResponse = await axios({
      method: "get",
      url: "/enrollments",
      params: {
        limit: 0,
        deviceEnrollmentId: patientId,
        id: {$ne: study.enrollmentId},
      },
    });

    if (Number(enrollmentsResponse.headers.count) > 0) {
      return {error: patientDataConflictError};
    }

    return {pass: true};
  };

  const displayCheckInButton = React.useMemo(() => {
    // Studies with a proceeding follow-up study should not get manually checked in
    if (study.followUpStudy?.id) {
      return false;
    }

    // Holter studies can be checked in at any time
    if (["holter", "extendedHolter"].includes(study.studyType)) {
      return !isInAnyRole(["physician"]) && !study.currentEnrollment?.formatted;
    }

    // Non-Holter studies can be checked in once the device is done recording
    return !isInAnyRole(["physician"]) && !study.currentEnrollment?.formatted && study.studyEndDate;
  }, [
    study.followUpStudy?.id,
    study.studyType,
    study.currentEnrollment?.formatted,
    study.studyEndDate,
    isInAnyRole,
  ]);
  const watchPhysicalDeviceAvailable = watch("deviceCheckInPhysical");

  return (
    <>
      {displayCheckInButton && (
        <IconButtonWithTooltip
          title={study.checkInDeviceInProgress ? "Device Check In is in progress" : "Check In Device"}
          onClick={handleClickOpen}
          color="secondary"
          otherProps={{disabled: study.checkInDeviceInProgress}}
          data-cy={`device-check-in-button-${study.id}`}
        >
          <MobileFriendly />
        </IconButtonWithTooltip>
      )}

      <Dialog
        data-cy="check-in-device-dialog"
        onClose={handleClose}
        open={open}
        study={study}
        fullWidth
        maxWidth="lg"
        sx={{
          "& .MuiDialog-container": {
            alignItems: "flex-start",
            marginTop: "max(calc(35vh - 200px), 0px)",
            maxHeight: "80vh",
          },
        }}
      >
        <Alert message={error} setMessage={setError} level="error" />

        <DialogTitleBar icon={<MobileFriendly color="secondary" />} title="Check In Device" />

        <DialogContent>
          {!["holter", "extendedHolter"].includes(study.studyType) && (
            <>
              <FormRadioInput
                id="deviceCheckInPhysical"
                name="deviceCheckInPhysical"
                control={control}
                options={[
                  {name: "Yes", id: "Yes"},
                  {name: "No", id: "No"},
                ]}
                ratio={[8, 4]}
              >
                <Typography>Is the device connected to your computer?</Typography>
              </FormRadioInput>

              {watchPhysicalDeviceAvailable === "No" && (
                <>
                  <Box sx={{mb: 2}}>
                    <Alert
                      message="Remotely checking in the device will delete all data pertaining to the patient
                    off of the device. This action cannot be undone."
                      level="warning"
                    />
                  </Box>

                  {!study.currentEnrollment.enrollmentEnded && (
                    <Box data-cy="stop-event-warning-message" sx={{mb: 2}}>
                      <Alert
                        message="Device may not have finished transmitting files for the study. The final
                    study Summary Report item has not been generated."
                        level="warning"
                      />
                    </Box>
                  )}

                  <Typography sx={{mb: 1}}>
                    Please enter the device serial number below to check in the device.
                  </Typography>
                  <FormStringInput
                    control={control}
                    defaultValue=""
                    label="Device Serial Number"
                    name="serialInput"
                    id="serialInput"
                    rules={{
                      required: "A device must be entered",
                      validate: {
                        value: (value) => {
                          if (value !== study.currentEnrollment.tzSerial) {
                            return "The entered device must match the device associated with the selected study";
                          }
                          return true;
                        },
                      },
                    }}
                  />
                </>
              )}
              {watchPhysicalDeviceAvailable === "Yes" && (
                <Alert
                  message="BitRhythm does not currently support physically checking in a device."
                  level="error"
                />
              )}
            </>
          )}

          {["holter", "extendedHolter"].includes(study.studyType) && formProgress === 0 && (
            <Grid container spacing={3}>
              <Grid size={{xs: 12, md: 3}}>
                <CompactStudyInfo study={study} />
              </Grid>
              <Grid size={{xs: 12, md: 9}}>
                <Alert
                  message="Uploading device data may take several minutes. Do not navigate away from the page
                during the upload process. This will overwrite any existing patient data for the study. "
                  level="warning"
                />
              </Grid>
              <Grid size={12} align="center">
                <FormDirectoryInput
                  control={control}
                  name="upload"
                  label="Drag & Drop a Device"
                  id="deviceUploadInput"
                  disabled={submitting}
                  filePickerLabel="Browse zip files"
                  directoryPickerLabel="Browse devices"
                  accept={{"application/zip": [".zip"]}}
                  allowFileAccept
                  disableTabbing
                  rules={{
                    pattern: {
                      value: /\.zip$/i,
                      message: "Selected data must be in .zip format or connected drive",
                    },
                    validate: {
                      single: [
                        async (zipFile) => {
                          const files = [];
                          try {
                            const zipReader = new ZipReader(new BlobReader(zipFile));
                            files.push(...(await zipReader.getEntries()));
                          } catch (e) {
                            console.error(e);
                            return "Selected zip file could not be parsed due to an error";
                          }

                          // apply the validators to files array
                          let result = await hasConfig(files, false);
                          if (result.error) {
                            return result.error;
                          }
                          result = await hasECG(files, false); // {pass, error, patientId}
                          if (result.error) {
                            return result.error;
                          }
                          result = await uniqueDeviceEnrollmentId(result.patientId);
                          if (result.error) {
                            return result.error;
                          }

                          // if none of the validators have returned an error, return true
                          return true;
                        },
                      ],
                      multiple: [
                        async (files) => {
                          // apply the validators to files array
                          let result = await hasConfig(files);
                          if (result.error) {
                            return result.error;
                          }
                          result = await hasECG(files); // {pass, error, patientId}
                          if (result.error) {
                            return result.error;
                          }
                          result = await uniqueDeviceEnrollmentId(result.patientId);
                          if (result.error) {
                            return result.error;
                          }

                          // if none of the validators have returned an error, return true
                          return true;
                        },
                      ],
                    },
                    required: {
                      value: true,
                      message: "Select a device",
                    },
                  }}
                />
              </Grid>
              {submitting && (
                <Grid size={12}>
                  <LinearProgress variant="determinate" value={loadingPercent} data-cy="loading-bar" />
                  <Typography sx={{color: "primary.light", fontStyle: "italic", fontSize: 14}}>
                    {loadingMessage}
                  </Typography>
                </Grid>
              )}
              <Grid size={12}>
                <PatientDiaryTable study={study} />
              </Grid>
            </Grid>
          )}

          {["holter", "extendedHolter"].includes(study.studyType) && formProgress === 1 && (
            <>
              <Box sx={{px: 3, mb: 1}}>
                <Alert
                  message="In order to complete device check-in, BitRhythm needs to download an
                actions file to the device. The actions file must be saved to the device."
                  level="warning"
                />
              </Box>
              <Grid container>
                <Grid size={12} align="center">
                  <Box sx={{px: 3}}>
                    <Button
                      data-cy="download-actions-file-button"
                      variant="contained"
                      color="secondary"
                      onClick={clickedDownloadActions}
                      disabled={submitting}
                    >
                      Download Actions File
                    </Button>
                  </Box>
                </Grid>
                {combinedZipBlob && (
                  <Grid size={12} align="center" sx={{pt: 1}}>
                    <Box sx={{px: 3}}>
                      <Button
                        data-cy="download-zip-data-button"
                        variant="outlined"
                        color="secondary"
                        onClick={clickedDownloadZip}
                        disabled={submitting}
                      >
                        Download Zipped Data
                      </Button>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </DialogContent>

        <DialogActions>
          {formProgress === 0 && (
            <>
              <Box sx={{mx: 2, mb: 2}}>
                <Button color="secondary" onClick={handleClose} data-cy="cancel-check-in-device">
                  Cancel
                </Button>
              </Box>
              <Box sx={{mx: 2, mb: 2}}>
                <LoadingButton
                  id="remoteCheckInButton"
                  variant="contained"
                  color="secondary"
                  onClick={handleSubmit(onSubmit)}
                  disabled={
                    submitting ||
                    (!["holter", "extendedHolter"].includes(study.studyType) &&
                      watchPhysicalDeviceAvailable !== "No")
                  }
                  loading={submitting}
                >
                  Check In
                </LoadingButton>
              </Box>
            </>
          )}
          {formProgress === 1 && (
            <Box sx={{mx: 2, mb: 2}}>
              <Tooltip
                title={!hasDownloadedActions && "Download the Actions file before proceeding"}
                placement="top"
              >
                <span>
                  <LoadingButton
                    id="checkInDoneButton"
                    variant="contained"
                    color="secondary"
                    onClick={handleDone}
                    disabled={submitting || !hasDownloadedActions}
                    loading={submitting}
                  >
                    Done
                  </LoadingButton>
                </span>
              </Tooltip>
            </Box>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

CheckInDeviceDialog.propTypes = {
  study: PropTypes.object.isRequired,
};

export default CheckInDeviceDialog;
