import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import AddProviders from "../../../../shared/react/AddProviders.jsx";
import StudyActionsMenu from "./StudyActionsMenu.jsx";

// =============================================================================
function StudyActionsMenuWrapper({study}) {
  //---------------------------------------------------------------------------
  // NOTE: The only reason that this file is separate from StudyActionsMenu.jsx is because of Angular.
  //  Once we switch completely from Angular to React, ConfirmProvider can be moved to an app.js so
  //  that it encapsulates the entire application. Then these two files can be combined.
  //---------------------------------------------------------------------------

  return (
    <AddProviders>
      <StudyActionsMenu study={study} />
    </AddProviders>
  );
}

StudyActionsMenuWrapper.propTypes = {
  study: PropTypes.object.isRequired,
};

export default StudyActionsMenuWrapper;
