import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import FormStringInput from "../../../shared/react/FormStringInput.jsx";

function DurationOfRecordingInput({
  // Props
  recordedDuration,
  configuredDuration,
  reportType,
  disabled = false,
}) {
  const defaultValue = React.useMemo(() => {
    const durationInHours = recordedDuration || configuredDuration;

    if (reportType === "Summary") {
      return Math.round(durationInHours / 24);
    }
    return durationInHours;
  }, [configuredDuration, recordedDuration, reportType]);

  return (
    <Box sx={{mt: 1, display: "inline-flex", justifyContent: "space-between"}} width="100%">
      <Typography variant="body2" mt={1}>
        Duration of Recording
      </Typography>

      <FormStringInput
        name="recordedDuration"
        type="number"
        size="small"
        endAdornment={<Typography variant="body2">{reportType === "Summary" ? "days" : "hours"}</Typography>}
        htmlInputProps={{min: 1, max: reportType === "Summary" ? 30 : 720}}
        otherProps={{fullWidth: false, variant: "outlined", sx: {width: 115}}}
        rules={{
          required: "Study duration is required",
          min: {
            value: 1,
            message: `Study duration must be greater than or equal to 1 ${reportType === "Summary" ? "day" : "hour"}`,
          },
          max: {
            value: reportType === "Summary" ? 30 : 720,
            message: `Study duration must be less than or equal to ${reportType === "Summary" ? "30 days" : "720 hours"}`,
          },
        }}
        disabled={disabled}
        data-cy="recorded-duration-input"
        defaultValue={defaultValue}
      />
    </Box>
  );
}

DurationOfRecordingInput.propTypes = {
  recordedDuration: PropTypes.number,
  configuredDuration: PropTypes.number,
  reportType: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default React.memo(DurationOfRecordingInput);
