import moment from "moment";

/* @ngInject */
export default class SearchService {
  constructor($rootScope, Config) {
    this._$rootScope = $rootScope;
    this.features = Config.features;
    this._searchText = "";
    this._autoFillValues = [];

    // Visible columns that can be searched using Standard Search
    this._searchableColumnFields = [];

    /* Structure of Item Category Groupings (keys define searchable item categories)
     *
     * Example:
     * {
     *   "notification": ["study action failed", "unconfigured device on patient", "lead disconnected"],
     *   "event": [
     *     "tachycardia", "bradycardia", "unreadable ecg data",
     *     "normal sinus rhythm", "cardiac pause", "atrial fibrillation",
     *     "baseline", "ecg data request", "patient activated event"
     *   ],
     *   "report": ["Daily Trend", "Summary"]
     * }
     */
    this._typeGroups = {};

    /*
     * Structure of searchByCriteria object:
     *   {
     *     <criteria name>: {
     *       criteria: "category|key|timestamp|custom",
     *       columnName: "<name>",
     *       period: "before|after"
     *       throwIfNegated: true|false,
     *       isExact: true|false,
     *       handler: (value, isUuid, isNegated, searchObject, appendToSearch) => {...}
     *     },
     *     ...
     *   }
     * Example: {
     *            "is": {criteria: "category", columnName: "type", throwIfNegated: false},
     *            "device": {criteria: "key", columnName: "tzSerial", throwIfNegated: false, isExact: false},
     *            "serial": {criteria: "key", columnName: "tzSerial", throwIfNegated: false, isExact: false},
     *            "type": {criteria: "key", columnName: "type", throwIfNegated: false, isExact: false},
     *            "study": {criteria: "key", columnName: "studyId", throwIfNegated: false, isExact: false},
     *            "before": {criteria: "timestamp", period: "before", throwIfNegated: true},
     *            "after": {criteria: "timestamp", period: "after", throwIfNegated: true}
     *          }
     *
     *
     * When specifying criteria as "custom", a handler function is expected and will be used. If none is specified,
     * the custom criteria will have no effect. The "appendToSearch" parameter is used by Search service methods
     * to specify whether to build a query on the search object or simply to return whether the given search object
     * matches the given search criteria. Use of internal/private Search service methods will only work for building
     * a query on the object, in which case "appendToSearch" is not needed.
     *
     * Structure of handler function:
     * /
     * * @param {String} value The value on which to match
     * * @param {Boolean} isUuid Whether the value is a UUID
     * * @param {Boolean} isNegated Whether the search for the value is negated
     * * @param {Object} searchObject The search criteria to expand or the item to check
     * * @param {Boolean} appendToSearch Whether to append query objects to the search or simply to calculate a match
     * * @return {Boolean|undefined}
     * /
     * handler(value, isUuid, isNegated, searchObject, appendToSearch) {
     *   // Calculate key
     *   if (...) {
     *      key = ...;
     *      isExact = ...;
     *   }
     *   ...
     *   return Search.searchByKey(key, value, isNegated, isExact, searchObject, appendToSearch);
     * }
     */
    this._searchByCriteria = {};
    this._customStandardSearch = null;
  }

  get searchText() {
    return this._searchText;
  }

  set searchText(searchText) {
    this._searchText = searchText;
    this._notify();
  }

  get columns() {
    // Matches colon-form search pair where quotes are optional both on key and value.
    // Prepend search value with "-" to negate; "-" does not negate when within quotes, when preceding the key,
    // or when not at the beginning of the search value.
    const advancedMatcher =
      /(?<!\S)(?<negatedName>-)?(?<nameGroup>(?:"[^"]+?")|(?:'[^']+?')|[^'":\s-][^'":\s]*):(?<negatedValue>-)?(?<valueGroup>(?:"[^"]*?")|(?:'[^']*?')|(?:[^'"\s]+))(?!\S)/g;
    const discardMatcher = /(?<!\S)((?:"[^"]+?")|(?:'[^']+?')|[^'":\s-][^'":\s]*)?:|-(?!\S)/g;

    // Matches a "dumb" search (without key/column) where quote marks are optional.
    // Prepend with "-" to negate; "-" does not negate within quotes or when not at the beginning of the search value.
    const standardMatcher =
      /(?<!\S)(?<negated>-)?(?<valueGroup>(?:"[^"]*?")|(?:'[^']*?')|(?:[^'":\s]+))(?!\S)/g;

    // Matchers for individual search elements
    const quotedMatcher = /^"[^"]+"|'[^']+'$/;
    const uuidMatcher = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

    let name;
    let searchText;
    let matches;
    let isNegated;
    let isUuid;
    const result = {advancedColumns: [], standardColumns: []};

    // searchTextData is an array[4] of the search text, shrinking at each successive
    // element as content is matched and removed. Search Text is made up of 4 components:
    // 'Key:Value' pairs, lonely 'Key:' instances, Standard search terms, and leftovers.
    //
    // 0: Full text made lowercase
    // 1: Minus 'Key:Value' pairs
    // 2: Minus 'Key:' instances without values
    // 3: Text that did not match the standard matcher; lonely '-' and ':'
    const searchTextData = [this._searchText.toLowerCase()];

    // Parse and collect advanced search terms
    searchTextData.push(searchTextData[0]);
    // eslint-disable-next-line no-cond-assign
    while ((matches = advancedMatcher.exec(searchTextData[0]))) {
      // Filter out consumed search text from string
      searchTextData[1] = searchTextData[1].replace(matches[0], "");

      const {nameGroup, negatedName, negatedValue, valueGroup} = matches.groups;

      name = quotedMatcher.test(nameGroup) ? nameGroup.slice(1, -1) : nameGroup;
      isNegated = (negatedName === "-") !== (negatedValue === "-");
      searchText = quotedMatcher.test(valueGroup) ? valueGroup.slice(1, -1) : valueGroup;
      isUuid = uuidMatcher.test(searchText);

      if (name === "number") {
        // Remove # from search if they are searching by itemNumber
        searchText = searchText.replace("#", "");
      }

      // Standardize Negation on Item-Category searching
      if (name === "not") {
        name = "is";
        isNegated = !isNegated;
      }

      result.advancedColumns.push({name, searchText, isNegated, isUuid});
    }

    // Discard lonely 'Key:' instances, dashes ('-'), and colons (':')
    searchTextData.push(searchTextData[1]);
    // eslint-disable-next-line no-cond-assign
    while ((matches = discardMatcher.exec(searchTextData[1]))) {
      searchTextData[2] = searchTextData[2].replace(matches[0], "");
    }

    // Parse and collect standard search terms
    searchTextData.push(searchTextData[2]);
    // eslint-disable-next-line no-cond-assign
    while ((matches = standardMatcher.exec(searchTextData[2]))) {
      // Filter out consumed search text from string
      searchTextData[3] = searchTextData[3].replace(matches[0], "");

      const {negated, valueGroup} = matches.groups;

      isNegated = negated === "-";
      searchText = quotedMatcher.test(valueGroup) ? valueGroup.slice(1, -1) : valueGroup;
      result.standardColumns.push({searchText, isNegated});
    }

    if (result.advancedColumns.length === 0) {
      delete result.advancedColumns;
    }
    if (result.standardColumns.length === 0) {
      delete result.standardColumns;
    }
    return result;
  }

  get autoFillValues() {
    return this._autoFillValues;
  }

  set autoFillValues(values) {
    this._autoFillValues = values;
  }

  get searchableColumnFields() {
    return this._searchableColumnFields;
  }

  set searchableColumnFields(fields) {
    this._searchableColumnFields = fields;
  }

  get typeGroups() {
    return this._typeGroups;
  }

  set typeGroups(groupsObject) {
    this._typeGroups = groupsObject;
  }

  get searchByCriteria() {
    return this._searchByCriteria;
  }

  set searchByCriteria(criteriaObject) {
    this._searchByCriteria = criteriaObject;
  }

  get customStandardSearch() {
    return this._customStandardSearch;
  }

  set customStandardSearch(searchFunction) {
    this._customStandardSearch = searchFunction;
  }

  // Used to clear without notifying subscribers. Implemented for state change.
  clearSearchText() {
    this._searchText = "";
  }

  getDateFromText(searchText) {
    const date = moment(searchText);
    if (date.year() === 2001) {
      date.year(moment().year());
    }
    const formattedDate = date.utc().format("YYYY-MM-DDTHH:mm:ssZ");
    if (formattedDate === "Invalid date") {
      throw new Error(`"${searchText}" could not be read as a date.`);
    }
    return formattedDate;
  }

  subscribe(scope, callback) {
    const handler = this._$rootScope.$on("search-text-changed", callback);
    // de-registers handler
    scope.$on("$destroy", handler);
  }

  querySearch(query) {
    if (query) {
      return this._autoFillValues.filter((value) => {
        // make it case insensitive and remove all double quotes
        const updatedQuery = query.toLowerCase().replace(/"/g, "");
        return value.toLowerCase().includes(updatedQuery) && value.toLowerCase() !== updatedQuery;
      });
    }
    return this._autoFillValues;
  }

  setSearch(text) {
    this._searchText = text;
    this._notify();
  }

  /**
   * Digests the current search text of the Search service and converts them into query parameters.
   *
   * Structure of params object:
   *   - For Searches:
   *       $and: [
   *         {name: {$like: `%${text}%`}}, -> (one per keyword search)
   *         ...
   *         {$or: [{},{}...{} (one per searchable column)]} -> (one per standard search or category search)
   *         ...            ^ Each object is:
   *                               {columnName: {$like: `%${text}%`}} (for Standard Search)
   *                               {type: {$eq: categoryOption}} (for Category Search)
   *       ],
   *
   * @param {Object} params
   * @example {
   *            completed: false,
   *            order,
   *            $and: [], // For Searches
   *          }
   * @return {Object}
   */
  convertSearchToQueryParams(params = {}) {
    params.$and = params.$and || [];

    const {standardColumns, advancedColumns} = this.columns;

    if (advancedColumns || standardColumns) {
      try {
        if (advancedColumns) {
          advancedColumns.forEach((column) => {
            const searchCriteria = this.searchByCriteria[column.name];
            if (!searchCriteria) {
              throw new Error(`Search column "${column.name}" is not supported.`);
            }

            if (searchCriteria.throwIfNegated) {
              this.throwIfNegated(column);
            }

            switch (searchCriteria.criteria) {
              case "category":
                this.searchByCategory(searchCriteria.columnName, column.searchText, column.isNegated, params);
                break;
              case "timestamp":
                this._searchByTimestamp(
                  searchCriteria.columnName,
                  searchCriteria.period,
                  this.getDateFromText(column.searchText),
                  column.isNegated,
                  params
                );
                break;
              case "key":
                this.searchByKey(
                  searchCriteria.columnName,
                  column.searchText,
                  column.isNegated,
                  searchCriteria.isExact,
                  params
                );
                break;
              case "custom":
                this._searchByCustomHandler(
                  searchCriteria.handler,
                  column.searchText,
                  column.isUuid,
                  column.isNegated,
                  params
                );
                break;
              default:
                throw new Error(`Search column "${column.name}" is not supported.`);
            }
          });
        }
        if (standardColumns) {
          standardColumns.forEach((column) => this._searchByVisible(column, params));
        }
      } catch (error) {
        throw new Error(`Search text invalid: ${error.message}`);
      }
    } else {
      try {
        this.validateUnparsableSearchText();
      } catch (error) {
        throw new Error(`Search text invalid: ${error.message}`);
      }
    }

    return params;
  }

  /**
   * Determine if Item matches the restrictions set by search text.
   * @param {Object} item
   * @returns {Boolean} true if item matches all of the conditions specified by search text.
   *
   * @see BR-4060
   */
  itemMatchesSearchText(item = {}) {
    if (!this.searchText) {
      return true;
    }
    const {standardColumns, advancedColumns} = this.columns;
    let matchesAllStandardColumns = true;
    let matchesAllAdvancedColumns = true;

    if (standardColumns) {
      // All of the standard terms have to match (every)
      matchesAllStandardColumns = standardColumns.every((column) => {
        // ...but each term only has to match one of the searchableFields (some)
        let someMatch = this.searchableColumnFields.some((field) => {
          if (item[field] === undefined || item[field] === null) {
            return false;
          }
          return `${item[field]}`.toLowerCase().includes(column.searchText);
        });
        if (column.isNegated) {
          someMatch = !someMatch;
        }
        return someMatch;
      });
    }
    if (advancedColumns) {
      // All of the advanced terms have to match (every)
      matchesAllAdvancedColumns = advancedColumns.every((column) => {
        let validColumnName = true;
        let match = false;
        const searchCriteria = this.searchByCriteria[column.name];

        if (!searchCriteria) {
          validColumnName = false;
          match = false;
        } else {
          switch (searchCriteria.criteria) {
            case "category":
              if (this.typeGroups[column.searchText]) {
                const typeListOrHandler = this.typeGroups[column.searchText];
                if (typeof typeListOrHandler === "function") {
                  // is:assigned, is:unassigned, is:classified, is:unclassified
                  // no negation since negation is handled below
                  const isNegated = false;
                  const appendToSearch = false;
                  match = typeListOrHandler(isNegated, item, appendToSearch);
                } else {
                  // is:event, is:report, is:notification
                  match = typeListOrHandler.includes(item[searchCriteria.columnName].toLowerCase());
                }
              }
              break;
            case "timestamp":
              try {
                // Determine whether to check before or after the given time
                if (searchCriteria.period === "before") {
                  match = moment(item[searchCriteria.columnName]).isBefore(
                    this.getDateFromText(column.searchText)
                  );
                } else if (searchCriteria.period === "after") {
                  match = moment(item[searchCriteria.columnName]).isAfter(
                    this.getDateFromText(column.searchText)
                  );
                }
              } catch (error) {
                match = false;
              }
              break;
            case "key":
              // Match by key with no negation since will check below for all cases
              match = this._matchByKey(
                searchCriteria.columnName,
                column.searchText,
                false,
                searchCriteria.isExact,
                item
              );
              break;
            case "custom":
              // Match by custom handler with no negation since will check below for all cases
              match = this._matchByCustomHandler(
                searchCriteria.handler,
                column.searchText,
                column.isUuid,
                false,
                item
              );
              break;
            default:
              validColumnName = false;
              match = false;
          }
          // Invert if negated
          if (column.isNegated) {
            match = !match;
          }
        }
        // validColumnName needs to be evaluated here due to negation logic
        return validColumnName && match;
      });
    }
    return matchesAllStandardColumns && matchesAllAdvancedColumns;
  }

  validateUnparsableSearchText() {
    // This occurs when there are mismatched/missing quotation marks
    const containsQuotes = /"|'/.test(this._searchText);

    if (containsQuotes) {
      throw new Error(
        `Missing quotation mark. Quotes may only be used in the following format:\n column:"search text"`
      );
    }
  }

  /**
   * Exact form is required for searching UUID fields.
   * E.g. studyId must be exact
   * Patient must be exact if searching by UUID but not if by name
   *
   * @param {String} key The search key (column name)
   * @param {String} value The value to search for (search text)
   * @param {Boolean} isNegated
   * @param {Boolean} isExact
   * @param {Object} searchObject The search criteria to expand or the item to check
   * @param {Boolean} appendToSearch Whether to append query objects to the search or simply to calculate a match
   * @return {Object|undefined}
   *
   * @see BR-4060
   */
  searchByKey(key, value, isNegated, isExact, searchObject, appendToSearch = true) {
    if (appendToSearch) {
      return this._queryByKey(key, value, isNegated, isExact, searchObject);
    }
    return this._matchByKey(key, value, isNegated, isExact, searchObject);
  }

  /**
   * @param {Array} keyValueList The search query list to add (OR logic)
   * @param {Boolean} isNegated
   * @param {Boolean} isExact
   * @param {Object} searchObject The search criteria to expand or the item to check
   * @param {Boolean} appendToSearch Whether to append query objects to the search or simply to calculate a match
   * @return {Object|undefined}
   *
   * @see BR-4060
   */
  searchByKeyValueList(keyValueList, isNegated, isExact, searchObject, appendToSearch = true) {
    if (appendToSearch) {
      return this._queryByKeyValueList(keyValueList, isNegated, isExact, searchObject);
    }
    return this._matchByKeyValueList(keyValueList, isNegated, isExact, searchObject);
  }

  /**
   * Throws error if column is Negated
   * @param {Object} column
   * @param {Boolean} column.isNegated
   * @param {String} column.name
   *
   * @see BR-4060
   */
  throwIfNegated(column) {
    if (column.isNegated) {
      throw new Error(`Negation not supported on search term "${column.name}".`);
    }
  }

  /**
   * Exact form is required for searching UUID fields.
   * E.g. facilityId must be exact
   * Device must be exact if searching by UUID but not if by tzSerial
   *
   * @param {String} key The search key (column name)
   * @param {String} value The value to search for (search text)
   * @param {Boolean} isNegated
   * @param {Boolean} isExact
   * @param {Object} params object to which to append searches
   *
   * @see BR-4060
   */
  _queryByKey(key, value, isNegated, isExact, params) {
    const result = {};
    if (isExact) {
      const dynamicKey = isNegated ? "$not" : "$eq";
      result[key] = {[dynamicKey]: value};
    } else {
      let dynamicObject = result;
      if (isNegated) {
        result.$not = {};
        dynamicObject = result.$not;
      }
      dynamicObject[key] = {$like: `%${value}%`};
    }

    params.$and.push(result);
  }

  /**
   * Calculates whether the given item matches the given search terms
   *
   * @param {String} key The search key (column name)
   * @param {String} value The value to search for (search text)
   * @param {Boolean} isNegated
   * @param {Boolean} isExact
   * @param {Object} item object to check
   * @return {Boolean} Whether the item matches the given search terms
   *
   * @see BR-4060
   */
  _matchByKey(key, value, isNegated, isExact, item) {
    let match = false;
    if (item[key] !== undefined || item[key] !== null) {
      if (isExact) {
        match = `${item[key]}`.toLowerCase() === value;
      } else {
        match = `${item[key]}`.toLowerCase().includes(value);
      }
    }

    if (isNegated) {
      match = !match;
    }

    return match;
  }

  /**
   * @param {Array} keyValueList The search query list to add (OR logic)
   * @param {Boolean} isNegated
   * @param {Boolean} isExact
   * @param {Object} params object to which to append searches
   *
   * @see BR-4060
   */
  _queryByKeyValueList(keyValueList, isNegated, isExact, params) {
    const searchList = keyValueList.map(({key, value, isExactOverride = false}) => {
      const result = {};
      const valueIsExact = isExact || isExactOverride;
      if (valueIsExact) {
        result[key] = {$eq: value};
      } else {
        result[key] = {$like: `%${value}%`};
      }
      return result;
    });

    if (isNegated) {
      // Add the list as an "$nor" object
      params.$and.push({$not: {$or: searchList}});
    } else {
      // Add the list as an $or object
      params.$and.push({$or: searchList});
    }
  }

  /**
   * @param {Array} keyValueList The search query list to add (OR logic)
   * @param {Boolean} isNegated
   * @param {Boolean} isExact
   * @param {Object} item object to check
   * @return {Boolean} Whether the item matches the given search terms
   *
   * @see BR-4060
   */
  _matchByKeyValueList(keyValueList, isNegated, isExact, item) {
    let match = keyValueList.some(({key, value, isExactOverride = false}) => {
      const valueIsExact = isExact || isExactOverride;
      if (item[key] === undefined || item[key] === null) {
        return false;
      }
      if (valueIsExact) {
        return `${item[key]}`.toLowerCase() === value;
      }
      return `${item[key]}`.toLowerCase().includes(value);
    });

    if (isNegated) {
      match = !match;
    }

    return match;
  }

  /**
   * @param {Object} column
   * @param {Boolean} column.isNegated
   * @param {String} column.searchText value to search visible columns for
   * @param {Object} params object to which to append searches
   *
   * @see BR-4060
   */
  _searchByVisible(column, params) {
    // Create a list from each searchable field name
    let standardSearchList = this.searchableColumnFields.map((name) => {
      return {key: name, value: column.searchText};
    });
    if (this.customStandardSearch) {
      standardSearchList = standardSearchList.concat(this.customStandardSearch(column.searchText));
    }

    this.searchByKeyValueList(standardSearchList, column.isNegated, false, params);
  }

  /**
   * @param {String} key The search key (column name)
   * @param {String} category The category name to search by
   * @param {Boolean} isNegated
   * @param {Object} params object to which to append searches
   *
   * @see BR-4060
   */
  searchByCategory(key, category, isNegated, params) {
    const typeListOrHandler = this.typeGroups[category];
    if (typeListOrHandler === undefined) {
      throw new Error(`Search Text "${category}" is not a valid item category.`);
    }

    if (typeof typeListOrHandler === "function") {
      // Call Custom Type Handler (is:assigned, is:unassigned, is:classified, is:unclassified)
      const appendToSearch = true;
      typeListOrHandler(isNegated, params, appendToSearch);
    } else {
      // Create a list where the field must equal one of the category's item types
      const categorySearchList = typeListOrHandler.map((option) => ({key, value: option}));
      this.searchByKeyValueList(categorySearchList, isNegated, true, params);
    }
  }

  /**
   * @param {Function} handler The custom handler accepting the search text value, whether it is a UUID,
   *                           whether it is negated, and the query parameters object
   * @param {String} value The value to search for (search text)
   * @param {Boolean} isUuid
   * @param {Boolean} isNegated
   * @param {Object} params object to which to append searches
   *
   * @see BR-4060
   */
  _searchByCustomHandler(handler, value, isUuid, isNegated, params) {
    if (typeof handler !== "function") {
      handler = () => undefined;
    }
    const appendToSearch = true;
    handler(value, isUuid, isNegated, params, appendToSearch);
  }

  /**
   * @param {Function} handler The custom handler accepting the search text value, whether it is a UUID,
   *                           whether it is negated, and the query parameters object
   * @param {String} value The value to search for (search text)
   * @param {Boolean} isUuid
   * @param {Boolean} isNegated
   * @param {Object} item object to check
   * @return {Boolean} Whether the item matches the given search terms
   *
   * @see BR-4060
   */
  _matchByCustomHandler(handler, value, isUuid, isNegated, item) {
    if (typeof handler !== "function") {
      handler = () => undefined;
    }
    return handler(value, isUuid, isNegated, item, false);
  }

  /**
   * @param {String} key The search key (column name)
   * @param {String} beforeOrAfter Must be one of "before" or "after"
   * @param {String} datetime
   * @param {Object} isNegated
   * @param {Object} params object to which to append searches
   *
   * @see BR-4060
   */
  _searchByTimestamp(key, beforeOrAfter, datetime, isNegated, params) {
    if (!params[key]) {
      params[key] = {};
    }
    if ((beforeOrAfter === "before" && !isNegated) || (beforeOrAfter === "after" && isNegated)) {
      params[key].$lt = datetime;
    } else if ((beforeOrAfter === "after" && !isNegated) || (beforeOrAfter === "before" && isNegated)) {
      params[key].$gt = datetime;
    }
  }

  _notify() {
    this._$rootScope.$emit("search-text-changed");
  }
}
