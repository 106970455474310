import angular from "angular";

import {angularizeDirective} from "../../shared/react-in-angular.js";
import ResetAccountWrapper from "./ResetAccountWrapper.jsx";

export default angular.module("app.components.resetAccount", []).name;

// matches <br-reset-account>
angularizeDirective(ResetAccountWrapper, "brResetAccount", angular.module("app.components.resetAccount"), {
  username: "<",
});
