import angular from "angular";

import assignItem from "./assignItem/index.js";
import editReport from "./editReport/index.js";
import itemConfirmationDialog from "./itemConfirmationDialog/index.js";
import rawHrData from "./rawHrData/index.js";
import NewStudy from "./StudyDialogs/NewStudy/index.js";
import StudyActionsMenu from "./StudyDialogs/StudyActions/StudyActionsMenu/index.js";

export default angular.module("app.dialogs", [
  assignItem,
  editReport,
  rawHrData,
  itemConfirmationDialog,
  NewStudy,
  StudyActionsMenu,
]).name;
