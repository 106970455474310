import React from "react";
import {useFormContext} from "react-hook-form";
import {DateTime} from "luxon";
import PropTypes from "prop-types";
import isMobilePhone from "validator/lib/isMobilePhone";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import FormDateTimeInput from "../../shared/react/FormDateTimeInput.jsx";
import FormStringInput from "../../shared/react/FormStringInput.jsx";
import LinkifyText from "../../shared/react/LinkifyText.jsx";
import languages from "./languages.js";

function PatientInfo({
  // Props
  readOnly = false,
  study = {},
  requiredFields,
}) {
  //---------------------------------------------------------------------------
  // Retrieve all hook methods from the controlled form
  //---------------------------------------------------------------------------
  const {control} = useFormContext();

  const patientInfoFields = React.useMemo(() => {
    const validPatientGenderFieldOptions = ["", "Male", "Female"];
    return [
      {
        id: "patientName",
        label: "Name",
        xs: 12,
        sm: 6,
        rules: {
          validate: {
            hasTwoNames: (patientName) => {
              if (requiredFields?.patientName) {
                return /\S\s+\S/.test(patientName) || "Include first and last name";
              }
              return true;
            },
          },
        },
      },
      {id: "patientId", label: "ID", xs: 12, sm: readOnly ? 6 : 3},
      {
        id: "patientLanguage",
        label: "Language",
        xs: 12,
        sm: readOnly ? 6 : 3,
        options: languages.map((l) => ({...l, id: l.name})),
        defaultValue: "English",
      },
      {
        id: "patientDob",
        isDate: true,
        label: "DOB",
        xs: 6,
        sm: readOnly ? 6 : 3,
        rules: {
          validate: {
            isValidDoB: (patientDob) => {
              if (patientDob) {
                if (patientDob.invalid) {
                  return "Patient date of birth must be in a valid format";
                }
                if (
                  !patientDob
                    .toFormat("yyyy/MM/dd")
                    .match("^(([1-2][0-9][0-9][0-9])/(1[0-2]|0[1-9])/(0[1-9]|[1-2][0-9]|3[0-1]))?$")
                ) {
                  return "Patient date of birth is not a valid date";
                }
                if (patientDob > DateTime.local()) {
                  return "Patient date of birth cannot be in the future";
                }
              }
              return true;
            },
          },
        },
        otherProps: {
          views: ["year", "month", "day"],
          ampm: false,
          disableFuture: true,
        },
      },
      {
        id: "patientGender",
        label: "Sex Designated at Birth",
        xs: 6,
        sm: readOnly ? 6 : 3,
        options: [
          {name: "<None Selected>", id: ""},
          {name: "Male", id: "Male"},
          {name: "Female", id: "Female"},
          ...(!validPatientGenderFieldOptions.includes(study.studyDetails?.patientGender || "")
            ? [
                {
                  name: study.studyDetails.patientGender,
                  id: study.studyDetails.patientGender,
                  disabled: true,
                },
              ]
            : []),
        ],
        rules: {
          validate: {
            isValidSex: (sex) =>
              validPatientGenderFieldOptions.includes(sex) ||
              "Please select a valid option from the dropdown",
          },
        },
      },
      {id: "patientHeight", label: "Height", xs: 12, sm: readOnly ? 6 : 1.5},
      {id: "patientWeight", label: "Weight", xs: 12, sm: readOnly ? 6 : 1.5},
      {
        id: "patientPhoneNumber",
        label: "Phone",
        xs: 12,
        sm: readOnly ? 6 : 3,
        rules: {
          validate: {
            isValidPhone: (phone) =>
              !phone || isMobilePhone(phone) ? true : "Phone must be a valid phone number",
          },
        },
      },
      {
        id: "patientAddress",
        label: "Address",
        xs: 12,
        sm: 12,
      },
      {
        id: "patientNotes",
        label: "Notes",
        xs: 12,
        sm: 12,
        link: true,
        otherProps: {multiline: true, maxRows: 4},
      },
    ];
  }, [readOnly, study.studyDetails?.patientGender, requiredFields?.patientName]);

  const emergencyContactFields = React.useMemo(
    () => [
      {
        id: "emergencyContactName",
        label: "Name",
        xs: 12,
        sm: 4,
      },
      {
        id: "emergencyContactRelation",
        label: "Relation to Patient",
        xs: 12,
        sm: 4,
      },
      {
        id: "emergencyContactPhone",
        label: "Phone",
        xs: 12,
        sm: 4,
        rules: {
          validate: {
            isValidPhone: (phone) =>
              !phone || isMobilePhone(phone) ? true : "Phone must be a valid phone number",
          },
        },
      },
    ],
    []
  );

  const initializeFieldValue = React.useCallback((field, studyDetails) => {
    if (field.isDate) {
      return studyDetails?.[field.id] ? DateTime.fromFormat(studyDetails?.[field.id], "yyyy/MM/dd") : null;
    }
    return studyDetails?.[field.id] || field.defaultValue || "";
  }, []);

  return (
    <Grid container spacing={2} sx={{alignItems: "flex-start"}}>
      {patientInfoFields.map((field) => {
        if (readOnly) {
          return (
            <Grid key={field.id} size={{xs: field.xs, sm: field.sm}}>
              <Typography variant="body2" id={`${field.id}Value`}>
                <b>{field.label}</b>:{" "}
                {field.link ? (
                  <LinkifyText text={study.studyDetails?.[field.id]} />
                ) : (
                  study.studyDetails?.[field.id]
                )}
              </Typography>
            </Grid>
          );
        }

        if (field.isDate) {
          return (
            <Grid key={field.id} size={{xs: field.xs, sm: field.sm}}>
              <FormDateTimeInput
                disabled={readOnly}
                required={requiredFields?.[field.id]}
                defaultValue={initializeFieldValue(field, study.studyDetails)}
                control={control}
                label={field.label}
                name={field.id}
                id={`${field.id}Input`}
                rules={{
                  ...field.rules,
                  ...(requiredFields?.[field.id] && {required: `${field.label} is required`}),
                }}
                otherProps={field.otherProps}
              />
            </Grid>
          );
        }

        return (
          <Grid key={field.id} size={{xs: field.xs, sm: field.sm}}>
            <FormStringInput
              disabled={readOnly}
              required={requiredFields?.[field.id]}
              control={control}
              defaultValue={initializeFieldValue(field, study.studyDetails)}
              label={field.label}
              name={field.id}
              id={`${field.id}Input`}
              rules={{
                ...field.rules,
                ...(requiredFields?.[field.id] && {required: `${field.label} is required`}),
              }}
              options={field.options}
            />
          </Grid>
        );
      })}

      <Grid size={12} sx={{mt: 2}}>
        <Typography variant="h6">Emergency Contact</Typography>
      </Grid>
      {emergencyContactFields.map((field) => (
        <Grid key={field.id} size={{xs: field.xs, sm: field.sm}}>
          {readOnly ? (
            <Typography id={`${field.id}Value`} sx={{fontSize: 15}}>
              <b>{field.label}</b>:{" "}
              {field.link ? (
                <LinkifyText text={study.studyDetails?.[field.id]} />
              ) : (
                study.studyDetails?.[field.id]
              )}
            </Typography>
          ) : (
            <FormStringInput
              disabled={readOnly}
              required={requiredFields?.[field.id]}
              control={control}
              defaultValue={study.studyDetails?.[field.id] || ""}
              label={field.label}
              name={field.id}
              id={`${field.id}Input`}
              rules={{
                ...field.rules,
                ...(requiredFields?.[field.id] && {required: `${field.label} is required`}),
              }}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
}

PatientInfo.propTypes = {
  readOnly: PropTypes.bool,
  study: PropTypes.object,
  requiredFields: PropTypes.object.isRequired,
};

export default PatientInfo;
