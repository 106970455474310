import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import AddProviders from "../../shared/react/AddProviders.jsx";
import StudiesTable from "./StudiesTable.jsx";

function Studies({
  // Props
  search = "",
}) {
  return (
    <AddProviders>
      <StudiesTable searchText={search} />
    </AddProviders>
  );
}
Studies.propTypes = {
  search: PropTypes.string,
};
export default Studies;
