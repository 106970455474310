import React from "react";
import {useForm, useFormState} from "react-hook-form";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import FilterAlt from "@mui/icons-material/FilterAlt";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import CheckboxInput from "./CheckboxInput.jsx";
import IconButtonWithTooltip from "./IconButtonWithTooltip.jsx";

function Filter({
  // Props
  "data-cy": dataCy,
  options,
  optionsFilter,
  title = "Filter",
  onSubmit,
}) {
  //---------------------------------------------------------------------------
  // Form Submission
  //---------------------------------------------------------------------------
  const {handleSubmit, control, setValue, getValues} = useForm();
  const {isDirty} = useFormState({control});

  //---------------------------------------------------------------------------
  // Menu state management
  //---------------------------------------------------------------------------
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = React.useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleCloseMenu = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  //---------------------------------------------------------------------------
  // Filter Badge
  //---------------------------------------------------------------------------
  const invisible = React.useMemo(() => {
    // Don't show badge if all of the filters are selected
    const numSelected = Object.values(optionsFilter).filter((selected) => selected).length;
    return numSelected === Object.values(optionsFilter).length;
  }, [optionsFilter]);

  return (
    <>
      <IconButtonWithTooltip
        title={title}
        onClick={handleOpenMenu}
        color="tertiary"
        otherProps={{size: "small"}}
        data-cy={`filter-${dataCy}-button`}
      >
        <Badge variant="dot" color="secondary" invisible={invisible}>
          <FilterAlt />
        </Badge>
      </IconButtonWithTooltip>

      <Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
        <MenuList dense data-cy={`filter-${dataCy}-menu`} sx={{p: 0, mt: 0.5, mx: 0.5}}>
          {options.map(({id, label}) => (
            <MenuItem
              onClick={() => setValue(id, !getValues(id), {shouldDirty: true})}
              key={id}
              data-cy={`filter-${id}-button`}
            >
              <CheckboxInput
                name={id}
                control={control}
                data-cy={`${id}-input`}
                defaultValue={optionsFilter[id]}
                otherProps={{size: "small", sx: {py: 0, pl: 0, pr: 1}}}
              />
              {label}
            </MenuItem>
          ))}
          <Box sx={{px: 1, pt: 1.5}}>
            <Button
              data-cy="submit-button"
              variant="contained"
              color="secondary"
              onClick={handleSubmit(onSubmit)}
              disabled={!isDirty}
              size="small"
              fullWidth
            >
              Apply
            </Button>
          </Box>
          <Box sx={{px: 1, pt: 1}}>
            <Button
              color="secondary"
              size="small"
              fullWidth
              data-cy="filter-cancel-button"
              onClick={handleCloseMenu}
            >
              Cancel
            </Button>
          </Box>
        </MenuList>
      </Menu>
    </>
  );
}

Filter.propTypes = {
  "data-cy": PropTypes.string.isRequired,
  title: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired,
    })
  ).isRequired,
  optionsFilter: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Filter;
