import {DateTime, IANAZone, Interval} from "luxon";

/* @ngInject */
export default class Timeline {
  constructor(Drawing) {
    this._Drawing = Drawing;

    this.NUMBER_OF_MARKS = 5;

    // Adding half values to correct for the Canvas Grid system
    // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Applying_styles_and_colors#A_lineWidth_example
    this.CANVAS_LINE_OFFSET = 0.5;

    this.timelineColor = "rgb(0, 0, 0)";
  }

  drawTimeline(ecgViewer) {
    const timeMarks = this._getTimeMarks(
      DateTime.fromISO(ecgViewer.ecgData.startTime),
      ecgViewer.config.timeZone,
      ecgViewer.ecgData.leads[0].totalSamples,
      ecgViewer.ecgData.samplesPerSecond
    );

    this._drawTopLine(ecgViewer);
    this._drawTimeMarks(ecgViewer, timeMarks);
  }

  _drawTopLine(ecgViewer) {
    const canvas = ecgViewer.timelineCanvas;
    const y = 1 + this.CANVAS_LINE_OFFSET;
    const line = {
      startX: 0,
      startY: y,
      endX: ecgViewer.width,
      endY: y,
      color: this.timeLineColor,
      width: 2,
    };

    this._Drawing.drawLine(canvas, line);
  }

  _drawTimeMarks(ecgViewer, timestamps) {
    const canvas = ecgViewer.timelineCanvas;
    const segmentWidth = ecgViewer.width / (this.NUMBER_OF_MARKS - 1);
    const textSettings = {
      fontSize: 12,
      fontFamily: "Roboto",
      color: "black",
    };

    for (let i = 0; i < this.NUMBER_OF_MARKS; i++) {
      const line = {
        startX: 0 + i * segmentWidth + this.CANVAS_LINE_OFFSET,
        startY: 0 + this.CANVAS_LINE_OFFSET,
        endX: 0 + i * segmentWidth + this.CANVAS_LINE_OFFSET,
        endY: 6 + this.CANVAS_LINE_OFFSET,
        color: this.timeLineColor,
        width: 2,
      };

      // default text align for timestamps
      textSettings.textAlign = "center";

      // Handle positioning of 1st tick and timestamp label
      if (i === 0) {
        line.startX++;
        line.endX++;
        textSettings.textAlign = "left";
      }

      // Handle positioning of last tick and timestamp label
      if (i === this.NUMBER_OF_MARKS - 1) {
        line.startX -= 2;
        line.endX -= 2;
        textSettings.textAlign = "right";
      }
      this._Drawing.drawLine(canvas, line);
      this._Drawing.drawText(canvas, line.startX, line.endY + 2, timestamps[i], textSettings);
    }
  }

  _getTimeMarks(startTime, timeZone, totalSamples, samplesPerSecond) {
    const durationInSeconds = totalSamples / samplesPerSecond;

    if (timeZone && IANAZone.isValidZone(timeZone)) {
      startTime = startTime.setZone(timeZone);
    }

    const endTime = startTime.plus({seconds: durationInSeconds});
    const segments = Interval.fromDateTimes(startTime, endTime).divideEqually(this.NUMBER_OF_MARKS - 1);

    return [startTime.toFormat("HH:mm:ss"), ...segments.map((segment) => segment.end.toFormat("HH:mm:ss"))];
  }
}
