import React from "react";
import {useFormContext} from "react-hook-form";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Print from "@mui/icons-material/Print";

//---------------------------------------------------------------------------
// MUI
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../../axiosClient.js";
import {useReportsDispatch} from "../../../contexts/ReportsContext.jsx";
import MdnCriteriaTooltip from "../../MdnCriteriaTooltip/MdnCriteriaTooltip.jsx";

function SignedReportActions({
  // Props
  report,
  study,
  disabled = false,
  setSuccessMessage,
  setError,
  handleClose,
}) {
  //---------------------------------------------------------------------------
  // Form submission
  //---------------------------------------------------------------------------
  const {handleSubmit} = useFormContext();
  const [submitting, setSubmitting] = React.useState(false);
  const dispatch = useReportsDispatch();

  const onPrint = React.useCallback(async () => {
    setSubmitting(true);

    try {
      //---------------------------------------------------------------------------
      // Update the generated report
      //---------------------------------------------------------------------------
      const {data: updatedReport} = await axios({
        method: "patch",
        url: `/generatedReports/${report.id}`,
        data: {state: "printed"},
      });

      // Set the success message before the dispatcher in case filters hide this report state
      setSuccessMessage("Successfully marked as printed"); // @TODO `Successfully marked ${title} as printed`

      dispatch({type: "updated", updatedElement: {...report, ...updatedReport}});

      setSubmitting(false);
      handleClose();
    } catch (err) {
      setError(err.message);
      setSubmitting(false);
    }
  }, [dispatch, report, handleClose, setError, setSuccessMessage]);

  return (
    <>
      <MdnCriteriaTooltip criteria={study.studyDetails?.physicianNotes} met={!!report.meetsMdnCriteria} />

      <form onSubmit={handleSubmit(onPrint)} noValidate>
        <LoadingButton
          variant="contained"
          color="secondary"
          fullWidth
          startIcon={<Print />}
          loading={submitting}
          disabled={disabled || submitting}
          data-cy="print-button"
          type="submit"
        >
          Mark as Printed
        </LoadingButton>
      </form>
    </>
  );
}

SignedReportActions.propTypes = {
  report: PropTypes.object.isRequired,
  study: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  setError: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
};

export default SignedReportActions;
