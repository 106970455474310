import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// Constants
//---------------------------------------------------------------------------
const FONT_SIZE = 12;
const PADDING = 2;
const COLOR = "rgb(245, 131, 26)";

function TriggerMarker({
  // Props
  width,
  height,
  x,
  label = true,
}) {
  //---------------------------------------------------------------------------
  // Determine the alignment of the trigger marker label depending on where
  // the marker is on the viewer
  //---------------------------------------------------------------------------
  const triggerRef = React.useRef();
  const textAnchor = React.useMemo(() => {
    const triggerLabel = triggerRef.current;
    let anchor = "middle";

    if (triggerLabel) {
      const {width: labelWidth} = triggerLabel.getBBox();

      if (triggerLabel && x < labelWidth / 2) {
        anchor = "start";
      } else if (triggerLabel && width - x < labelWidth / 2) {
        anchor = "end";
      }
    }

    return anchor;
  }, [width, x]);

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  if (!x || x < 0 || x > width) {
    return null;
  }
  return (
    <>
      {label && (
        <text
          ref={triggerRef}
          x={x}
          y={FONT_SIZE + PADDING}
          fill={COLOR}
          fontSize={FONT_SIZE}
          textAnchor={textAnchor}
        >
          Trigger
        </text>
      )}

      {!label && <polygon points={`${x - 6},0 ${x + 6},0 ${x},9`} fill={COLOR} />}

      <line
        x1={x}
        x2={x}
        y1={label ? FONT_SIZE + PADDING * 2 : 0}
        y2={height}
        stroke={COLOR}
        strokeWidth={2}
      />
    </>
  );
}

TriggerMarker.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  label: PropTypes.bool,
};

export default TriggerMarker;
