import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import Alert from "../../shared/react/Alert.jsx";
import TableLoading from "../../shared/react/TableLoading.jsx";
import useArrhythmiaTimelineData from "../hooks/useArrhythmiaTimelineData.jsx";

function ArrhythmiaTimeline({
  // Props
  study,
  startTime,
  endTime,
  data,
}) {
  //---------------------------------------------------------------------------
  // Error handling state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Data state management
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);
  const charts = useArrhythmiaTimelineData({study, data, startTime, endTime, setLoading, setError});

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />

      {loading && <TableLoading />}

      {!loading && charts}
    </>
  );
}

ArrhythmiaTimeline.propTypes = {
  study: PropTypes.object.isRequired,
  data: PropTypes.object,
  startTime: PropTypes.object.isRequired,
  endTime: PropTypes.object.isRequired,
};

export default ArrhythmiaTimeline;
