import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Close from "@mui/icons-material/Close";
import CloudDone from "@mui/icons-material/CloudDone";
import Description from "@mui/icons-material/Description";
import DoDisturbOn from "@mui/icons-material/DoDisturbOn";
import MarkEmailRead from "@mui/icons-material/MarkEmailRead";
import MarkEmailUnread from "@mui/icons-material/MarkEmailUnread";
import OpenInNew from "@mui/icons-material/OpenInNew";
import Print from "@mui/icons-material/Print";
import Task from "@mui/icons-material/Task";

//---------------------------------------------------------------------------
// MUI
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {useTheme} from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import Alert from "../../shared/react/Alert.jsx";
import IconButtonWithTooltip from "../../shared/react/IconButtonWithTooltip.jsx";
import useJwt from "../hooks/useJwt.jsx";
import ReportViewer from "../ReportViewer/ReportViewer.jsx";
import ReportStatusChip from "./ReportStatusChip.jsx";

function ReportDialog({
  // Props
  handleClose,
  report,
  setInfoMessage,
  angularActions,
}) {
  //---------------------------------------------------------------------------
  // Global Variables
  //---------------------------------------------------------------------------
  const {isInAnyRole} = useJwt();

  //---------------------------------------------------------------------------
  // Dialog Title
  //---------------------------------------------------------------------------
  const title = React.useMemo(
    () => `${report.reportType} Report ${report.studyId}-${report.reportNumber}`,
    [report.reportNumber, report.reportType, report.studyId]
  );
  const auditText = React.useMemo(() => {
    let action = "Generated";
    if (report.reportType === "Uploaded") {
      action = "Uploaded";
    } else if (report.lastModifiedByUser?.fullName) {
      action = "Edited";
    }

    const user = report.lastModifiedByUser?.fullName || report.generatedByUser?.fullName;

    return user ? `${action} by ${user}` : "";
  }, [report.reportType, report.lastModifiedByUser, report.generatedByUser]);

  //---------------------------------------------------------------------------
  // Set up hooks for confirmation dialogs and styling
  //---------------------------------------------------------------------------
  const theme = useTheme();

  //---------------------------------------------------------------------------
  // Error and Success alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Report state management
  //---------------------------------------------------------------------------
  const reportStates = React.useMemo(
    () => ({
      generated: {text: "Pending QC", icon: <Description />},
      submitted: {
        text: "Pending Signature",
        icon: isInAnyRole(["physician"]) ? <MarkEmailUnread /> : <MarkEmailRead />,
      },
      published: {text: "Published", icon: <CloudDone />},
      signed: {text: "Signed", icon: <Task />},
      printed: {text: "Printed", icon: <Print />},
      rejectedByTech: {text: "Rejected by Tech", icon: <DoDisturbOn />},
      rejectedByPhysician: {text: "Rejected by Clinic", icon: <DoDisturbOn />},
    }),
    [isInAnyRole]
  );

  const generatedReportUrl = React.useMemo(() => {
    const reportTypeRouteMap = {
      "Single Episode": "single-episode",
      "Daily Trend": "daily-trend",
      Summary: "summary",
      Uploaded: "uploaded",
    };

    return `reports/${reportTypeRouteMap[report.reportType]}/${report.id}`;
  }, [report.id, report.reportType]);

  return (
    <Dialog fullWidth maxWidth="xl" open data-cy="report-viewer-dialog">
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          zIndex: 1,
        }}
      >
        <Box display="inline-flex" alignItems="center" data-cy="dialog-title">
          <Tooltip title={reportStates[report.state]?.text}>
            {React.cloneElement(reportStates[report.state]?.icon, {
              color: report.meetsMdnCriteria ? "error" : "secondary",
            })}
          </Tooltip>

          <Typography variant="inherit" mx={1} data-cy="report-dialog-title">
            {title}
          </Typography>

          <ReportStatusChip status={report.state} width="auto" data-cy="report-status" />
          {auditText && (
            <Typography variant="caption" ml={1} data-cy="audit-message">
              {auditText}
            </Typography>
          )}
        </Box>

        <Box>
          <IconButtonWithTooltip
            title="Open in New Tab"
            otherProps={{
              "data-cy": "open-in-new-tab-button",
              size: "small",
              href: generatedReportUrl,
              target: "_blank",
              sx: {mr: 2},
            }}
          >
            <OpenInNew />
          </IconButtonWithTooltip>

          <IconButtonWithTooltip
            title="Close"
            onClick={handleClose}
            otherProps={{"data-cy": "close-button", size: "small"}}
          >
            <Close />
          </IconButtonWithTooltip>
        </Box>
      </DialogTitle>

      <Alert message={error} setMessage={setError} level="error" otherProps={{sx: {mb: 0}}} />

      <DialogContent
        sx={{p: 0, minHeight: "80vh", maxHeight: "80vh", backgroundColor: theme.palette.background.main}}
      >
        <ReportViewer
          height="80vh"
          handleClose={handleClose}
          report={report}
          setInfoMessage={setInfoMessage}
          setError={setError}
          angularActions={angularActions}
        />
      </DialogContent>
    </Dialog>
  );
}

ReportDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  report: PropTypes.object.isRequired,
  setInfoMessage: PropTypes.func.isRequired,
  angularActions: PropTypes.object.isRequired,
};

export default ReportDialog;
