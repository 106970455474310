import React from "react";
import isEmpty from "lodash/isEmpty";
import {DateTime} from "luxon";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import DeleteForever from "@mui/icons-material/DeleteForever";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import CircularProgress from "@mui/material/CircularProgress";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../../../axiosClient.js";
import DateAndTime from "../../../../components/DateAndTime/DateAndTime.jsx";
import useJwt from "../../../../components/hooks/useJwt.jsx";
import {useStudiesDispatch} from "../../../../contexts/StudiesContext.jsx";
import IconButtonWithTooltip from "../../../../shared/react/IconButtonWithTooltip.jsx";

function ActionsTableRow({
  // Props
  action,
  actions,
  setActions,
  allActions,
  study,
  tableType,
  deviceHasBeenReplaced,
  setParentLoadingState,
  setError,
}) {
  const {isInAnyRole} = useJwt();

  const dispatch = useStudiesDispatch();

  //---------------------------------------------------------------------------
  // Helper Functions
  //---------------------------------------------------------------------------
  const displayedActionName = React.useMemo(() => {
    const actionType = action.subType || action.name;
    const actionNames = {
      requestErrorLogs: "Request Error Logs",
      requestEcgData: "Request ECG Data",
      sendMessage: "Send Message to Patient",
      endStudy: "End Study",
      updateSettings: "Change Device Settings",
      formatDevice: "Check In Device",
      forceCheckIn: "Force Check In Device",
      forceActionsFailure: "Force Actions Failure",
      replaceDevice: "Replace Device on Patient",
      convertMctToCem: "Downgrade to CEM Study",
      convertMctWithFullDisclosureToCem: "Downgrade to CEM Study",
      updateFirmware: "Update Firmware",
      resumeStudy: "Resume Study",
      startStudy: "Start Study",
      studyTransition: "Transition to Follow-Up",
    };

    if (actionType === "updateFirmware" && action.pendingFirmwareVersion) {
      return `Update Firmware to ${action.pendingFirmwareVersion}`;
    }
    return actionNames[actionType];
  }, [action]);

  const hasLaterPendingAction = React.useMemo(() => {
    const actionTypeToCheck = action.subType || action.name;
    return actions.some(({name, subType, createdAt}) => {
      const actionType = subType || name;
      if (actionTypeToCheck === "updateSettings" || actionTypeToCheck.startsWith("convertMct")) {
        return (
          (actionType === "updateSettings" ||
            actionType === "replaceDevice" ||
            actionType.startsWith("convertMct")) &&
          DateTime.fromISO(createdAt) > DateTime.fromISO(action.createdAt)
        );
      }
      return (
        actionType === actionTypeToCheck && DateTime.fromISO(createdAt) > DateTime.fromISO(action.createdAt)
      );
    });
  }, [action, actions]);

  const userFriendlyActionData = React.useMemo(() => {
    switch (action.name) {
      case "requestEcgData":
        try {
          const parsedData = JSON.parse(action.originalData);
          return (
            <>
              <DateAndTime datetime={parsedData.requestedTime} zone={study.timeZone} /> ,{" "}
              {parsedData.preDuration} min pre, {parsedData.postDuration} min post, {parsedData.comment}
            </>
          );
        } catch (e) {
          /* Do Nothing */
        }
        return "";
      case "requestErrorLogs":
      case "endStudy":
        if (action.comment) {
          return action.comment;
        }
        return action.originalData;
      case "sendMessage":
        return action.comment;
      case "updateSettings":
      case "convertMctToCem":
      case "convertMctWithFullDisclosureToCem":
        if (action.comment) {
          return action.comment;
        }
        try {
          // Use study note instead of settings values
          return `${JSON.parse(action.originalData).studyNote}`;
        } catch (e) {
          /* Do Nothing */
        }
        return "";
      case "updateFirmware":
      case "formatDevice":
      case "replaceDevice":
      case "startStudy":
      case "forceActionsFailure":
        if (action.comment) {
          return action.comment;
        }
        return "";
      default:
        return action.comment;
    }
  }, [action.comment, action.name, action.originalData, study.timeZone]);

  const displayDelete = React.useMemo(() => {
    const actionType = action.subType || action.name;
    let displayButton = true;
    if (
      actionType === "formatDevice" ||
      (actionType === "forceCheckIn" && !isInAnyRole(["tzAdmin", "facilityAdmin"])) ||
      (actionType === "updateSettings" &&
        (hasLaterPendingAction || userFriendlyActionData === "Sending Initial Study Settings")) ||
      (actionType.startsWith("convertMct") && hasLaterPendingAction) ||
      (actionType === "forceActionsFailure" && !isInAnyRole(["tzAdmin"])) ||
      (actionType === "updateFirmware" && isInAnyRole(["tech"])) ||
      (actionType === "replaceDevice" && deviceHasBeenReplaced)
    ) {
      displayButton = false;
    }
    return displayButton;
  }, [
    action.subType,
    action.name,
    hasLaterPendingAction,
    userFriendlyActionData,
    isInAnyRole,
    deviceHasBeenReplaced,
  ]);

  //---------------------------------------------------------------------------
  // Remove Action
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);
  const handleRemoveAction = React.useCallback(async () => {
    try {
      setLoading(true);
      const actionType = action.subType || action.name;

      const studyPropertiesToUpdate = {};

      // If removing a settings action, update the study's pending duration to the latest settings
      const actionsWithStudyHours = [
        "updateSettings",
        "replaceDevice",
        "convertMctToCem",
        "convertMctWithFullDisclosureToCem",
        "resumeStudy",
      ];
      if (actionsWithStudyHours.includes(actionType)) {
        const [mostRecentSettingsAction] = allActions
          .filter((a) => a.id !== action.id)
          .filter((a) => actionsWithStudyHours.includes(a.subType || a.name))
          .sort((a, b) => (DateTime.fromISO(a.createdAt) < DateTime.fromISO(b.createdAt) ? 1 : -1));

        if (mostRecentSettingsAction) {
          try {
            const mostRecentStudyHours = JSON.parse(mostRecentSettingsAction.data)?.studyHours;
            studyPropertiesToUpdate.pendingDuration = mostRecentStudyHours;
          } catch (err) {
            // Do nothing
          }
        } else {
          studyPropertiesToUpdate.pendingDuration = study.configuredDuration;
        }
      }

      if (actionType === "convertMctToCem") {
        studyPropertiesToUpdate.studyType = "mct";
      } else if (actionType === "convertMctWithFullDisclosureToCem") {
        studyPropertiesToUpdate.studyType = "mctWithFullDisclosure";
      }

      if (!isEmpty(studyPropertiesToUpdate)) {
        await axios({
          url: `/studies/${study.id}`,
          method: "patch",
          data: studyPropertiesToUpdate,
        });
      }

      let url = `actions/${action.id}`;
      if (action.name === "replaceDevice") {
        url = `studies/replaceDevice/${study.id}`;
      }
      await axios({url, method: "delete"});

      const {
        data: [updatedStudy],
      } = await axios({
        method: "get",
        url: "/studies",
        params: {id: study.id},
      });
      dispatch({type: "updated", updatedElement: updatedStudy});

      // use the setter provided to this component to remove the deleted action from the list
      setActions((prev) => prev.filter(({id}) => id !== action.id));
    } catch (err) {
      setError(err.response.data.title);
    }

    setLoading(false);
    setParentLoadingState("reloading");
  }, [
    setParentLoadingState,
    action.subType,
    action.name,
    action.id,
    study.id,
    study.configuredDuration,
    dispatch,
    setActions,
    allActions,
    setError,
  ]);

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <TableRow data-cy={`action-${action.id}`}>
      <TableCell data-cy={`action-name-${action.id}`}>{displayedActionName}</TableCell>
      <TableCell data-cy={`action-comment-${action.id}`}>{userFriendlyActionData}</TableCell>
      <TableCell>
        <DateAndTime datetime={action.createdAt} zone={study.timeZone} />
      </TableCell>
      <TableCell data-cy={`action-created-by-${action.id}`}>{action.createdBy}</TableCell>
      <TableCell width="10%">
        {!loading && tableType === "pending" && displayDelete && (
          <IconButtonWithTooltip
            title="Delete"
            color="secondary"
            data-cy={`delete-pending-action-button-${action.id}`}
            onClick={handleRemoveAction}
            otherProps={{size: "small", sx: {padding: 0}}}
          >
            <DeleteForever fontSize="small" />
          </IconButtonWithTooltip>
        )}
        {loading && <CircularProgress color="secondary" data-cy="loading-circle" size={18} />}
      </TableCell>
    </TableRow>
  );
}

ActionsTableRow.propTypes = {
  action: PropTypes.object.isRequired,
  actions: PropTypes.array.isRequired,
  setActions: PropTypes.func.isRequired,
  allActions: PropTypes.array,
  study: PropTypes.object.isRequired,
  tableType: PropTypes.string.isRequired,
  deviceHasBeenReplaced: PropTypes.bool,
  setParentLoadingState: PropTypes.func,
  setError: PropTypes.func,
};

export default ActionsTableRow;
