import editReportPug from "../../dialogs/editReport/editReport.pug";

/* @ngInject */
export default class ReportsController {
  constructor($rootScope, $scope, $window, $injector, SearchService) {
    this._$rootScope = $rootScope;
    this._$scope = $scope;
    this._$window = $window;
    this._Search = SearchService;
    this._$mdDialog = $injector.get("$mdDialog");
    this._InboxItemService = $injector.get("InboxItemService");

    // WARNING! Make sure this matches the searchFields in ReportsPage.jsx
    // (Omit values here that are handled by a filter button)
    this._Search.autoFillValues = [
      "study:",
      "study-type:",
      "facility:",
      "number:",
      "type:",
      "findings:",
      "comments:",
      "is:mdn",
      "timestamp:",
      "patient:",
      "gender:",
      "physician:",
      "physician-name:",
      "physician-phone:",
      "physician-facility:",
      "physician-email:",
      "physician-address:",
    ];

    const searchFromStorage = this._$window.localStorage.getItem("search");
    if (searchFromStorage) {
      const currentTime = new Date().getTime();
      const search = JSON.parse(searchFromStorage);

      if (currentTime < search.timeExpired) {
        this._Search.searchText = search.text;
      }
      this._$window.localStorage.removeItem("search");
    }

    const deregister = this._$rootScope.$on("clicked-edit-report", (event, generatedReport, item) => {
      this.clickEditReportButton(generatedReport, item);
    });
    this._$scope.$on("$destroy", deregister);

    this.$onInit = this._init;
  }

  _init() {
    this.actions = {
      editReport: (generatedReport, item) => {
        this._$rootScope.$emit("clicked-edit-report", generatedReport, item);
      },
    };
  }

  async clickEditReportButton(generatedReport, item) {
    let selectedItem;

    try {
      // Obtain a lock on the item
      selectedItem = await this._InboxItemService.selectItem(item, false);
    } catch (err) {
      await this._$mdDialog.show(
        this._$mdDialog
          .alert()
          .title("Failed to load item")
          .htmlContent(
            `<p class="warningMessage"><i class="material-icons dialogErrorIcon"> error </i> ` +
              `${err.message}</p>`
          )
          .ok("Ok")
          .multiple(true)
      );

      return;
    }

    this._$scope.$apply();

    // Open the Edit Report dialog
    const error = await this._$mdDialog
      .show({
        controller: "EditReportController",
        controllerAs: "editReport",
        template: editReportPug(),
        locals: {item, selectedItem, generatedReport},
      })
      .catch((e) => e);

    // If the Edit Report dialog was cancelled, deselect the item
    if (error && error === "Cancelled edits") {
      await this._$mdDialog.hide();
      await this._InboxItemService.deselectItem().catch((e) => e);
    }
  }
}
