import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

function IconButtonWithTooltip({title, color, id, "data-cy": dataCy, onClick, otherProps, children}) {
  return (
    <Tooltip title={title}>
      <span>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <IconButton color={color} id={id} data-cy={dataCy} onClick={onClick} {...otherProps}>
          {children}
        </IconButton>
      </span>
    </Tooltip>
  );
}

IconButtonWithTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  id: PropTypes.string,
  "data-cy": PropTypes.string,
  onClick: PropTypes.func,
  otherProps: PropTypes.object,
  children: PropTypes.node.isRequired,
};

export default IconButtonWithTooltip;
