import React from "react";
import {DateTime, IANAZone} from "luxon";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import CheckInDeviceDialog from "../../dialogs/StudyDialogs/StudyActions/CheckInDeviceDialog.jsx";
import StudyActionsMenu from "../../dialogs/StudyDialogs/StudyActions/StudyActionsMenu/StudyActionsMenu.jsx";
import Alert from "../../shared/react/Alert.jsx";
import CardContentNoPadding from "../../shared/react/CardContentNoPadding.jsx";
import CollapsibleContent from "../../shared/react/CollapsibleContent.jsx";
import DateAndTime from "../DateAndTime/DateAndTime.jsx";
import useDoesNotMeetExtendedHolterRequirements from "../hooks/useDoesNotMeetExtendedHolterRequirements.jsx";
import useJwt from "../hooks/useJwt.jsx";
import useStudyTypeNames from "../hooks/useStudyTypeNames.jsx";
import PatientDiaryTable from "../PatientDiaryTable/PatientDiaryTable.jsx";
import ReportUpload from "../ReportUpload/ReportUpload.jsx";
import ArrhythmiaTimeline from "../study-graphs/ArrhythmiaTimeline.jsx";
import HeartRateTrend from "../study-graphs/HeartRateTrend.jsx";
import PvcBurden from "../study-graphs/PvcBurden.jsx";
import StudyDetails from "./StudyDetails/StudyDetails.jsx";
import StudyStatusChip from "./StudyStatusChip.jsx";

function StudiesRow({
  // Props
  study,
  alwaysOpen,
}) {
  //---------------------------------------------------------------------------
  // Global Variables
  //---------------------------------------------------------------------------
  const displayableStudyTypes = useStudyTypeNames();
  const {isInAnyRole} = useJwt();

  //---------------------------------------------------------------------------
  // Row expansion state management
  //---------------------------------------------------------------------------
  const [expanded, setExpanded] = React.useState(alwaysOpen);
  const handleExpandClick = React.useCallback(() => {
    setExpanded((previous) => !previous || alwaysOpen);
  }, [alwaysOpen]);

  //---------------------------------------------------------------------------
  // Handle clicks to open initial study with pre-filled search
  //---------------------------------------------------------------------------
  const handleOpenInNewTab = React.useCallback(() => {
    window.localStorage.setItem(
      "search",
      JSON.stringify({
        text: `study:${study.initialStudyAssociation?.id}`,
        timeExpired: DateTime.now().plus({seconds: 30}).toMillis(),
      })
    );
  }, [study.initialStudyAssociation?.id]);

  //---------------------------------------------------------------------------
  // Calculate the start and end times for the charts
  //---------------------------------------------------------------------------
  const thirtyDaysAfterStart = React.useMemo(
    () => DateTime.fromISO(study.studyStartDate).plus({days: 30}),
    [study.studyStartDate]
  );

  const timelineStart = React.useMemo(() => {
    let startDate = DateTime.fromISO(study.studyStartDate);

    // We need to use the study's time zone when using `useLocalWeeks`
    if (study.timeZone && IANAZone.isValidZone(study.timeZone)) {
      startDate = startDate.setZone(study.timeZone);
    }
    return startDate.startOf("week", {useLocaleWeeks: true});
  }, [study.studyStartDate, study.timeZone]);

  const timelineEnd = React.useMemo(() => {
    let endDate = study.studyEndDate
      ? DateTime.fromISO(study.studyEndDate)
      : DateTime.min(DateTime.now(), thirtyDaysAfterStart);

    // We need to use the study's time zone when using `useLocalWeeks`
    if (study.timeZone && IANAZone.isValidZone(study.timeZone)) {
      endDate = endDate.setZone(study.timeZone);
    }
    return endDate.endOf("week", {useLocaleWeeks: true});
  }, [study.studyEndDate, study.timeZone, thirtyDaysAfterStart]);

  //---------------------------------------------------------------------------
  // Determine the status of the study
  //---------------------------------------------------------------------------
  const status = React.useMemo(() => {
    // FINALIZED - study has nothing more to do so far as reporting/analyzing goes
    if (study.studyState === "finalized") {
      return "finalized";
    }
    // NOTE: delete this block when removing feature toggle for finalizeStudy (FEATURE_FINALIZE_STUDY)
    if (study.studyState === "completed") {
      return "finalized";
    }

    // ARCHIVED - study has been hidden from Inbox and is all done
    if (study.studyState === "archived") {
      return "archived";
    }

    // FAILED - something went wrong and all items/reports are hidden
    if (study.studyState === "failed") {
      return "failed";
    }

    // PENDING - study has been created, but we have not received any data
    if (!study.dataReceived && study.studyState === "active") {
      return "pending";
    }

    // RECORDING - study is currently running, device is on a patient
    if (!study.studyEndDate && study.studyState === "active") {
      return "recording";
    }

    // DONE RECORDING - study has finished recording
    if (study.studyEndDate && study.studyState === "active") {
      return "done";
    }

    // Catch-all - label as failed if the study is in an unexpected state
    return "failed";
  }, [study.dataReceived, study.studyEndDate, study.studyState]);

  //---------------------------------------------------------------------------
  // Determine study warning messages
  //---------------------------------------------------------------------------
  const doesNotMeetExtendedHolterRequirements = useDoesNotMeetExtendedHolterRequirements(
    study.studyType,
    study.downgradeAuthorized,
    study.configuredDuration,
    study.recordedDuration
  );
  const warningMessages = React.useMemo(() => {
    const messages = [];

    if (
      study.currentEnrollment?.pendingSettingsDownload &&
      !["holter", "extendedHolter"].includes(study.studyType)
    ) {
      messages.push("Study detected with unconfigured settings");
    }

    if (doesNotMeetExtendedHolterRequirements) {
      messages.push(
        "Study duration does not meet criteria to publish or submit reports without downgrade authorization"
      );
    }

    return messages;
  }, [
    doesNotMeetExtendedHolterRequirements,
    study.currentEnrollment?.pendingSettingsDownload,
    study.studyType,
  ]);

  //---------------------------------------------------------------------------
  // Tab state management
  //---------------------------------------------------------------------------
  // If the search results in only 1 item, this will make that item auto-expand
  React.useEffect(() => {
    setExpanded(alwaysOpen);
  }, [alwaysOpen]);

  return (
    <Card square sx={{width: "100%", ...(expanded && {my: 1})}}>
      <CardContentNoPadding spacing={0} data-cy={`${study.id}`}>
        <Grid container sx={{alignItems: "center"}}>
          <Grid size={{xs: 9, sm: 10, md: 11}}>
            <CardActionArea onClick={handleExpandClick}>
              <Grid container columns={15} sx={{alignItems: "center"}}>
                <Grid size={{xs: 5, md: 2}} sx={{px: 2}}>
                  <StudyStatusChip
                    status={status}
                    width={{xs: "100%", sm: "70%"}}
                    data-cy={`status-${study.id}`}
                  />
                </Grid>

                <Grid
                  size={2}
                  sx={{display: {xs: "none", md: "inline-flex"}, alignItems: "center"}}
                  data-cy={`study-id-${study.id}`}
                >
                  <Typography variant="cardRow">{study.id}</Typography>

                  {warningMessages.length > 0 && (
                    <Tooltip
                      title={
                        <>
                          {warningMessages.map((message) => (
                            <div key={message} style={{margin: "0.25rem"}}>
                              {message}
                            </div>
                          ))}
                        </>
                      }
                    >
                      <WarningAmberIcon
                        color="warning"
                        sx={{paddingLeft: "10px"}}
                        data-cy={`study-warning-icon-${study.id}`}
                      />
                    </Tooltip>
                  )}
                </Grid>

                <Grid size={{xs: 10, md: 4}} data-cy={`patient-name-${study.id}`}>
                  <Typography variant="cardRow">
                    {study.studyDetails?.patientName || study.currentEnrollment?.tzSerial || "Unknown"}
                  </Typography>
                </Grid>

                <Grid
                  size={3}
                  data-cy={`study-type-${study.id}`}
                  sx={{display: {xs: "none", md: "inline-flex"}}}
                >
                  <Typography variant="cardRow">{displayableStudyTypes[study.studyType]}</Typography>
                </Grid>

                <Grid
                  size={2}
                  data-cy={`study-start-date-${study.id}`}
                  sx={{display: {xs: "none", md: "inline-flex"}}}
                >
                  <Typography variant="cardRow">
                    {study.dataReceived ? (
                      <DateAndTime datetime={study.studyStartDate} zone={study.timeZone} />
                    ) : (
                      "Pending Data Upload"
                    )}
                  </Typography>
                </Grid>

                <Grid
                  size={2}
                  data-cy={`study-end-date-${study.id}`}
                  sx={{display: {xs: "none", md: "inline-flex"}}}
                >
                  <Typography variant="cardRow">
                    {study.studyEndDate ? (
                      <DateAndTime datetime={study.studyEndDate} zone={study.timeZone} />
                    ) : (
                      ""
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </CardActionArea>
          </Grid>

          <Grid size={{xs: 3, sm: 2, md: 1}} align="end">
            <CheckInDeviceDialog study={study} />

            {study.studyState !== "failed" && <StudyActionsMenu study={study} />}
          </Grid>
        </Grid>
      </CardContentNoPadding>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider variant="middle" width="100%" sx={{mx: 0}} />

        {study.initialStudyAssociation?.id && (
          <Box sx={{pt: 2, px: 2}}>
            <Alert
              level="info"
              message={
                <Typography variant="body2">
                  Follow-up for Study ID{" "}
                  <Link href="/studies" target="_blank" onClick={handleOpenInNewTab}>
                    {study.initialStudyAssociation.id}
                  </Link>
                  .
                </Typography>
              }
              otherProps={{"data-cy": "follow-up-study-alert"}}
            />
          </Box>
        )}

        {!isInAnyRole(["clinicalStaff", "physician"]) && study.studyState !== "failed" && (
          <ReportUpload study={study} />
        )}

        {["holter", "extendedHolter"].includes(study.studyType) && (
          <Box sx={{px: 2, pb: 2}}>
            <PatientDiaryTable study={study} />
          </Box>
        )}

        {study.studyType.startsWith("mct") && (
          <Box sx={{px: 2, pb: 2, pt: 1}}>
            <CollapsibleContent title="Heart Rate Trend" data-cy={`heart-rate-trend-accordion-${study.id}`}>
              <HeartRateTrend study={study} startTime={timelineStart} endTime={timelineEnd} />
            </CollapsibleContent>

            <CollapsibleContent title="PVC Burden" data-cy={`pvc-burden-accordion-${study.id}`}>
              <PvcBurden study={study} startTime={timelineStart} endTime={timelineEnd} />
            </CollapsibleContent>

            <CollapsibleContent
              title="Arrhythmia Timeline"
              data-cy={`arrhythmia-timeline-accordion-${study.id}`}
            >
              <ArrhythmiaTimeline study={study} startTime={timelineStart} endTime={timelineEnd} />
            </CollapsibleContent>
          </Box>
        )}

        <Divider variant="middle" width="100%" sx={{mx: 0}} />

        <StudyDetails study={study} />
      </Collapse>
    </Card>
  );
}
StudiesRow.propTypes = {
  study: PropTypes.object.isRequired,
  alwaysOpen: PropTypes.bool,
};
export default React.memo(StudiesRow);
