import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid2";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import CardContentNoPadding from "../../shared/react/CardContentNoPadding.jsx";
import DateAndTime from "../DateAndTime/DateAndTime.jsx";
import useDoesNotMeetExtendedHolterRequirements from "../hooks/useDoesNotMeetExtendedHolterRequirements.jsx";
import ReportDialog from "./ReportDialog.jsx";
import ReportStatusChip from "./ReportStatusChip.jsx";

function ReportRow({
  // Props
  report,
  setInfoMessage,
  angularActions,
}) {
  //---------------------------------------------------------------------------
  // Handle opening report rows
  //---------------------------------------------------------------------------
  const [openViewer, setOpenViewer] = React.useState(false);

  const handleClickReport = React.useCallback(() => setOpenViewer(true), []);
  const handleCloseReport = React.useCallback(() => setOpenViewer(false), []);

  //---------------------------------------------------------------------------
  // Get displayed values for the report row
  //---------------------------------------------------------------------------
  const type = React.useMemo(() => {
    if (report.reportType === "Single Episode" && report.eventClassification) {
      return `${report.reportType} - ${report.eventClassification}`;
    }
    return report.reportType;
  }, [report.reportType, report.eventClassification]);

  //---------------------------------------------------------------------------
  // Determine report warning messages
  //---------------------------------------------------------------------------
  const doesNotMeetExtendedHolterRequirements = useDoesNotMeetExtendedHolterRequirements(
    report.studyType,
    report.study?.downgradeAuthorized,
    report.study?.configuredDuration,
    report.study?.recordedDuration
  );

  return (
    <>
      <Card square sx={{width: "100%"}}>
        <CardContentNoPadding spacing={0} data-cy={`report-${report.id}`}>
          <CardActionArea onClick={handleClickReport}>
            <Grid container spacing={2} columns={40} sx={{alignItems: "center"}}>
              <Grid size={{xs: 15, sm: 8, md: 5, lg: 4}}>
                <Typography variant="cardRow" sx={{pl: 2}}>
                  <ReportStatusChip status={report.state} data-cy={`status-${report.id}`} />
                </Typography>
              </Grid>

              <Grid size={{xs: 20, sm: 12, md: 7, lg: 5}}>
                <Typography variant="cardRow">
                  {report.studyDetails?.patientName || report?.patientName}
                </Typography>
              </Grid>

              <Grid
                size={{md: 5, lg: 4}}
                data-cy={`study-id-${report.id}`}
                sx={{display: {xs: "none", md: "inline-flex"}, alignItems: "center"}}
              >
                <Typography variant="cardRow">{report.studyId}</Typography>

                {doesNotMeetExtendedHolterRequirements && (
                  <Tooltip title="Study duration does not meet criteria to publish or submit reports without downgrade authorization">
                    <WarningAmberIcon
                      color="warning"
                      sx={{paddingLeft: "10px"}}
                      data-cy={`report-warning-icon-${report.id}`}
                    />
                  </Tooltip>
                )}
              </Grid>

              <Grid
                size={{sm: 2}}
                data-cy={`number-${report.id}`}
                sx={{display: {xs: "none", sm: "inline-flex"}, justifyContent: "end"}}
              >
                <Typography variant="cardRow">{report.reportNumber}</Typography>
              </Grid>

              <Grid
                size={{sm: 15, md: 11, lg: 9}}
                data-cy={`type-${report.id}`}
                sx={{display: {xs: "none", sm: "inline-flex"}}}
              >
                <Typography variant="cardRow">
                  {report.meetsMdnCriteria && (
                    <Chip
                      label="MDN"
                      size="small"
                      color="error"
                      sx={{mr: 1}}
                      data-cy={`mdn-criteria-${report.id}`}
                    />
                  )}
                  {type}
                </Typography>
              </Grid>

              <Grid size={{lg: 9}} sx={{display: {xs: "none", lg: "inline-flex"}}}>
                <Typography variant="cardRow">{report.comment}</Typography>
              </Grid>

              <Grid
                size={{md: 7, lg: 5}}
                data-cy={`timestamp-${report.id}`}
                sx={{display: {xs: "none", md: "inline-flex"}}}
              >
                <Typography variant="cardRow">
                  <DateAndTime datetime={report.timestamp} />
                </Typography>
              </Grid>
            </Grid>
          </CardActionArea>
        </CardContentNoPadding>
      </Card>

      {openViewer && (
        <ReportDialog
          handleClose={handleCloseReport}
          report={report}
          setInfoMessage={setInfoMessage}
          angularActions={angularActions}
        />
      )}
    </>
  );
}

ReportRow.propTypes = {
  report: PropTypes.object.isRequired,
  setInfoMessage: PropTypes.func,
  angularActions: PropTypes.object.isRequired,
};

export default ReportRow;
