import React from "react";
import PropTypes from "prop-types";

import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";

// "Hack" to support starting the week on Monday until support is added to AdapterLuxon here:
// https://github.com/mui/mui-x/pull/10964
// When that PR is merged and released, we should be able to go back to vanilla AdapterLuxon
class CustomLuxonAdapter extends AdapterLuxon {
  startOfWeek = (date) => {
    return date.startOf("week", {useLocaleWeeks: true});
  };

  getWeekArray = (date) => {
    const endDate = date.endOf("month", {useLocaleWeeks: true}).endOf("week", {useLocaleWeeks: true});
    const startDate = date.startOf("month", {useLocaleWeeks: true}).startOf("week", {useLocaleWeeks: true});

    const {days} = endDate.diff(startDate, "days").toObject();

    const weeks = [];
    Array.from(new Array(Math.round(days || 0)).keys())
      .map((dayOffset) => startDate.plus({days: dayOffset}))
      .forEach((day, i) => {
        if (i % 7 === 0) {
          weeks.push([day]);
          return;
        }

        weeks[weeks.length - 1].push(day);
      });

    // a consequence of all this shifting back/forth 1 day is that you might end up with a week
    // where all the days are actually in the previous or next month.
    // this happens when the first day of the month is Sunday (Dec 2019 or Mar 2020 are examples)
    // or the last day of the month is Sunday (May 2020 or Jan 2021 is one example)
    // so we're only including weeks where ANY day is in the correct month to handle that
    return weeks.filter((w) => w.some((d) => d.month === date.month));
  };
}

function CustomLocalizationProvider({children}) {
  return (
    // Set the locale based on the browser language, so date pickers are comfortable to users
    <LocalizationProvider dateAdapter={CustomLuxonAdapter} adapterLocale={navigator.language}>
      {children}
    </LocalizationProvider>
  );
}

CustomLocalizationProvider.propTypes = {
  children: PropTypes.node,
};

export default CustomLocalizationProvider;
