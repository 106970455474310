import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Grid from "@mui/material/Grid2";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import ColumnHeader from "../../shared/react/ColumnHeader.jsx";
import ReportStatusFilter from "./ReportStatusFilter.jsx";

function ReportsHeader({
  // Props
  sort,
  setSort,
  setFilterLoading,
}) {
  //---------------------------------------------------------------------------
  // Render the column headers plus the filter select if needed
  //---------------------------------------------------------------------------
  return (
    <Grid container columns={40} spacing={2} sx={{alignItems: "center", pb: 1}}>
      <Grid size={{xs: 15, sm: 8, md: 5, lg: 4}} sx={{display: "inline-flex", alignItems: "center"}}>
        <ColumnHeader
          id="state"
          display="Status"
          sortField={sort.field}
          reverseSort={sort.reverse}
          onClick={setSort}
        />
        <ReportStatusFilter setFilterLoading={setFilterLoading} />
      </Grid>
      <Grid size={{xs: 20, sm: 12, md: 7, lg: 5}} sx={{display: "inline-flex", alignItems: "center"}}>
        <ColumnHeader
          id="patientName"
          display="Patient"
          sortField={sort.field}
          reverseSort={sort.reverse}
          onClick={setSort}
        />
      </Grid>
      <Grid size={{md: 5, lg: 4}} sx={{display: {xs: "none", md: "inline-flex"}, alignItems: "center"}}>
        <ColumnHeader
          id="studyId"
          display="Study"
          sortField={sort.field}
          reverseSort={sort.reverse}
          onClick={setSort}
        />
      </Grid>
      <Grid size={{sm: 2}} sx={{display: {xs: "none", sm: "inline-flex"}, alignItems: "center"}}>
        <ColumnHeader
          id="reportNumber"
          display="Number"
          sortField={sort.field}
          reverseSort={sort.reverse}
          onClick={setSort}
          justify="left"
        />
      </Grid>
      <Grid
        size={{sm: 15, md: 11, lg: 9}}
        sx={{display: {xs: "none", sm: "inline-flex"}, alignItems: "center"}}
      >
        <ColumnHeader
          id="reportType"
          display="Type"
          sortField={sort.field}
          reverseSort={sort.reverse}
          onClick={setSort}
        />
      </Grid>
      <Grid size={{lg: 9}} sx={{display: {xs: "none", lg: "inline-flex"}, alignItems: "center"}}>
        <ColumnHeader
          id="comment"
          display="Findings"
          sortField={sort.field}
          reverseSort={sort.reverse}
          onClick={setSort}
        />
      </Grid>
      <Grid size={{md: 7, lg: 5}} sx={{display: {xs: "none", md: "inline-flex"}, alignItems: "center"}}>
        <ColumnHeader
          id="timestamp"
          display="Timestamp"
          sortField={sort.field}
          reverseSort={sort.reverse}
          onClick={setSort}
        />
      </Grid>
    </Grid>
  );
}
ReportsHeader.propTypes = {
  sort: PropTypes.object.isRequired,
  setSort: PropTypes.func.isRequired,
  setFilterLoading: PropTypes.func.isRequired,
};
export default React.memo(ReportsHeader);
